import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getCachedObservable } from '@/rxdb/observablesCache';
import { createObservationsReport } from '@/features/observationsReports/observationsReports.service';

function mapAutoControl(item) {
    return item
        ? {
              ...item.toMutableJSON(),
              emissionDueDate: item.emissionDueDate ? new Date(item.emissionDueDate) : null,
              emissionDate: item.emissionDate ? new Date(item.emissionDate) : null,
          }
        : null;
}

export function getAutoControls(projectId) {
    return getCachedObservable('getAutoControls_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .autoControls.find()
            .$.pipe(map((item) => item.map(mapAutoControl))),
    );
}
export function removeAutoControl(projectId, id) {
    return db
        .getProjectCollections(projectId)
        .autoControls.findOne({ selector: { id: id } })
        .remove();
}

export async function createAutoControl(projectId, item) {
    const result = await db.getProjectCollections(projectId).autoControls.insert({
        locationIds: [],
        serviceIds: [],
        ...item,
    });
    const autoControl = await result.toJSON();
    await createObservationsReport(projectId, {
        name: '',
        date: new Date(),
        showEmptyZones: false,
        showTables: true,
        showPhotos: true,
        showPending: true,
        showAdministrative: true,
        showObservationsOnTodoWork: true,
        showObservationsOnDoneWork: true,
        showOtherObservations: true,
        showObservationsWithoutZone: true,
        showCONObservations: true,
        showEXEObservations: true,
        showOPRObservations: true,
        showReceiptObservations: true,
        showOPLObservations: true,
        showDeliveryObservations: true,
        showAPAObservations: true,
        showMaps: true,
        showResolved: true,
        showStats: true,
        generateAPDFByBundle: false,
        showValidated: true,
        bundleIds: [],
        zoneIds: [],
        files: [],
        relatedContentId: autoControl.id,
        relatedContentType: 'autoControl',
    });
    return autoControl;
}

export async function updateAutoControl(projectId, autoControl) {
    const dbAutoControl = await db
        .getProjectCollections(projectId)
        .autoControls.findOne({ selector: { id: autoControl.id } })
        .exec();
    const patch = { ...autoControl };
    if (autoControl.emissionDate === null || !!autoControl.emissionDate) {
        patch.emissionDate = autoControl.emissionDate ? autoControl.emissionDate.toISOString() : null;
    }
    if (autoControl.emissionDueDate === null || !!autoControl.emissionDueDate) {
        patch.emissionDueDate = autoControl.emissionDueDate ? autoControl.emissionDueDate.toISOString() : null;
    }
    return dbAutoControl.atomicPatch(patch);
}

export function getAutoControl(projectId, autoControlId) {
    return db
        .getProjectCollections(projectId)
        .autoControls.findOne({ selector: { id: autoControlId } })
        .$.pipe(map(mapAutoControl));
}

export async function filterLocationIds(projectId, autoControl, filterFn) {
    const dbAutoControl = await db
        .getProjectCollections(projectId)
        .autoControls.findOne({ selector: { id: autoControl.id } })
        .exec();
    return dbAutoControl.atomicPatch({
        locationIds: dbAutoControl.locationIds.filter(filterFn),
    });
}
export async function filterServiceIds(projectId, autoControl, filterFn) {
    const dbAutoControl = await db
        .getProjectCollections(projectId)
        .autoControls.findOne({ selector: { id: autoControl.id } })
        .exec();
    return dbAutoControl.atomicPatch({
        serviceIds: dbAutoControl.serviceIds.filter(filterFn),
    });
}

export async function queryAutoControl(projectId, autoControlId) {
    const dbAutoControl = await db
        .getProjectCollections(projectId)
        .autoControls.findOne({ selector: { id: autoControlId } })
        .exec();
    return mapAutoControl(dbAutoControl);
}
export default {
    filterLocationIds,
    updateAutoControl,
    filterServiceIds,
};
