<template>
    <app-mail-popup ref="popup">
        <div>
            <app-select
                :value="selectedMailTemplateId"
                @change="onSelectedMailTemplateChanged"
                :label="$t('project.diffusion.chooseMailTemplateLabel')"
            >
                <option :value="mailTemplate.id" v-for="mailTemplate in mailTemplates">{{ mailTemplate.name }}</option>
            </app-select>
        </div>
    </app-mail-popup>
</template>

<script>
import AppMailPopup from '@/features/planningExports/AppMailPopup.vue';
import { getMailTemplates } from '@/features/mailTemplates/mailTemplates.service';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { getLastMailTemplateId, saveLastMailTemplateId } from '@/services/mailPopupService';
import sanitizeFileName from 'sanitize-filename';

export default {
    props: {
        visas: { type: Array },
        preparation: { type: Object },
    },
    components: {
        AppSelect,
        AppMailPopup,
    },
    async created() {
        this.subscriptions = [
            getMailTemplates(this.$route.params.projectId).subscribe((mailTemplates) => {
                this.mailTemplates = mailTemplates.filter((mailTemplate) => mailTemplate.type === 'preparationVisas');
            }),
        ];
    },
    methods: {
        replaceTokens(string) {
            if (!string) {
                return string;
            }
            return string
                .replaceAll('NomDocument', this.preparation.name)
                .replaceAll(
                    'TableauDocumentsVises',
                    `<table style="border: black solid 1px; margin: 0.1em;  border-collapse: collapse;">
                      <tr>
                         <th style="border: black solid 1px; padding: 0.1em">Documents visés</th>
                      </tr>
                    ${
                        this.preparation.attachments
                            ? this.preparation.attachments
                                  .map(
                                      (attachment) =>
                                          `<tr><td style="border: black solid 1px; padding: 0.2em"><a
                                   href="https://app.tex.moe${attachment.url}"
                                   download="${attachment.name}"
                                   class="underline">${attachment.name}</a></td></tr>`,
                                  )
                                  .join('')
                            : ''
                    }
                    </table>`,
                )
                .replaceAll(
                    'TableauDocumentsVisas',
                    `<table style="border: black solid 1px; margin: 0.1em;  border-collapse: collapse;">
                      <tr>
                         <th style="border: black solid 1px; padding: 0.1em">Relecteur</th>
                         <th style="border: black solid 1px; padding: 0.1em">Conclusion</th>
                      </tr>
                    ${this.visas
                        .map(
                            (visa) =>
                                `<tr><td style="border: black solid 1px; padding: 0.2em">${
                                    visa.emitter.label
                                }</td><td style="border: black solid 1px; padding: 0.2em;"><div style="display:flex; gap:0.5em"><span>${
                                    this.iconsMap[visa.conclusion] || visa.conclusion
                                }</span><span>${this.$t(
                                    'preparations.conclusionValues.' + visa.conclusion,
                                )}</span></div></td></tr>`,
                        )
                        .join('')}
                    </table>`,
                );
        },
        replaceRecipientTokens(recipientIds = []) {
            return recipientIds.map((bundleId) =>
                bundleId === 'preparation.bundleId'
                    ? this.preparation.bundleId
                    : bundleId === 'visas.emitterId'
                    ? this.visas.map(({ emitterId }) => emitterId)
                    : bundleId,
            );
        },
        onSelectedMailTemplateChanged(mailTemplateId) {
            this.selectedMailTemplateId = mailTemplateId;
            saveLastMailTemplateId(this.cacheKey, mailTemplateId);
            this.applyMailTemplate(this.mailTemplates.find((mailTemplate) => mailTemplate.id === mailTemplateId));
        },
        applyMailTemplate(template) {
            this.$refs.popup.updateMail({
                subject: this.replaceTokens(template.subject),
                body: this.replaceTokens(template.body),
                recipientIds: this.replaceRecipientTokens(template.to),
                copyIds: this.replaceRecipientTokens(template.cc),
                attachments: [
                    ...this.visas
                        .filter((visa) => !!visa.url)
                        .map((visa) => ({
                            fileName: `visa_${sanitizeFileName(this.preparation.code || this.preparation.name)}_${(
                                this.preparation.index + ''
                            ).padStart(2, '0')}_${visa.emitter.reference}.pdf`,
                            url: visa.url,
                        })),
                    ...this.visas
                        .filter((visa) => visa.attachments.length > 0)
                        .reduce(
                            (acc, visa) => [
                                ...acc,
                                ...visa.attachments.map((attachment) => ({
                                    fileName: attachment.fileName,
                                    url: attachment.url,
                                })),
                            ],
                            [],
                        ),
                ],
            });
        },
        open() {
            this.onSelectedMailTemplateChanged(getLastMailTemplateId(this.cacheKey, this.mailTemplates));
            this.$refs.popup.open({});
        },
    },
    data() {
        return {
            cacheKey: 'preparationVisas.lastMailTemplate.' + this.$route.params.projectId,
            mailTemplates: [],
            subscriptions: [],
            selectedMailTemplateId: null,
            iconsMap: {
                approved:
                    '<svg width="24" height="24" viewBox="0 0 24 24" fill="#16a34a"><path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"/></svg>',
                nonCompliant: `<svg width="24" height="24" viewBox="0 0 24 24" fill="#B91C1C"><path d="M8.27,3L3,8.27V15.73L8.27,21H15.73L21,15.73V8.27L15.73,3M8.41,7L12,10.59L15.59,7L17,8.41L13.41,12L17,15.59L15.59,17L12,13.41L8.41,17L7,15.59L10.59,12L7,8.41"/></svg>`,
                rejected: `<svg width="24" height="24" viewBox="0 0 24 24" fill="#B91C1C"><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z"/></svg>`,
                approvedWithComments: `<svg width="24" height="24" viewBox="0 0 24 24" fill="#ffcc66"><path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z"></path></svg>`,
            },
        };
    },
};
</script>
