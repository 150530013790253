import { applyDelay } from '@/services/duration.service';
import { filterMatch, groupBy } from '@/services/sanitize.service';
import { humanizeDate } from '@/filters/dateFilter';
import db from '@/rxdb/database';
import { combineLatest, firstValueFrom, map, mergeMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { getCachedObservable } from '@/rxdb/observablesCache';

function mapAmendment(item /*visas*/) {
    if (item) {
        const plainItem = item.toMutableJSON();
        return {
            ...plainItem,
            emissionDueDate: plainItem.emissionDueDate ? new Date(plainItem.emissionDueDate) : null,
            emissionDate: plainItem.emissionDate ? new Date(plainItem.emissionDate) : null,
            //conclusion: conclusionResult(plainItem, visas),
            //visas: visas.map((visa) => visa.toMutableJSON()),
        };
    } else {
        return null;
    }
}

export async function bulkInsert(projectId, amendments) {
    return db.getProjectCollections(projectId).amendments.bulkInsert(amendments);
}
function mapAmendments(dbAmendments, visas) {
    const amendments = dbAmendments.map((amendment) => {
        return mapAmendment(
            amendment,
            visas.filter((visa) => visa.amendmentId === amendment.id),
        );
    });
    return groupBy(amendments, 'groupId').map((versions) => {
        versions.reverse();
        return {
            ...versions[0],
            versions,
        };
    });
}
function mergeToAmendment(amendmentId, dbAmendments) {
    const versions = dbAmendments
        .map((amendment) => {
            return mapAmendment(amendment, []);
        })
        .reverse();
    const amendment = versions.find((amendment) => amendment.id === amendmentId);
    return {
        ...amendment,
        versions,
    };
}

export function getAmendments(projectId, phase) {
    return getCachedObservable('getAmendments_' + projectId + '_' + phase, () =>
        combineLatest([
            db
                .getProjectCollections(projectId)
                .amendments.find(
                    phase ? { selector: { phase }, sort: [{ createdAt: 'asc' }] } : { sort: [{ createdAt: 'asc' }] },
                ).$,
            db.getProjectCollections(projectId).amendmentVisas.find().$,
        ]).pipe(map(([amendments, visas]) => mapAmendments(amendments, visas))),
    );
}

export function getAllAmendments(projectId) {
    return getCachedObservable('getAllAmendments_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .amendments.find({ sort: [{ createdAt: 'asc' }] })
            .$.pipe(map((amendments) => amendments.map((amendment) => amendment.toMutableJSON()))),
    );
}

export function getAmendment(projectId, amendmentId) {
    return db
        .getProjectCollections(projectId)
        .amendments.findOne({ selector: { id: amendmentId } })
        .$.pipe(
            mergeMap((amendment) =>
                amendment
                    ? combineLatest([
                          firstValueFrom(
                              db.getProjectCollections(projectId).amendments.find({
                                  selector: { groupId: amendment.groupId },
                                  sort: [{ createdAt: 'asc' }],
                              }).$,
                          ),
                      ])
                    : null,
            ),
        )
        .pipe(map(([amendments]) => mergeToAmendment(amendmentId, amendments)));
}
export async function queryAmendment(projectId, amendmentId) {
    const amendment = await db
        .getProjectCollections(projectId)
        .amendments.findOne({ selector: { id: amendmentId } })
        .exec();
    if (amendment) {
        const amendments = await db
            .getProjectCollections(projectId)
            .amendments.find({
                selector: { groupId: amendment.groupId },
                sort: [{ createdAt: 'asc' }],
            })
            .exec();
        return mergeToAmendment(amendmentId, amendments);
    } else {
        return null;
    }
}

export function conclusionResult(amendment, visas) {
    if (!amendment) {
        return;
    } else if (!amendment.emissionDate) {
        return 'waitingForDocument';
    } else if ((visas || amendment.visas).some((visa) => !visa.emissionDate)) {
        return 'incompleteVisa';
    } else if ((visas || amendment.visas).length === 0) {
        return 'noConclusion';
    } else if ((visas || amendment.visas).some((visa) => visa.conclusion === 'nonCompliant')) {
        return 'nonCompliant';
    } else if ((visas || amendment.visas).some((visa) => visa.conclusion === 'rejected')) {
        return 'rejected';
    } else if ((visas || amendment.visas).some((visa) => visa.conclusion === 'approvedWithComments')) {
        return 'approvedWithComments';
    } else {
        return 'approved';
    }
}

export function mapAmendmentStatus(i18nFn, amendment, date, defaultLabel) {
    const isEmitted = !!amendment.emissionDate;
    const isRejected = amendment.visas.find((visa) => visa.conclusion === 'rejected');
    const nonCompliant = amendment.visas.find((visa) => visa.conclusion === 'nonCompliant');
    const isVisaPending = !isRejected && !!amendment.visas.find((visa) => !visa.emissionDate);
    const noVisa = !isRejected && amendment.visas.length === 0;
    const isReserved = !isRejected && amendment.visas.find((visa) => visa.conclusion === 'approvedWithComments');
    const isApproved = !noVisa && !nonCompliant && !isRejected && !isReserved && !isVisaPending;
    const isLate = !isEmitted && amendment.emissionDueDate && amendment.emissionDueDate < date;
    let label = defaultLabel;
    if (amendment.emissionDueDate) {
        label = i18nFn('commons.expectedOn') + ' ' + humanizeDate(amendment.emissionDueDate);
    }
    if (isEmitted && nonCompliant) {
        label = i18nFn('amendments.conclusionValues.nonCompliant');
    } else if (isEmitted && isRejected) {
        label = i18nFn('amendments.conclusionValues.rejected');
    } else if (isEmitted && isVisaPending) {
        label = i18nFn('amendments.conclusionValues.incompleteVisa');
    } else if (isEmitted && isReserved) {
        label = i18nFn('amendments.conclusionValues.approvedWithComments');
    } else if (isEmitted && isApproved) {
        label = i18nFn('amendments.conclusionValues.approved');
    } else if (isEmitted && noVisa) {
        label = i18nFn('commons.emittedOn') + ' ' + humanizeDate(amendment.emissionDate);
    }
    return {
        ...amendment,
        isEmitted,
        isLate,
        label,
    };
}

const pageScore = (comment) => (comment.scope === 'document' ? 0 : comment.page || 0);
const positionScore = (comment) => Math.round(comment.y || 0);
const indexScore = (comment) => comment.index || 0;
function score(comment) {
    return pageScore(comment) * 1000 * 100 + positionScore(comment) * 1000 + indexScore(comment);
}
export function sortComments(comments) {
    return comments.sort((a, b) => {
        return score(a) - score(b);
    });
}

export function getRecommendedEmissionDate(
    amendmentReviewMargin,
    deliveryDuration,
    firstTaskDate,
    visaDuration,
    agenda,
) {
    if ((amendmentReviewMargin || amendmentReviewMargin === 0) && firstTaskDate) {
        const nbDaysToSub = amendmentReviewMargin + (deliveryDuration || 0) + visaDuration;
        return applyDelay(firstTaskDate, -nbDaysToSub, agenda, null);
    }
}

export function removeAmendment(projectId, id) {
    return db
        .getProjectCollections(projectId)
        .amendments.findOne({ selector: { id: id } })
        .remove();
}

export async function createAmendment(projectId, item) {
    const result = await db.getProjectCollections(projectId).amendments.insert({
        projectId,
        locationIds: [],
        groupId: uuidv4(),
        ...item,
        emissionDueDate: item.emissionDueDate ? item.emissionDueDate.toISOString() : null,
        emissionDate: item.emissionDate ? item.emissionDate.toISOString() : null,
    });
    return result.toJSON();
}

export async function updateAmendment(projectId, amendment) {
    const dbAmendment = await db
        .getProjectCollections(projectId)
        .amendments.findOne({ selector: { id: amendment.id } })
        .exec();
    const patch = { ...JSON.parse(JSON.stringify(amendment)) };
    if (amendment.emissionDate === null || !!amendment.emissionDate) {
        patch.emissionDate = amendment.emissionDate ? amendment.emissionDate.toISOString() : null;
    }
    if (amendment.emissionDueDate === null || !!amendment.emissionDueDate) {
        patch.emissionDueDate = amendment.emissionDueDate ? amendment.emissionDueDate.toISOString() : null;
    }
    delete patch.conclusion;
    return dbAmendment.atomicPatch(patch);
}

export async function removeAmendmentGroup(projectId, amendmentGroupId) {
    const entities = await db
        .getProjectCollections(projectId)
        .amendments.find({ selector: { groupId: amendmentGroupId } })
        .exec();
    return Promise.all(entities.map((entity) => entity.remove()));
}
export function getConclusionIds(amendmentsReport) {
    if (!amendmentsReport) return [];
    const conclusionIds = [];
    if (amendmentsReport.showApproved) {
        conclusionIds.push('approved');
    }
    if (amendmentsReport.showApprovedWithComments) {
        conclusionIds.push('approvedWithComments');
    }
    if (amendmentsReport.showRejected) {
        conclusionIds.push('rejected');
    }
    if (amendmentsReport.showToEmit) {
        conclusionIds.push('toEmit');
    }
    if (amendmentsReport.showToVisa) {
        conclusionIds.push('toVisa');
    }
    if (amendmentsReport.showVised) {
        conclusionIds.push('closed');
    }
    return conclusionIds;
}

export function matchMultiFilter(item, stringCriteria, bundleIds, locationIds, conclusionIds) {
    const bundleMatch =
        bundleIds.length === 0 ||
        bundleIds.includes(item.bundleId) ||
        item.visas.find((visa) => bundleIds.includes(visa.emitterId));
    const stringMatch =
        stringCriteria.length === 0 ||
        !!stringCriteria.find((criteria) => filterMatch(item.code + ' ' + item.name, criteria, true));
    const locationMatch =
        locationIds.length === 0 || !!locationIds.find((locationId) => item.locationIds.includes(locationId));
    const conclusionMatch =
        conclusionIds.length === 0 ||
        !!conclusionIds.find(
            (conclusion) =>
                (conclusion === 'toEmit' && !item.emissionDate) ||
                (conclusion === 'toVisa' && item.emissionDate && item.conclusion === 'incompleteVisa') ||
                (conclusion === 'closed' && item.emissionDate && item.conclusion !== 'incompleteVisa') ||
                (conclusion === 'rejected' && item.emissionDate && item.conclusion === 'rejected') ||
                (conclusion === 'approved' && item.emissionDate && item.conclusion === 'approved') ||
                (conclusion === 'approvedWithComments' &&
                    item.emissionDate &&
                    item.conclusion === 'approvedWithComments'),
        );
    return bundleMatch && stringMatch && locationMatch && conclusionMatch;
}

export async function filterLocationIds(projectId, amendment, filterFn) {
    const dbAmendment = await db
        .getProjectCollections(projectId)
        .amendments.findOne({ selector: { id: amendment.id } })
        .exec();
    return dbAmendment.atomicPatch({
        locationIds: dbAmendment.locationIds.filter(filterFn),
    });
}

export default {
    filterLocationIds,
    updateAmendment,
};
