<template>
    <ValidationObserver
        v-slot="{ dirty }"
        tag="div"
        class="p-2"
        @click="$emit('click', observation)"
        :class="!edit && selected ? 'bg-gray-300' : ''"
    >
        <div class="flex justify-between">
            <div class="flex text-xs mb-2 items-center gap-2">
                <div class="flex gap-2" @click="$emit('gotoPage', observation.page)">
                    <span class="text-gray-700 p-1" :class="observation.classes" v-if="!observation.footprint">
                        {{ observation.index + 1 }}
                    </span>
                    <span class="text-gray-700" v-else-if="observation.footprint.type === 'marker'">
                        <app-static-marker size="mini" class="text-xs cursor-pointer" :observation="observation">
                            {{ observation.index + 1 }}
                        </app-static-marker>
                    </span>
                    <span class="text-gray-700" v-else-if="observation.footprint.type === 'rectangle'">
                        <app-static-marker
                            size="mini"
                            class="text-xs cursor-pointer border-2 border-black"
                            :observation="observation"
                        >
                            {{ observation.index + 1 }}
                        </app-static-marker>
                    </span>
                </div>
                <a
                    href="#"
                    @click="$emit('gotoPage', observation.page)"
                    class="text-gray-700"
                    v-if="isMultiPages && observation.page"
                >
                    {{ $t('commons.page') }} {{ observation.page }}
                </a>
            </div>
            <div class="flex">
                <app-button
                    size="mini"
                    @click.stop="$emit('edit', observation)"
                    icon="icon-pencil"
                    aria-label="edit document"
                    class="mx-2"
                    v-if="!edit && !disabled"
                />
            </div>
        </div>
        <div class="flex">
            <div class="flex flex-col w-full" @click.stop>
                <div class="flex items-center my-2 w-full">
                    <div class="flex flex-col w-full" v-if="edit">
                        <observation-status
                            :show-phase="false"
                            :observation="observation"
                            @input="$emit('save', $event)"
                            @remove="$emit('delete', $event)"
                        ></observation-status>
                        <app-text-editor
                            class="h-full w-full"
                            :label="$t('preparations.observation')"
                            :show-label="false"
                            v-model="observation.title"
                            :required="true"
                            :showToolbar="!!edit"
                            :disabled="!edit"
                            end-point="documents?scope=preparationObservationContent"
                            @blur="$emit('save', { id: observation.id, title: observation.title })"
                        ></app-text-editor>
                    </div>
                    <div class="w-full" v-else>
                        <div v-html="observation.title" class="whitespace-pre-wrap w-full html-breakline"></div>
                    </div>
                </div>
                <div class="w-full flex flex-col gap-2">
                    <div class="flex flex-col w-full items-end">
                        <a
                            v-if="observation.attachments.length > 0"
                            href="#"
                            class="text-gray-700 flex gap-1 flex-row text-xs cursor-pointer underline"
                            @click="editAttachment()"
                        >
                            <icon-image-outline width="16" height="16" />
                            {{ $t('preparations.seeCapture') }}
                        </a>
                        <a
                            v-if="synthesis"
                            href="#"
                            @click="$refs.synthesisPopup.open()"
                            class="text-gray-700 underline flex gap-1 items-center"
                        >
                            <icon-rightwards-arrow-with-tail width="1.2em"></icon-rightwards-arrow-with-tail>
                            {{ $t('preparations.hasSyntheses') }}
                        </a>
                    </div>
                    <template v-if="edit">
                        <app-button
                            :label="$t('commons.pickLocation')"
                            icon="icon-map-marker-plus"
                            size="mini"
                            @click="$emit('startPickPosition')"
                            @focus="$emit('save', { id: observation.id, title: observation.title })"
                            v-if="observation.attachments.length === 0 && !observation.footprint"
                        />
                        <app-button
                            :label="$t('commons.removeMarker')"
                            icon="icon-map-marker-off"
                            icon-class="text-red-600 mr-3"
                            size="mini"
                            @click="$emit('removeMarker', observation)"
                            @focus="$emit('save', { id: observation.id, title: observation.title })"
                            v-if="observation.footprint && observation.footprint.type === 'marker'"
                        />
                        <app-button
                            :label="$t('commons.newCapture')"
                            size="mini"
                            @focus="$emit('save', { id: observation.id, title: observation.title })"
                            @click="$emit('startCapture')"
                            icon="icon-full-screen"
                            v-if="observation.attachments.length === 0"
                        />

                        <app-button
                            :label="$t('commons.replaceCapture')"
                            size="mini"
                            @focus="$emit('save', { id: observation.id, title: observation.title })"
                            @click="onReplaceCapture(observation.attachments[0])"
                            icon="icon-full-screen"
                            v-if="observation.attachments.length > 0"
                        />

                        <app-button
                            :label="$t('preparations.addToSyntheses')"
                            size="mini"
                            @focus="$emit('save', { id: observation.id, title: observation.title })"
                            icon="icon-rightwards-arrow-with-tail"
                            @click="createSynthesis"
                            :disabled="disabled"
                            v-if="!synthesis"
                        />

                        <app-button
                            variant="primary"
                            size="mini"
                            :label="$t('commons.close')"
                            @click="$emit('close', observation)"
                        />
                    </template>
                </div>
            </div>
        </div>
        <app-popup ref="popup" :showHeader="true" :title="$t('photo.annotate')" popup-extra-class="left-50">
            <app-photo-editor
                v-if="observation.attachments[0]"
                :attachment="observation.attachments[0]"
                :read-only="disabled"
            ></app-photo-editor>
        </app-popup>
        <app-popup
            ref="synthesisPopup"
            :showHeader="true"
            :title="$t('preparations.synthesisTitle')"
            popup-extra-class="left-50"
        >
            <app-synthesis-observation :observation-id="synthesis.id" v-if="synthesis" @deleted="onDeleteSynthesis" />
        </app-popup>
        <app-save-on-leave :dirty="dirty" :saveFn="() => $emit('save', observation)"></app-save-on-leave>
    </ValidationObserver>
</template>

<script>
import AppButton from '../../components/appButton/AppButton';
import AppTextEditor from '../../components/appTextEditor/AppTextEditor';
import IconShapeOutline from '../../icons/IconShapeOutline';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import AppPopup from '@/components/app-popup/AppPopup';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import IconCameraOutline from '@/icons/IconCameraOutline';
import AppPhotoEditor from '@/components/appPhotoEditor/AppPhotoEditor';
import { confirm } from '@/features/dialogs/dialogs.service';
import { removeObservationAttachment } from '@/features/observations/observationAttachments.service';
import {
    createObservation,
    getObservationsSyntheses,
    updateObservation,
} from '@/features/observations/observation.service';
import AppStaticMarker from '@/components/appStaticMarker/AppStaticMarker';
import ObservationStatus from '@/features/observations/ObservationStatus.vue';
import ObservationStatusButtons from '@/features/observations/ObservationStatusButtons.vue';
import AppSynthesisObservation from '@/features/preparations/AppSynthesisObservation.vue';

export default {
    components: {
        AppSynthesisObservation,
        ObservationStatusButtons,
        ObservationStatus,
        AppStaticMarker,
        AppPhotoEditor,
        IconCameraOutline,
        AppSaveOnLeave,
        AppLeafletViewer,
        AppPopup,
        AppFileLink,
        AppUploadButton,
        IconShapeOutline,
        AppButton,
        AppTextEditor,
    },
    props: {
        observation: { type: Object },
        edit: Boolean,
        isMultiPages: Boolean,
        selected: Boolean,
        disabled: { type: Boolean, default: false },
    },
    data() {
        return { synthesis: null };
    },
    watch: {
        async edit(value) {
            if (!value) return;
            this.synthesis = await getObservationsSyntheses(this.$route.params.projectId, this.observation.id);
            this.focus();
        },
    },
    created() {
        this.focus();
    },
    async mounted() {
        if (this.observation) {
            this.synthesis = await getObservationsSyntheses(this.$route.params.projectId, this.observation.id);
        }
    },
    methods: {
        onDeleteSynthesis() {
            this.synthesis = null;
            this.$refs.synthesisPopup.close();
        },
        focus() {
            this.$nextTick(() => {
                const element = document.querySelector('[contenteditable]');
                if (element) {
                    element.focus();
                }
            });
        },
        async onReplaceCapture() {
            if (await confirm(this.$t('preparations.replaceObservationCapture'))) {
                this.$emit('startCapture');
                await removeObservationAttachment(this.$route.params.projectId, this.observation.attachments[0].id);
                await updateObservation(this.$route.params.projectId, { id: this.observation.id, footprint: null });
            }
        },
        editAttachment() {
            this.$refs.popup.open();
        },
        async createSynthesis() {
            this.synthesis = await createObservation(this.$route.params.projectId, {
                title: this.observation.title,
                relatedObservationIds: [this.observation.id],
                relatedPreparationIds: [this.observation.relatedContentId],
                reportedBy: this.observation.reportedBy,
                type: 'synthesis',
                recipientIds: this.observation.recipientIds,
            });
            this.$refs.synthesisPopup.open();
        },
    },
};
</script>
