import defaultReplication from '@/rxdb/defaultReplication';
import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';

export const meetings = {
    schema: {
        title: 'meetings schema',
        version: 12,
        description: 'describes a meetings',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: {
                type: 'string',
                final: true,
                maxLength: 40,
            },
            projectId: { type: ['string', 'null'], format: 'uuid' },
            name: { type: ['string', 'null'] },
            type: { type: ['string', 'null'] },
            date: { type: ['string', 'null'], format: 'date-time' },
            location: { type: ['string', 'null'] },
            index: { type: ['number', 'null'] },
            coverPhotoUrl: { type: ['string', 'null'] },
            showCoverPageWithPhoto: { type: ['boolean', 'null'] },
            showParticipantsIndex: { type: ['boolean', 'null'] },
            showGeneralProgress: { type: ['boolean', 'null'] },
            showTasksTable: { type: ['boolean', 'null'] },
            showPlanning: { type: ['boolean', 'null'] },
            showGeneralObservations: { type: ['boolean', 'null'] },
            planningStartDate: { type: ['string', 'null'], format: 'date-time' },
            planningEndDate: { type: ['string', 'null'], format: 'date-time' },
            planningStructure: { type: ['string', 'null'] },
            planningScale: { type: ['string', 'null'] },
            planningShowProgressLine: { type: ['boolean', 'null'] },
            nextMeetingId: { type: ['string', 'null'], format: 'uuid' },
            url: { type: ['string', 'null'] },
            fileName: { type: ['string', 'null'] },
            bundleDataStartDate: { type: ['string', 'null'], format: 'date-time' },
            bundleDataEndDate: { type: ['string', 'null'], format: 'date-time' },
            bundleTasksStartDate: { type: ['string', 'null'], format: 'date-time' },
            bundleTasksEndDate: { type: ['string', 'null'], format: 'date-time' },
            showEmptyBundlePage: { type: ['boolean', 'null'] },
            showEmptySectionBundlePage: { type: ['boolean', 'null'] },
            showPreparations: { type: ['boolean', 'null'] },
            showPreparationVisas: { type: ['boolean', 'null'] },
            showTasksSectionBundlePage: { type: ['boolean', 'null'] },
            showCONObservations: { type: ['boolean', 'null'] },
            showEXEObservations: { type: ['boolean', 'null'] },
            showOPRObservations: { type: ['boolean', 'null'] },
            showReceiptObservations: { type: ['boolean', 'null'] },
            showOPLObservations: { type: ['boolean', 'null'] },
            showDeliveryObservations: { type: ['boolean', 'null'] },
            showAPAObservations: { type: ['boolean', 'null'] },
            showConceptionVisas: { type: ['boolean', 'null'] },
            showConceptions: { type: ['boolean', 'null'] },
            showCertificates: { type: ['boolean', 'null'] },
            showReceptions: { type: ['boolean', 'null'] },
            showAutoControls: { type: ['boolean', 'null'] },
            generationStartDate: { type: ['string', 'null'], format: 'date-time' },
            generationEndDate: { type: ['string', 'null'], format: 'date-time' },
            generationSucceeded: { type: ['boolean', 'null'] },
            recipientIds: { type: 'array', items: { type: ['string', 'null'] } },
            emitterIds: { type: ['array', 'null'], items: { type: ['string', 'null'] } },
            isLast: { type: ['boolean', 'null'] },
            attachments: {
                type: 'array',
                items: { type: 'object' },
                properties: {
                    url: { type: ['string'] },
                    name: { type: ['string'] },
                    legend: { type: ['string'] },
                },
            },
            convocations: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        convocationDate: { type: ['string', 'null'], format: 'date-time' },
                        attendance: { type: ['string', 'null'] },
                        bundleId: { type: ['string', 'null'], format: 'uuid' },
                    },
                },
            },
            createdAt: { type: ['string', 'null'], format: 'date-time' },
            updatedAt: { type: ['string', 'null'], format: 'date-time' },
            deletedAt: { type: ['string', 'null'], format: 'date-time' },
            createdBy: { type: ['string', 'null'], format: 'uuid' },
            updatedBy: { type: ['string', 'null'], format: 'uuid' },
            deletedBy: { type: ['string', 'null'], format: 'uuid' },
            rootVersion: { type: ['string', 'null'], format: 'date-time' },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId, {
                cacheURLsFn: (documents) =>
                    documents.filter((document) => document.url && document.isLast).map((document) => document.url),
            });
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {
        1: (doc) => doc,
        2: (doc) => doc,
        3: (doc) => {
            return {
                ...doc,
                showEXEObservations: true,
                showOPRObservations: false,
                showReceiptObservations: false,
                showOPLObservations: false,
                showDeliveryObservations: false,
                showAPAObservations: false,
            };
        },
        4: (doc) => doc,
        5: (doc) => doc,
        6: (doc) => doc,
        7: (doc) => doc,
        8: (doc) => doc,
        9: (doc) => doc,
        10: (doc) => doc,
        11: (doc) => doc,
        12: (doc) => {
            return {
                ...doc,
                showCONObservations: false,
            };
        },
    },
};
