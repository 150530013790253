<template>
    <main class="max-w-5xl m-5 mb-10 w-full p-1">
        <ReadOnlyAmendment v-if="!isAdmin" :is-emitter="isEmitter" />
        <AmendmentForm ref="form" v-else />
        <AmendmentAttachments
            ref="document"
            :is-admin="isAdmin"
            :allow-new-attachment="isLastVersion"
            :me="project ? project.me : null"
        />
        <div>
            <app-button
                v-if="
                    isAdmin &&
                    amendment.versions &&
                    amendment.versions.length > 1 &&
                    amendment.versions[amendment.versions.length - 1].id !== amendment.id
                "
                size="mini"
                icon="icon-trash-can-outline"
                variant="danger"
                @click="removeAmendment()"
                :label="$t('commons.removeThisVersion')"
                class="m-2"
            />
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import AmendmentForm from './AmendmentForm';
import AmendmentVisas from './AmendmentVisas';
import ReadOnlyAmendment from './ReadOnlyAmendment';
import { getAmendment, removeAmendment } from './amendments.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import { getProject } from '@/features/projects/projects.service';
import AmendmentAttachments from '@/features/amendments/AmendmentAttachments';
import AppButton from '@/components/appButton/AppButton.vue';
import { combineLatest } from 'rxjs';

export default {
    components: {
        AppButton,
        AmendmentAttachments,
        AmendmentForm,
        AmendmentVisas,
        AppQuickActions,
        ReadOnlyAmendment,
    },

    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getProject(this.$route.params.projectId),
                    getAmendment(this.$route.params.projectId, this.$route.params.amendmentId),
                ]).subscribe(([project, amendment]) => {
                    this.project = project;
                    this.amendment = amendment;
                    this.isLastVersion =
                        amendment.versions.findIndex((version) => version.id === this.$route.params.amendmentId) === 0;

                    this.isAdmin = project.me.allowedFeatures.includes('project_amendments');
                    this.isEmitter = project.me.bundleIds.includes(amendment.bundleId);
                    updateBreadCrumbs({
                        amendmentName: amendment.name,
                    });
                }),
            ];
        },
        async removeAmendment() {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                removeAmendment(this.$route.params.projectId, this.$route.params.amendmentId);
                if (this.amendment.versions.length > 1) {
                    this.$router.push({
                        name: this.$route.name,
                        params: {
                            projectId: this.$route.params.projectId,
                            amendmentId: this.amendment.versions[1].id,
                        },
                    });
                } else {
                    this.$router.push({
                        name: this.$route.meta.parentName,
                        params: {
                            projectId: this.$route.params.projectId,
                        },
                    });
                }
            }
        },
    },
    data() {
        return {
            subscriptions: [],
            amendment: { versions: [] },
            project: null,
            isAdmin: false,
            isEmitter: false,
            isLastVersion: false,
        };
    },
    computed: {
        quickActions() {
            if (!this.isAdmin) {
                return [];
            }
            const defaultOptions = [
                {
                    name: this.$t('amendments.newAmendment'),
                    run: () => this.$refs.form.createNew(),
                },
                {
                    name: this.$t('amendments.newDocument'),
                    run: () => this.$refs.document.newDocument(),
                },
                {
                    name: this.$t('commons.newVersion'),
                    run: () => this.$refs.form.onNewVersion(),
                },
            ];
            if (
                this.amendment.versions &&
                this.amendment.versions.length > 0 &&
                this.amendment.versions[0].id === this.amendment.id
            ) {
                defaultOptions.push({
                    name:
                        this.amendment.versions.length === 1
                            ? this.$t('commons.removeThisGroup')
                            : this.$t('commons.removeThisVersion'),
                    run: async () => {
                        this.removeAmendment();
                    },
                });
            }
            return defaultOptions;
        },
    },
};
</script>
