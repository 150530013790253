<template>
    <main class="max-w-5xl m-5 h-full w-full mb-20">
        <ReadOnlyCompany v-if="!isAdmin && !isMember"></ReadOnlyCompany>
        <template v-else>
            <AppLegend />
            <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
                <form>
                    <app-fieldset :label="$t('commons.infos')" class="mt-4">
                        <div class="col-span-2 md:col-span-1 grid grid-cols-2 gap-4">
                            <app-input-text
                                :label="$t('commons.name')"
                                :required="true"
                                v-model="company.name"
                                class="col-span-2"
                                @input="onNameInput"
                                autocomplete="off"
                                @blur="save"
                                :disabled="!isAdmin"
                            />
                            <div
                                class="col-span-2 border bg-green-50 p-2 text-sm flex items-center"
                                v-if="companyProposal.length > 0"
                            >
                                <span v-if="companyProposal.length === 1">{{ $t('companies.matchFound') }}</span>
                                <span v-if="companyProposal.length > 1">{{ $t('companies.matchesFound') }}</span>
                                <span class="flex-grow" />
                                <app-drop-down-button
                                    class="bg-transparent"
                                    @input="onProposalChoose"
                                    :placeholder="$t('companies.importData')"
                                >
                                    <template v-for="(proposal, index) in companyProposal">
                                        <option :value="index">{{ proposal.name }} {{ proposal.city }}</option>
                                    </template>
                                </app-drop-down-button>
                            </div>

                            <app-siret-input
                                v-model="company.siret"
                                class="col-span-2"
                                @input="checkDuplicateInProject"
                            />
                            <div
                                class="col-span-2 border bg-yellow-100 p-2 text-sm flex flex-col"
                                v-if="duplicateCompanies.length > 0"
                            >
                                <span>{{ $t('companies.duplicateFound') }}</span>
                                <ul class="ml-2">
                                    <template v-for="duplicateCompany in duplicateCompanies">
                                        <li>
                                            -
                                            <router-link
                                                class="underline"
                                                :to="{
                                                    name: 'company',
                                                    params: {
                                                        projectId: $route.params.projectId,
                                                        companyId: duplicateCompany.id,
                                                    },
                                                }"
                                            >
                                                {{ duplicateCompany.name }} {{ duplicateCompany.siret }}
                                            </router-link>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                        <div
                            class="relative col-span-2 row-start-1 md:row-start-auto md:col-span-1 min-200 flex flex-col m-auto"
                        >
                            <img
                                ref="image"
                                class="h-40 object-contain"
                                :class="{ 'p-4 bg-gray-200 text-gray-700': !company.logoUrl }"
                                :src="company.logoUrl"
                                :alt="$t('companies.companyLogo')"
                            />
                            <app-upload-button
                                variant="link"
                                class="col-span-2 justify-center text-sm"
                                :label="$t('companies.imageUrlLabel')"
                                @select="changeImage"
                                :resize="300"
                                :name="$t('companies.imageUrlLabel')"
                            ></app-upload-button>
                        </div>
                    </app-fieldset>
                    <app-fieldset icon="icon-map-marker" :label="$t('commons.coordinate')" class="mt-4">
                        <app-input-text
                            :label="$t('commons.address1')"
                            v-model="company.address1"
                            class="col-span-2"
                            @blur="save"
                        >
                            <template v-slot:tip>
                                <span class="text-xs text-gray-700 italic">{{ $t('commons.address1Example') }}</span>
                            </template>
                        </app-input-text>

                        <app-input-text
                            :label="$t('commons.address2')"
                            v-model="company.address2"
                            class="col-span-2"
                            @blur="save"
                        >
                            <template v-slot:tip>
                                <span class="text-xs text-gray-700 italic">{{ $t('commons.address2Example') }}</span>
                            </template>
                        </app-input-text>

                        <app-input-text
                            :label="$t('commons.address3')"
                            v-model="company.address3"
                            class="col-span-2"
                            @blur="save"
                        >
                            <template v-slot:tip>
                                <span class="text-xs text-gray-700 italic">{{ $t('commons.address3Example') }}</span>
                            </template>
                        </app-input-text>

                        <app-input-text
                            rules="length:5"
                            :label="$t('commons.postalCode')"
                            v-model="company.postalCode"
                            class="col-span-2 md:col-span-1"
                            pattern="[A-Ba-b0-9]{5}"
                            size="5"
                            @blur="savePostalCode"
                        />

                        <app-input-text
                            rules="min:2"
                            :label="$t('commons.city')"
                            v-model="company.city"
                            class="col-span-2 md:col-span-1"
                            @blur="save"
                        />

                        <app-phone-input v-model="company.phone" class="col-span-2" @blur="save" />
                        <app-input-text
                            :label="$t('users.email')"
                            type="email"
                            v-model="company.email"
                            @blur="save"
                        ></app-input-text>
                    </app-fieldset>

                    <app-footer @click="save()" :disabled="invalid" />
                </form>
                <app-save-on-leave :dirty="dirty" :saveFn="save"></app-save-on-leave>
            </ValidationObserver>

            <app-separator icon="icon-account-group" class="mt-4 mb-2" :label="$t('users.users')" />
            <div class="p-2">
                <app-button @click="addContact()" :label="$t('companies.newUser')" />
                <bundle-contacts
                    :contacts="company.contacts"
                    :company="company"
                    :project="project"
                    :show-responsible="false"
                    @save="saveContact($event)"
                    @remove="removeContact($event)"
                    ref="contacts"
                ></bundle-contacts>
            </div>
            <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        </template>
    </main>
</template>

<script>
import { debounce, getMapById, omit, sortBy } from '@/services/sanitize.service';
import AppPhone from '../../components/appPhone/AppPhone';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppSeparator from '../../components/appSeparator/AppSeparator';
import AppButton from '../../components/appButton/AppButton';
import AppPhoneInput from '../../components/appPhoneInput/AppPhoneInput';
import AppSiretInput from '../../components/appSiretInput/AppSiretInput';
import AppFooter from '../../components/appFooter/AppFooter';
import AppLegend from '../../components/appLegend/AppLegend';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import { getCompanies, getCompany, getKnownCompanies, updateCompany } from './companies.service';
import { updateBreadCrumbs } from '@/state/state';
import AppSelect from '../../components/appSelect/AppSelect';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppOpenSelect from '@/components/appOpenSelect/AppOpenSelect';
import AppBackButton from '@/components/appBackButton/AppBackButton';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton';
import ReadOnlyCompany from './ReadOnlyCompany';
import BundleContacts from '@/features/bundles/BundleContacts';
import { createContact, getContacts, removeContact, updateContact } from '@/features/contacts/contacts.service';
import { combineLatest } from 'rxjs';
import { getProject } from '@/features/projects/projects.service';
import { getBundleMap } from '@/features/bundles/bundles.service';

export default {
    components: {
        BundleContacts,
        AppUploadButton,
        AppDropDownButton,
        AppBackButton,
        AppOpenSelect,
        AppSaveOnLeave,
        AppSelect,
        AppQuickActions,
        AppFooter,
        AppSiretInput,
        AppPhoneInput,
        AppButton,
        AppSeparator,
        AppFieldset,
        AppInputText,
        AppPhone,
        AppLegend,
        ReadOnlyCompany,
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getCompany(this.$route.params.projectId, this.$route.params.companyId),
                getCompanies(this.$route.params.projectId),
                getContacts(this.$route.params.projectId),
                getBundleMap(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
            ]).subscribe(([company, companies, contacts, bundleMap, project]) => {
                this.company = {
                    ...company,
                    contacts: sortBy(
                        contacts.filter((contact) => contact.companyId === this.$route.params.companyId),
                        (contact) => contact.lastName,
                    ),
                };
                this.project = project;
                this.isAdmin = project.me.allowedFeatures.includes('project_bundles');
                const companyMap = getMapById(companies);
                this.isMember = !project.me.bundleIds
                    .map((bundleId) => companyMap[bundleMap[bundleId].companyId])
                    .filter((a) => !!a)
                    .includes(this.$route.params.companyId);
                this.projectCompanies = companies;
                return updateBreadCrumbs({
                    companyName: this.company.name,
                });
            }),
        ];
    },
    methods: {
        changeImage: function ({ url }) {
            this.company.logoUrl = url;
            this.save();
        },
        async onProposalChoose(proposalIndex) {
            const proposal = this.companyProposal[proposalIndex];
            this.company.name = proposal.name;
            this.company.siret = proposal.siret;
            this.company.city = proposal.city;
            this.company.postalCode = proposal.postalCode;
            this.company.address1 = proposal.address1;
            this.company.address2 = proposal.address2;
            this.company.address3 = proposal.address3;
            this.company.phone = proposal.phone;
            this.company.email = proposal.email;

            await Promise.all(
                proposal.contacts.map((contact) => {
                    if (!this.company.contacts.find((aContact) => aContact.email === contact.email)) {
                        createContact(this.$route.params.projectId, {
                            companyId: this.company.id,
                            firstName: contact.firstName,
                            lastName: contact.lastName,
                            email: contact.email,
                            phone: contact.phone,
                            role: contact.role,
                        });
                    }
                }),
            );
            await this.save();
            this.companyProposal = [];
        },
        onNameInput: debounce(async function (name) {
            if (name.length >= 3) {
                this.checkDuplicateInProject();
                getKnownCompanies(name).then((companies) => {
                    this.companyProposal = sortBy(
                        companies.filter(
                            (company) =>
                                !this.projectCompanies.find(
                                    (aCompany) =>
                                        (aCompany.name && aCompany.name === company.name) ||
                                        (aCompany.siret === company.siret && aCompany.siret),
                                ),
                        ),
                        'name',
                    );
                });
            }
        }, 250),
        checkDuplicateInProject() {
            this.duplicateCompanies = this.projectCompanies.filter(
                (projectCompany) =>
                    projectCompany.id !== this.company.id &&
                    ((projectCompany.name &&
                        this.company.name &&
                        projectCompany.name.toLowerCase().includes(this.company.name.toLowerCase())) ||
                        (projectCompany.siret &&
                            this.company.siret &&
                            projectCompany.siret.includes(this.company.siret))),
            );
        },
        addContact() {
            this.$refs.contacts.editContact({});
        },
        saveContact: async function (contact) {
            if (!contact.id) {
                await createContact(this.$route.params.projectId, {
                    companyId: this.company.id,
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    email: contact.email,
                    phone: contact.phone,
                    role: contact.role,
                });
            } else {
                await updateContact(this.$route.params.projectId, {
                    id: contact.id,
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    email: contact.email,
                    phone: contact.phone,
                    role: contact.role,
                });
            }
        },
        removeContact: function (contact) {
            return removeContact(this.$route.params.projectId, contact.id);
        },
        save: async function () {
            const company = omit(this.company, ['contacts']);
            await updateCompany(this.$route.params.projectId, company);
            if (this.$refs.observer) {
                this.$refs.observer.reset();
            }
        },
        savePostalCode: function () {
            this.company.postalCode = this.company.postalCode ? this.company.postalCode.replaceAll(' ', '') : '';
            this.save();
        },
    },
    data() {
        return {
            isAdmin: false,
            isMember: false,
            project: {},
            companyProposal: [],
            subscriptions: [],
            projectCompanies: [],
            duplicateCompanies: [],
            company: { contacts: [], siret: '' },
            quickActions: [
                {
                    name: this.$t('companies.newUser'),
                    run: () => {
                        this.addContact();
                    },
                },
                {
                    name: this.$t('companies.newCompany'),
                    run: () => this.$router.push({ name: 'newCompany' }),
                },
            ],
        };
    },
};
</script>
