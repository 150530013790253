<template>
    <main class="p-2 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button @click="addAmendment()" :label="$t('amendments.newAmendment')" v-if="!readOnly" />
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.fullName || option.name }}</span>
                    <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="modify">{{ $t('commons.actionModify') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row sm:">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="done"
                        :label="$t('project.follow.follow.done') + ' (' + doneCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="coming"
                        :label="$t('project.follow.follow.coming') + ' (' + comingCount + ')'"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="hidden sm:table-cell text-left border-r p-1" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('object')"
                            >
                                {{ $t('amendments.object') }}
                                <div v-if="sortKey === 'object'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1" style="width: 15rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emitter')"
                            >
                                {{ $t('commons.bundle') }}
                                <div v-if="sortKey === 'emitter'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 3rem" class="hidden sm:table-cell text-center border-r p-1">
                            <div>
                                {{ $t('commons.type') }}
                            </div>
                        </th>
                        <th style="width: 3rem" class="hidden sm:table-cell text-center border-r p-1">
                            <div>
                                {{ $t('commons.location') }}
                            </div>
                        </th>
                        <th style="width: 3rem" class="hidden sm:table-cell text-center border-r p-1">
                            <div :title="$t('commons.version')">
                                {{ $t('commons.versionShort') }}
                            </div>
                        </th>
                        <th class="text-center border-r p-1" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emissionDueDate')"
                            >
                                {{ $t('amendments.emissionDueDateShort') }}
                                <div v-if="sortKey === 'emissionDueDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emissionDate')"
                            >
                                {{ $t('amendments.emissionDateShort') }}
                                <div v-if="sortKey === 'emissionDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1" style="width: 6rem">
                            {{ $t('amendments.amountHT') }}
                        </th>
                        <th style="width: 3rem" class="hidden sm:table-cell text-center border-r p-1">
                            <div>
                                {{ $t('amendments.quoteStatusTitle') }}
                            </div>
                        </th>
                        <th style="width: 3rem" class="hidden sm:table-cell text-center border-r p-1">
                            <div>
                                {{ $t('amendments.amendmentStatus') }}
                            </div>
                        </th>
                        <th style="width: 3rem" class="hidden sm:table-cell text-center border-r p-1">
                            <div :title="$t('amendments.SOStatus')">
                                {{ $t('amendments.SOStatus') }}
                            </div>
                        </th>
                        <th class="hidden sm:table-cell text-center border-r p-1" style="width: 6rem">
                            <div :title="$t('amendments.validatedSumHT')">
                                {{ $t('amendments.validatedSumHTShort') }}
                            </div>
                        </th>
                        <th class="hidden sm:table-cell text-center border-r p-1" style="width: 6rem">
                            <div :title="$t('amendments.validatedSumTTC')">
                                {{ $t('amendments.validatedSumTTCShort') }}
                            </div>
                        </th>
                        <th class="hidden sm:table-cell text-center border-r p-1" style="width: 6rem">
                            <div :title="$t('amendments.sumHT')">
                                {{ $t('amendments.sumHTShort') }}
                            </div>
                        </th>
                        <th class="hidden sm:table-cell text-center border-r p-1" style="width: 6rem">
                            <div :title="$t('amendments.sumTTC')">
                                {{ $t('amendments.sumTTCShort') }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="amendment in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="hidden sm:table-cell border-r p-1" v-if="!readOnly">
                                <a :id="'uuid_' + amendment.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="amendment.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(amendment)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                <router-link
                                    class="hover:underline"
                                    :to="{
                                        name: 'followAmendment',
                                        params: {
                                            ...$route.params,
                                            amendmentId: amendment.id,
                                        },
                                    }"
                                >
                                    <span v-if="amendment.code" class="mr-1">#{{ amendment.code }}</span>
                                    <span class="font-bold">{{ amendment.object }}</span>
                                    <span v-if="!amendment.object || amendment.object.trim().length === 0">...</span>
                                </router-link>
                            </td>
                            <td class="text-left border-r p-1">
                                <div
                                    :title="
                                        amendment.bundle && amendment.bundle.company
                                            ? amendment.bundle.company.name
                                            : ''
                                    "
                                >
                                    {{ amendment.bundle ? amendment.bundle.label : '' }}
                                </div>
                            </td>
                            <td class="text-left border-r p-1">
                                {{ $t(amendment.type) ? $t('amendments.types.' + amendment.type) : '' }}
                            </td>
                            <td class="text-left border-r p-1">
                                <div v-if="amendment.locations.length <= 2">
                                    {{ amendment.locations.map((location) => location.fullName).join(',') }}
                                </div>
                                <div
                                    v-else
                                    :title="amendment.locations.map((location) => location.fullName).join('\n')"
                                >
                                    {{ amendment.locations.length }}&nbsp;{{ $t('commons.locations') }}
                                </div>
                            </td>
                            <td class="hidden sm:table-cell text-center border-r p-1">
                                <router-link
                                    class="hover:underline"
                                    :to="{
                                        name: 'followAmendment',
                                        params: {
                                            ...$route.params,
                                            amendmentId: amendment.id,
                                        },
                                    }"
                                >
                                    {{ amendment.index }}
                                </router-link>
                            </td>
                            <td
                                class="text-center border-r p-1"
                                :class="{
                                    'text-red-600 font-bold':
                                        !amendment.emissionDate && amendment.emissionDueDate < new Date(),
                                }"
                            >
                                <app-date-link
                                    @enter="focusToNextDueDate(amendment)"
                                    :ref="'dueDate_' + amendment.id"
                                    :disabled="readOnly"
                                    :label="$t('amendments.emissionDueDateShort')"
                                    :show-label="false"
                                    v-model="amendment.emissionDueDate"
                                    @input="updateDueDate(amendment, $event)"
                                ></app-date-link>
                            </td>
                            <td class="hidden sm:table-cell text-center border-r p-1">
                                <app-date-link
                                    @enter="focusToNextDate(amendment)"
                                    :ref="'date_' + amendment.id"
                                    :disabled="readOnly"
                                    :label="$t('amendments.emissionDateShort')"
                                    :show-label="false"
                                    v-model="amendment.emissionDate"
                                    @input="updateDate(amendment, $event)"
                                ></app-date-link>
                            </td>
                            <td class="text-left border-r p-1">
                                <app-number-link
                                    v-model="amendment.amountHT"
                                    format="double"
                                    @input="saveAmount(amendment, $event)"
                                >
                                    <u>{{ $n(amendment.amountHT, currencyFormat) }}</u>
                                </app-number-link>
                            </td>
                            <td class="text-left border-r p-1">
                                {{ amendment.quoteStatus ? $t('amendments.quoteStatus.' + amendment.quoteStatus) : '' }}
                            </td>
                            <td class="text-left border-r p-1">
                                {{
                                    amendment.amendmentStatus
                                        ? $t('amendments.status.' + amendment.amendmentStatus)
                                        : ''
                                }}
                            </td>
                            <td class="text-left border-r p-1">
                                {{ amendment.SOStatus ? $t('amendments.status.' + amendment.SOStatus) : '' }}
                            </td>
                            <td class="text-right border-r p-1">
                                {{ $n(amendment.totalHT, currencyFormat) }}
                            </td>
                            <td class="text-right border-r p-1">
                                {{ $n(amendment.totalHT * 1.2, currencyFormat) }}
                            </td>
                            <td class="text-right border-r p-1">
                                {{ $n(amendment.validatedTotalHT, currencyFormat) }}
                            </td>
                            <td class="text-right border-r p-1">
                                {{ $n(amendment.validatedTotalHT * 1.2, currencyFormat) }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        <app-popup ref="modifyAllPopup" :showHeader="true" :title="$t('commons.actionOnSelection')">
            <ValidationObserver v-slot="{ invalid }" tag="form" ref="observer" class="p-2 gap-2 flex flex-col">
                <app-bundle-picker v-model="editedAmendment.bundle" :options="bundles"></app-bundle-picker>
                <app-date-input
                    v-model="editedAmendment.emissionDueDate"
                    :label="$t('amendments.emissionDueDate')"
                ></app-date-input>
                <app-date-input v-model="editedAmendment.emissionDate" :label="$t('amendments.emissionDate')" />
                <app-footer @click="saveMultiple" :disabled="invalid" class="mt-2"></app-footer>
            </ValidationObserver>
        </app-popup>
    </main>
</template>

<script>
import AppCheckbox from '../../components/app-checkbox/AppCheckbox';
import { isMobile } from '@/state/state';
import { sortBy, filterMatch, get, uniqBy, getMapById } from '@/services/sanitize.service';
import AppList from '../../components/appList/AppList';
import locationService from '../../services/location.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import AppButton from '@/components/appButton/AppButton';
import { createAmendment, removeAmendmentGroup, updateAmendment } from '@/features/amendments/amendments.service';
import AppPreparationVisaStatus from '@/components/appPreparationVisaStatus/AppPreparationVisaStatus';
import AppDateLink from '@/components/appDateLink/AppDateLink';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppBundlePicker from '@/components/appBundlePicker';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppFooter from '@/components/appFooter/AppFooter';
import AppInputText from '@/components/appInputText/AppInputText';
import AppCancel from '@/components/appCancel/AppCancel';
import IconMenuDown from '@/icons/IconMenuDown';
import IconMenuUp from '@/icons/IconMenuUp';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import { queryProject } from '@/features/projects/projects.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getAmendments } from '@/features/amendments/amendments.service';
import { getCompanies } from '@/features/companies/companies.service';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppTextarea from '@/components/app-textarea/AppTextarea.vue';

export default {
    components: {
        AppTextarea,
        AppTips,
        AppNumberLink,
        AppNumberInput,
        IconMenuUp,
        IconMenuDown,
        AppCancel,
        AppInputText,
        AppFooter,
        AppDateInput,
        AppBundlePicker,
        AppPopup,
        AppDateLink,
        AppPreparationVisaStatus,
        AppButton,
        AppQuickActions,
        AppMultiPicker,
        AppSelect,
        AppList,
        AppCheckbox,
    },
    async created() {
        this.restoreFilter();
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_amendments');
        });
        this.init();
    },
    computed: {
        filteredItems() {
            let result = uniqBy(this.filterFn(this.filterValue), 'groupId').map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'object') {
                        return item.code ? item.code + ' ' + item.object : item.object;
                    } else if (this.sortKey === 'emissionDueDate') {
                        return item.emissionDueDate;
                    } else if (this.sortKey === 'emitter') {
                        return item.bundle ? item.bundle.label : null;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.status'),
                    id: this.$t('commons.status'),
                    children: [
                        {
                            id: 'quote_toEmit',
                            name: this.$t('amendments.quoteToEmit'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_toAnalyse',
                            name: this.$t('amendments.quoteToAnalyse'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_toNegotiate',
                            name: this.$t('amendments.quoteToNegotiate'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_toValidateMOA',
                            name: this.$t('amendments.quoteToValidateMOA'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_validated',
                            name: this.$t('amendments.quoteValidated'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_rejected',
                            name: this.$t('amendments.quoteRejected'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_obsolete',
                            name: this.$t('amendments.quoteObsolete'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_isMarket',
                            name: this.$t('amendments.quoteIsMarket'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_isMarketOption',
                            name: this.$t('amendments.quoteIsMarketOption'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'quote_noImpact',
                            name: this.$t('amendments.quoteNoImpact'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'amendment_toEmit',
                            name: this.$t('amendments.amendmentToEmit'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'amendment_toSignByBundle',
                            name: this.$t('amendments.amendmentToSignByBundle'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'amendment_toSignByMOE',
                            name: this.$t('amendments.amendmentToSignByMOE'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'amendment_toSignByMOA',
                            name: this.$t('amendments.amendmentToSignByMOA'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'amendment_signed',
                            name: this.$t('amendments.amendmentSigned'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'SO_toEmit',
                            name: this.$t('amendments.SOToEmit'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'SO_toSignByBundle',
                            name: this.$t('amendments.SOToSignByBundle'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'SO_toSignByMOE',
                            name: this.$t('amendments.SOToSignByMOE'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'SO_toSignByMOA',
                            name: this.$t('amendments.SOToSignByMOA'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                        {
                            id: 'SO_signed',
                            name: this.$t('amendments.SOSigned'),
                            firstOfCriteriaType: false,
                            _isStateCriteria: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.type'),
                    id: this.$t('commons.type'),
                    children: [
                        {
                            id: 'tma',
                            name: this.$t('amendments.types.tma'),
                            firstOfCriteriaType: true,
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'choice',
                            name: this.$t('amendments.types.choice'),
                            firstOfCriteriaType: false,
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'conceptionError',
                            name: this.$t('amendments.types.conceptionError'),
                            firstOfCriteriaType: false,
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'executionError',
                            name: this.$t('amendments.types.executionError'),
                            firstOfCriteriaType: false,
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'unexpected',
                            name: this.$t('amendments.types.unexpected'),
                            firstOfCriteriaType: false,
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'cie',
                            name: this.$t('amendments.types.cie'),
                            firstOfCriteriaType: false,
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'marketOption',
                            name: this.$t('amendments.types.marketOption'),
                            firstOfCriteriaType: false,
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'marketUpdate',
                            name: this.$t('amendments.types.marketUpdate'),
                            firstOfCriteriaType: false,
                            _isTypeCriteria: true,
                        },
                        {
                            id: 'other',
                            name: this.$t('amendments.types.other'),
                            firstOfCriteriaType: false,
                            _isTypeCriteria: true,
                        },
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.emitterCriteriaType'),
                    id: this.$t('commons.emitterCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.label,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.emitterCriteriaType'),
                        _isBundleCriteria: true,
                    })),
                },
                {
                    isGroup: true,
                    name: this.$t('commons.locationCriteriaType'),
                    id: this.$t('commons.locationCriteriaType'),
                    children: this.locationOptions
                        .filter((location) => location.type === 'folder' || location.type === 'location')
                        .map((location, index) => ({
                            ...location,
                            name: location.fullName,
                            firstOfCriteriaType: index === 0,
                            criteriaType: this.$t('commons.locationCriteriaType'),
                            _isLocationCriteria: true,
                        })),
                },
            ];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getBundles(this.$route.params.projectId),
                    getAmendments(this.$route.params.projectId, this.phase),
                    getCompanies(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId),
                ]).subscribe(([bundles, amendments, companies, folders]) => {
                    this.locationOptions = locationService.buildLocationOptions(folders);
                    this.companies = companies.map((company) => ({
                        ...company,
                        bundleIds: bundles
                            .filter((bundle) => bundle.companyId === company.id)
                            .map((bundle) => bundle.id),
                    }));
                    const companyMap = getMapById(companies);
                    this.bundles = sortBy(
                        bundles.map((bundle) => ({ ...bundle, company: companyMap[bundle.companyId] })),
                        (bundle) => bundle.label,
                    );
                    this.bundleMap = getMapById(this.bundles);
                    const locationMap = locationService.getLocationMap(folders);
                    this.restoreSelection();
                    const marketAmounts = this.bundles.reduce(
                        (acc, bundle) => ({ ...acc, [bundle.id]: bundle.marketAmount }),
                        {},
                    );
                    const PENDING_QUOTE_STATUS = ['toNegotiate', 'toValidateMOA', 'validated'];
                    const totalsByBundleMap = amendments
                        .filter((amendment) => PENDING_QUOTE_STATUS.includes(amendment.quoteStatus))
                        .reduce(
                            (acc, amendment) => {
                                acc[amendment.bundleId] = (acc[amendment.bundleId] || 0) + amendment.amountHT;
                                return acc;
                            },
                            { ...marketAmounts },
                        );
                    const validatedTotalsByBundleMap = amendments
                        .filter((amendment) => amendment.amendmentStatus === 'signed')
                        .reduce(
                            (acc, amendment) => {
                                acc[amendment.bundleId] = (acc[amendment.bundleId] || 0) + amendment.amountHT;
                                return acc;
                            },
                            { ...marketAmounts },
                        );

                    this.items = amendments.map((amendment) => {
                        const bundle = this.bundleMap[amendment.bundleId];
                        return {
                            ...amendment,
                            locations: amendment.locationIds
                                .map((locationId) => locationMap[locationId])
                                .filter((a) => !!a),
                            lastVisaByEmitterIdMap: amendment.visas.reduce(
                                (acc, visa) => ({ ...acc, [visa.emitterId]: visa }),
                                {},
                            ),
                            index: amendment.versions.length,
                            versions: amendment.versions.map((version) => ({
                                ...version,
                                bundle: this.bundleMap[version.bundleId],
                                lastVisaByEmitterIdMap: version.visas.reduce(
                                    (acc, visa) => ({ ...acc, [visa.emitterId]: visa }),
                                    {},
                                ),
                            })),
                            bundle,
                            filterString: [
                                amendment.code,
                                amendment.object,
                                amendment.bundleId && bundle ? bundle.label : '',
                            ].join(),
                            isSelected: this.selection.includes(amendment.id),
                            totalHT: totalsByBundleMap[bundle.id],
                            validatedTotalHT: validatedTotalsByBundleMap[bundle.id],
                        };
                    });
                    this.doneCount = this.items.filter((item) => this.isDone(item)).length;
                    this.comingCount = this.items.filter((item) => !this.isDone(item)).length;
                    this.cleanupSavedSelection();
                    this.scrollToLastVisited();
                    this.loading = false;
                }),
            ];
        },
        scrollToLastVisited() {
            const lastVisitedAmendmentId = localStorage.getItem(
                'amendment.lastVisitedAmendmentId.' + this.$route.params.projectId,
            );
            if (lastVisitedAmendmentId) {
                setTimeout(() => {
                    const element = this.$el.querySelector('#uuid_' + lastVisitedAmendmentId);
                    if (element) element.scrollIntoView();
                }, 800);
            }
        },
        get,
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveFilter(this.filterValue);
        },
        saveMultiple() {
            this.selection.map((itemId) => {
                const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
                const patch = {};
                if (this.editedAmendment.emissionDueDate) {
                    patch.emissionDueDate = this.editedAmendment.emissionDueDate;
                } else if (this.getSelectionCommonEmissionDueDate(selectedItems)) {
                    patch.emissionDueDate = null;
                }
                if (this.editedAmendment.emissionDate) {
                    patch.emissionDate = this.editedAmendment.emissionDate;
                } else if (this.getSelectionCommonEmissionDate(selectedItems)) {
                    patch.emissionDate = null;
                }
                return updateAmendment(this.$route.params.projectId, { id: itemId, ...patch });
            });
            this.$refs.modifyAllPopup.close();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeAmendmentGroup(this.$route.params.projectId, item.groupId)),
                    );
                    this.selection = [];
                }
            } else if (action === 'modify') {
                this.editedAmendment = {
                    emissionDueDate: this.getSelectionCommonEmissionDueDate(selectedItems),
                    emissionDate: this.getSelectionCommonEmissionDate(selectedItems),
                    bundle: this.getSelectionCommonBundle(selectedItems),
                    locations: [],
                };
                this.$refs.modifyAllPopup.open();
            }
        },
        getSelectionCommonBundle(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.bundle);
            if (firstItem && selectedItems.every((item) => item.bundle && item.bundle.id === firstItem.bundle.id)) {
                return firstItem.bundle;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) => item.emissionDate && item.emissionDate.getTime() === firstItem.emissionDate.getTime(),
                )
            ) {
                return firstItem.emissionDate;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDueDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDueDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) =>
                        item.emissionDueDate && item.emissionDueDate.getTime() === firstItem.emissionDueDate.getTime(),
                )
            ) {
                return firstItem.emissionDueDate;
            } else {
                return null;
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem(
                'amendments.selection.' + this.$route.params.projectId,
                JSON.stringify(this.selection),
            );
        },
        restoreSelection() {
            const cache = localStorage.getItem('amendments.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addAmendment() {
            const result = await createAmendment(this.$route.params.projectId, {
                object: '',
            });
            await this.$router.push({
                name: 'followAmendment',
                params: {
                    projectId: this.$route.params.projectId,
                    amendmentId: result.id,
                },
            });
        },
        isDone(item) {
            return (
                item.emissionDate &&
                item.visas.every(
                    (visa) =>
                        visa.emissionDate &&
                        (visa.conclusion === 'approved' || visa.conclusion === 'approvedWithComments'),
                )
            );
        },
        matchBundleFilter(item, bundleIds) {
            return (
                (item.bundle && bundleIds.includes(item.bundle.id)) ||
                item.visas.find((visa) => visa.emitter && bundleIds.includes(visa.emitter.id))
            );
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString || item.name, criteria, true));
        },
        matchState(stateCriteria, item) {
            if (!stateCriteria || stateCriteria.length === 0) {
                return true;
            }
            return stateCriteria.find(
                (criteria) =>
                    (item.amendmentStatus && criteria.id === 'amendment_' + item.amendmentStatus) ||
                    (item.quoteStatus && criteria.id === 'quote_' + item.quoteStatus) ||
                    (item.SOStatus && criteria.id === 'SO_' + item.SOStatus),
            );
        },
        matchType(criteria, item) {
            if (!criteria || criteria.length === 0) {
                return true;
            }
            return criteria.find((criteria) => item.type === criteria.id);
        },
        matchStatusFilter(item) {
            const isDone = this.isDone(item);
            return (!isDone && this.coming) || (isDone && this.done);
        },
        matchVisaEmitterFilter(item, bundleIds) {
            return item.visas.find(({ emitterId }) => bundleIds.includes(emitterId));
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'amendments_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterValue,
                    done: this.done,
                    coming: this.coming,
                    sortKey: this.sortKey,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('amendments_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
                this.done = !!parsedCache.done;
                this.coming = !!parsedCache.coming;
                this.sortKey = parsedCache.sortKey || 'object';
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const bundleIds = [
                ...filter.filter((aCriteria) => aCriteria._isBundleCriteria).map((bundle) => bundle.id),
                ...filter
                    .filter((aCriteria) => aCriteria._isCompanyCriteria)
                    .reduce((acc, company) => [...acc, ...(company.bundleIds || [])], []),
            ];
            const visaEmitterIds = filter
                .filter((aCriteria) => aCriteria._isVisaEmitterCriteria)
                .map((aCriteria) => aCriteria.id.substring('visaEmitter_'.length));

            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const locationCriteria = filter.filter((aCriteria) => aCriteria._isLocationCriteria);
            const stateCriteria = filter.filter((aCriteria) => aCriteria._isStateCriteria);
            const typeCriteria = filter.filter((aCriteria) => aCriteria._isTypeCriteria);

            return this.items.filter((item) => {
                const fullCriteria = {
                    matchBundleFilter: bundleIds.length === 0 || this.matchBundleFilter(item, bundleIds),
                    matchVisaEmitterFilter:
                        visaEmitterIds.length === 0 || this.matchVisaEmitterFilter(item, visaEmitterIds),
                    matchStatusFilter: this.matchStatusFilter(item),
                    matchLocationCriteria: locationService.matchLocationCriteria(locationCriteria, item.locations),
                    matchString: this.matchString(stringCriteria, item),
                    matchState: this.matchState(stateCriteria, item),
                    typeCriteria: this.matchType(typeCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        updateDueDate(amendment, date) {
            updateAmendment(this.$route.params.projectId, { emissionDueDate: date, id: amendment.id });
        },
        updateDate(amendment, date) {
            if (amendment.emissionDueDate) {
                updateAmendment(this.$route.params.projectId, {
                    emissionDate: date,
                    id: amendment.id,
                });
            } else {
                updateAmendment(this.$route.params.projectId, {
                    id: amendment.id,
                    emissionDueDate: date,
                    emissionDate: date,
                });
            }
        },
        saveAmount(amendment, amountHT) {
            updateAmendment(this.$route.params.projectId, {
                id: amendment.id,
                amountHT,
            });
        },
        focusToNextDate(amendment) {
            let index = this.filteredItems.findIndex((aAmendment) => aAmendment.id === amendment.id);
            let nextAmendment = this.filteredItems[index + 1];
            while (!nextAmendment && index < this.filteredItems.length) {
                nextAmendment = this.filteredItems[++index];
            }
            if (nextAmendment) {
                this.$emit('select', nextAmendment);
                const element = this.$refs['date_' + nextAmendment.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
        focusToNextDueDate(amendment) {
            let index = this.filteredItems.findIndex((aAmendment) => aAmendment.id === amendment.id);
            let nextAmendment = this.filteredItems[index + 1];
            while (!nextAmendment && index < this.filteredItems.length) {
                nextAmendment = this.filteredItems[++index];
            }
            if (nextAmendment) {
                this.$emit('select', nextAmendment);
                const element = this.$refs['dueDate_' + nextAmendment.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
    },
    data() {
        return {
            currencyFormat: {
                currency: 'EUR',
                currencyDisplay: 'symbol',
                style: 'currency',
                minimumFractionDigits: 2,
            },
            bundleMap: {},
            loading: 0,
            doneCount: 0,
            comingCount: 0,
            quickActions: [
                {
                    name: this.$t('amendments.newAmendment'),
                    run: () => this.addAmendment(),
                },
            ],
            sortKey: 'name',
            sortAsc: true,
            readOnly: true,
            selection: [],
            editedAmendment: {
                emissionDueDate: null,
                emissionDate: null,
                bundle: null,
                locations: [],
            },
            subscriptions: [],
            bundles: [],
            companies: [],
            isMobile,
            items: [],
            done: false,
            coming: true,
            filterValue: [],
            locationOptions: [],
        };
    },
};
</script>
