<template>
    <div style="page-break-before: always">
        <app-separator tag="h1" :label="$t('project.editions.meetingReports.generalProgress')"></app-separator>
        <div
            class="mx-16 mt-5 grid gap-2 items-center"
            :style="{
                'max-height': '900px',
                height: '900px',
                'grid-template-columns': meeting.attachments.length <= 3 ? '1fr' : '1fr 1fr',
                'grid-template-rows':
                    meeting.attachments.length <= 1
                        ? '1fr'
                        : meeting.attachments.length === 2
                        ? '1fr 1fr'
                        : meeting.attachments.length === 3
                        ? '1fr 1fr 1fr'
                        : 'repeat(' + (Math.floor(meeting.attachments.length / 2) + 1) + ', 1fr)',
            }"
        >
            <template v-for="(attachment, index) in meeting.attachments">
                <div
                    class="flex w-full h-full flex-col justify-center items-center"
                    :class="{
                        'col-span-2':
                            meeting.attachments.length > 3 &&
                            meeting.attachments.length % 2 === 1 &&
                            index === meeting.attachments.length - 1,
                    }"
                >
                    <div
                        class="w-full h-full bg-contain bg-no-repeat bg-center"
                        :style="'background-image: url(' + attachment.url + ')'"
                    ></div>
                    <div v-if="attachment.legend && attachment.legend.length > 0">{{ attachment.legend }}</div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { getMeeting } from './meetings.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppPhone from '@/components/appPhone/AppPhone';
import AppMail from '@/components/appMail/AppMail';

export default {
    components: { AppMail, AppPhone, AppSeparator },
    async created() {
        this.subscriptions = [
            getMeeting(this.$route.params.projectId, this.$route.params.meetingId).subscribe((meeting) => {
                this.meeting = meeting;
            }),
        ];
    },
    data() {
        return { subscriptions: [], meeting: { attachments: [] } };
    },
};
</script>
