<template>
    <div class="w-full m-4 text-xs">
        <app-report-header
            :document-name="
                $route.query.phase === 'con'
                    ? $t('project.editions.preparationList.documentNameConception')
                    : $t('project.editions.preparationList.documentName')
            "
            :documentTitle="preparationsReport.name"
        ></app-report-header>
        <app-separator :label="$t('project.editions.preparationList.content')" />
        <div class="m-4 col-span-2 flex flex-col items-start text-sm">
            <app-checkbox
                class="col-span-2"
                v-model="preparationsReport.showToEmit"
                :label="$t('project.editions.preparationsReports.showToEmitShort')"
            />
            <app-checkbox
                class="col-span-2"
                v-model="preparationsReport.showToVisa"
                :label="$t('project.editions.preparationsReports.showToVisaShort')"
            />
            <app-checkbox
                class="col-span-2"
                v-model="preparationsReport.showApproved"
                :label="$t('project.editions.preparationsReports.showApprovedShort')"
            />
            <app-checkbox
                class="col-span-2"
                v-model="preparationsReport.showApprovedWithComments"
                :label="$t('project.editions.preparationsReports.showApprovedWithCommentsShort')"
            />
            <app-checkbox
                class="col-span-2"
                v-model="preparationsReport.showRejected"
                :label="$t('project.editions.preparationsReports.showRejectedShort')"
            />
            <app-checkbox
                class="col-span-2"
                v-model="preparationsReport.showVised"
                :label="$t('project.editions.preparationsReports.showVisedShort')"
            />
            <app-checkbox
                class="col-span-2"
                v-model="preparationsReport.showAllVersions"
                :label="$t('project.editions.preparationsReports.showAllVersions')"
            />
        </div>
        <app-separator class="mt-4" variant="underline" :label="$t('commons.bundles')" />
        <div class="ml-2 mt-2 text-sm" v-if="preparationsReport.bundles.length > 0" style="column-count: 2">
            <template v-for="bundle in preparationsReport.bundles">
                <a :href="'#uuid_' + bundle.id"><app-bundle :bundle="bundle" /></a>
            </template>
        </div>
        <div v-else class="ml-2 mt-2">
            <app-tips>{{ $t('commons.all') }}</app-tips>
        </div>
        <app-separator class="mt-4" variant="underline" :label="$t('commons.locations')" />
        <div class="ml-2 mt-2 text-sm flex flex-col" v-if="preparationsReport.locations.length > 0">
            <template v-for="location in preparationsReport.locations">
                <span>{{ location.fullName }}</span>
            </template>
        </div>
        <div v-else class="ml-2 mt-2">
            <app-tips>{{ $t('commons.allFemale') }}</app-tips>
        </div>
        <preparation-visa-legend class="ml-2 text-sm" :show-document-to-emit="true" />
        <div style="page-break-after: always"></div>
        <template v-for="bundle in mapByBundles">
            <template v-if="bundle.preparations.length > 0 || bundle.visas.length > 0">
                <app-separator variant="underline" class="border-b-2 border-secondary my-4 flex items-baseline gap-2">
                    {{ $t('commons.bundle') }} {{ bundle.label }}
                    <span class="text-xs" v-if="bundle.company">{{ bundle.company.name }}</span>
                    <a :id="'#uuid_' + bundle.id" />
                </app-separator>
                <preparation-list
                    :bundle="bundle"
                    :preparations="bundle.preparations"
                    :agenda="agenda"
                    :showAllVersions="preparationsReport.showAllVersions"
                    v-if="bundle.preparations.length > 0"
                    :bundleMap="bundleMap"
                />
                <preparation-visa-list
                    :bundle="bundle"
                    :visas="bundle.visas"
                    :agenda="agenda"
                    v-if="bundle.visas.length > 0"
                />
            </template>
        </template>
    </div>
</template>

<script>
import AppReportHeader from '@/components/appReportHeader/AppReportHeader';
import PreparationList from '@/features/preparations/PreparationList';
import { getPreparations, getConclusionIds, matchMultiFilter } from '@/features/preparations/preparations.service';
import { getPreparationsReport } from '@/features/preparationsReports/preparationsReports.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import PreparationVisaLegend from '@/features/preparations/PreparationVisaLegend';
import AppBundle from '@/components/app-bundle/appBundle';
import AppTips from '@/components/app-tips/AppTips';
import PreparationVisaList from '@/features/preparations/PreparationVisaList';
import { getMapById, sortBy, uniqBy } from '@/services/sanitize.service';
import { differenceInWorkingDays } from '@/services/duration.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { combineLatest } from 'rxjs';
import { getCompanies } from '@/features/companies/companies.service';
import locationService from '@/services/location.service';
import { getLocationsTree } from '@/features/locations/locations.service';
export default {
    components: {
        PreparationVisaList,
        AppTips,
        AppBundle,
        PreparationVisaLegend,
        AppCheckbox,
        AppSeparator,
        PreparationList,
        AppReportHeader,
    },
    created() {
        this.subscriptions = [
            getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
            getPreparations(this.$route.params.projectId, this.$route.query.phase).subscribe((preparations) => {
                this.preparations = preparations;
            }),
            combineLatest([
                getPreparationsReport(this.$route.params.projectId, this.$route.params.preparationsReportId),
                getBundles(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
            ]).subscribe(([preparationsReport, bundles, companies, folders]) => {
                const locationMap = getMapById(locationService.buildLocationOptions(folders));
                const companyMap = getMapById(companies);
                this.bundles = bundles.map((bundle) => ({ ...bundle, company: companyMap[bundle.companyId] }));
                this.bundleMap = getMapById(bundles);
                this.preparationsReport = {
                    ...preparationsReport,
                    bundles: sortBy(
                        preparationsReport.bundleIds.map((bundleId) => this.bundleMap[bundleId]),
                        'label',
                    ),
                    locations: preparationsReport.locationIds.map((locationId) => locationMap[locationId]),
                };
            }),
        ];
    },
    computed: {
        mapByBundles() {
            if (!this.bundles.length) return;

            const statusCriteria = getConclusionIds(this.preparationsReport);
            const bundleCriteria = this.preparationsReport.bundleIds;
            const locationCriteria = this.preparationsReport.locationIds;

            const preparations = this.preparations.filter((preparation) =>
                matchMultiFilter(preparation, [], bundleCriteria, locationCriteria, statusCriteria),
            );
            const lastPreparations = uniqBy(sortBy(preparations, 'createdAt'), 'groupId');
            const visas = sortBy(
                lastPreparations
                    .reduce(
                        (acc, preparation) => [
                            ...acc,
                            ...preparation.visas.map((visa) => ({
                                ...visa,
                                preparation: { ...preparation, bundle: this.bundleMap[preparation.bundleId] },
                            })),
                        ],
                        [],
                    )
                    .map((visa) => ({
                        ...visa,
                        late: visa.emissionDate
                            ? differenceInWorkingDays(visa.emissionDate, visa.emissionDueDate, this.agenda)
                            : differenceInWorkingDays(new Date(), visa.emissionDueDate, this.agenda),
                    })),
                'emissionDueDate',
            );
            console.log(visas);
            const bundles =
                this.preparationsReport.bundleIds.length > 0 ? this.preparationsReport.bundles : this.bundles;
            return bundles.map((bundle) => ({
                ...bundle,
                preparations: preparations.filter((preparation) => preparation.bundleId === bundle.id),
                visas: visas.filter((visa) => visa.emitterId === bundle.id),
            }));
        },
    },
    data() {
        return {
            preparationsReport: {
                bundles: [],
                locations: [],
            },
            preparations: [],
            bundles: [],
            bundleMap: {},
            agenda: [],
        };
    },
};
</script>
