<template>
    <div :class="{ loading }">
        <div style="page-break-before: always" v-if="hasItems || meeting.showEmptyBundlePage">
            <div class="flex items-start gap-3">
                <app-separator>
                    <a :id="'a_' + bundle.id"></a>
                    <div class="flex justify-between w-full">
                        <h1>
                            <span>{{ bundle.label }}</span>
                        </h1>
                    </div>
                </app-separator>
                <div class="flex flex-col justify-center items-center" v-if="bundle.company?.logoUrl">
                    <img ref="image" class="h-12 object-contain" :src="bundle.company?.logoUrl" />
                </div>
            </div>
            <div class="m-1 text-xs gap-4 flex w-full">
                <span class="font-bold">{{ $t('project.editions.meetingReports.attendanceStatsTitle') }} :</span>
                <span>
                    {{ attendanceStats.present }}
                    {{ $t('project.editions.meetingReports.attendanceValuesPlural.present') }}
                </span>
                <span>
                    {{ attendanceStats.excusedAbsent }}
                    {{ $t('project.editions.meetingReports.attendanceValuesPlural.excusedAbsent') }}
                </span>
                <span>
                    {{ attendanceStats.unexcusedAbsent }}
                    {{ $t('project.editions.meetingReports.attendanceValuesPlural.unexcusedAbsent') }}
                </span>
            </div>
            <div class="mt-4">
                <template
                    v-if="meeting.showPreparations && (preparations.length > 0 || meeting.showEmptySectionBundlePage)"
                >
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <h2>{{ $t('project.editions.meetingReports.preparationsToEmit') }}</h2>
                    </app-separator>
                    <table class="w-full text-xs border" v-if="preparations.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" width="20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="preparation in preparations">
                                <tr class="border">
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <div>
                                                <span class="">
                                                    {{
                                                        preparation.type
                                                            ? $t('preparations.types.' + preparation.type) + ' :'
                                                            : ''
                                                    }}
                                                </span>
                                                <router-link
                                                    :to="{
                                                        name: 'followPreparation',
                                                        params: {
                                                            ...$route.params,
                                                            preparationId: preparation.id,
                                                        },
                                                    }"
                                                >
                                                    {{ preparation.name }}
                                                </router-link>
                                            </div>
                                            <div v-if="preparation.versions.length > 1">
                                                <span class="text-red-500 text-xs italic">
                                                    {{ $t('preparations.newVersionTip') }}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': preparation.isLate,
                                        }"
                                    >
                                        {{ preparation.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <span class="ml-2 text-xs italic text-gray-700" v-else>
                        {{ $t('commons.emptyList') }}
                    </span>
                </template>
                <template
                    v-if="
                        meeting.showPreparationVisas &&
                        (preparationVisas.length > 0 || meeting.showEmptySectionBundlePage)
                    "
                >
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <h2>{{ $t('project.editions.meetingReports.preparationsToVisa') }}</h2>
                    </app-separator>
                    <span class="ml-2 text-xs italic text-gray-700" v-if="preparationVisas.length === 0">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-if="preparationVisas.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" width="20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="preparationVisa in preparationVisas">
                                <tr class="border">
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <div class="flex gap-1">
                                                <router-link
                                                    :to="{
                                                        name: 'followPreparation',
                                                        params: {
                                                            ...$route.params,
                                                            preparationId: preparationVisa.id,
                                                        },
                                                    }"
                                                >
                                                    {{ preparationVisa.name }}
                                                </router-link>
                                                <app-tips class="flex gap-1" v-if="preparationVisa.bundle">
                                                    {{ preparationVisa.bundle.reference }}
                                                    {{ preparationVisa.bundle.name }}
                                                </app-tips>
                                            </div>
                                            <div class="ml-4 text-blue-500">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        preparationVisa.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': preparationVisa.isLate,
                                        }"
                                    >
                                        {{ preparationVisa.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template
                    v-if="meeting.showConceptions && (conceptions.length > 0 || meeting.showEmptySectionBundlePage)"
                >
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <h2>{{ $t('project.editions.meetingReports.conceptionsToEmit') }}</h2>
                    </app-separator>
                    <table class="w-full text-xs border" v-if="conceptions.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" width="20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="preparation in conceptions">
                                <tr class="border">
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <div>
                                                <span class="">
                                                    {{
                                                        preparation.type
                                                            ? $t('preparations.types.' + preparation.type) + ' :'
                                                            : ''
                                                    }}
                                                </span>
                                                <router-link
                                                    :to="{
                                                        name: 'followPreparation',
                                                        params: {
                                                            ...$route.params,
                                                            preparationId: preparation.id,
                                                        },
                                                    }"
                                                >
                                                    {{ preparation.name }}
                                                </router-link>
                                            </div>
                                            <div v-if="preparation.versions.length > 1">
                                                <span class="text-red-500 text-xs italic">
                                                    {{ $t('preparations.newVersionTip') }}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': preparation.isLate,
                                        }"
                                    >
                                        {{ preparation.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <span class="ml-2 text-xs italic text-gray-700" v-else>
                        {{ $t('commons.emptyList') }}
                    </span>
                </template>
                <template
                    v-if="
                        meeting.showConceptionVisas &&
                        (conceptionVisas.length > 0 || meeting.showEmptySectionBundlePage)
                    "
                >
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <h2>{{ $t('project.editions.meetingReports.conceptionsToVisa') }}</h2>
                    </app-separator>
                    <span class="ml-2 text-xs italic text-gray-700" v-if="conceptionVisas.length === 0">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-if="conceptionVisas.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" width="20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="preparationVisa in conceptionVisas">
                                <tr class="border">
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <div class="flex gap-1">
                                                <router-link
                                                    :to="{
                                                        name: 'followPreparation',
                                                        params: {
                                                            ...$route.params,
                                                            preparationId: preparationVisa.id,
                                                        },
                                                    }"
                                                >
                                                    {{ preparationVisa.name }}
                                                </router-link>
                                                <app-tips class="flex gap-1" v-if="preparationVisa.bundle">
                                                    {{ preparationVisa.bundle.reference }}
                                                    {{ preparationVisa.bundle.name }}
                                                </app-tips>
                                            </div>
                                            <div class="ml-4 text-blue-500">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        preparationVisa.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': preparationVisa.isLate,
                                        }"
                                    >
                                        {{ preparationVisa.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template v-if="bundle.category === 'company'">
                    <template
                        v-if="meeting.showReceptions && (receptions.length > 0 || meeting.showEmptySectionBundlePage)"
                    >
                        <app-separator variant="underline" class="w-full my-2 text-base">
                            <h2>{{ $t('receptions.title') }}</h2>
                        </app-separator>
                        <table class="w-full text-xs border" v-if="receptions.length > 0">
                            <thead class="table-header-group font-bold">
                                <tr class="border">
                                    <td class="p-1">{{ $t('commons.name') }}</td>
                                    <td class="p-1" width="20%">{{ $t('commons.status') }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="reception in receptions">
                                    <tr class="border">
                                        <td class="p-1">
                                            <div class="w-full flex flex-col">
                                                <router-link
                                                    :to="{
                                                        name: 'followReception',
                                                        params: {
                                                            ...$route.params,
                                                            receptionId: reception.id,
                                                        },
                                                    }"
                                                >
                                                    {{ reception.name }}
                                                </router-link>
                                                <div class="ml-4 text-blue-500">
                                                    <div
                                                        v-for="locationPath in mergeLocationsPathsByFolders(
                                                            reception.locations,
                                                        )"
                                                    >
                                                        {{ locationPath }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            class="p-1"
                                            :class="{
                                                'font-bold text-red-500': reception.isLate && !reception.isEmitted,
                                            }"
                                        >
                                            {{ reception.label }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <span class="ml-2 text-xs italic text-gray-700" v-if="receptions.length === 0">
                            {{ $t('commons.emptyList') }}
                        </span>
                    </template>
                    <template
                        v-if="
                            meeting.showAutoControls && (autoControls.length > 0 || meeting.showEmptySectionBundlePage)
                        "
                    >
                        <app-separator variant="underline" class="w-full my-2 text-base">
                            <h2>{{ $t('autoControls.title') }}</h2>
                        </app-separator>
                        <table class="w-full text-xs border" v-if="autoControls.length > 0">
                            <thead class="table-header-group font-bold">
                                <tr class="border">
                                    <td class="p-1">{{ $t('commons.name') }}</td>
                                    <td class="p-1" width="20%">{{ $t('commons.status') }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="autoControl in autoControls">
                                    <tr class="border">
                                        <td class="p-1">
                                            <div class="w-full flex flex-col">
                                                <router-link
                                                    :to="{
                                                        name: 'followAutoControl',
                                                        params: {
                                                            ...$route.params,
                                                            autoControlId: autoControl.id,
                                                        },
                                                    }"
                                                >
                                                    {{ autoControl.name }}
                                                </router-link>
                                                <div class="ml-4 text-blue-500">
                                                    <div
                                                        v-for="locationPath in mergeLocationsPathsByFolders(
                                                            autoControl.locations,
                                                        )"
                                                    >
                                                        {{ locationPath }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            class="p-1"
                                            :class="{
                                                'font-bold text-red-500': autoControl.isLate && !autoControl.isEmitted,
                                            }"
                                        >
                                            {{ autoControl.label }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <span class="ml-2 text-xs italic text-gray-700" v-if="autoControls.length === 0">
                            {{ $t('commons.emptyList') }}
                        </span>
                    </template>
                </template>
                <template v-for="group in observationsGroups">
                    <template v-if="group.observations.length > 0 || meeting.showEmptySectionBundlePage">
                        <app-separator variant="underline" class="w-full my-2 text-base">
                            <h2 class="flex justify-between items-baseline w-full">
                                <span>{{ group.label }}</span>
                            </h2>
                        </app-separator>
                        <span v-if="group.observations.length === 0" class="ml-2 text-xs italic text-gray-700">
                            {{ $t('commons.emptyList') }}
                        </span>
                        <table class="w-full text-xs border" v-if="group.observations.length > 0">
                            <thead class="table-header-group font-bold">
                                <tr class="border">
                                    <td class="p-1">{{ $t('commons.index') }}</td>
                                    <td class="p-1">{{ $t('commons.phase') }}</td>
                                    <td class="p-1">{{ $t('project.editions.observationsReports.title') }}</td>
                                    <td class="p-1" width="20%">{{ $t('commons.status') }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="observation in group.observations">
                                    <tr class="border">
                                        <td class="p-1 align-top">
                                            <div class="flex justify-start">
                                                <div
                                                    v-if="!observation.footprint || observation.obsoleteAt"
                                                    class="p-1 px-2 border-black"
                                                    :class="{
                                                        [getObservationClass(observation)]: true,
                                                        border:
                                                            observation.type === 'other' ||
                                                            observation.type === 'general' ||
                                                            observation.type === 'administrative',
                                                    }"
                                                >
                                                    {{ (observation.index + 1).toString().padStart(3, '0') }}
                                                </div>
                                                <app-static-marker
                                                    v-else
                                                    size="mini"
                                                    class="text-xs cursor-pointer"
                                                    :observation="observation"
                                                ></app-static-marker>
                                            </div>
                                        </td>
                                        <td class="p-1 align-top">{{ $t('commons.phases.' + observation.phase) }}</td>
                                        <td class="p-1">
                                            <div class="w-full flex flex-col">
                                                <router-link
                                                    :to="{
                                                        name: 'followObservation',
                                                        params: { ...$route.params, observationId: observation.id },
                                                    }"
                                                >
                                                    <div class="flex flex-col justify-start">
                                                        <span
                                                            v-html="
                                                                observation.title.startsWith('<p>')
                                                                    ? observation.title.replace(
                                                                          '<p>',
                                                                          '<p>' +
                                                                              humanizeDate(observation.reportedAt) +
                                                                              ' ',
                                                                      )
                                                                    : humanizeDate(observation.reportedAt) +
                                                                      ' ' +
                                                                      observation.title
                                                            "
                                                            class="whitespace-pre-wrap html-breakline"
                                                        />
                                                        <div
                                                            class="w-full"
                                                            v-if="
                                                                observation.attachments &&
                                                                observation.attachments.length > 0
                                                            "
                                                        >
                                                            <template v-for="attachment of observation.attachments">
                                                                <div
                                                                    style="min-height: 400px; max-height: 400px"
                                                                    v-if="isImage(attachment.url)"
                                                                >
                                                                    <app-leaflet-viewer
                                                                        class="flex-grow"
                                                                        :src="attachment.url"
                                                                        :shapes="attachment.shapes"
                                                                        :preview="true"
                                                                        style="min-height: 400px"
                                                                    />
                                                                </div>
                                                                <div v-else class="flex items-center">
                                                                    <icon-file-document-outline />
                                                                    <a
                                                                        class="undeline"
                                                                        :href="`https://app.tex.moe${attachment.url}`"
                                                                    >
                                                                        {{ `https://app.tex.moe${attachment.url}` }}
                                                                    </a>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </router-link>
                                                <div class="ml-4 text-blue-500" v-if="observation.room">
                                                    {{ observation.room.fullName }}
                                                </div>
                                                <div class="ml-4 text-blue-500" v-else-if="observation.zone">
                                                    {{ observation.zone.fullName }}
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            class="p-1"
                                            :class="{
                                                'font-bold text-red-500': observation.isLate && !observation.isEmitted,
                                            }"
                                        >
                                            {{ observation.label }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>
                </template>
                <template
                    v-if="
                        meeting.showTasksSectionBundlePage &&
                        (taskTree.length > 0 || meeting.showEmptySectionBundlePage)
                    "
                >
                    <app-separator variant="underline" class="w-full my-2 text-base flex gap-1 items-baseline">
                        <h2>{{ $t('project.editions.meetingReports.taskProgress') }}</h2>
                        <div class="flex text-xs gap-1 items-center">
                            {{ $t('commons.from') }}
                            {{ meeting.bundleTasksStartDate | humanizeDate }}
                            {{ $t('commons.to') }}
                            {{ meeting.bundleTasksEndDate | humanizeDate }}
                        </div>
                    </app-separator>
                    <span v-if="taskTree.length === 0" class="ml-2 text-xs italic text-gray-700">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-if="taskTree.length > 0">
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">
                                    <span>{{ $t('commons.location') }}</span>
                                </td>
                                <td class="p-1" style="" colspan="2">{{ $t('commons.task') }}</td>
                                <td class="p-1 text-center">
                                    {{ $t('project.editions.meetingReports.startDates') }}
                                </td>
                                <td class="p-1 text-center">
                                    {{ $t('project.editions.meetingReports.endDates') }}
                                </td>
                                <td class="p-1 text-center">
                                    {{ $t('project.editions.meetingReports.progress') }}
                                </td>
                                <td class="p-1 text-center">
                                    {{ $t('project.editions.meetingReports.diff') }}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="firstLevelNode in taskTree">
                                <template v-for="bundle in firstLevelNode.children">
                                    <template v-for="(task, index) in bundle.children">
                                        <tr class="border">
                                            <td
                                                class="p-1 align-top"
                                                :rowspan="bundle.children.length"
                                                v-if="index === 0"
                                            >
                                                {{ firstLevelNode.folder.name }} > {{ firstLevelNode.location.name }}
                                            </td>
                                            <td class="p-1 w-1/2 border">
                                                <div class="flex flex-col">
                                                    <span v-if="task.path && task.path.length > 0">
                                                        {{ task.path }} >
                                                    </span>
                                                    <span class="ml-5">{{ task.service.name }}</span>
                                                </div>
                                            </td>
                                            <td class="py-1">
                                                <div class="flex flex-col">
                                                    <span
                                                        class="text-right font-bold border-b border-gray-300 whitespace-nowrap"
                                                    >
                                                        {{ $t('project.editions.meetingReports.planned') }} :
                                                    </span>
                                                    <span class="text-right font-bold whitespace-nowrap">
                                                        {{ $t('project.editions.meetingReports.real') }} :
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="py-1">
                                                <div class="flex flex-col justify-center">
                                                    <span class="text-center border-b border-gray-300">
                                                        {{ task.startDate | humanizeDate }}
                                                    </span>
                                                    <span class="text-center" v-if="task.realStartDate">
                                                        {{ task.realStartDate | humanizeDate }}
                                                    </span>
                                                    <span class="text-center" v-else>&nbsp;</span>
                                                </div>
                                            </td>
                                            <td class="py-1">
                                                <div class="flex flex-col">
                                                    <span class="text-center border-b border-gray-300">
                                                        {{ task.endDate | humanizeDate }}
                                                    </span>
                                                    <span class="text-center" v-if="task.realEndDate">
                                                        {{ task.realEndDate | humanizeDate }}
                                                    </span>
                                                    <span class="text-center" v-else>&nbsp;</span>
                                                </div>
                                            </td>
                                            <td class="py-1 text-center">
                                                <div class="flex flex-col">
                                                    <div class="text-center border-b border-gray-300">
                                                        <span v-if="task.endDate <= meeting.date">100%</span>
                                                        <span v-else-if="task.startDate > meeting.date">0%</span>
                                                        <span
                                                            v-else-if="
                                                                task.startDate < meeting.date &&
                                                                task.endDate >= meeting.date
                                                            "
                                                        >
                                                            {{
                                                                Math.round(
                                                                    (getDuration(
                                                                        meeting.date,
                                                                        task.startDate,
                                                                        agenda,
                                                                        task.service.bundle.id,
                                                                    ) /
                                                                        task.duration) *
                                                                        100,
                                                                )
                                                            }}%
                                                        </span>
                                                    </div>
                                                    <span class="text-center">{{ task.progress }}%</span>
                                                </div>
                                            </td>
                                            <td class="py-1 text-center">
                                                <span class="font-bold text-red-700" v-if="task.late > 1">
                                                    J+{{ task.late }}
                                                </span>
                                                <span v-else-if="task.late === 0" class="font-bold text-green-600">
                                                    J
                                                </span>
                                                <span v-else class="font-bold text-green-600">J{{ task.late }}</span>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </template>
                <template
                    v-if="meeting.showCertificates && (certificates.length > 0 || meeting.showEmptySectionBundlePage)"
                >
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <h2>{{ $t('certificates.title') }}</h2>
                    </app-separator>
                    <span v-if="certificates.length === 0" class="ml-2 text-xs italic text-gray-700">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border" v-else>
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <td class="p-1">{{ $t('commons.name') }}</td>
                                <td class="p-1" width="20%">{{ $t('commons.status') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="certificate in certificates">
                                <tr class="border">
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <router-link
                                                :to="{
                                                    name: 'followCertificate',
                                                    params: { ...$route.params, certificateId: certificate.id },
                                                }"
                                            >
                                                {{ certificate.name }}
                                            </router-link>
                                            <div class="ml-4 text-blue-500">
                                                <div
                                                    v-for="locationPath in mergeLocationsPathsByFolders(
                                                        certificate.locations,
                                                    )"
                                                >
                                                    {{ locationPath }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="p-1"
                                        :class="{
                                            'font-bold text-red-500': certificate.isLate && !certificate.isEmitted,
                                        }"
                                    >
                                        {{ certificate.label }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { getDuration } from '@/services/duration.service';
import { mergeLocationsPathsByFolders } from '@/services/location.service';
import AppTips from '@/components/app-tips/AppTips';
import { getByBundleData, getByBundlePreparedDate } from '@/features/meetings/byBundle.service';
import { queryMeeting } from '@/features/meetings/meetings.service';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer.vue';
import { isImage } from '@/services/file.service';
import AppStaticMarker from '@/components/appStaticMarker/AppStaticMarker.vue';
import { getObservationClass } from '@/features/observations/observation.service';
import { humanizeDate } from '@/filters/dateFilter';

export default {
    props: {
        bundle: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    components: { AppStaticMarker, AppLeafletViewer, AppTips, AppSeparator },
    async created() {
        this.meeting = await queryMeeting(this.$route.params.projectId, this.$route.params.meetingId);
        this.subscriptions = [
            getByBundleData(this.$route.params.projectId).subscribe((byBundleData) => {
                const data = getByBundlePreparedDate(
                    (key) => this.$t(key),
                    this.$route.params.meetingId,
                    this.bundle.id,
                    this.meeting.bundleTasksStartDate,
                    this.meeting.bundleTasksEndDate,
                    byBundleData,
                    this.meeting.showPreparations,
                    this.meeting.showPreparationVisas,
                    this.meeting.showConceptions,
                    this.meeting.showConceptionVisas,
                    this.meeting.showCONObservations,
                    this.meeting.showEXEObservations,
                    this.meeting.showOPRObservations,
                    this.meeting.showReceiptObservations,
                    this.meeting.showOPLObservations,
                    this.meeting.showDeliveryObservations,
                    this.meeting.showAPAObservations,
                    this.meeting.showCertificates,
                    this.meeting.showReceptions,
                    this.meeting.showAutoControls,
                    false,
                    'date',
                    this.meeting.emitterIds,
                );
                this.agenda = data.agenda;
                this.attendanceStats = data.attendanceStats;
                this.hasItems = data.hasItems;
                this.taskTree = data.taskTree;
                this.observationsGroups = data.observationsGroups;
                this.certificates = data.certificates;
                this.autoControls = data.autoControls;
                this.receptions = data.receptions;
                this.preparationVisas = data.preparationVisas;
                this.conceptionVisas = data.conceptionVisas;
                this.preparations = data.preparations;
                this.conceptions = data.conceptions;
                this.$nextTick(() => (this.loading = false));
            }),
        ];
    },
    methods: { getObservationClass, isImage, mergeLocationsPathsByFolders, getDuration, humanizeDate },
    data() {
        return {
            subscriptions: [],
            taskTree: [],
            meetings: [],
            meeting: {},
            agenda: [],
            attendanceStats: {},
            previousMeeting: null,
            hasItems: false,
            loading: true,
            preparations: [],
            conceptions: [],
            receptions: [],
            autoControls: [],
            certificates: [],
            preparationVisas: [],
            conceptionVisas: [],
            observationsGroups: [],
        };
    },
};
</script>
