<template>
    <div>
        <div class="flex justify-between flex-col-reverse sm:flex-row sm:items-center">
            <div class="flex sm:items-center flex-col sm:flex-row">
                <table class="mx-2">
                    <tr>
                        <th scope="row" class="text-right text-sm whitespace-nowrap">{{ $t('commons.version') }} :</th>
                        <td class="px-3">
                            <app-select
                                :label="$t('commons.version')"
                                :value="preparation.id"
                                class=""
                                :show-label="false"
                                :disabled="!preparation.id"
                                @change="onVersionSelected($event)"
                            >
                                <option
                                    :value="version.id"
                                    v-for="(version, index) in preparation.versions"
                                    :key="version.id"
                                >
                                    {{ preparation.versions.length - index }} ({{ version.createdAt | humanizeDate }})
                                </option>
                            </app-select>
                        </td>
                    </tr>
                </table>

                <div class="m-2 sm:my-0">
                    <app-button @click="onNewVersion" :disabled="!preparation.id">
                        {{ $t('commons.newVersion') }}
                    </app-button>
                </div>
            </div>
        </div>
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('commons.name')"
                        v-model="preparation.name"
                        class="col-span-2 md:col-span-1"
                        :required="true"
                        @blur="save()"
                        ref="nameInput"
                    />
                    <app-input-text
                        :label="$t('preparations.code')"
                        v-model="preparation.code"
                        class="col-span-2 md:col-span-1"
                        @blur="save()"
                    />
                    <app-select
                        :label="$t('preparations.type')"
                        v-model="preparation.type"
                        class="col-span-2"
                        @change="save()"
                    >
                        <template v-if="preparation.phase === 'exe'">
                            <option value="technicalDetail">{{ $t('preparations.types.technicalDetail') }}</option>
                            <option value="sample">{{ $t('preparations.types.sample') }}</option>
                            <option value="technicalFile">{{ $t('preparations.types.technicalFile') }}</option>
                            <option value="note">{{ $t('preparations.types.note') }}</option>
                            <option value="plan">{{ $t('preparations.types.plan') }}</option>
                            <option value="synthesis">{{ $t('preparations.types.synthesis') }}</option>
                            <option value="other">{{ $t('preparations.types.other') }}</option>
                        </template>
                        <template v-if="preparation.phase === 'con'">
                            <option value="priceList">{{ $t('preparations.types.priceList') }}</option>

                            <option value="cartridge">{{ $t('preparations.types.cartridge') }}</option>
                            <option value="diagnostic">{{ $t('preparations.types.diagnostic') }}</option>
                            <option value="diuo">{{ $t('preparations.types.diuo') }}</option>
                            <option value="sample">{{ $t('preparations.types.sample') }}</option>
                            <option value="estimate">{{ $t('preparations.types.estimate') }}</option>

                            <option value="technicalFile">{{ $t('preparations.types.technicalFile') }}</option>
                            <option value="note">{{ $t('preparations.types.note') }}</option>
                            <option value="noticeCCTP">{{ $t('preparations.types.noticeCCTP') }}</option>
                            <option value="plan">{{ $t('preparations.types.plan') }}</option>
                            <option value="pgc">{{ $t('preparations.types.pgc') }}</option>
                            <option value="planning">{{ $t('preparations.types.planning') }}</option>
                            <option value="distributorReport">{{ $t('preparations.types.distributorReport') }}</option>
                            <option value="report">{{ $t('preparations.types.report') }}</option>
                            <option value="CTinitialReport">{{ $t('preparations.types.CTinitialReport') }}</option>
                            <option value="certificationReport">
                                {{ $t('preparations.types.certificationReport') }}
                            </option>
                            <option value="synthesis">{{ $t('preparations.types.synthesis') }}</option>

                            <option value="other">{{ $t('preparations.types.other') }}</option>
                        </template>
                    </app-select>
                    <app-select
                        :label="$t('commons.phase')"
                        v-model="preparation.subPhase"
                        class="col-span-2"
                        @change="save()"
                        v-if="preparation.phase === 'con'"
                    >
                        <option value="DIAG">{{ $t('preparations.subPhases.DIAG') }}</option>
                        <option value="ESQ">{{ $t('preparations.subPhases.ESQ') }}</option>
                        <option value="APS">{{ $t('preparations.subPhases.APS') }}</option>
                        <option value="APD">{{ $t('preparations.subPhases.APD') }}</option>
                        <option value="PC">{{ $t('preparations.subPhases.PC') }}</option>
                        <option value="AVP">{{ $t('preparations.subPhases.AVP') }}</option>
                        <option value="PRO">{{ $t('preparations.subPhases.PRO') }}</option>
                        <option value="DCE">{{ $t('preparations.subPhases.DCE') }}</option>
                        <option value="OTHER">{{ $t('preparations.subPhases.OTHER') }}</option>
                    </app-select>
                    <app-bundle-picker
                        class="col-span-2"
                        v-model="preparation.bundle"
                        :options="bundles"
                        @input="onBundleChange"
                    ></app-bundle-picker>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="preparation.locations"
                        :options="locations"
                        :show-label="true"
                        label-key="fullName"
                        @input="save()"
                        :label="$t('preparations.locations')"
                        :title="$t('preparations.chooseLocations')"
                    ></app-multi-picker>
                    <app-label
                        :label="$t('services.predecessor')"
                        class="col-span-2"
                        v-if="preparation.phase === 'exe'"
                    >
                        <app-multi-picker
                            :value="preparation.taskPredecessors"
                            @check="createPredecessors($event)"
                            @uncheck="removePredecessorsFromTaskId($event)"
                            :options="tasksOptions"
                            variant="button"
                            :show-label="false"
                            :show-input="false"
                            :label="$t('services.newPredecessor')"
                            :title="$t('services.newPredecessor')"
                        ></app-multi-picker>
                        <table class="flex-grow mb-10 text-sm">
                            <tr v-if="preparation && preparation.predecessors.length === 0">
                                <td colspan="3">{{ $t('services.emptyPredecessors') }}</td>
                            </tr>
                            <template v-for="task in preparation.taskPredecessors">
                                <tr :v-key="task.id">
                                    <td class="px-2 py-1">
                                        <div>{{ task.label }}</div>
                                    </td>
                                    <td class="px-2 py-1">
                                        <div>
                                            {{ (task.realStartDate || task.startDate) | humanizeDate }}
                                        </div>
                                    </td>
                                    <td class="px-2 py-1">
                                        <div class="flex items-center">
                                            <app-button
                                                size="mini"
                                                @click.prevent="removePredecessorsFromTaskId([task])"
                                                icon="icon-trash-can-outline"
                                                variant="danger"
                                                aria-label="delete predecessor"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </app-label>
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-5" :label="$t('preparations.productionDates')">
                    <div
                        class="col-span-2 bg-green-50 p-2 text-xs border"
                        v-if="firstTask && firstTaskDate && recommendedEmissionDate && !preparation.emissionDate"
                    >
                        <ul class="list-disc p-2">
                            <li>
                                <div>
                                    <span>{{ $t('preparations.firstTaskDateTip') }}</span>
                                    <span class="font-bold">
                                        {{ firstTaskDate | humanizeDate }}
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div class="flex">
                                    <span>{{ $t('preparations.deliveryDuration') }}&nbsp;:</span>
                                    <span class="flex">
                                        <app-duration-link
                                            v-model="preparation.deliveryDuration"
                                            :label="$t('preparations.deliveryDuration')"
                                            :show-label="false"
                                            class="ml-1 font-bold underline"
                                            @input="save()"
                                        >
                                            {{ preparation.deliveryDuration }}
                                        </app-duration-link>
                                        <span>{{ $t('commons.openDayUnit') }}</span>
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>{{ $t('preparations.validationDate') }}&nbsp;:</span>
                                    <span class="font-bold">
                                        {{ validationDate | humanizeDate }}
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>{{ $t('preparations.visaDuration') }}&nbsp;:</span>
                                    <span class="font-bold ml-1">{{ visaDuration }}</span>
                                    <span class="font-bold">{{ $t('commons.openDayUnit') }}</span>
                                    <span v-if="visaDuration === 0" class="mx-1">
                                        ({{ $t('preparations.noVisaDuration') }})
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span>{{ $t('preparations.reviewMargin') }}&nbsp;:</span>
                                    <span class="font-bold ml-1">
                                        {{ preparationReviewMargin }}
                                    </span>
                                    <span class="font-bold">{{ $t('commons.openDayUnit') }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="mt-2">
                                    <span>{{ $t('preparations.idealEmissionDate') }}</span>
                                    <span class="mr-2">:</span>
                                    <span class="whitespace-nowrap">{{ firstTaskDate | humanizeDate }}</span>
                                    <span class="mx-1">-</span>
                                    <span>{{ preparation.deliveryDuration }}</span>
                                    <span>{{ $t('commons.openDayUnit') }}</span>
                                    <span class="mx-1">-</span>
                                    <span>{{ visaDuration }}</span>
                                    <span>{{ $t('commons.openDayUnit') }}</span>
                                    <span class="mx-1">-</span>
                                    <span>{{ preparationReviewMargin }}</span>
                                    <span>{{ $t('commons.openDayUnit') }}</span>
                                    <span class="mx-1">=</span>
                                    <span class="whitespace-nowrap">{{ recommendedEmissionDate | humanizeDate }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-span-2 text-xs" v-if="recommendedEmissionDate">
                        <div
                            class="flex items-center p-2 border"
                            :class="
                                !preparation.emissionDueDate || preparation.emissionDueDate > recommendedEmissionDate
                                    ? 'bg-yellow-100'
                                    : 'bg-green-50'
                            "
                            v-if="recommendedEmissionDate > new Date()"
                        >
                            {{ $t('preparations.recommendedDate') }}
                            <app-button @click="onSetRecommendedDate()" size="mini" variant="none" class="m-1">
                                {{ recommendedEmissionDate | humanizeDate }}
                            </app-button>
                        </div>
                        <div
                            class="flex items-center p-2 border"
                            :class="
                                !preparation.emissionDueDate || preparation.emissionDueDate > lastDateToStartVisa
                                    ? 'bg-yellow-100'
                                    : 'bg-green-50'
                            "
                            v-else-if="recommendedEmissionDate < new Date() && lastDateToStartVisa > new Date()"
                        >
                            {{ $t('preparations.atLeast5DaysBeforeCommand') }}
                            <app-button
                                @click="preparation.emissionDueDate = validationDate"
                                size="mini"
                                variant="none"
                                class="m-1"
                            >
                                {{ lastDateToStartVisa | humanizeDate }}
                            </app-button>
                        </div>
                        <div class="flex items-center bg-yellow-100 p-2 border" v-else>
                            {{ $t('preparations.sendAndVisaAsap') }}
                        </div>
                    </div>
                    <app-date-input
                        class="col-span-2 md:col-span-1"
                        v-model="preparation.emissionDueDate"
                        :label="$t('preparations.emissionDueDate')"
                        :warning="preparation.emissionDueDate > recommendedEmissionDate"
                        @input="save()"
                    ></app-date-input>
                    <app-date-input
                        class="col-span-2 md:col-span-1"
                        v-model="preparation.emissionDate"
                        :label="$t('preparations.emissionDate')"
                        @input="save()"
                    />
                    <div v-if="preparationVisas.length > 0" class="flex col-span-2 items-center">
                        <div>
                            <app-button
                                :label="$t('preparations.updateVisaDates')"
                                @click="updateVisaDate"
                            ></app-button>
                        </div>
                        <app-tips class="ml-1">{{ $t('preparations.updateVisaDatesTooltip') }}</app-tips>
                    </div>
                </app-fieldset>
                <app-save-on-leave :dirty="dirty" :saveFn="() => save(true)"></app-save-on-leave>
            </form>
        </ValidationObserver>
        <app-multi-picker
            ref="multiBundlePicker"
            class="col-span-2"
            variant="hidden"
            :show-label="false"
            v-model="bundlesToCopy"
            :options="bundles"
            :title="$t('preparations.copyByBundles')"
        >
            <template v-slot:option="{ option }">
                <app-bundle :bundle="option"></app-bundle>
            </template>
            <template v-slot:footer>
                <div class="p-4 flex justify-end">
                    <app-button
                        @click="copyToBundles"
                        :disabled="bundlesToCopy.length === 0"
                        variant="primary"
                        :label="$t('preparations.copyByBundleValidation')"
                    ></app-button>
                </div>
            </template>
        </app-multi-picker>
    </div>
</template>

<script>
import AppLegend from '../../components/appLegend/AppLegend';
import { getMapById, sanitize, sortBy } from '@/services/sanitize.service';
import AppSelect from '../../components/appSelect/AppSelect';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppFooter from '../../components/appFooter/AppFooter';
import AppErrors from '../../components/appErrors/AppErrors';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppPicker from '../../components/appPicker/AppPicker';
import AppButton from '../../components/appButton/AppButton';
import AppDurationInput from '../../components/appDurationInput/AppDurationInput';
import {
    conclusionResult,
    createPreparation,
    createPreparationPredecessors,
    getPreparation,
    getRecommendedEmissionDate,
    removePreparationPredecessors,
    updatePreparation,
} from './preparations.service';
import { humanizeDate } from '@/filters/dateFilter';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import AppMultiPicker from '../../components/appMultiPicker/AppMultiPicker';
import AppBundlePicker from '../../components/appBundlePicker';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppTips from '@/components/app-tips/AppTips';
import { applyDuration } from '@/services/duration.service';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppBundle from '@/components/app-bundle/appBundle';
import AppDurationLink from '@/components/appDurationLink/AppDurationLink';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { getProject } from '@/features/projects/projects.service';
import {
    createPreparationVisa,
    getPreparationVisas,
    updatePreparationVisa,
} from '@/features/preparations/preparationVisas.service';
import { isMobile } from '@/state/state';
import AppLabel from '@/components/appLabel/AppLabel.vue';
import { reportError } from '@/features/tracker/tracker.service';
import compareDesc from 'date-fns/compareDesc';
import { getCompanies } from '@/features/companies/companies.service';

export default {
    components: {
        AppLabel,
        AppDurationLink,
        AppBundle,
        AppSeparator,
        AppAutocomplete,
        AppTips,
        AppSaveOnLeave,
        AppBundlePicker,
        AppMultiPicker,
        AppDurationInput,
        AppButton,
        AppPicker,
        AppDateInput,
        AppErrors,
        AppFooter,
        AppFieldset,
        AppInputText,
        AppSelect,
        AppLegend,
    },
    async created() {
        this.init();
        localStorage.setItem(
            'preparation.lastVisitedPreparationId.' + this.$route.params.projectId,
            this.$route.params.preparationId,
        );
    },
    watch: {
        $route: function (from, to) {
            if (from.name !== to.name || from.params.preparationId !== to.params.preparationId) {
                this.focusDone = false;
            }
            this.init();
        },
    },
    computed: {
        recommendedEmissionDate() {
            if (this.project) {
                return getRecommendedEmissionDate(
                    this.preparationReviewMargin,
                    this.preparation.deliveryDuration,
                    this.firstTaskDate,
                    this.visaDuration,
                    this.agenda,
                );
            }
        },
        firstTaskDate() {
            if (this.firstTask) {
                return this.firstTask.realStartDate || this.firstTask.startDate;
            } else null;
        },
        validationDate() {
            if (this.firstTaskDate) {
                return applyDuration(this.firstTaskDate, -this.preparation.deliveryDuration, this.agenda, null);
            } else null;
        },
        lastDateToStartVisa() {
            if (this.validationDate) {
                return applyDuration(this.validationDate, -5, this.agenda, null);
            } else null;
        },
        conclusionDate() {
            const conclusion = conclusionResult(this.preparation);
            if (conclusion === 'incompleteVisa' || conclusion === 'noConclusion') {
                return this.$t('preparations.conclusionValues.' + conclusion);
            } else {
                return humanizeDate(
                    this.preparationVisas
                        .filter((preparation) => preparation.conclusion)
                        .map((visa) => visa.emissionDate)
                        .sort()
                        .pop(),
                );
            }
        },
        firstTask() {
            return this.preparation?.taskPredecessors
                ? [...this.preparation.taskPredecessors]
                      .sort((taskA, taskB) =>
                          compareDesc(taskA.realStartDate || taskA.startDate, taskB.realStartDate || taskB.startDate),
                      )
                      .pop()
                : null;
        },
        visaDuration() {
            return this.preparationVisas.reduce(
                (acc, visa) => (visa.emitter && visa.emitter.visaDelay > acc ? visa.emitter.visaDelay : acc),
                0,
            );
        },
        tasksOptions() {
            return sortBy(
                this.planning.map((task) => ({
                    ...task,
                    name: task.service.bundle.label + ' > ' + task.location.fullName + ' > ' + task.name,
                })),
                'name',
            );
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                getCalendar(this.$route.params.projectId).subscribe((agenda) => (this.agenda = agenda)),
                getProject(this.$route.params.projectId).subscribe(
                    (project) => (this.preparationReviewMargin = project.preparationReviewMargin),
                ),
                combineLatest([
                    getBundles(this.$route.params.projectId),
                    getCompanies(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId).pipe(
                        map((folders) => locationService.buildLocationOptions(folders)),
                    ),
                    getPreparation(this.$route.params.projectId, this.$route.params.preparationId),
                    getPreparationVisas(this.$route.params.projectId, this.$route.params.preparationId),
                    getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(new Date())),
                ]).subscribe(([bundles, companies, locations, preparation, visas, planning]) => {
                    this.planning = planning;
                    this.locations = locations.filter((location) => location.type === 'location');
                    const locationMap = getMapById(locations);
                    const companyMap = getMapById(companies);
                    const mappedBundles = bundles.map((bundle) => ({
                        ...bundle,
                        company: companyMap[bundle.companyId],
                    }));
                    const bundleMap = getMapById(mappedBundles);
                    this.preparationVisas = visas.map((visa) => ({ ...visa, emitter: bundleMap[visa.emitterId] }));
                    this.preparation = {
                        ...preparation,
                        bundle: mappedBundles.find((bundle) => bundle.id === preparation.bundleId),
                        locations: preparation.locationIds.map((id) => locationMap[id]),
                        taskPredecessors: sortBy(
                            preparation.predecessors
                                .map((predecessor) => {
                                    const task = planning.find((aTask) => aTask.id === predecessor.taskId);
                                    if (task) {
                                        return {
                                            ...task,
                                            label:
                                                task.service.bundle.label +
                                                ' > ' +
                                                task.location.fullName +
                                                ' > ' +
                                                task.name,
                                        };
                                    } else {
                                        reportError(
                                            'missing task ' +
                                                predecessor.taskId +
                                                ' referenced by ' +
                                                this.preparation.id,
                                        );
                                        return null;
                                    }
                                })
                                .filter((a) => !!a),
                            'label',
                        ),
                    };
                    this.bundles = mappedBundles;
                    this.selectedVersion = this.$route.params.preparationId;
                    if (!this.focusDone && !isMobile && this.preparation.name === this.$t('preparations.newName')) {
                        this.$nextTick(() => {
                            this.$refs.nameInput.focus();
                            this.$refs.nameInput.select();
                        });
                        this.focusDone = true;
                    }
                }),
            ];
        },
        createPredecessors(tasks) {
            return createPreparationPredecessors(
                this.$route.params.projectId,
                this.preparation,
                tasks.map((task) => ({
                    taskId: task.id,
                    type: 'ES',
                    delay: 0,
                })),
            );
        },
        async removePredecessorsFromTaskId(tasks) {
            return removePreparationPredecessors(
                this.$route.params.projectId,
                this.preparation,
                tasks.map((task) => task.id),
            );
        },
        onSetRecommendedDate() {
            this.preparation.emissionDueDate = this.recommendedEmissionDate;
            this.save(true);
        },
        updateVisaDate() {
            const newDate = this.preparation.emissionDate || this.preparation.emissionDueDate;
            if (newDate) {
                this.preparationVisas
                    .filter((visa) => !visa.emissionDate)
                    .map((visa) =>
                        updatePreparationVisa(this.$route.params.projectId, {
                            id: visa.id,
                            emissionDueDate: applyDuration(
                                new Date(newDate),
                                visa.emitter.visaDelay,
                                this.agenda,
                                visa.emitter.id,
                            ),
                        }),
                    );
            }
        },
        onBundleChange() {
            this.save();
        },
        async onVersionSelected(preparationId) {
            await this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    preparationId,
                },
            });
        },
        async onNewVersion() {
            const preparationEntity = {
                groupId: this.preparation.groupId,
                name: this.preparation.name,
                type: this.preparation.type,
                code: this.preparation.code,
                deliveryDuration: this.preparation.deliveryDuration,
                emissionDate: null,
                emissionDueDate: null,
                bundleId: this.preparation.bundle ? this.preparation.bundle.id : null,
                locationIds: this.preparation.locations.map((location) => location.id),
                phase: this.preparation.phase,
                subPhase: this.preparation.subPhase,
            };
            const result = await createPreparation(this.$route.params.projectId, preparationEntity);
            await Promise.all(
                this.preparationVisas
                    .filter((visa) => visa.emitterId)
                    .map((visa) => {
                        return createPreparationVisa(this.$route.params.projectId, {
                            emitterId: visa.emitterId,
                            preparationId: result.id,
                        });
                    }),
            );
            await this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    preparationId: result.id,
                },
            });
        },
        sanitizePreparation(preparation, toOmit = []) {
            return {
                ...sanitize(preparation, [
                    'bundle',
                    'locations',
                    'visas',
                    'documents',
                    'versions',
                    'conclusion',
                    'createdAt',
                    'taskPredecessors',
                    ...toOmit,
                ]),
                bundleId: preparation.bundle ? preparation.bundle.id : null,
                locationIds: preparation.locations.map((location) => location.id),
                emissionDueDate: preparation.emissionDueDate || null,
            };
        },
        async save() {
            if (this.savingFlag) {
                return;
            } else {
                this.savingFlag = true;
            }
            try {
                const preparationEntity = this.sanitizePreparation(this.preparation);
                await updatePreparation(this.$route.params.projectId, {
                    id: this.preparation.id,
                    ...preparationEntity,
                });
                if (this.$refs.observer) {
                    this.$refs.observer.reset();
                }
            } finally {
                this.savingFlag = false;
            }
        },
        async createNew() {
            const result = await createPreparation(this.$route.params.projectId, {
                name: this.$t('preparations.newName'),
                deliveryDuration: 0,
                phase: this.preparation.phase,
                subPhase: 'OTHER',
            });
            await this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    preparationId: result.id,
                },
            });
        },
        async duplicate() {
            const result = await createPreparation(this.$route.params.projectId, {
                ...this.sanitizePreparation(this.preparation, ['groupId']),
                name: this.preparation.name + ' ' + this.$t('preparations.newCopySuffixName'),
                phase: this.preparation.phase,
                subPhase: this.preparation.subPhase,
                emissionDate: null,
                visas: this.preparationVisas.map((visa) => ({
                    emitterId: visa.emitter ? visa.emitter.id : null,
                    conclusion: null,
                    emissionDueDate: visa.emissionDueDate,
                })),
            });
            if (!result.errors) {
                this.$refs.observer.reset();
                await this.$router.push({
                    name: this.$route.name,
                    params: {
                        projectId: this.$route.params.projectId,
                        preparationId: result.id,
                    },
                });
            }
        },
        async copyToBundles() {
            this.$refs.multiBundlePicker.close();
            const visas = this.preparationVisas.map((visa) => ({
                emitterId: visa.emitter ? visa.emitter.id : null,
                emissionDueDate: visa.emissionDueDate,
            }));
            const locationIds = this.preparation.locations.map((location) => location.id);
            await Promise.all(
                this.bundlesToCopy.map((bundle) => {
                    return createPreparation(this.$route.params.projectId, {
                        name: this.preparation.name,
                        type: this.preparation.type,
                        code: this.preparation.code,
                        phase: this.preparation.phase,
                        subPhase: this.preparation.subPhase,
                        bundleId: bundle.id,
                        emissionDueDate: this.preparation.emissionDueDate,
                        visas,
                        locationIds,
                    });
                }),
            );
            this.bundlesToCopy = [];
        },
        async copyByBundles() {
            this.$refs.multiBundlePicker.open();
        },
    },
    data() {
        return {
            focusDone: false,
            subscriptions: [],
            savingFlag: false,
            preparationReviewMargin: 0,
            bundles: [],
            bundlesToCopy: [],
            preparationVisas: [],
            preparation: {
                name: this.$t('preparations.newName'),
                locations: [],
                bundle: null,
                documents: [],
                versions: [],
                deliveryDuration: 0,
                predecessors: [],
                subPhase: 'OTHER',
            },
            selectedVersion: null,
            planning: [],
            agenda: [],
            project: {},
            locations: [],
        };
    },
};
</script>
