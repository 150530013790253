<template>
    <main class="max-w-5xl m-5 h-full w-full">
        <ProjectReadOnly :project="project" v-if="realOnly" />
        <template v-else>
            <div class="col-span-2 flex justify-end">
                <app-button
                    size="mini"
                    variant="danger"
                    :label="project.archivedAt ? $t('projects.unarchive') : $t('projects.archive')"
                    v-if="isRoot || isOwner"
                    @click="onArchive()"
                ></app-button>
            </div>
            <AppLegend />
            <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
                <form class="m-auto">
                    <app-fieldset class="col-span-2 mt-5" :label="$t('commons.infos')">
                        <div class="col-span-2 md:col-span-1">
                            <app-input-text
                                :label="$t('commons.name')"
                                :required="true"
                                v-model="project.name"
                                class="col-span-2 md:col-span-1"
                                @blur="saveProject"
                            />

                            <app-input-text
                                :label="$t('project.information.generals.code')"
                                :required="true"
                                v-model="project.code"
                                class="mt-4 col-span-2 md:col-span-1"
                                @blur="saveProject"
                            />
                        </div>
                        <div
                            class="relative col-span-2 row-start-1 md:row-start-auto md:col-span-1 min-w-[200px] flex flex-col m-auto"
                        >
                            <img
                                ref="image"
                                class="h-40 object-contain"
                                :src="project.imageUrl || '/defaultImage.svg'"
                                :alt="$t('project.information.generals.imageAlt')"
                            />
                            <app-upload-button
                                variant="link"
                                class="col-span-2 justify-center text-sm"
                                :label="$t('project.information.generals.imageUrlLabel')"
                                @select="changeImage"
                                :resize="1024"
                                :name="$t('project.information.generals.imageUrlLabel')"
                            ></app-upload-button>
                        </div>

                        <span class="col-span-2">
                            <AppSelect
                                id="type"
                                name="type"
                                v-model="project.type"
                                :label="$t('project.information.generals.type')"
                                @input="saveProject"
                                :required="true"
                            >
                                <option>Résidentiel collectif</option>
                                <option>Résidentiel individuel</option>
                                <option>Educatif</option>
                                <option>Tertiaire</option>
                                <option>Commercial</option>
                                <option>Industriel</option>
                                <option>Agricole</option>
                                <option>Santé</option>
                                <option>Récréatif et culturel</option>
                                <option>Religieux</option>
                                <option>Transport</option>
                                <option>Voirie / Espaces Verts</option>
                                <option>Sportif</option>
                                <option>Autre</option>
                            </AppSelect>
                        </span>
                        <span class="col-span-2">
                            <AppSelect
                                id="typeOfWork"
                                name="typeOfWork"
                                v-model="project.typeOfWork"
                                :label="$t('project.information.generals.typeOfWork')"
                                @input="saveProject"
                                :required="true"
                            >
                                <option>Neuf</option>
                                <option>Rénovation extérieure</option>
                                <option>Rénovation énergétique</option>
                                <option>Rénovation intérieure</option>
                                <option>Rénovation structurelle</option>
                                <option>Réhabilitation</option>
                            </AppSelect>
                        </span>
                        <span class="col-span-2" v-if="showPhase">
                            <AppSelect :label="$t('commons.phase')" v-model="project.phase" @input="saveProject">
                                <option></option>
                                <option value="CON" :disabled="!isCONAllowed">{{ $t('commons.phases.CON') }}</option>
                                <option value="EXE" :disabled="!isEXEAllowed">{{ $t('commons.phases.EXE') }}</option>
                                <option value="OPR" :disabled="!isOPRAllowed">{{ $t('commons.phases.OPR') }}</option>
                                <option value="Receipt" :disabled="!isOPRAllowed">
                                    {{ $t('commons.phases.Receipt') }}
                                </option>
                                <option value="OPL" :disabled="!isOPLAllowed">{{ $t('commons.phases.OPL') }}</option>
                                <option value="Delivery" :disabled="!isOPLAllowed">
                                    {{ $t('commons.phases.Delivery') }}
                                </option>
                                <option value="APA" :disabled="!isAPAAllowed">{{ $t('commons.phases.APA') }}</option>
                            </AppSelect>
                        </span>
                    </app-fieldset>

                    <app-number-input
                        class="p-2 col-span-2 my-4"
                        v-model="project.size"
                        :label="$t('order.project.dimension') + ' (m²)'"
                        @blur="saveProject"
                        :required="true"
                        format="integer"
                    >
                        <template v-slot:tip>
                            <app-tips class="flex flex-col">
                                <span>{{ $t('order.project.dimensionTips1') }}</span>
                                <span>{{ $t('order.project.dimensionTips2') }}</span>
                                <span>{{ $t('order.project.dimensionTips3') }}</span>
                            </app-tips>
                            <div class="flex flex-col md:flex-row p-5 justify-between">
                                <img
                                    src="/dimension1.png"
                                    width="150"
                                    alt="Exemple de calcul de dimension pour un batiment simple"
                                />
                                <img
                                    src="/dimension2.png"
                                    width="200"
                                    alt="Exemple de calcul de dimension pour un batiment avec un décroché"
                                />
                                <img
                                    src="/dimension3.png"
                                    width="300"
                                    alt="Exemple de calcul de dimension pour un batiment avec toiture et terrasse"
                                />
                            </div>
                        </template>
                    </app-number-input>

                    <app-fieldset :label="$t('commons.address')" icon="icon-map-marker" class="col-span-2 mt-5">
                        <app-input-text
                            :label="$t('commons.address1')"
                            v-model="project.address1"
                            class="col-span-2"
                            @blur="saveProject"
                        >
                            <template v-slot:tip>
                                <span class="text-xs text-gray-700 italic">
                                    {{ $t('commons.address1Example') }}
                                </span>
                            </template>
                        </app-input-text>

                        <app-input-text
                            :label="$t('commons.address2')"
                            v-model="project.address2"
                            class="col-span-2"
                            @blur="saveProject"
                        >
                            <template v-slot:tip>
                                <span class="text-xs text-gray-700 italic">
                                    {{ $t('commons.address2Example') }}
                                </span>
                            </template>
                        </app-input-text>

                        <app-input-text
                            :label="$t('commons.address3')"
                            v-model="project.address3"
                            class="col-span-2"
                            @blur="saveProject"
                        >
                            <template v-slot:tip>
                                <span class="text-xs text-gray-700 italic">
                                    {{ $t('commons.address3Example') }}
                                </span>
                            </template>
                        </app-input-text>

                        <app-input-text
                            rules="length:5"
                            :label="$t('commons.postalCode')"
                            v-model="project.postalCode"
                            class="col-span-2 md:col-span-1"
                            pattern="[A-Ba-b0-9]{5}"
                            size="5"
                            @blur="saveProject"
                        />

                        <app-input-text
                            rules="min:2"
                            :label="$t('commons.city')"
                            v-model="project.city"
                            class="col-span-2 md:col-span-1"
                            @blur="saveProject"
                        />
                    </app-fieldset>

                    <app-fieldset
                        :label="$t('project.information.generals.dates')"
                        icon="icon-calendar"
                        class="col-span-2 mt-5"
                        v-if="showDates"
                    >
                        <app-date-input
                            :label="$t('project.information.generals.startDate')"
                            v-model="project.startDate"
                            class="col-span-2 md:col-span-1"
                            size="10"
                            @input="saveProject"
                        >
                            <template slot="tip">
                                <app-tips>
                                    {{ $t('project.information.generals.startDateTip') }}
                                </app-tips>
                            </template>
                        </app-date-input>
                        <app-date-input
                            :label="$t('project.information.generals.endDate')"
                            v-model="project.endDate"
                            class="col-span-2 md:col-span-1"
                            size="10"
                            @input="saveProject"
                        />
                    </app-fieldset>
                    <AppSaveOnLeave :dirty="dirty" :saveFn="saveProject"></AppSaveOnLeave>
                </form>
            </ValidationObserver>
        </template>
        <app-fieldset
            :label="$t('project.information.generals.modules')"
            class="col-span-2 mt-5"
            v-if="project.me && project.me.allowedFeatures.includes('project_subscriber')"
        >
            <app-checkbox
                class="col-span-2"
                :disabled="isCONAllowed"
                :label="$t('order.project.new.perimeterValues.CON')"
                v-model="CON"
            />
            <app-checkbox
                class="col-span-2"
                :disabled="isEXEAllowed"
                :label="$t('order.project.new.perimeterValues.EXE')"
                v-model="EXE"
            />
            <app-checkbox
                class="col-span-2"
                :disabled="isOPRAllowed"
                :label="$t('order.project.new.perimeterValues.OPR')"
                v-model="OPR"
            />
            <app-checkbox
                class="col-span-2"
                :disabled="isOPLAllowed"
                :label="$t('order.project.new.perimeterValues.OPL')"
                v-model="OPL"
            />
            <app-checkbox
                :disabled="isCompanyAllowed"
                :label="$t('order.project.new.perimeterValues.company')"
                class="mt-4 col-span-2"
                v-model="company"
            />
            <span
                v-if="
                    project.me &&
                    (project.me.allowedFeatures.includes('project_subscriber') ||
                        project.createdBy === project.me.id) &&
                    ((!isOPRAllowed && OPR) ||
                        (!isCONAllowed && CON) ||
                        (!isOPLAllowed && OPL) ||
                        (!isEXEAllowed && EXE) ||
                        (!isCompanyAllowed && company))
                "
            >
                <app-bundle-picker
                    v-model="selectedBundle"
                    :options="bundles"
                    v-if="bundles.length > 1"
                    :label="$t('order.project.billBundle')"
                ></app-bundle-picker>
                <div class="flex gap-2 items-center">
                    <app-button
                        variant="primary"
                        :label="$t('order.project.new.order')"
                        :disabled="
                            orderLoading ||
                            (isCompanyAllowed && isOPLAllowed && isCONAllowed && isOPRAllowed && isEXEAllowed)
                        "
                        @click="orderModules"
                    ></app-button>
                    <icon-rotate-right v-if="orderLoading" class="animate animate-spin"></icon-rotate-right>
                </div>
            </span>
        </app-fieldset>

        <div class="w-full flex flex-col gap-4 pb-40" v-if="licensesOrders.length > 0">
            <app-separator :label="$t('order.project.orders.title')" class="my-2"></app-separator>
            <table class="w-full">
                <thead class="border">
                    <tr class="border font-bold">
                        <td class="border p-1">{{ $t('commons.date') }}</td>
                        <td class="border p-1">{{ $t('order.project.orders.reference') }}</td>
                        <td class="border p-1">{{ $t('order.project.billedBundle') }}</td>
                        <td class="border p-1">{{ $t('commons.state') }}</td>
                        <td class="border p-1">{{ $t('order.project.orders.quoteOrBill') }}</td>
                        <td class="border p-1" v-if="isRoot">{{ $t('order.project.orders.discount') }}</td>
                        <td class="border p-1" v-if="isRoot">{{ $t('order.project.orders.totalTTC') }}</td>
                        <td class="border p-1"></td>
                    </tr>
                </thead>
                <tbody class="text-sm">
                    <tr v-for="(order, index) in licensesOrders" class="border">
                        <td class="border p-2">
                            {{ order.createdAt | humanizeDate }}
                        </td>
                        <td class="border p-2">
                            {{ order.reference }}
                        </td>
                        <td class="border p-2">
                            {{ order.bundle ? order.bundle.label : '' }}
                        </td>
                        <td class="border p-2">
                            <span v-if="!order.validatedOn">
                                {{ $t('order.project.orders.statusPendingQuote') }}
                            </span>
                            <span v-else-if="!order.billingDate">
                                {{ $t('order.project.orders.statusPendingPayment') }}
                            </span>
                            <span v-else>{{ $t('order.project.orders.statusOk') }}</span>
                        </td>
                        <td class="border p-2">
                            <span v-if="order.billUrl">
                                <app-file-link
                                    class="underline"
                                    :url="order.billUrl"
                                    :file-name="$t('order.project.orders.bill') + '_' + order.reference + '.pdf'"
                                />
                            </span>
                            <span v-else-if="order.quoteUrl" class="flex">
                                <app-file-link
                                    class="underline"
                                    :url="order.quoteUrl"
                                    :file-name="$t('order.project.orders.quote') + '_' + order.reference + '.pdf'"
                                />
                                <a
                                    :href="`/api/projects/${$route.params.projectId}/licensesOrders/${order.id}/mail.eml`"
                                    class="focus:outline-none focus:ring font-bold cursor-pointer p-2 border hover:border-black"
                                    target="_blank"
                                    download
                                    :title="$t('commons.downloadMail')"
                                    v-if="isRoot && !!order.validatedOn"
                                >
                                    <icon-email-outline width="16" height="16" />
                                </a>
                            </span>
                        </td>
                        <td v-if="isRoot" class="border p-2">
                            <div class="flex gap-2 items-center justify-between">
                                <span :title="order.discountLabel">
                                    {{ $n(order.discountAmount, 'currency') }}
                                </span>
                                <app-button
                                    size="mini"
                                    icon="icon-pencil"
                                    v-if="!order.billingDate"
                                    @click="editDiscount(order)"
                                ></app-button>
                            </div>
                        </td>
                        <td v-if="isRoot" class="border p-2" :class="{ 'italic text-gray-700': !order.billingDate }">
                            {{ $n(order.totalTTC, 'currency') }}
                        </td>
                        <td class="border p-2">
                            <app-button
                                v-if="isRoot"
                                :disabled="!!order.validatedOn"
                                size="mini"
                                @click="onValidateOrder(order)"
                                icon="icon-check"
                                icon-class="text-green-900 mx-2"
                            >
                                {{ $t('order.project.orders.validateQuote') }}
                            </app-button>
                            <app-button
                                v-if="isRoot"
                                :disabled="!order.validatedOn || !!order.billingDate"
                                size="mini"
                                @click="onValidateOrderBilling(order)"
                                icon="icon-currency-eur"
                                icon-class="text-green-900 mx-2"
                            >
                                {{ $t('order.project.orders.validateBilling') }}
                            </app-button>
                            <app-button
                                v-if="isRoot && order.billingDate && index === licensesOrders.length - 1"
                                size="mini"
                                @click="onResetOrder(order)"
                                icon="icon-undo"
                                icon-class="text-red-700 mx-2"
                            >
                                {{ $t('order.project.orders.reset') }}
                            </app-button>
                            <app-button
                                size="mini"
                                class="p-2 my-1"
                                @click="removeLicensesOrder(order)"
                                v-if="!order.billingDate"
                                icon="icon-close"
                                icon-class="text-red-700 mx-2"
                            >
                                {{ $t('order.project.orders.cancel') }}
                            </app-button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!--<div v-if="pendingOrder && pendingOrder.totalTTC > 0" class="flex justify-center col-span-2">
                <PayPal :licensesOrderId="pendingOrder.id" class="max-w-1/2" @paid="refreshLicensesOrders">
                    <div class="flex flex-col justify-start gap-2">
                        <span>
                            {{ $t('order.licensesOrder.HTAMount') }}
                            {{ $n(pendingOrder.totalHT, 'currency') }}
                        </span>
                        <span>
                            {{ $t('order.licensesOrder.TTCAMount') }}
                            {{ $n(pendingOrder.totalTTC, 'currency') }}
                        </span>
                    </div>
                </PayPal>
            </div>-->
        </div>
        <app-popup ref="discountPopup">
            <app-separator :label="$t('order.licensesOrder.discountTitle')"></app-separator>
            <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
                <form class="p-2 flex flex-col gap-2">
                    <app-number-input
                        v-model="discountAmount"
                        :label="$t('order.licensesOrder.discountAmount')"
                    ></app-number-input>
                    <app-textarea
                        v-model="discountLabel"
                        :label="$t('order.licensesOrder.discountLabel')"
                    ></app-textarea>
                    <div class="flex items-center gap-2">
                        <app-button
                            @click="saveDiscount()"
                            :disabled="invalid || discountLoading"
                            :label="$t('commons.validate')"
                            variant="primary"
                        />
                        <template v-if="discountLoading">
                            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                            {{ $t('order.licensesOrder.quotePending') }}
                        </template>
                    </div>
                </form>
            </ValidationObserver>
        </app-popup>
        <app-popup ref="validationPopup">
            <app-separator :label="$t('order.licensesOrder.validateTitle')"></app-separator>
            <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
                <form class="p-2 flex flex-col gap-2">
                    <app-date-input
                        :required="true"
                        v-model="billingDate"
                        :label="$t('order.licensesOrder.billingDate')"
                    ></app-date-input>
                    <app-textarea
                        v-model="billingReference"
                        :label="$t('order.licensesOrder.billingReference')"
                    ></app-textarea>
                    <div class="flex items-center gap-2">
                        <app-button
                            @click="validateLicensesOrder()"
                            :disabled="invalid || billingLoading"
                            :label="$t('commons.validate')"
                            variant="primary"
                        />
                        <template v-if="billingLoading">
                            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                            {{ $t('order.licensesOrder.billingLoading') }}
                        </template>
                    </div>
                </form>
            </ValidationObserver>
        </app-popup>
    </main>
</template>

<script>
import AppSelect from '../../components/appSelect/AppSelect';
import { error, notyf } from '../toasts/toats.service';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppFooter from '../../components/appFooter/AppFooter';
import AppLegend from '../../components/appLegend/AppLegend';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppUploadButton from '../../components/app-uploadButton/AppUploadButton';
import { getProject, updateProject } from './projects.service';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppTips from '@/components/app-tips/AppTips';
import ProjectReadOnly from './ProjectReadOnly';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import IconCheck from '@/icons/IconCheck.vue';
import IconClose from '@/icons/IconClose.vue';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';
import PayPal from '@/features/paypal/PayPal.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import {
    createLicensesOrder,
    getLicensesOrders,
    removeLicensesOrder,
    updateLicensesOrder,
    updateLicensesOrderDiscount,
    updateLicensesOrderValidation,
} from '@/features/projects/licensesOrders.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppBundlePicker from '@/components/appBundlePicker.vue';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLoggedUser } from '@/features/tokens/token.service';
import { replicateProjects } from '@/rxdb/database';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppTextarea from '@/components/app-textarea/AppTextarea.vue';
import { getMapById } from '@/services/sanitize.service';
import IconEmailOutline from '@/icons/IconEmailOutline.vue';

export default {
    components: {
        IconEmailOutline,
        AppTextarea,
        AppPopup,
        AppBundlePicker,
        AppButton,
        AppSeparator,
        PayPal,
        AppFileLink,
        IconClose,
        IconCheck,
        AppCheckbox,
        AppNumberInput,
        ProjectReadOnly,
        AppTips,
        AppSaveOnLeave,
        AppUploadButton,
        AppDateInput,
        AppLegend,
        AppFooter,
        AppFieldset,
        AppInputText,
        AppSelect,
    },
    props: {
        showDates: { type: Boolean, default: true },
        showPhase: { type: Boolean, default: true },
    },
    created() {
        this.subscriptions = [
            combineLatest([
                getBundles(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
            ]).subscribe(async ([bundles, project]) => {
                if (project === null) {
                    this.project = {};
                    error(this.$t('project.information.generals.unknown'));
                    this.$router.push({ name: 'home' });
                } else {
                    const token = getLoggedUser();
                    this.isRoot = token && token.roles.includes('root');
                    this.isOwner = project.createdBy === project.me.id;
                    this.bundles = this.isRoot
                        ? bundles
                        : bundles.filter((bundle) => project.me.bundleIds.includes(bundle.id));
                    this.selectedBundle = this.bundles[0];
                    this.bundleMap = getMapById(bundles);
                    this.refreshLicensesOrders();
                    this.realOnly = !project.me.allowedFeatures.includes('project_generals');
                    this.isEXEAllowed = project.projectFeatures.includes('project_EXE');
                    this.isOPRAllowed = project.projectFeatures.includes('project_OPR');
                    this.isCONAllowed = project.projectFeatures.includes('project_CON');
                    this.isOPLAllowed = project.projectFeatures.includes('project_OPL');
                    this.isAPAAllowed = project.projectFeatures.includes('project_OPL');
                    this.isCompanyAllowed = project.projectFeatures.includes('project_company');
                    this.CON = this.isCONAllowed;
                    this.EXE = this.isEXEAllowed;
                    this.OPR = this.isOPRAllowed;
                    this.OPL = this.isOPLAllowed;
                    this.company = this.isCompanyAllowed;
                    this.project = project;
                }
            }),
        ];
    },
    methods: {
        editDiscount(order) {
            this.discountAmount = null;
            this.discountLabel = null;
            this.selectedLicensesOrder = order;
            this.$refs.discountPopup.open();
        },
        onValidateOrder(order) {
            order.validatedOn = new Date();
            return updateLicensesOrderValidation(this.$route.params.projectId, order.id);
        },
        onValidateOrderBilling(order) {
            this.billingDate = null;
            this.billingReference = null;
            this.selectedLicensesOrder = order;
            this.$refs.validationPopup.open();
        },
        async onResetOrder(order) {
            if (await confirm(this.$t('order.licensesOrder.confirmReset'))) {
                await updateLicensesOrder(this.$route.params.projectId, order.id, null, null);
                order.billingDate = null;
                order.billingReference = null;
                await this.refreshLicensesOrders();
            }
        },
        async saveDiscount() {
            this.discountLoading = true;
            await updateLicensesOrderDiscount(
                this.$route.params.projectId,
                this.selectedLicensesOrder.id,
                this.discountAmount,
                this.discountLabel,
            );
            this.selectedLicensesOrder.discountAmount = this.discountAmount;
            this.selectedLicensesOrder.discountLabel = this.discountLabel;
            await this.refreshLicensesOrders();
            this.$refs.discountPopup.close();
            this.discountLoading = false;
        },
        async orderModules() {
            this.orderLoading = true;
            try {
                const licensesOrder = await createLicensesOrder(this.$route.params.projectId, {
                    bundleId: this.selectedBundle?.id,
                    modules: {
                        CON: this.CON && !this.isCONAllowed,
                        EXE: this.EXE && !this.isEXEAllowed,
                        OPR: this.OPR && !this.isOPRAllowed,
                        OPL: this.OPL && !this.isOPLAllowed,
                        company: this.company && !this.isCompanyAllowed,
                    },
                });
                this.licensesOrders.push(licensesOrder);
                await replicateProjects();
            } catch (e) {
                notyf.error(this.$t('order.licensesOrder.quoteConflict'));
            } finally {
                this.orderLoading = false;
            }
        },
        async refreshLicensesOrders() {
            const licensesOrders = await getLicensesOrders(this.$route.params.projectId);
            this.licensesOrders = licensesOrders.map((licensesOrder) => ({
                ...licensesOrder,
                bundle: this.bundleMap[licensesOrder.bundleId],
            }));
        },
        changeImage: function ({ url }) {
            this.project.imageUrl = url;
            this.saveProject();
        },
        async onArchive() {
            if (this.project.archivedAt) {
                this.project.archivedAt = null;
                await this.saveProject();
            } else {
                if (await confirm(this.$t('projects.confirmArchive'))) {
                    this.project.archivedAt = new Date().toISOString();
                    await this.saveProject();
                }
            }
        },
        saveProject: async function () {
            await updateProject(this.project);
            this.$refs.observer.reset();
        },
        async removeLicensesOrder(order) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                await removeLicensesOrder(this.$route.params.projectId, order.id);
                this.licensesOrders = this.licensesOrders.filter((licensesOrder) => licensesOrder.id !== order.id);
                await replicateProjects();
            }
        },
        async validateLicensesOrder() {
            this.billingLoading = true;
            await updateLicensesOrder(
                this.$route.params.projectId,
                this.selectedLicensesOrder.id,
                this.billingDate,
                this.billingReference,
            );
            await this.refreshLicensesOrders();
            this.$refs.validationPopup.close();
            this.selectedLicensesOrder.billingDate = this.billingDate;
            this.selectedLicensesOrder.billingReference = this.billingReference;
            this.billingLoading = false;
        },
    },
    data() {
        return {
            subscriptions: [],
            selectedLicensesOrder: null,
            licensesOrders: [],
            bundles: [],
            selectedBundle: null,
            project: {},
            bundleMap: {},
            realOnly: true,
            isRoot: false,
            isOwner: false,
            isEXEAllowed: false,
            isOPRAllowed: false,
            isCONAllowed: false,
            isOPLAllowed: false,
            isAPAAllowed: false,
            isCompanyAllowed: false,
            orderLoading: false,
            discountLoading: false,
            billingLoading: false,
            CON: false,
            EXE: false,
            OPR: false,
            OPL: false,
            company: false,
            discountAmount: 0,
            discountLabel: '',
            billingDate: null,
            billingReference: '',
        };
    },
};
</script>
