import { getId, sanitize, sortBy } from '@/services/sanitize.service';
import db from '@/rxdb/database';
import { map } from 'rxjs';
import { getCachedObservable } from '@/rxdb/observablesCache';

export async function createObservation(projectId, observation) {
    const result = await db.getProjectCollections(projectId).observations.insert({
        ...observation,
        recipientIds: observation.recipientIds ? JSON.parse(JSON.stringify(observation.recipientIds)) : [],
        dueDate: observation.dueDate ? observation.dueDate.toISOString() : new Date().toISOString(),
        validatedAt: observation.validatedAt ? observation.validatedAt.toISOString() : null,
        resolvedAt: observation.resolvedAt ? observation.resolvedAt.toISOString() : null,
        reportedOnPhase: observation.phase,
        reportedAt: new Date().toISOString(),
    });
    return result.toJSON();
}

export async function bulkInsert(projectId, observations) {
    return db.getProjectCollections(projectId).observations.bulkInsert(observations);
}

export function getObservationsForGroupDashboard(projectId, restrictedToPhases) {
    return db
        .getProjectCollections(projectId)
        .observations.find({
            selector: {
                ...(restrictedToPhases ? { phase: { $in: restrictedToPhases } } : {}),
                type: { $nin: ['general', 'preparationVisa', 'synthesis'] },
                obsoleteAt: null,
            },
        })
        .$.pipe(map((items) => items.map(mapObservation)));
}

export function getObservations(projectId) {
    return db
        .getProjectCollections(projectId)
        .observations.find({ selector: {} })
        .$.pipe(
            map((items) =>
                items
                    .map(mapObservation)
                    .filter(
                        (observation) => observation.type !== 'preparationVisa' && observation.type !== 'synthesis',
                    ),
            ),
        );
}

export function getAllObservations(projectId) {
    return getCachedObservable('getAllObservations_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .observations.find({ selector: {}, sort: [{ index: 'asc' }] })
            .$.pipe(map((items) => items.map(mapObservation))),
    );
}

export function getObservationsForRelatedContent(projectId, relatedContentId) {
    return db
        .getProjectCollections(projectId)
        .observations.find({ selector: { relatedContentId } })
        .$.pipe(map((items) => items.map(mapObservation)));
}
export function getObservationsSyntheses(projectId, observationId) {
    return db
        .getProjectCollections(projectId)
        .observations.findOne({
            selector: { relatedObservationIds: { $elemMatch: { $eq: observationId } } },
        })
        .exec();
}
export function getAllObservationsSyntheses(projectId) {
    return db
        .getProjectCollections(projectId)
        .observations.find({
            selector: { type: 'synthesis' },
        })
        .$.pipe(map((items) => items.map(mapObservation)));
}
export async function getVisaObservations(projectId) {
    const observations = await db
        .getProjectCollections(projectId)
        .observations.find({
            selector: { type: 'preparationVisa' },
        })
        .exec();
    return observations.map(mapObservation);
}
export async function queryObservationsForRelatedContent(projectId, relatedContentId) {
    const observations = await db
        .getProjectCollections(projectId)
        .observations.find({
            selector: { relatedContentId },
        })
        .exec();
    return observations.map(mapObservation);
}
export async function queryObservations(projectId, observationId) {
    const observation = await db
        .getProjectCollections(projectId)
        .observations.findOne({
            selector: { id: observationId },
        })
        .exec();
    return mapObservation(observation);
}

function mapObservation(observationDb) {
    return observationDb
        ? {
              ...observationDb.toMutableJSON(),
              dueDate: observationDb.dueDate ? new Date(observationDb.dueDate) : null,
              resolvedAt: observationDb.resolvedAt ? new Date(observationDb.resolvedAt) : null,
              validatedAt: observationDb.validatedAt ? new Date(observationDb.validatedAt) : null,
              reportedAt: observationDb.reportedAt ? new Date(observationDb.reportedAt) : null,
              obsoleteAt: observationDb.obsoleteAt ? new Date(observationDb.obsoleteAt) : null,
          }
        : null;
}

export async function updateObservation(projectId, observation) {
    const dbObservation = await db
        .getProjectCollections(projectId)
        .observations.findOne({ selector: { id: observation.id } })
        .exec();
    const patch = { ...JSON.parse(JSON.stringify(observation)) };
    if (observation.dueDate === null || !!observation.dueDate) {
        patch.dueDate = observation.dueDate ? observation.dueDate.toISOString() : null;
    }
    if (observation.validatedAt === null || !!observation.validatedAt) {
        patch.validatedAt = observation.validatedAt ? observation.validatedAt.toISOString() : null;
    }
    if (observation.resolvedAt === null || !!observation.resolvedAt) {
        patch.resolvedAt = observation.resolvedAt ? observation.resolvedAt.toISOString() : null;
    }
    if (observation.resolvedBy === null || observation.resolvedBy) {
        patch.resolvedBy = getId(observation.resolvedBy);
    }
    if (observation.validatedBy === null || observation.validatedBy) {
        patch.validatedBy = getId(observation.validatedBy);
    }
    if (observation.obsoleteBy === null || observation.obsoleteBy) {
        patch.obsoleteBy = getId(observation.obsoleteBy);
    }
    if (observation.reportedBy === null || observation.reportedBy) {
        patch.reportedBy = getId(observation.reportedBy);
    }
    return dbObservation.atomicPatch(patch);
}
export function getObservationClass(item) {
    if (!item) return null;
    if (item.obsoleteAt) {
        return 'observations-obsolete';
    } else if (!item.resolvedAt && (item.dueDate < Date.now() || item.preparationDocumentId !== 'preparationVisa')) {
        return 'observations-to-fix-late';
    } else if (!item.resolvedAt) {
        return 'observations-to-fix';
    } else if (item.resolvedAt && !item.validatedAt) {
        return 'observations-done';
    } else if (item.resolvedAt && item.validatedAt) {
        return 'observations-validated';
    }
}

export function getObservation(projectId, observationId) {
    return db
        .getProjectCollections(projectId)
        .observations.findOne({ selector: { id: observationId } })
        .$.pipe(map(mapObservation));
}

export function sanitizeObservation(observation, toOmit = []) {
    return {
        ...sanitize(observation, [
            'draggable',
            'support',
            'emitter',
            'attachments',
            'zone',
            'index',
            'room',
            'recipient',
            'recipients',
            'recipientIds',
            /*if used as markers*/
            'color',
            'classes',
            ...toOmit,
        ]),
        roomId: getId(observation.room),
        supportId: observation.supportId || getId(observation.support),
        zoneId: getId(observation.zone),
        validatedBy: getId(observation.validatedBy),
        reportedBy: getId(observation.reportedBy),
        obsoleteBy: getId(observation.obsoleteBy),
        resolvedBy: getId(observation.resolvedBy),
    };
}

export async function removeObservation(projectId, observationId) {
    const entity = await db
        .getProjectCollections(projectId)
        .observations.findOne({ selector: { id: observationId } })
        .exec();
    return entity.remove();
}

export function sortObservations(observations) {
    return sortBy(observations, 'dueDate');
}

export default {
    updateObservation,
};
