<template>
    <main class="max-w-5xl m-5 max-h-full mb-10 w-full">
        <div class="border p-2 opacity-75 bg-violet-700 text-white flex gap-4" v-if="observation.type === 'private'">
            <icon-incognito width="48" height="48"></icon-incognito>
            <div class="flex flex-col">
                <span class="text-bold">{{ $t('project.follow.observation.incognitoWarning') }}</span>
                <span class="text-sm italic">
                    {{ $t('project.follow.observation.incognitoWarningTips') }}
                </span>
            </div>
        </div>
        <AppLegend />
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2" @submit.prevent="">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        class="col-span-2"
                        :label="$t('commons.index')"
                        :value="observation.index ? observation.index + 1 : ''"
                        :disabled="true"
                    />
                    <app-text-editor
                        ref="titleInput"
                        class="col-span-2"
                        :label="$t('project.follow.observation.titleLabel')"
                        v-model="observation.title"
                        :required="true"
                        :showToolbar="true"
                        end-point="documents?scope=observationContent"
                        @blur="saveText()"
                        :disabled="!(isAdmin || isReporter)"
                    ></app-text-editor>

                    <app-select
                        class="col-span-2"
                        :label="$t('project.follow.observation.type')"
                        v-model="observation.type"
                        :required="true"
                        v-if="observation.type !== 'autoControl' && observation.type !== 'reception'"
                        :disabled="!(isAdmin || isReporter)"
                        @input="save()"
                    >
                        <option value="onDoneWork">{{ $t('observations.types.onDoneWork') }}</option>
                        <option value="general">{{ $t('observations.types.general') }}</option>
                        <option value="administrative">
                            {{ $t('observations.types.administrative') }}
                        </option>
                        <option value="onTodoWork">{{ $t('observations.types.onTodoWork') }}</option>
                        <option value="private">{{ $t('observations.types.private') }}</option>
                        <option value="other">{{ $t('observations.types.other') }}</option>
                    </app-select>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="selectedRecipients"
                        @check="onCheck"
                        @uncheck="onUncheck"
                        :options="bundles"
                        :label="$t('project.follow.observation.recipients')"
                        v-if="observation.type !== 'autoControl' && observation.type !== 'general'"
                        :disabled="!(isAdmin || isReporter)"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option" />
                        </template>
                    </app-multi-picker>

                    <app-select
                        class="col-span-2"
                        :label="$t('commons.currentPhase')"
                        v-model="observation.phase"
                        :required="true"
                        v-if="observation.type !== 'autoControl' && observation.type !== 'reception'"
                        :disabled="!(isAdmin || isReporter)"
                        @input="save()"
                    >
                        <option value="CON" :disabled="!isCONAllowed">{{ $t('commons.phases.CON') }}</option>
                        <option value="EXE" :disabled="!isEXEAllowed">{{ $t('commons.phases.EXE') }}</option>
                        <option value="OPR" :disabled="!isOPRAllowed">{{ $t('commons.phases.OPR') }}</option>
                        <option value="Receipt" :disabled="!isOPRAllowed">{{ $t('commons.phases.Receipt') }}</option>
                        <option value="OPL" :disabled="!isOPLAllowed">{{ $t('commons.phases.OPL') }}</option>
                        <option value="Delivery" :disabled="!isOPLAllowed">{{ $t('commons.phases.Delivery') }}</option>
                        <option value="APA" :disabled="!isAPAAllowed">{{ $t('commons.phases.APA') }}</option>
                    </app-select>

                    <app-date-input
                        class="col-span-2"
                        v-model="observation.dueDate"
                        :label="$t('project.follow.observation.dueDate')"
                        @input="save()"
                        :disabled="!(isAdmin || isReporter)"
                    ></app-date-input>
                </app-fieldset>

                <app-fieldset class="col-span-2 mt-4" :label="$t('project.follow.observation.status')">
                    <div class="col-span-2 flex">
                        <observation-status
                            class="col-span-2 w-full"
                            :observation="observation"
                            @input="saveStatus($event)"
                            @remove="removeObservation()"
                        ></observation-status>
                    </div>
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-4" :label="$t('project.follow.observation.attachments')">
                    <div class="col-span-2 flex gap-2">
                        <app-multiple-upload-button
                            ref="upload"
                            icon="icon-paperclip"
                            size="mini"
                            :label="$t('observations.newAttachment')"
                            :end-point="`/api/projects/${$route.params.projectId}/observations/${observation.id}/attachment`"
                            :resize="1024"
                            :name="$t('project.follow.weatherIssue.newAttachment')"
                            class="text-sm offline:hidden"
                            v-if="isAdmin || isReporter"
                            @loaded="onNewAttachment"
                        />
                        <app-offline-upload-button
                            ref="fileInput"
                            icon="icon-paperclip"
                            size="mini"
                            :label="$t('observations.newAttachment')"
                            class="text-sm hidden offline:block"
                            @select="onNewOfflineAttachment"
                        />
                        <app-button
                            class="px-2"
                            size="mini"
                            icon="icon-camera-outline"
                            v-if="navigator.userAgent.indexOf('Windows NT') >= 0"
                            @click="takePicture"
                        ></app-button>
                        <app-capturePhoto
                            ref="camera"
                            @input="onNewOfflineAttachment({ dataUrl: $event })"
                        ></app-capturePhoto>
                    </div>
                    <div class="col-span-2 flex gap-2 flex-wrap">
                        <template v-for="attachment in observation.attachments">
                            <app-file-link
                                :fileName="attachment.name"
                                :url="attachment.url"
                                v-if="attachment.url && attachment.url.endsWith('pdf')"
                                :to="{
                                    name: 'fileViewer',
                                    params: {
                                        projectId: $route.params.projectId,
                                    },
                                    query: {
                                        url: attachment.url,
                                        backName: $t('observations.title'),
                                    },
                                }"
                                :removable="isAdmin || isReporter"
                                @delete="onDeleteAttachment(attachment)"
                            ></app-file-link>
                            <app-photo
                                v-else
                                @click.native="openPhotoPopup(attachment)"
                                :url="attachment.url || attachment.dataUrl"
                                :removable="isAdmin || isReporter"
                                class="m-2 cursor-pointer"
                                @delete="onDeleteAttachment(attachment)"
                            ></app-photo>
                        </template>
                    </div>
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-4" :label="$t('project.follow.observation.location')">
                    <app-picker
                        class="col-span-2"
                        v-model="observation.zone"
                        :options="zones"
                        :label="$t('project.follow.observation.zone')"
                        :show-label="true"
                        label-key="fullName"
                        @input="onZoneChange"
                        :disabled="!(isAdmin || isReporter)"
                    >
                        <template v-slot:option="{ option }">
                            <span class="mr-2 p-2">
                                {{ option.parent.parent.name }} > {{ option.parent.name }} > {{ option.name }}
                            </span>
                        </template>
                    </app-picker>
                    <app-picker
                        class="col-span-2"
                        v-model="observation.room"
                        :options="observation.zone ? observation.zone.rooms : []"
                        :label="$t('project.follow.observation.local')"
                        :show-label="true"
                        :disabled="!observation.zone || !(isAdmin || isReporter)"
                        @input="save()"
                    ></app-picker>
                    <div class="col-span-2 flex justify-end" v-if="observation.footprint">
                        <app-button
                            :label="$t('observations.removeMarkerFromMap')"
                            @click="removeFootprint()"
                            variant="danger"
                            size="mini"
                        />
                    </div>
                    <app-leaflet-viewer
                        class="col-span-2 mx-5 min-h-main"
                        v-if="observation.zone && observation.zone.supports[0]"
                        :src="observation.zone.supports[0].url"
                        :crop-box="observation.zone.supports[0].cropBox"
                        :page="1"
                        :markers="markers"
                        :rotation="observation.zone.supports[0].rotation"
                        :allowMarkerMove="isAdmin || isReporter"
                        @markerMoved="onMarkerMoved"
                        @pickPosition="onMarkerMoved"
                        :longPressToPick="false"
                    ></app-leaflet-viewer>
                </app-fieldset>
                <AppWarnOnLeave
                    :errors="errors"
                    :dirty="dirty"
                    :invalid="invalid"
                    ref="warnOnLeave"
                    v-if="isAdmin || isReporter || isRecipient"
                />
                <app-popup ref="popup" class="md:max-w-3/4 min-h-main max-h-main" :show-header="true">
                    <app-photo-editor
                        v-if="selectedAttachment"
                        :attachment="selectedAttachment"
                        :read-only="!(isAdmin || (selectedAttachment.createdBy === me.id && !observation.validatedAt))"
                    ></app-photo-editor>
                </app-popup>
            </form>
            <app-save-on-leave :dirty="dirty" :saveFn="saveOnLeave"></app-save-on-leave>
        </ValidationObserver>
    </main>
</template>

<script>
import AppLegend from '../../components/appLegend/AppLegend';
import { get, omit, sortBy } from '@/services/sanitize.service';
import AppSelect from '../../components/appSelect/AppSelect';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppFooter from '../../components/appFooter/AppFooter';
import AppWarnOnLeave from '../../components/AppWarnOnLeave';
import AppErrors from '../../components/appErrors/AppErrors';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppPicker from '../../components/appPicker/AppPicker';
import AppButton from '../../components/appButton/AppButton';
import AppBundlePicker from '../../components/appBundlePicker';
import AppMultiPicker from '../../components/appMultiPicker/AppMultiPicker';
import { getObservation, getObservationClass, removeObservation, updateObservation } from './observation.service';
import AppNumberInput from '../../components/appNumberInput/AppNumberInput';
import AppSlider from '../../components/appSlider/AppSlider';
import AppUploadButton from '../../components/app-uploadButton/AppUploadButton';
import AppCapturePhoto from '../../components/app-capturePhoto/AppCapturePhoto.vue';
import AppFileLink from '../../components/appFileLink/AppFileLink';
import { confirm } from '../dialogs/dialogs.service';
import AppCheckbox from '../../components/app-checkbox/AppCheckbox';
import AppViewport from '@/components/AppViewport';
import { isMobile, updateBreadCrumbs } from '@/state/state';
import AppPhoto from '@/components/appPhoto/AppPhoto';
import AppPopup from '@/components/app-popup/AppPopup';
import AppPhotoEditor from '@/components/appPhotoEditor/AppPhotoEditor';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppBundle from '@/components/app-bundle/appBundle';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer';
import AppTextEditor from '@/components/appTextEditor/AppTextEditor';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import { queryProject } from '@/features/projects/projects.service';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { combineLatest } from 'rxjs';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getSupports } from '@/features/supports/supports.service';
import {
    createObservationAttachment,
    getObservationAttachmentsByObservationId,
    removeObservationAttachment,
} from '@/features/observations/observationAttachments.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import AppOfflineUploadButton from '@/components/app-offlineUploadButton/AppOfflineUploadButton';
import ObservationStatus from '@/features/observations/ObservationStatus';
import IconIncognito from '@/icons/IconIncognito.vue';
import AppTips from '@/components/app-tips/AppTips.vue';
export default {
    components: {
        AppTips,
        IconIncognito,
        ObservationStatus,
        AppOfflineUploadButton,
        AppMultipleUploadButton,
        AppSaveOnLeave,
        AppTextEditor,
        AppLeafletViewer,
        AppBundle,
        AppSeparator,
        AppPhotoEditor,
        AppPopup,
        AppPhoto,
        AppViewport,
        AppCheckbox,
        AppFileLink,
        AppUploadButton,
        AppSlider,
        AppNumberInput,
        AppMultiPicker,
        AppBundlePicker,
        AppButton,
        AppPicker,
        AppDateInput,
        AppErrors,
        AppWarnOnLeave,
        AppFooter,
        AppFieldset,
        AppInputText,
        AppSelect,
        AppLegend,
        AppCapturePhoto,
    },
    props: {
        id: String,
        type: { type: String, default: null },
        saveFn: { type: Function, default: () => null },
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.me = project.me;
            this.isEXEAllowed = project.projectFeatures.includes('project_EXE');
            this.isOPRAllowed = project.projectFeatures.includes('project_OPR');
            this.isCONAllowed = project.projectFeatures.includes('project_CON');
            this.isOPLAllowed = project.projectFeatures.includes('project_OPL');
            this.isAdmin = project.me.allowedFeatures.includes('project_observations');
            this.isValidator = project.me.allowedFeatures.includes('project_observations_MOEValidation');
            this.myBundleIds = project.me.bundleIds;
            this.projectPhase = project.phase;
        });
        this.init();
    },
    computed: {
        markers() {
            return this.observation.footprint
                ? [
                      {
                          ...this.observation.footprint,
                          title: this.observation.title,
                          label: this.observation.index + 1,
                          classes: this.observation.classes,
                          draggable: true,
                      },
                  ]
                : [];
        },
        isReporter() {
            return this.myBundleIds && !!this.myBundleIds.includes(this.observation.reportedBy);
        },
        isRecipient() {
            if (this.observation.recipientIds && this.observation.recipientIds.length > 0) {
                return (
                    this.myBundleIds &&
                    !!this.myBundleIds.some((bundleId) =>
                        this.observation.recipientIds.find((recipientId) => recipientId === bundleId),
                    )
                );
            }
        },
    },
    watch: {
        id: function () {
            this.init();
            this.hasBeenDeleted = false;
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getObservation(this.$route.params.projectId, this.id),
                    getLocationsTree(this.$route.params.projectId),
                    getSupports(this.$route.params.projectId),
                    getObservationAttachmentsByObservationId(this.$route.params.projectId, this.id),
                ]).subscribe(([bundleMap, observation, folders, supports, attachments]) => {
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.zones = folders
                        .reduce((acc, folder) => {
                            return [
                                ...acc,
                                ...folder.locations.reduce((acc, location) => {
                                    return [...acc, ...location.zones];
                                }, []),
                            ];
                        }, [])
                        .map((zone) => ({
                            ...zone,
                            supports: supports.filter(
                                (support) => support.locationIds.includes(zone.id) && support.type === 'graphic',
                            ),
                        }));
                    const zone = observation.zoneId ? this.zones.find((zone) => zone.id === observation.zoneId) : null;
                    this.observation = {
                        ...observation,
                        classes: getObservationClass(observation),
                        draggable: true,
                        recipients: observation.recipientIds.map((id) => bundleMap[id]),
                        validator: bundleMap[observation.validatedBy],
                        reporter: bundleMap[observation.reportedBy],
                        obsoleter: bundleMap[observation.obsoleteBy],
                        resolver: bundleMap[observation.resolvedBy],
                        zone,
                        room: zone ? zone.rooms.find((room) => room.id === observation.roomId) : null,
                        attachments,
                    };
                    updateBreadCrumbs({
                        observationName: this.observation.index
                            ? '#' + (this.observation.index + 1).toString().padStart(3, '0')
                            : '',
                    });
                    if (this.selectedAttachment) {
                        this.selectedAttachment = this.observation.attachments.find(
                            (attachment) => attachment.url === this.selectedAttachment.url,
                        );
                    }
                    this.selectedRecipients = this.observation.recipients;
                    if (!isMobile && this.observation.title === this.$t('project.follow.observation.newObservation')) {
                        if (!this.focusOnce) {
                            this.$nextTick(() => this.$refs.titleInput.focus());
                            this.focusOnce = true;
                        }
                    }
                }),
            ];
        },
        takePicture() {
            this.$refs.camera.start();
        },
        onNewAttachment(attachment) {
            createObservationAttachment(this.$route.params.projectId, attachment);
        },
        onNewOfflineAttachment({ dataUrl }) {
            createObservationAttachment(this.$route.params.projectId, { dataUrl, observationId: this.observation.id });
        },
        onUncheck(bundles) {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                recipientIds: this.observation.recipientIds.filter((id) => !bundles.find((bundle) => bundle.id === id)),
            });
        },
        onCheck(bundles) {
            return updateObservation(this.$route.params.projectId, {
                id: this.observation.id,
                recipientIds: [...this.observation.recipientIds, ...bundles.map((bundle) => bundle.id)],
            });
        },
        openPhotoPopup(attachment) {
            this.selectedAttachment = attachment;
            this.$refs.popup.open();
        },
        beforeRouteLeave(to, from, next) {
            if (this.isAdmin || this.isReporter || this.isRecipient) {
                this.$refs.warnOnLeave.beforeRouteLeave(() => this.saveOnLeave(), null, next);
            }
        },
        get,
        onZoneChange() {
            this.observation.footprint = null;
            this.observation.room = this.observation.zone ? this.observation.zone.rooms[0] : null;
            this.save();
        },
        onMarkerMoved(event) {
            if (this.isAdmin || this.isReporter) {
                this.observation.footprint = { x: event.x, degree: event.degree, y: event.y, type: 'marker', page: 1 };
                this.observation.supportId = this.observation.zone?.supports[0]
                    ? this.observation.zone?.supports[0].id
                    : null;
                this.save();
            }
        },
        removeFootprint() {
            if (this.isAdmin || this.isReporter) {
                this.observation.footprint = null;
                this.observation.supportId = null;
                this.save();
            }
        },
        async onDeleteAttachment(attachment) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                return removeObservationAttachment(this.$route.params.projectId, attachment.id);
            }
        },
        async removeObservation() {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                await removeObservation(this.$route.params.projectId, this.observation.id);
                this.hasBeenDeleted = true;
                this.$emit('deleted');
            }
        },
        safePhase() {
            const phase = this.observation.phase || this.projectPhase;
            if (phase === 'CON' && this.isCONAllowed) {
                return phase;
            } else if (phase === 'EXE' && this.isEXEAllowed) {
                return phase;
            } else if ((phase === 'OPR' || phase === 'Receipt') && this.isOPRAllowed) {
                return phase;
            } else if ((phase === 'OPL' || phase === 'Delivery') && this.isOPLAllowed) {
                return phase;
            } else if (phase === 'APA' && this.isOPLAllowed) {
                return phase;
            } else {
                if (this.isCONAllowed) {
                    return 'CON';
                } else if (this.isEXEAllowed) {
                    return 'EXE';
                } else if (this.isOPRAllowed) {
                    return 'OPR';
                } else if (this.isOPLAllowed) {
                    return 'OPL';
                }
            }
        },
        saveText() {
            this.$nextTick(() => this.save());
        },
        cleanEntity(observation) {
            return {
                ...omit(observation, ['attachments', 'validator', 'reporter', 'obsoleter', 'resolver']),
                phase: this.safePhase(),
            };
        },
        async save(preventRouting) {
            this.saveFn(this.cleanEntity(this.observation), preventRouting);
            this.$nextTick(() => (this.$refs.observer ? this.$refs.observer.reset() : null));
        },
        async saveStatus(observation) {
            this.saveFn(
                this.cleanEntity({
                    ...observation,
                    title: this.observation.title,
                    /* workaround for blur Quilljs bug.
                        When you change title content and directly click on a new status,
                         the observation title is not saved : just reverted
                         */
                }),
            );
        },
        saveOnLeave() {
            if (!this.hasBeenDeleted) {
                this.save(true);
            }
        },
    },
    data() {
        return {
            me: null,
            navigator: navigator,
            focusOnce: false,
            hasBeenDeleted: false,
            subscriptions: [],
            bundles: [],
            myBundleIds: [],
            selectedAttachment: null,
            companies: [],
            observation: { recipientIds: [] },
            zones: [],
            selectedRecipients: [],
            projectPhase: null,
            isAdmin: false,
            isValidator: false,
            isCONAllowed: false,
            isEXEAllowed: false,
            isOPRAllowed: false,
            isAPAAllowed: false,
            isOPLAllowed: false,
        };
    },
};
</script>
