<template>
    <div>
        <app-separator tag="h1" :label="$t('project.editions.meetingReports.attendanceTitle')"></app-separator>
        <div class="text-xs w-full flex gap-5 justify-end mr-4 my-2">
            <span class="flex gap-1 items-center">
                <icon-alpha-p-circle-outline class="text-green-600" />
                {{ $t('project.editions.meetingReports.present') }}
            </span>
            <span class="flex gap-1 items-center">
                <icon-alpha-e-circle-outline class="text-yellow-600" />
                {{ $t('project.editions.meetingReports.excused') }}
            </span>
            <span class="flex gap-1 items-center">
                <icon-alpha-a-circle-outline class="text-red-600" />
                {{ $t('project.editions.meetingReports.attendanceShortValues.unexcusedAbsent') }}
            </span>
        </div>
        <div class="grid text-xs gap-y-2 items-center" style="grid-template-columns: auto auto 1fr 1fr 1fr 3em 3em 8em">
            <!-- header -->

            <div class="col-span-5"></div>
            <div class="mt-2 flex h-full items-end mx-1">
                <div class="vertical-text">
                    {{ $t('project.editions.meetingReports.attendance') }}
                </div>
            </div>
            <div class="mt-2 mx-1 flex items-end h-full">
                <div class="vertical-text">
                    {{ $t('project.editions.meetingReports.diffusion') }}
                </div>
            </div>
            <div class="mt-2 text-center justify-end flex flex-col h-full mx-1">
                <template v-if="nextMeeting" class="vertical-text">
                    {{ $t('project.editions.meetingReports.nextConvocation') }}
                </template>
            </div>

            <!-- MOA-->
            <app-separator variant="underline" class="col-span-8 my-2 text-base">
                {{ $t('project.editions.meetingReports.moas') }}
            </app-separator>
            <template v-for="bundle in groups.MOA">
                <span class="border-b border-gray-200 h-full">
                    <a :href="'#a_' + bundle.id">
                        <span v-if="bundle.reference" class="pr-1">{{ bundle.reference }} -</span>
                        {{ bundle.name }}
                    </a>
                </span>
                <span class="font-bold border-b border-gray-200 h-full px-2">{{ bundle.company.name }}</span>
                <span class="border-b border-gray-200 h-full whitespace-nowrap">
                    {{ bundle.responsible | contact }}
                </span>
                <app-phone
                    class="border-b border-gray-200 h-full px-2"
                    :number="bundle.responsible ? bundle.responsible.phone : null"
                ></app-phone>
                <app-mail
                    class="border-b border-gray-200 h-full"
                    :email="bundle.responsible ? bundle.responsible.email : null"
                ></app-mail>
                <span class="flex justify-center border-b border-gray-200 h-full">
                    <span v-if="getAttendance(bundle) === 'present'" class="text-green-600">
                        <icon-alpha-p-circle-outline />
                    </span>
                    <span v-if="getAttendance(bundle) === 'excusedAbsent'" class="text-yellow-600">
                        <icon-alpha-e-circle-outline />
                    </span>
                    <span v-if="getAttendance(bundle) === 'unexcusedAbsent'" class="text-red-600">
                        <icon-alpha-a-circle-outline />
                    </span>
                </span>
                <span class="flex justify-center border-b border-gray-200 h-full text-green-600">
                    <span v-if="isRecipient(bundle)" :title="$t('project.editions.meetingReports.diffusion')">
                        <icon-email />
                    </span>
                </span>
                <span class="flex justify-center border-b border-gray-200 h-full">
                    <template v-if="nextMeeting">{{ getNextConvocation(bundle) | humanizeDateTimeShort }}</template>
                </span>
            </template>

            <!-- AMOA -->
            <template v-if="groups.AMOA.length > 0">
                <app-separator variant="underline" class="col-span-8 my-2 text-base">
                    {{ $t('project.editions.meetingReports.amoas') }}
                </app-separator>
                <template v-for="bundle in groups.AMOA">
                    <span class="mr-1 border-b border-gray-200 h-full">
                        <a :href="'#a_' + bundle.id">
                            <span v-if="bundle.reference" class="pr-1">{{ bundle.reference }} -</span>
                            {{ bundle.name }}
                        </a>
                    </span>
                    <span class="font-bold border-b border-gray-200 h-full px-2">{{ bundle.company.name }}</span>
                    <span class="whitespace-nowrap">
                        {{ bundle.responsible | contact }}
                    </span>
                    <app-phone class="px-2" :number="bundle.responsible ? bundle.responsible.phone : null"></app-phone>
                    <app-mail :email="bundle.responsible ? bundle.responsible.email : null"></app-mail>
                    <span class="flex justify-center border-b border-gray-200 h-full">
                        <span v-if="getAttendance(bundle) === 'present'" class="text-green-600">
                            <icon-alpha-p-circle-outline />
                        </span>
                        <span v-if="getAttendance(bundle) === 'excusedAbsent'" class="text-yellow-600">
                            <icon-alpha-e-circle-outline />
                        </span>
                        <span v-if="getAttendance(bundle) === 'unexcusedAbsent'" class="text-red-600">
                            <icon-alpha-a-circle-outline />
                        </span>
                    </span>
                    <span class="flex justify-center border-b border-gray-200 h-full text-green-600">
                        <span v-if="isRecipient(bundle)" :title="$t('project.editions.meetingReports.diffusion')">
                            <icon-email />
                        </span>
                    </span>
                    <span class="flex justify-center border-b border-gray-200 h-full">
                        <template v-if="nextMeeting">{{ getNextConvocation(bundle) | humanizeDateTimeShort }}</template>
                    </span>
                </template>
            </template>

            <!-- MOE -->
            <app-separator variant="underline" class="col-span-8 my-2 text-base">
                {{ $t('project.editions.meetingReports.moes') }}
            </app-separator>
            <template v-for="bundle in groups.MOE">
                <span class="mr-1 border-b border-gray-200 h-full">
                    <a :href="'#a_' + bundle.id">
                        <span v-if="bundle.reference" class="pr-1">{{ bundle.reference }} -</span>
                        {{ bundle.name }}
                    </a>
                </span>
                <span class="font-bold border-b border-gray-200 h-full px-2">{{ bundle.company.name }}</span>
                <span class="border-b border-gray-200 h-full whitespace-nowrap">
                    {{ bundle.responsible | contact }}
                </span>
                <app-phone
                    class="border-b border-gray-200 h-full px-2"
                    :number="bundle.responsible ? bundle.responsible.phone : null"
                ></app-phone>
                <app-mail
                    class="border-b border-gray-200 h-full"
                    :email="bundle.responsible ? bundle.responsible.email : null"
                ></app-mail>
                <span class="flex justify-center border-b border-gray-200 h-full">
                    <span v-if="getAttendance(bundle) === 'present'" class="text-green-600">
                        <icon-alpha-p-circle-outline />
                    </span>
                    <span v-if="getAttendance(bundle) === 'excusedAbsent'" class="text-yellow-600">
                        <icon-alpha-e-circle-outline />
                    </span>
                    <span v-if="getAttendance(bundle) === 'unexcusedAbsent'" class="text-red-600">
                        <icon-alpha-a-circle-outline />
                    </span>
                </span>
                <span class="flex justify-center border-b border-gray-200 h-full text-green-600">
                    <span v-if="isRecipient(bundle)" :title="$t('project.editions.meetingReports.diffusion')">
                        <icon-email />
                    </span>
                </span>
                <span class="flex justify-center border-b border-gray-200 h-full">
                    <template v-if="nextMeeting">{{ getNextConvocation(bundle) | humanizeDateTimeShort }}</template>
                </span>
            </template>
            <!-- Distributors -->
            <template v-if="groups.distributor.length > 0">
                <app-separator variant="underline" class="col-span-8 my-2 text-base">
                    {{ $t('project.editions.meetingReports.distributors') }}
                </app-separator>
                <template v-for="bundle in groups.distributor">
                    <span class="mr-1 border-b border-gray-200 h-full">
                        <a :href="'#a_' + bundle.id">
                            <span v-if="bundle.reference" class="pr-1">{{ bundle.reference }} -</span>
                            {{ bundle.name }}
                        </a>
                    </span>
                    <span class="font-bold border-b border-gray-200 h-full px-2">{{ bundle.company.name }}</span>
                    <span class="border-b border-gray-200 h-full whitespace-nowrap">
                        {{ bundle.responsible | contact }}
                    </span>
                    <app-phone
                        class="border-b border-gray-200 h-full px-2"
                        :number="bundle.responsible ? bundle.responsible.phone : null"
                    ></app-phone>
                    <app-mail
                        class="border-b border-gray-200 h-full"
                        :email="bundle.responsible ? bundle.responsible.email : null"
                    ></app-mail>
                    <span class="flex justify-center border-b border-gray-200 h-full">
                        <span v-if="getAttendance(bundle) === 'present'" class="text-green-600">
                            <icon-alpha-p-circle-outline />
                        </span>
                        <span v-if="getAttendance(bundle) === 'excusedAbsent'" class="text-yellow-600">
                            <icon-alpha-e-circle-outline />
                        </span>
                        <span v-if="getAttendance(bundle) === 'unexcusedAbsent'" class="text-red-600">
                            <icon-alpha-a-circle-outline />
                        </span>
                    </span>
                    <span class="flex justify-center border-b border-gray-200 h-full text-green-600">
                        <span v-if="isRecipient(bundle)" :title="$t('project.editions.meetingReports.diffusion')">
                            <icon-email />
                        </span>
                    </span>
                    <span class="flex justify-center border-b border-gray-200 h-full">
                        <template v-if="nextMeeting">{{ getNextConvocation(bundle) | humanizeDateTimeShort }}</template>
                    </span>
                </template>
            </template>
            <!-- companies -->
            <app-separator variant="underline" class="col-span-8 my-2 text-base">
                {{ $t('project.editions.meetingReports.companies') }}
            </app-separator>
            <template v-for="bundle in groups.company">
                <a :href="'#a_' + bundle.id" class="border-b border-gray-200 h-full px-2">
                    {{ bundle.reference }} {{ bundle.name }}
                </a>
                <span class="font-bold border-b border-gray-200 h-full">
                    {{ bundle.company ? bundle.company.name : '' }}
                </span>

                <span class="border-b border-gray-200 h-full whitespace-nowrap px-2">
                    {{ bundle.responsible | contact }}
                </span>
                <app-phone
                    class="border-b border-gray-200 h-full px-2"
                    :number="bundle.responsible ? bundle.responsible.phone : null"
                ></app-phone>
                <app-mail
                    class="border-b border-gray-200 h-full"
                    :email="bundle.responsible ? bundle.responsible.email : null"
                ></app-mail>
                <span class="flex justify-center border-b border-gray-200 h-full">
                    <span v-if="getAttendance(bundle) === 'present'" class="text-green-600">
                        <icon-alpha-p-circle-outline />
                    </span>
                    <span v-if="getAttendance(bundle) === 'excusedAbsent'" class="text-yellow-600">
                        <icon-alpha-e-circle-outline />
                    </span>
                    <span v-if="getAttendance(bundle) === 'unexcusedAbsent'" class="text-red-600">
                        <icon-alpha-a-circle-outline />
                    </span>
                </span>
                <span class="flex justify-center border-b border-gray-200 h-full">
                    <span
                        v-if="isRecipient(bundle)"
                        :title="$t('project.editions.meetingReports.diffusion')"
                        class="text-green-600"
                    >
                        <icon-email />
                    </span>
                </span>
                <span class="flex justify-center border-b border-gray-200 h-full">
                    <template v-if="nextMeeting">{{ getNextConvocation(bundle) | humanizeDateTimeShort }}</template>
                </span>
            </template>
        </div>
    </div>
</template>
<script>
import { getMeetings } from './meetings.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import { bundlesToGroups, getBundles } from '@/features/bundles/bundles.service';
import AppPhone from '@/components/appPhone/AppPhone';
import AppMail from '@/components/appMail/AppMail';
import { getMapById, sortBy } from '@/services/sanitize.service';
import { combineLatest } from 'rxjs';
import { getCompanies } from '@/features/companies/companies.service';
import { getContactMap } from '@/features/contacts/contacts.service';
import IconAlphaPCircleOutline from '@/icons/IconAlphaPCircleOutline.vue';
export default {
    components: { IconAlphaPCircleOutline, AppMail, AppPhone, AppSeparator },
    async created() {
        this.subscriptions = [
            combineLatest([
                getMeetings(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
                getContactMap(this.$route.params.projectId),
            ]).subscribe(([meetings, bundles, companies, contactMap]) => {
                const companyMap = getMapById(companies);
                this.bundles = bundles.map((bundle) => ({
                    ...bundle,
                    company: companyMap[bundle.companyId] || {},
                    responsible: contactMap[bundle.responsibleId],
                }));
                this.meeting = meetings.find((meeting) => meeting.id === this.$route.params.meetingId);
                this.nextMeeting = this.meeting.nextMeeting || meetings[meetings.indexOf(this.meeting) + 1];
                this.nextMeetings = meetings.slice(meetings.indexOf(this.meeting) + 1);
                this.groups = bundlesToGroups(
                    sortBy(this.bundles, (bundle) => (bundle.type === 'company' ? bundle.reference : bundle.index)),
                );
            }),
        ];
    },
    methods: {
        isRecipient(bundle) {
            return this.meeting.recipientIds.includes(bundle.id);
        },
        getAttendance(bundle) {
            let convocation = this.meeting.convocations.find((convocation) => convocation.bundleId === bundle.id);
            return convocation ? convocation.attendance : 'unknown';
        },
        getNextConvocation(bundle) {
            let convocations = this.nextMeetings.reduce((acc, meeting) => {
                const convocation = meeting.convocations.find((convocation) => convocation.bundleId === bundle.id);
                if (convocation) {
                    return [...acc, convocation];
                } else return acc;
            }, []);
            if (convocations.length > 0) {
                return convocations[0].convocationDate;
            } else return '';
        },
    },
    data() {
        return {
            subscriptions: [],
            groups: {
                MOA: [],
                AMOA: [],
                MOE: [],
                distributor: [],
                company: [],
            },
            bundles: [],
            nextMeeting: {},
            nextMeetings: {},
            meeting: {
                name: '',
                location: '',
                date: null,
                convocations: [],
                attachments: [],
            },
        };
    },
};
</script>
