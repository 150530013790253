<template>
    <app-input-text
        :label="label"
        :title="title"
        :uid="uid"
        :showLabel="showLabel"
        :disabled="disabled"
        :required="required"
        v-model="internalValue"
        type="text"
        :size="size"
        :min="min ? min.toISOString().substring(0, 10) : null"
        ref="input"
        :warning="warning"
        @keyup="$emit('keyup', $event)"
        :inline="inline"
        autocomplete="off"
    >
        <template v-slot:tip>
            <slot name="tip"></slot>
        </template>
    </app-input-text>
</template>
<script>
import AppInputText from '../appInputText/AppInputText';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import Datepicker from 'vanillajs-datepicker/Datepicker';
import 'vanillajs-datepicker/css/datepicker.css';
import fr from 'vanillajs-datepicker/locales/fr';
Object.assign(Datepicker.locales, fr);
export default {
    components: { AppInputText },
    watch: {
        value(newValue) {
            this.hours = this.keepHours(newValue);
            this.datepicker.setDate(newValue, { clear: true });
            if (!newValue) {
                this.internalValue = '';
            }
        },
    },
    data() {
        return {
            hours: this.keepHours(this.value),
            datepicker: null,
            internalValue: '',
        };
    },
    mounted() {
        const input = this.$refs.input.$el.querySelector('input');
        if (!input) {
            return;
        }
        this.datepicker = new Datepicker(input, {
            autohide: true,
            clearButton: true,
            language: 'fr',
            format: {
                toValue: (date) => {
                    if (date instanceof Date) {
                        return date;
                    } else if (typeof date === 'string') {
                        let strResult;
                        if (date.length === 8) {
                            // 10122023
                            strResult =
                                date[4] +
                                date[5] +
                                date[6] +
                                date[7] +
                                '-' +
                                date[2] +
                                date[3] +
                                '-' +
                                date[0] +
                                date[1];
                        } else if (date.length === 6) {
                            // 101223
                            strResult = '20' + date[4] + date[5] + '-' + date[2] + date[3] + '-' + date[0] + date[1];
                        } else if (date.length === 4) {
                            // 1012
                            strResult = new Date().getFullYear() + '-' + date[2] + date[3] + '-' + date[0] + date[1];
                        } else if (date.length === 10) {
                            // 01/01/2022
                            strResult =
                                date[6] +
                                date[7] +
                                date[8] +
                                date[9] +
                                '-' +
                                date[3] +
                                date[4] +
                                '-' +
                                date[0] +
                                date[1];
                        }
                        return new Date(strResult);
                    } else {
                        this.internalValue = '';
                        return null;
                    }
                },
                toDisplay: (date) => {
                    const result =
                        date.getDate().toString().padStart(2, '0') +
                        '/' +
                        (date.getMonth() + 1).toString().padStart(2, '0') +
                        '/' +
                        date.getFullYear().toString();
                    this.internalValue = result;
                    return result;
                },
            },
            minDate: this.min,
            todayButton: true,
            todayHighlight: true,
            todayButtonMode: 1,
            weekNumbers: 1,
            weekStart: 1,
            container: 'body',
        });
        input.addEventListener('changeDate', (event) => {
            this.onDateChanged(event.detail.date);
        });
        if (this.value) {
            this.hours = this.keepHours(this.value);
            this.datepicker.setDate(this.value, { clear: true });
        }
    },
    methods: {
        onDateChanged(date) {
            if (date) {
                const strDate =
                    date.getFullYear().toString() +
                    '-' +
                    (date.getMonth() + 1).toString().padStart(2, '0') +
                    '-' +
                    date.getDate().toString().padStart(2, '0') +
                    'T' +
                    this.hours;
                this.$emit('input', new Date(strDate));
            } else {
                this.$emit('input', null);
            }
        },
        focus() {
            this.$refs.input.focus();
        },
        keepHours(value) {
            if (value && isValid(value)) {
                return format(value, 'HH:mm:00.000xxx');
            } else {
                return format(new Date(), 'HH:mm:00.000xxx');
            }
        },
    },
    props: {
        inline: Boolean,
        title: String,
        min: String | Object,
        size: String,
        disabled: Boolean,
        required: Boolean,
        label: String,
        value: Date | String,
        uid: Date | String,
        warning: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
