<template>
    <main ref="main" class="h-full w-main relative min-h-main max-h-main flex flex-col p-2" @click="onClick">
        <div v-if="loading" class="flex justify-center">
            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
        </div>
        <template v-else>
            <div ref="planningToolbar" class="flex text-xs gap-2" v-if="plannings.length">
                <input
                    ref="filePickerMsProject"
                    type="file"
                    class="hidden"
                    accept="application/xml"
                    tabindex="-1"
                    v-on:change="onPickMSProjectFile"
                />
                <input
                    ref="filePickerGanttProject"
                    type="file"
                    class="hidden"
                    accept="text/csv"
                    tabindex="-1"
                    v-on:change="onPickGanttProjectFile"
                />
                <app-select
                    :label="$t('planning.selectedPlanning')"
                    v-model="selectedPlanningId"
                    @input="onPlanningSelect"
                >
                    <option :value="null"></option>
                    <template v-for="planning in plannings">
                        <option :value="planning.id">
                            {{ planning.name }}
                        </option>
                    </template>
                    <option value="new">- {{ $t('planning.newPlanning') }} -</option>
                    <option value="importGanttProject">- {{ $t('planning.importPlanningGantt') }} -</option>
                    <option value="importMsProject">- {{ $t('planning.importPlanningMsProject') }} -</option>
                </app-select>
                <app-select :label="$t('planning.structure')" v-model="structure" @input="updateState">
                    <option value="free">{{ $t('planning.free') }}</option>
                    <option value="BSL">{{ $t('planning.BSL') }}</option>
                    <option value="LBS">{{ $t('planning.LBS') }}</option>
                </app-select>
                <div class="flex items-end">
                    <app-button
                        :label="$t('planning.newTask')"
                        icon="icon-plus"
                        size="mini"
                        @click="newTask"
                    ></app-button>
                </div>
            </div>
            <div v-if="plannings.length === 0" class="flex justify-center w-100 p-5">
                <app-button
                    :label="$t('planning.newPlanning')"
                    @click="$refs.newPlanning.open()"
                    variant="primary"
                ></app-button>
            </div>
            <gantt2
                v-if="!taskLoading"
                ref="gantt"
                :tasks="lines"
                :options="options"
                @select="onSelect"
                @open="onOpen"
                @contextMenu="onContextMenu"
                :selectedItem="selectedItem"
                :style="{ 'max-height': ganttMaxHeight, height: ganttMaxHeight }"
                class="px-1 text-xs"
                :agenda="agendaWithWeekEnds"
                :minDate="options.minDate"
                :maxDate="options.maxDate"
                @navigateToDate="navigateToDate($event)"
                @expand="expand"
                @expandDeep="expandDeep"
                @collapseDeep="collapseDeep"
                @collapse="collapse"
                @updateDuration="onDateChange"
                @updateStartDate="onDateChange"
                @updateEndDate="onDateChange"
                @updateProgress="updateTaskProgress"
                @updateRealStartDate="updateTaskRealStartDate"
                @updateRealEndDate="updateTaskRealEndDate"
                @itemNameFocus="onItemNameFocus"
                @itemNameBlur="onItemNameBlur"
                @updatedName="onRenameTask"
            ></gantt2>
            <hsc-menu-style-white>
                <hsc-menu-context-menu ref="contextMenu">
                    <div></div>
                    <template slot="contextmenu">
                        <hsc-menu-item v-if="selectedItem" @click.native="addService()">
                            <template v-slot:body>
                                <div class="flex w-full justify-between gap-2 items-end">
                                    <span>{{ $t('planning.newService') }}</span>
                                    <i class="text-xs font-mono italic text-gray-500">alt+=</i>
                                </div>
                            </template>
                        </hsc-menu-item>
                        <hsc-menu-item
                            :label="$t('planning.editLocations')"
                            v-if="selectedItem"
                            @click="addLocations"
                        />
                        <hsc-menu-separator />
                        <hsc-menu-item :label="$t('planning.markAsDone')" v-if="selectedItem" @click="markAsDone" />
                        <!--
                        <hsc-menu-item :label="$t('commons.copy')" v-if="selectedItem" @click="copyNode" />
                        <hsc-menu-item :label="$t('commons.paste')" v-if="selectedItem" @click="pasteNode" />
                        <hsc-menu-item
                            :label="$t('commons.duplicate')"
                            v-if="selectedItem && selectedItem.type !== 'location' && selectedItem.type !== 'bundle'"
                            @click="duplicateNode"
                        />-->
                        <hsc-menu-item
                            :disabled="selectedItem && selectedItem.type === 'bundle'"
                            @click.native="removeNode(selectedItem)"
                        >
                            <template v-slot:body>
                                <div class="flex w-full justify-between gap-2 items-end">
                                    <span>{{ $t('commons.removeShort') }}</span>
                                    <i class="text-xs font-mono italic text-gray-500">alt+backspace</i>
                                </div>
                            </template>
                        </hsc-menu-item>
                    </template>
                </hsc-menu-context-menu>
            </hsc-menu-style-white>
            <app-autocomplete
                ref="locationMenu"
                :options="locationOptions"
                @input="onLocation"
                :allowStringCriteria="true"
                string-criteria-prefix=""
                :filter-string-function="(item) => item.fullName || item.name"
            >
                <template v-slot:item="{ item }">
                    <span class="py-1">{{ item.fullName || item.name }}</span>
                </template>
            </app-autocomplete>
            <app-autocomplete
                ref="locationsMenu"
                :multiple="true"
                :options="locationOptions"
                @close="onLocations"
                :allowStringCriteria="true"
                string-criteria-prefix=""
                :filter-string-function="(item) => item.fullName"
                v-model="selectedItemLocations"
            >
                <template v-slot:item="{ item }">
                    <span class="py-1">{{ item.fullName || item.name }}</span>
                </template>
                <template v-slot:footer>
                    <app-footer @click="$refs.locationsMenu.close()" class="m-2"></app-footer>
                </template>
            </app-autocomplete>
        </template>
        <app-popup ref="newPlanning" :title="$t('planning.newPlanning')" :show-header="true">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
                <div class="p-2 mx-4 flex flex-col gap-3">
                    <app-input-text v-model="newPlanning.name" :label="$t('commons.name')"></app-input-text>
                    <app-footer
                        @click="
                            $refs.newPlanning.close();
                            createPlanning({ name: newPlanning.name });
                            newPlanning.name = '';
                        "
                        :label="$t('commons.validate')"
                        class="w-full"
                        :disabled="invalid"
                    >
                        <template v-slot:left>
                            <app-button
                                variant="default"
                                :label="$t('commons.cancel')"
                                size="mini"
                                @click="$refs.newPlanning.close()"
                            />
                        </template>
                    </app-footer>
                </div>
            </ValidationObserver>
        </app-popup>
        <app-popup ref="taskPopup" :title="$t('planning.taskDetail')" :show-header="true">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
                <div class="p-2 mx-4 flex flex-col gap-3">
                    <app-input-text
                        v-model="editedTask.name"
                        :label="$t('commons.name')"
                        :disabled="editedTask.type !== 'node' && editedTask.type !== 'service'"
                    ></app-input-text>
                    <app-bundle-picker
                        :options="bundles"
                        :value="editedTask.bundle"
                        :label="$t('bundles.bundle')"
                        :disabled="true"
                    />
                    <task-planning-detail2
                        :task="editedTask"
                        :tasks="tasks"
                        :agenda="agenda"
                        @input="onTaskDetailUpdate"
                    ></task-planning-detail2>
                    <app-footer
                        @click="onValidateEdition"
                        :label="$t('commons.validate')"
                        class="w-full"
                        :disabled="invalid"
                    >
                        <template v-slot:left>
                            <app-button
                                variant="default"
                                :label="$t('commons.cancel')"
                                size="mini"
                                @click="$refs.taskPopup.close()"
                            />
                        </template>
                    </app-footer>
                </div>
            </ValidationObserver>
        </app-popup>
        <app-popup ref="newTasksPopup" :title="$t('planning.newTask')" :show-header="true">
            <ValidationObserver v-slot="{ invalid }" ref="observer">
                <div class="p-2 mx-4 flex flex-col gap-3">
                    <app-input-text
                        v-model="newTasks.name"
                        :label="$t('planning.taskName')"
                        :required="true"
                    ></app-input-text>
                    <app-multi-picker
                        ref="bundles"
                        v-model="newTasks.bundles"
                        :options="bundles"
                        :label="$t('planning.responsible')"
                        :required="true"
                        label-key="label"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option" />
                        </template>
                        <template v-slot:footer="{ popup }">
                            <div class="flex justify-end p-1">
                                <app-button
                                    variant="primary"
                                    :label="$t('commons.next')"
                                    @click="$refs.bundles.close"
                                ></app-button>
                            </div>
                        </template>
                    </app-multi-picker>
                    <app-multi-picker
                        v-model="newTasks.locations"
                        :options="locationOptions"
                        :label="$t('services.locations')"
                        label-key="fullName"
                        ref="locations"
                    >
                        <template v-slot:footer="{ popup }">
                            <div class="flex justify-end p-1">
                                <app-button
                                    variant="primary"
                                    :label="$t('commons.next')"
                                    @click="$refs.locations.close"
                                ></app-button>
                            </div>
                        </template>
                    </app-multi-picker>
                    <app-number-input
                        v-model="newTasks.duration"
                        :show-tips="false"
                        format="integer"
                        :label="$t('project.follow.planning.duration')"
                    ></app-number-input>
                    <div>
                        <app-label :label="$t('project.follow.planning.predecessors')" class="mt-2"></app-label>
                        <TaskPredecessors2 :task="newTasks" :tasks="tasks" />
                    </div>
                    <app-footer
                        @click="createTasks()"
                        :label="$t('commons.validate')"
                        class="w-full"
                        :disabled="invalid || newTasks.bundles.length === 0"
                    ></app-footer>
                </div>
            </ValidationObserver>
        </app-popup>
    </main>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import Gantt2 from '../../../components/gantt/Gantt2';
import AppSelect from '../../../components/appSelect/AppSelect';
import { isInViewport, sortBy } from '@/services/sanitize.service';
import {
    applyCollapse,
    cleanOrDefineMaxDate,
    getWeekEnds,
    mapAgenda,
    mapTasks2,
} from '@/components/gantt/ganttService';
import hotkeys from 'hotkeys-js';
import startOfDay from 'date-fns/startOfDay';
import vueClickHelper from 'vue-click-helper';
import { queryProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getReferences } from '@/features/planning/references/references.service';
import planning2Service from './planning2.service';
import planning2BSLService from './planning2BSL.service';
import planning2LBSService from './planning2LBS.service';
import planning2FreeService from './planning2Free.service';
import TaskPredecessors2 from './TaskPredecessors2';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppFooter from '@/components/appFooter/AppFooter.vue';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppDateInput from '@/components/appDateInput/AppDateInput.vue';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete.vue';
import locationService from '@/services/location.service';
import { queryPlanningState, updatePlanningState } from '@/state/state';
import format from 'date-fns/format';
import { mapLocationsAndBundles } from '@/features/planning/planning2/planning2Mapping.service';
import AppBundlePicker from '@/components/appBundlePicker.vue';
import AppPicker from '@/components/appPicker/AppPicker.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppBundle from '@/components/app-bundle/appBundle.vue';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput.vue';
import AppLabel from '@/components/appLabel/AppLabel.vue';
import TaskPlanningDetail2 from '@/features/planning/planning2/TaskPlanningDetail2.vue';

export default {
    components: {
        TaskPlanningDetail2,
        AppLabel,
        AppNumberInput,
        AppBundle,
        AppMultiPicker,
        AppPicker,
        AppBundlePicker,
        AppAutocomplete,
        AppPopup,
        AppDateInput,
        AppInputText,
        AppButton,
        AppCheckbox,
        AppFooter,
        AppTips,
        AppSelect,
        Gantt2,
        TaskPredecessors2,
    },
    directives: { 'click-helper': vueClickHelper },
    async created() {
        const projectId = this.$route.params.projectId;
        queryProject(projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_planning');
        });
        this.restorePlanningState();
        this.plannings = await planning2Service.getPlannings(projectId);
        if (this.selectedPlanningId === null && this.plannings.length > 0) {
            this.selectedPlanningId = this.plannings[0].id;
        }
        this.subscriptions = [
            combineLatest([
                getBundles(projectId),
                getCalendar(projectId),
                getLocationsTree(projectId),
                getReferences(projectId),
            ]).subscribe(async ([bundles, agenda, folders, references]) => {
                this.bundles = bundles;
                this.agenda = agenda;
                this.folders = folders;
                this.references = references;

                if (this.plannings[0]?.id) {
                    await this.loadTasks();
                    try {
                        this.tasks = mapTasks2(this.buildTaskTree());
                    } catch (e) {
                        //nop
                    }
                }
                this.loading = false;
            }),
        ];
    },
    watch: {
        structure() {
            this.tasks = mapTasks2(this.buildTaskTree());
        },
    },
    updated: function () {
        this.updated();
    },
    data() {
        return {
            newTasks: { bundles: [], locations: [], duration: 1, predecessors: [] },
            editedTask: {
                bundle: null,
                location: null,
                duration: 1,
                predecessors: [],
                hasLocations: false,
                locations: [],
                plannedStartDate: null,
                plannedEndDate: null,
                realStartDate: null,
                realEndDate: null,
                plannedDuration: null,
                realDuration: null,
                realProgress: null,
            },
            filterOptions: [],
            filterAsArray: [],
            tasks: [],
            selectedItemLocations: [],
            contextMenuOpen: false,
            activeContextMenuOptions: [{ name: 'test1' }, { name: 'test3' }],
            bundleContextMenuOptions: [],
            serviceContextMenuOptions: [],
            locationContextMenuOptions: [],
            newPlanning: { name: this.$t('planning.newPlanningName') },
            selectedPlanningId: null,
            plannings: [],
            plannedTaskTrigger: new BehaviorSubject(new Date()),
            bundles: [],
            folders: [],
            agenda: [],
            loading: true,
            taskLoading: true,
            readOnly: true,
            structure: 'free',
            DBTasks: [],
            selectedItem: null,
            project: {},
            ganttMaxHeight: '100%',
            maxLevel: '99',
            options: {
                print: false,
                scale: 'months',
                name: '',
                fileName: null,
                maxDate: new Date(new Date().getTime() + 1000 * 24 * 30),
                minDate: new Date(),
                showCollapseButtons: true,
                showLineNumbers: false,
                showProgressLine: false,
                showReference: false,
                refDate: new Date(),
                showReal: false,
                showPlanned: false,
                progressReportedTo: 'reference',
                showReferenceData: false,
                showRealData: true,
                showLateColumn: true,
                showQuantityColumn: false,
                showDurationColumn: false,
                showPlannedData: true,
                showProgressColumn: true,
                showOutOfRangeLines: false,
                considerFilter: true,
                considerCollapsed: true,
                sortKey: 'date',
            },
            lastPlannedDate: null,
            references: [],
            collapsedLines: {},
            referenceId: null,
        };
    },
    mounted() {
        document.body.style.overflow = 'hidden';
        window.addEventListener('resize', this.onResize);
        hotkeys('down,ctrl+down', this.selectNextTask);
        hotkeys('up,ctrl+up', this.selectPreviousTask);
        hotkeys('right', this.next);
        hotkeys('left', this.previous);
        hotkeys('shift+alt+=', () => this.addService());
        hotkeys('shift+alt+backspace', () => this.removeNode(this.selectedItem));
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        hotkeys.unbind('down,ctrl+down');
        hotkeys.unbind('up,ctrl+up');
        hotkeys.unbind('left');
        hotkeys.unbind('right');
        hotkeys.unbind('shift+alt+=');
        hotkeys.unbind('shift+alt+backspace');
        document.body.style.overflow = 'auto';
    },
    computed: {
        lines() {
            return applyCollapse(
                this.tasks.map((task) => ({
                    ...task,
                    collapsed: this.collapsedLines[task.id] === true,
                })),
            );
        },
        locationOptions() {
            const allOptions = locationService.buildLocationOptions(this.folders);
            if (this.selectedItem && this.selectedItem.type === 'location') {
                return allOptions.filter(
                    (location) =>
                        location.fullName !== this.selectedItem.name &&
                        location.fullName.startsWith(this.selectedItem.name),
                );
            } else {
                return allOptions;
            }
        },
        bundleOptions() {
            return this.bundles;
        },
        agendaWithWeekEnds() {
            return mapAgenda(
                sortBy([...getWeekEnds(this.options.minDate, this.options.maxDate), ...this.agenda], 'time'),
                this.options.refDate,
            );
        },
    },
    methods: {
        onSelectedLocation() {},
        async updateState() {
            this.options.maxDate = cleanOrDefineMaxDate(this.options.minDate, null, this.options.scale);
            updatePlanningState(this.$route.params.projectId, {
                selectedPlanningId: this.options.selectedPlanningId,
                scale: this.options.scale,
                structure: this.structure,
                minDate: format(this.options.minDate, 'yyyy-MM-dd'),
                refDate: this.options.refDate.toISOString(),
                selectedItemId: this.selectedItem?.id,
                showProgressLine: this.options.showProgressLine,
                showReference: this.options.showReference,
                showReal: this.options.showReal,
                progressReportedTo: this.options.progressReportedTo,
                showPlanned: this.options.showPlanned,
                showLateColumn: this.options.showLateColumn,
                showQuantityColumn: this.options.showQuantityColumn,
                showDurationColumn: this.options.showDurationColumn,
                showProgressColumn: this.options.showProgressColumn,
                showPlannedData: this.options.showPlannedData,
                showReferenceData: this.options.showReferenceData,
                showRealData: this.options.showRealData,
                sortKey: this.options.sortKey || 'date',
                showOutOfRangeLines: this.options.showOutOfRangeLines,
                filter: this.filterAsArray.map((item) =>
                    item._isStringCriteria ? item : { id: item.id, _isBundleCriteria: item._isBundleCriteria },
                ),
                considerFilter: this.options.considerFilter,
                considerCollapsed: this.options.considerCollapsed,
                collapsedLines: this.collapsedLines,
                referenceId: this.referenceId,
            });
        },
        restorePlanningState() {
            const planningState = queryPlanningState(this.$route.params.projectId);
            if (planningState.filter) {
                this.filterAsArray = [
                    ...this.filterOptions
                        .reduce((acc, item) => [...acc, ...item.children], [])
                        .filter(
                            (criterion) =>
                                !!planningState.filter.find((item) => {
                                    return item.id === criterion.id;
                                }),
                        ),
                    ...planningState.filter.filter((criterion) => criterion._isStringCriteria),
                ];
            }
            this.options.scale = planningState.scale || 'months';
            this.options.minDate = planningState.minDate ? new Date(planningState.minDate) : new Date();
            this.options.maxDate = cleanOrDefineMaxDate(this.options.minDate, null, this.options.scale);
            this.options.refDate = startOfDay(this.options.refDate || new Date());
            this.options.showProgressLine =
                typeof planningState.showProgressLine === 'boolean' ? planningState.showProgressLine : true;
            this.options.showReference = !!planningState.showReference;
            this.options.showReal = !!planningState.showReal;
            this.options.progressReportedTo = planningState.progressReportedTo || 'reference';
            this.options.showPlanned = !!planningState.showPlanned;
            this.options.showLateColumn = planningState.showLateColumn;
            this.options.showQuantityColumn = planningState.showQuantityColumn;
            this.options.showDurationColumn = planningState.showDurationColumn;
            this.options.showProgressColumn = planningState.showProgressColumn;
            this.options.showReferenceData = planningState.showReferenceData;
            this.options.sortKey = planningState.sortKey || 'date';
            this.options.showPlannedData =
                typeof planningState.showPlannedData === 'boolean' ? planningState.showPlannedData : true;
            this.options.showRealData =
                typeof planningState.showRealData === 'boolean' ? planningState.showRealData : true;
            this.options.showOutOfRangeLines =
                typeof planningState.showOutOfRangeLines === 'boolean' ? planningState.showOutOfRangeLines : false;
            this.options.considerCollapsed =
                typeof planningState.considerCollapsed === 'boolean' ? planningState.considerCollapsed : true;
            this.options.considerFilter =
                typeof planningState.considerFilter === 'boolean' ? planningState.considerFilter : true;

            this.structure = planningState.structure || 'free';
            this.referenceId = planningState.referenceId || null;
            this.collapsedLines = planningState.collapsedLines || {};
        },
        buildTaskTree() {
            const branches = mapLocationsAndBundles(
                this.DBTasks.map((a) => ({ ...a })),
                this.bundles,
                this.locationOptions,
            );
            if (this.structure === 'free') {
                return planning2FreeService.toTaskFree(branches, this.DBTasks);
            } else if (this.structure === 'BSL') {
                return planning2BSLService.toTaskBSL(branches, this.bundles);
            } else {
                return planning2LBSService.toTaskLBS(branches, this.locationOptions);
            }
        },
        onRenameTask() {
            const node = this.applyLocal({
                id: this.selectedItem.id,
                name: this.selectedItem.name,
            });
            if (node.edited) {
                node.edited = false;
            }
            this.updateTask({
                id: this.selectedItem.id,
                name: this.selectedItem.name,
            });
        },
        newTask() {
            this.$refs.newTasksPopup.open();
        },
        createTasks() {
            const tasks = [];
            for (const bundle of this.newTasks.bundles) {
                const existingBundleNode = this.DBTasks.find(
                    (task) => task.type === 'bundle' && task.bundleId === bundle.id,
                );
                const bundleNode = existingBundleNode || {
                    id: uuidv4(),
                    parentId: null,
                    name: bundle.label,
                    bundleId: bundle.id,
                    type: 'bundle',
                    class: 'text-blue-600',
                    level: 0,
                    children: [],
                };
                if (!existingBundleNode) {
                    tasks.push(bundleNode);
                    this.tasks.push(bundleNode);
                }
                const existingTaskNode = this.DBTasks.find(
                    (task) => task.type === 'node' && task.name === this.newTasks.name && task.bundleId === bundle.id,
                );
                const taskNode = existingTaskNode || {
                    id: uuidv4(),
                    name: this.newTasks.name,
                    parentId: bundleNode.id,
                    bundleId: bundle.id,
                    type: 'node',
                    level: 1,
                    children: [],
                };
                if (!existingTaskNode) {
                    tasks.push(taskNode);
                    this.tasks.push(taskNode);
                }

                for (const location of this.newTasks.locations) {
                    const existingLocationNode = this.DBTasks.find(
                        (task) =>
                            task.type === 'location' &&
                            task.name === this.newTasks.name &&
                            task.bundleId === bundle.id &&
                            task.locationId === location.id &&
                            task.parentId === taskNode.id,
                    );
                    const locationNode = existingLocationNode || {
                        id: uuidv4(),
                        name: location.fullName,
                        parentId: taskNode.id,
                        locationId: location.id,
                        bundleId: bundle.id,
                        type: 'location',
                        level: 2,
                        children: [],
                    };
                    if (!existingLocationNode) {
                        tasks.push(locationNode);
                        this.tasks.push(locationNode);
                    }
                }
            }
            if (tasks.length > 0) {
                tasks.map((task) =>
                    planning2Service
                        .createTask(this.$route.params.projectId, this.selectedPlanningId, task)
                        .catch((err) => console.error(err)),
                );
            }
            this.$refs.newTasksPopup.close();
        },
        addService() {
            let parent = this.selectedItem;
            const newTask = {
                id: uuidv4(),
                bundleId: parent.bundleId === 'orphan' ? null : parent.bundleId,
                parentId: parent.id === 'orphan' ? null : parent.id,
                name: this.$t('planning.newServiceName'),
                type: 'service',
            };
            planning2Service
                .createTask(this.$route.params.projectId, this.selectedPlanningId, newTask)
                .catch((err) => console.error(err));
            const newNode = {
                ...newTask,
                level: parent.level + 1,
                editable: true,
                edited: true,
                children: [],
            };
            this.appendTask(newNode, parent);
            this.onSelect(newNode);
            this.strollTo(newNode);
        },
        appendTask(newNode, parent) {
            this.tasks.splice(
                this.tasks.findIndex((a) => a.id === this.getLastDescendantIndex(parent).id) + 1,
                0,
                newNode,
            );
            parent.children.push(newNode);
        },
        getLastDescendantIndex(node) {
            if (!node.children || node.children.length === 0) {
                return node;
            } else {
                return this.getLastDescendantIndex(node.children[node.children.length - 1]);
            }
        },
        onItemNameFocus() {
            if (this.selectedItem && this.selectedItem.type === 'location') {
                this.$refs.locationMenu.open();
            }
        },
        onItemNameBlur(node) {
            const task = this.tasks.find((task) => task.id === node.id);
            if (task) {
                task.edited = false;
            }
        },
        addLocations() {
            this.$refs.locationsMenu.open();
        },
        markAsDone() {
            const patch = {
                id: this.selectedItem.id,
                realProgress: 100,
                realEndDate: this.selectedItem.realEndDate || new Date(),
                realStartDate: this.selectedItem.realStartDate || new Date(),
            };
            this.applyLocal(patch);
            this.updateTask(patch);
        },
        removeNode(taskToRemove) {
            if (!taskToRemove) {
                return;
            }
            const parentNode = this.tasks.find((task) => task.id === taskToRemove.parentId);
            if (parentNode) {
                parentNode.children = parentNode.children.filter((node) => node.id !== taskToRemove.id);
            }
            this.removeNode_Recursive(taskToRemove, false);
        },
        removeNode_Recursive(taskToRemove) {
            planning2Service
                .deleteTasks(this.$route.params.projectId, this.selectedPlanningId, taskToRemove.id)
                .catch((err) => console.error(err));
            const nodeIndex = this.tasks.findIndex((item) => item.id === taskToRemove.id);
            this.tasks.splice(nodeIndex, 1);
            for (const child of taskToRemove.children) {
                this.removeNode_Recursive(child);
            }
        },
        copyNode() {},
        pasteNode() {},
        async duplicateNode_Recursive(node, parentId) {
            const parentNode = this.tasks.find((task) => task.id === parentId);
            const parentIndex = this.tasks.indexOf(parentNode);
            const newTask = await planning2Service.createTasks(this.$route.params.projectId, this.selectedPlanningId, {
                bundleId: node.bundleId,
                parentId,
                name: node.name,
                type: node.type,
            });
            const newNode = {
                ...newTask,
                level: node.level,
                editable: node.editable,
                children: [],
            };
            this.tasks.splice(parentIndex + 1, 0, newNode);
            parentNode.children.push(newNode);
            await Promise.all(node.children.map((child) => this.duplicateNode_Recursive(child, newNode.id)));
        },
        duplicateNode() {
            this.duplicateNode_Recursive(this.selectedItem, this.selectedItem.parentId);
        },
        onLocation(selectedLocation) {
            this.selectedItem.name = selectedLocation.fullName || selectedLocation.name;
            this.selectedItem.locationId = selectedLocation._isStringCriteria ? null : selectedLocation.id;
            this.onRenameTask(this.selectedItem);
        },
        onLocations() {
            let parentNode = this.selectedItem;
            const locationNodesToCreate = this.selectedItemLocations.filter(
                (location) =>
                    !parentNode.children.find((item) => item.name === location.fullName || item.name === location.name),
            );
            const locationNodesToRemove = parentNode.children.filter(
                (location) =>
                    !this.selectedItemLocations.find(
                        (item) => item.fullName === location.name || item.name === location.name,
                    ) &&
                    this.locationOptions.find((item) => item.fullName === location.name || item.name === location.name),
            );
            Promise.all([
                ...locationNodesToCreate.map(async (loc) => {
                    const newTask = {
                        id: uuidv4(),
                        bundleId: parentNode.bundleId === 'orphan' ? null : parentNode.bundleId,
                        parentId: parentNode.id === 'orphan' ? null : parentNode.id,
                        locationId: loc._isStringCriteria ? null : loc.id,
                        name: loc.fullName || loc.name,
                        type: 'location',
                    };
                    planning2Service
                        .createTask(this.$route.params.projectId, this.selectedPlanningId, newTask)
                        .catch((err) => console.error(err));
                    const newNode = {
                        ...newTask,
                        level: parentNode.level + 1,
                        editable: true,
                        children: [],
                        locationId: loc._isStringCriteria ? null : loc.id,
                    };
                    this.appendTask(newNode, parentNode);
                    parentNode.children.push(newNode);
                }),
                ...locationNodesToRemove.map((loc) => this.removeNode(loc)),
            ]);
        },
        onClick() {
            if (this.contextMenuOpen) {
                this.$refs.contextMenu.close();
                this.contextMenuOpen = false;
            }
        },
        async createPlanning(planning) {
            const newPlanning = await planning2Service.createPlanning(this.$route.params.projectId, planning);
            this.plannings.unshift(newPlanning);
            this.selectedPlanningId = newPlanning.id;
            await this.loadTasks();
            this.tasks = mapTasks2(this.buildTaskTree());
        },
        async loadTasks() {
            if (this.selectedPlanningId) {
                this.taskLoading = true;
                this.DBTasks = await planning2Service.getTasks(this.$route.params.projectId, this.selectedPlanningId);
                this.taskLoading = false;
            }
        },
        async onPlanningSelect(id) {
            if (id === 'importMsProject') {
                this.$refs.filePickerMsProject.click();
            } else if (id === 'importGanttProject') {
                this.$refs.filePickerGanttProject.click();
            } else if (id === 'new') {
                this.$refs.newPlanning.open();
                this.selectedPlanningId = null;
            } else {
                await this.loadTasks();
                this.tasks = mapTasks2(this.buildTaskTree());
            }
        },
        async onPickMSProjectFile(event) {
            const planning = await planning2Service.createPlanning(this.$route.params.projectId, {
                name: event.target.files[0].name,
            });
            this.plannings.unshift(planning);
            this.selectedPlanningId = planning.id;
            await planning2Service.importXML(this.$route.params.projectId, planning.id, event.target.files[0]);
            await this.loadTasks();
            this.structure = 'free';
            this.tasks = mapTasks2(this.buildTaskTree());
        },
        async onPickGanttProjectFile(event) {
            const planning = await planning2Service.createPlanning(this.$route.params.projectId, {
                name: event.target.files[0].name,
            });
            this.plannings.unshift(planning);
            this.selectedPlanningId = planning.id;
            await planning2Service.importCSV(this.$route.params.projectId, planning.id, event.target.files[0]);
            await this.loadTasks();
            this.structure = 'free';
            this.tasks = mapTasks2(this.buildTaskTree());
        },
        updateTaskRealEndDate(patch) {
            const fixedPatch = {
                ...patch,
                realProgress: patch.realEndDate ? 100 : 0,
                realStartDate: this.selectedItem.realStartDate || patch.realEndDate,
            };
            this.applyLocal(fixedPatch);
            this.updateTask(fixedPatch);
        },
        updateTaskRealStartDate(patch) {
            const fixedPatch = {
                ...patch,
                realProgress: patch.realStartDate ? this.selectedItem.realProgress : 0,
                realEndDate: patch.realStartDate ? this.selectedItem.realEndDate : null,
                realStartDate: patch.realStartDate,
            };
            this.applyLocal(fixedPatch);
            this.updateTask(fixedPatch);
        },
        onDateChange(patch) {
            this.applyLocal(patch);
            this.updateTask(patch);
        },
        updateTaskProgress({ id, realStartDate, realEndDate, realProgress }) {
            if ((realProgress > 0 && realProgress < 100 && realStartDate) || realProgress === 0) {
                const patch = {
                    id,
                    realProgress,
                };
                this.applyLocal(patch);
                this.updateTask(patch);
            } else if (realProgress > 0 && realProgress < 100 && !realStartDate) {
                const patch = {
                    id,
                    realProgress,
                    realStartDate: startOfDay(new Date()),
                };
                this.applyLocal(patch);
                this.updateTask(patch);
            } else if (realProgress >= 100 && !realEndDate) {
                const patch = {
                    id,
                    realProgress: 100,
                    realEndDate: startOfDay(new Date()),
                };
                this.applyLocal(patch);
                this.updateTask(patch);
            } else if (realProgress >= 100) {
                const patch = {
                    id,
                    realProgress: 100,
                };
                this.applyLocal(patch);
                this.updateTask(patch);
            }
        },
        selectNextTask(event) {
            if (event.target.nodeName === 'BODY') {
                const currentIndex = this.filteredLines.indexOf(
                    this.filteredLines.find((task) => task.id === this.selectedItem.id),
                );
                if (currentIndex >= 0) {
                    const nextTask = this.filteredLines.find((task, index) => {
                        return index > currentIndex && (!event.ctrlKey || task.children.length === 0);
                    });
                    if (nextTask) {
                        this.onSelect(nextTask);
                        event.preventDefault();
                        this.strollTo(nextTask);
                    }
                }
            }
        },
        selectPreviousTask(event) {
            if (event.target.nodeName === 'BODY') {
                const reversedTasks = [...this.filteredLines].reverse();
                const currentIndex = reversedTasks.indexOf(
                    reversedTasks.find((task) => task.id === this.selectedItem.id),
                );
                if (currentIndex >= 0) {
                    const previousTask = reversedTasks.find((task, index) => {
                        return index > currentIndex && (!event.ctrlKey || task.children.length === 0);
                    });
                    if (previousTask) {
                        this.onSelect(previousTask);
                        event.preventDefault();
                        this.strollTo(previousTask);
                    }
                }
            }
        },
        strollTo(task) {
            const element = document.querySelector('#uuid_' + task.id);
            if (element && !isInViewport(element)) {
                element.scrollIntoView();
            }
        },
        collapse: function (line) {
            this.collapsedLines = { ...this.collapsedLines, [line.id]: true };
            this.updateState();
        },
        collapseAll: function () {
            for (const line of this.lines) {
                this.collapsedLines[line.id] = true;
            }
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandToLevel: function (level) {
            for (const line of this.lines) {
                if (line.level < level) {
                    this.collapsedLines[line.id] = false;
                } else {
                    this.collapsedLines[line.id] = true;
                }
            }
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandDeep: function (task) {
            const taskIndex = this.lines.findIndex((aTask) => aTask.id === task.id);
            let indexOfNextTaskWithSameLevel = this.lines.findIndex(
                (aTask, index) => index > taskIndex && aTask.level === task.level,
            );
            if (indexOfNextTaskWithSameLevel === -1) {
                indexOfNextTaskWithSameLevel = this.lines.length - 1;
            }
            this.lines.map((aTask, index) => {
                if (index >= taskIndex && (!indexOfNextTaskWithSameLevel || index < indexOfNextTaskWithSameLevel)) {
                    this.collapsedLines[aTask.id] = false;
                }
            });
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        collapseDeep: function (task) {
            const taskIndex = this.lines.findIndex((aTask) => aTask.id === task.id);
            let indexOfNextTaskWithSameLevel = this.lines.findIndex(
                (aTask, index) => index > taskIndex && aTask.level === task.level,
            );
            if (indexOfNextTaskWithSameLevel === -1) {
                indexOfNextTaskWithSameLevel = this.lines.length - 1;
            }
            this.lines.map((aTask, index) => {
                if (index >= taskIndex && (!indexOfNextTaskWithSameLevel || index < indexOfNextTaskWithSameLevel)) {
                    this.collapsedLines[aTask.id] = true;
                }
            });
            this.collapsedLines = { ...this.collapsedLines };
            this.updateState();
        },
        expandAll: function () {
            this.collapsedLines = {};
            this.updateState();
        },
        expand: function (line) {
            this.collapsedLines = { ...this.collapsedLines, [line.id]: false };
            this.updateState();
        },
        async navigateToDate(date) {
            this.options.minDate = date;
            await this.updateState();
        },
        updated: function () {
            if (this.$refs.main && this.$refs.planningToolbar) {
                this.ganttMaxHeight =
                    this.$refs.main.offsetHeight -
                    this.$refs.planningToolbar.offsetHeight -
                    (this.$refs.details ? this.$refs.details.offsetHeight : 0) +
                    'px';
                this.$refs.gantt?.onResize();
            }
        },
        onSelect(item) {
            this.selectedItem = item;
            if (item.children) {
                this.selectedItemLocations = this.locationOptions.filter((location) =>
                    item.children.find((item) => item.name === location.fullName),
                );
            }
            this.updateState();
        },
        onOpen(item) {
            this.editedTask.id = item.id;
            this.editedTask.name = item.name;
            this.editedTask.type = item.type;
            this.editedTask.bundle = this.bundleOptions.find((bundle) => bundle.id === item.bundleId);
            this.editedTask.location = this.locationOptions.find((location) => location.id === item.locationId);
            this.editedTask.locations = item.children.filter((child) => child.type === 'location');
            this.editedTask.hasLocations = this.editedTask.locations.length > 0;
            this.editedTask.plannedStartDate = item.plannedStartDate;
            this.editedTask.plannedEndDate = item.plannedEndDate;
            this.editedTask.realStartDate = item.realStartDate;
            this.editedTask.realEndDate = item.realEndDate;
            this.editedTask.plannedDuration = item.plannedDuration;
            this.editedTask.realDuration = item.realDuration;
            this.editedTask.realProgress = item.realProgress;
            this.$refs.taskPopup.open();
        },
        onTaskDetailUpdate(patch) {
            Object.assign(this.editedTask, patch);
        },
        onValidateEdition() {
            const patch = { id: this.selectedItem.id };
            if (this.selectedItem.name !== this.editedTask.name) {
                patch.name = this.editedTask.name;
            }
            if (this.selectedItem.plannedStartDate !== this.editedTask.plannedStartDate) {
                patch.plannedStartDate = this.editedTask.plannedStartDate;
            }
            if (this.selectedItem.plannedEndDate !== this.editedTask.plannedEndDate) {
                patch.plannedEndDate = this.editedTask.plannedEndDate;
            }
            if (this.selectedItem.realStartDate !== this.editedTask.realStartDate) {
                patch.realStartDate = this.editedTask.realStartDate;
            }
            if (this.selectedItem.realEndDate !== this.editedTask.realEndDate) {
                patch.realEndDate = this.editedTask.realEndDate;
            }
            if (this.selectedItem.plannedDuration !== this.editedTask.plannedDuration) {
                patch.plannedDuration = this.editedTask.plannedDuration;
            }
            if (this.selectedItem.realDuration !== this.editedTask.realDuration) {
                patch.realDuration = this.editedTask.realDuration;
            }
            if (this.selectedItem.realProgress !== this.editedTask.realProgress) {
                patch.realProgress = this.editedTask.realProgress;
            }
            this.applyLocal(patch);
            this.updateTask(patch);
            this.$refs.taskPopup.close();
        },
        onContextMenu({ event, item }) {
            event.preventDefault();
            event.stopPropagation();
            this.onSelect(item);
            const menu = this.$refs.contextMenu.$refs.menu;
            menu.open();
            menu.setPosition(event.pageX, event.pageY, 'right');
            this.contextMenuOpen = true;
            this.updateState();
        },
        today() {
            this.options.minDate = sub(startOfDay(new Date()), { [this.options.scale]: 8 });
            this.updateState();
        },
        next() {
            if (this.options.scale === 'days') {
                this.options.minDate = add(this.options.minDate, { days: 7 });
            } else if (this.options.scale === 'weeks') {
                this.options.minDate = add(this.options.minDate, { weeks: 3 });
            } else if (this.options.scale === 'months') {
                this.options.minDate = add(this.options.minDate, { months: 3 });
            }
            this.updateState();
        },
        previous() {
            if (this.options.scale === 'days') {
                this.options.minDate = sub(this.options.minDate, { days: 7 });
            } else if (this.options.scale === 'weeks') {
                this.options.minDate = sub(this.options.minDate, { weeks: 3 });
            } else if (this.options.scale === 'months') {
                this.options.minDate = sub(this.options.minDate, { months: 3 });
            }
            this.updateState();
        },
        expandTo(level) {
            if (level === 'all') {
                this.expandAll();
            } else {
                this.expandToLevel(parseInt(level));
            }
        },
        applyLocal(patch) {
            const item = this.tasks.find((task) => task.id === patch.id);
            Object.assign(item, patch);
            return item;
        },
        updateTask(task) {
            planning2Service
                .updateTask(this.$route.params.projectId, this.selectedPlanningId, task)
                .catch((e) => console.error(e));
        },
    },
};
</script>
