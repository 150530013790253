<template>
    <div :class="{ loading: !pageLoaded, preview }" class="h-full w-full">
        <div ref="toolbar" class="flex justify-center items-center py-2" v-if="showRotation || numPages > 1">
            <slot name="extraToolbar"></slot>
            <app-pdf-page-stepper
                v-model="currentPage"
                :disabled="numPages <= 1"
                :numPages="numPages"
                v-if="numPages > 1 && !preview"
                @input="loadPage"
            />
            <div class="flex ml-4 m-1" v-if="showRotation">
                <app-button
                    class="mr-1"
                    size="mini"
                    icon="icon-rotate-left"
                    @click="onRotateLeft"
                    :title="$t('pdf.rotationMinus')"
                    :disabled="!pageLoaded"
                />
                <app-button
                    size="mini"
                    icon="icon-rotate-right"
                    @click="onRotateRight"
                    :title="$t('pdf.rotationPlus')"
                    :disabled="!pageLoaded"
                />
            </div>
            <div class="flex mx-4 text-xs p-1 bg-yellow-500 items-center gap-2" v-if="isPDFWithThumbnails && zoom > 4">
                <span>{{ $t('pdf.resolutionIssue') }} ?</span>
                <span class="bg-white">
                    <app-button
                        :label="$t('pdf.improveResolution')"
                        size="mini"
                        @click="onImproveResolution"
                    ></app-button>
                </span>
            </div>
        </div>
        <div id="debugInfo" class="hidden text-2xs"></div>
        <l-map
            class="z-10 prevent-select"
            :class="{ border: !preview }"
            ref="map"
            :min-zoom="minZoom"
            :crs="crs"
            :style="{ width, height }"
            @contextmenu="onPickPositionLongPress"
            @click="onPickPositionClick"
            :options="{
                attributionControl: false,
                zoomSnap: 0.1,
                zoomControl: false,
                editable: true,
                editOptions: { skipMiddleMarkers: true },
                interactive: !preview,
                dragging: !preview,
                scrollWheelZoom: !preview,
                tap: !preview,
                doubleClickZoom: false,
            }"
        >
            <l-control position="topleft" class="leaflet-bar" v-if="!preview && allowMarkerMove">
                <a
                    :class="{ 'leaflet-active': !dragMarkerMode }"
                    class="cursor-pointer"
                    @click="setDragMarkerMode(false)"
                >
                    <icon-arrow-all class="inline"></icon-arrow-all>
                </a>
                <a
                    :class="{ 'leaflet-active': dragMarkerMode }"
                    class="cursor-pointer"
                    @click="setDragMarkerMode(true)"
                >
                    <icon-marker-move class="inline"></icon-marker-move>
                </a>
            </l-control>
            <template v-for="shape in internalShapes">
                <app-rectangle
                    v-if="shape.type === 'rectangle'"
                    :shape="shape"
                    ref="shapes"
                    :weight="strokeWeight"
                    @click="onShapeClick($event, shape)"
                    @updateX1Y1X2Y2="onUpdateX1Y1X2Y2($event, shape)"
                ></app-rectangle>
                <app-arrow
                    v-if="shape.type === 'arrow' || shape.type === 'doubleArrow'"
                    :color="shape.style.color"
                    :type="shape.type"
                    :paths="shape.bounds"
                    :weight="strokeWeight"
                    ref="shapes"
                    @click="onShapeClick($event, shape)"
                    @updateX1Y1X2Y2="onUpdateX1Y1X2Y2($event, shape)"
                    :pixel-size="arrowPixelSize"
                ></app-arrow>
                <app-segment
                    v-if="shape.type === 'segment'"
                    :color="shape.style.color"
                    :paths="shape.bounds"
                    :weight="strokeWeight"
                    ref="shapes"
                    @click="onShapeClick($event, shape)"
                    @updateX1Y1X2Y2="onUpdateX1Y1X2Y2($event, shape)"
                    :pixel-size="arrowPixelSize"
                ></app-segment>
                <app-polyline
                    v-if="shape.type === 'polyline' || shape.type === 'freeDrawing'"
                    :color="shape.style.color"
                    :latLngs="shape.latLngs"
                    :weight="strokeWeight"
                    ref="shapes"
                    @click="onShapeClick($event, shape)"
                    @updateLatLngs="onUpdateLatLngs($event, shape)"
                    :pixel-size="arrowPixelSize"
                ></app-polyline>
                <app-polygon
                    v-if="shape.type === 'polygon'"
                    :color="shape.style.color"
                    :latLngs="shape.latLngs"
                    :weight="strokeWeight"
                    ref="shapes"
                    @click="onShapeClick($event, shape)"
                    @updateLatLngs="onUpdateLatLngs($event, shape)"
                ></app-polygon>
                <l-marker
                    v-if="shape.type === 'marker'"
                    :l-style="shape.style"
                    :lat-lng="shape.latLng"
                    :options="{ interactive: true }"
                    :icon="shape.icon"
                    :draggable="shape.id === selectShape.id"
                    @click="onShapeClick($event, shape)"
                    @dragend="onMarkerMoved($event, shape)"
                ></l-marker>
                <l-marker
                    v-if="shape.type === 'filledMarker'"
                    :l-style="shape.style"
                    :lat-lng="shape.latLng"
                    :options="{ interactive: true }"
                    :icon="shape.icon"
                    :draggable="shape.id === selectShape.id"
                    @click="onShapeClick($event, shape)"
                    @dragend="onMarkerMoved($event, shape)"
                ></l-marker>
            </template>
            <template v-for="shape in internalShapes">
                <l-marker
                    v-if="(shape.label || shape.title || shape.htmlTitle) && shape.type !== 'marker'"
                    :l-style="shape.style"
                    :options="{ interactive: true }"
                    :lat-lng="shape.latLng"
                    :icon="shape.icon"
                    @click="onShapeClick($event, shape)"
                >
                    <l-tooltip v-if="!isMobile && (shape.title || shape.htmlTitle)">
                        <span v-if="shape.htmlTitle" v-html="shape.htmlTitle"></span>
                        <span v-else-if="shape.title">{{ shape.title }}</span>
                    </l-tooltip>
                </l-marker>
            </template>
            <template v-for="marker in internalMarkers">
                <l-marker
                    v-if="marker.label"
                    :l-style="marker.style"
                    :options="{ interactive: true }"
                    :lat-lng="marker.latLng"
                    :draggable="marker.draggable && dragMarkerMode"
                    :icon="marker.icon"
                    @dragend="onMarkerMoved($event, marker)"
                    @click="$emit('markerClick', cleanUpMarker(marker))"
                    @mouseover="$emit('markerHover', cleanUpMarker(marker))"
                >
                    <l-tooltip v-if="!isMobile">
                        <div v-html="marker.title" />
                    </l-tooltip>
                </l-marker>
            </template>
            <slot></slot>
        </l-map>
    </div>
</template>

<script>
import 'leaflet-polylinedecorator';
import { CRS, divIcon } from 'leaflet';
import { LMap, LImageOverlay, LRectangle, LTooltip, LMarker, LPolyline, LControl } from 'vue2-leaflet';
import AppLeafletPDFOverlay from '@/components/appLeafletViewer/AppLeafletPDFOverlay';
import { isPDF } from '@/services/file.service';
import AppLeafletImageOverlay from '@/components/appLeafletViewer/AppLeafletImageOverlay';
import AppPdfPageStepper from '@/components/appPdfViewer/AppPdfPageStepper';
import AppButton from '@/components/appButton/AppButton';
import { reportError } from '@/features/tracker/tracker.service';
import { transposeShapeRotation, transposeXYWithRotation } from '@/components/appLeafletViewer/AppLeaflet.service';
import { getDocument } from 'pdfjs-dist/legacy/build/pdf';
import { debounce } from '@/services/sanitize.service';
import AppArrow from '@/components/appLeafletViewer/AppArrow';
import IconMarkerMove from '@/icons/IconMarkerMove';
import IconArrowAll from '@/icons/IconArrowAll';
import AppRectangle from '@/components/appLeafletViewer/AppRectangle';
import AppSegment from '@/components/appLeafletViewer/AppSegment';
import { getAPIHeaders } from '@/services/api.service';
import AppMarker from '@/components/appMarker/AppMarker';
import AppPolyline from '@/components/appLeafletViewer/AppPolyline';
import AppPolygon from '@/components/appLeafletViewer/AppPolygon';
import { isMobile } from '@/state/state';
export default {
    components: {
        AppPolygon,
        AppPolyline,
        AppMarker,
        AppSegment,
        AppRectangle,
        IconArrowAll,
        LControl,
        IconMarkerMove,
        AppArrow,
        AppButton,
        AppPdfPageStepper,
        LMap,
        LImageOverlay,
        LRectangle,
        LTooltip,
        LMarker,
        LPolyline,
    },
    props: {
        src: String,
        rotation: { type: Number, default: 0 },
        color: { type: String, default: '#ff0000' },
        pageCount: { type: Number, default: 0 },
        page: { type: Number, default: 1 },
        shapes: { type: Array, default: () => [] },
        showRotation: false,
        preview: false,
        variant: { type: String, default: 'default', enum: ['default', 'mini'] },
        markers: { type: Array, default: () => [] },
        allowMarkerMove: false,
        selectedShape: null,
        allowShapeEdit: false,
        imageFullSize: { type: Boolean, default: false },
        longPressToPick: { type: Boolean, default: true },
    },
    watch: {
        src() {
            this.currentPage = 1;
            this.resetView();
            this.loadDocument();
        },
        page(page) {
            if (this.currentPage !== page /* if changed from the stepper this event should be ignored*/) {
                this.currentPage = page;
                this.loadPage(this.currentPage);
            }
        },
        shapes() {
            this.$refs.map.mapObject.editTools.featuresLayer.eachLayer((layer) => {
                if (layer.eachLayer) {
                    layer.eachLayer((layer) => {
                        layer.remove();
                    });
                }
                layer.remove();
            });
        },
    },
    data() {
        return {
            zoom: 1,
            arrowPixelSize: this.variant === 'mini' ? 2 : 10,
            strokeWeight: this.variant === 'mini' ? 1 : 3,
            internalRotation: this.rotation,
            numPages: 1,
            pageLoaded: false,
            currentPage: this.page || 1,
            minZoom: 1,
            crs: CRS.Simple,
            width: 100,
            height: 100,
            imageOverlay: null,
            initialBounds: null,
            pdf: null,
            dragMarkerMode: false,
            isPDFWithThumbnails: false,
            isMobile,
            paintMode: false,
        };
    },
    created() {
        this.loadPage = debounce(this.loadPage, 200);
        window.addEventListener('resize', this.onResize);
        this.dragMarkerMode = this.allowMarkerMove && localStorage.getItem('dragMarkerMode') === 'true';
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    mounted() {
        this.initMap();
        this.$nextTick(() => this.loadDocument());
    },
    computed: {
        internalMarkers() {
            return this.markers
                .filter((marker) => !(marker.x === 0 && marker.y === 0))
                .map((marker) => {
                    const { x, y } = transposeXYWithRotation(marker.x, marker.y, this.internalRotation);
                    let markerBound = [
                        [
                            this.imageBounds.north - (y / 100) * this.imageBounds.height,
                            this.imageBounds.east - ((100 - x) / 100) * this.imageBounds.width,
                        ],
                    ];
                    return {
                        ...marker,
                        x,
                        y,
                        bounds: markerBound,
                        latLng: markerBound[0],
                        icon: divIcon({
                            html:
                                '<div class="marker ' +
                                (marker.classes ? marker.classes : '') +
                                ' ' +
                                (marker.active ? 'animate-marker-bounce' : '') +
                                '"><div>' +
                                marker.label +
                                '</div></div>',
                            iconSize: '',
                            className: 'markerIcon',
                        }),
                    };
                });
        },
        internalShapes() {
            return this.shapes
                .map((shape) => transposeShapeRotation(shape, this.internalRotation))
                .map((shape) => {
                    if (shape.type === 'polyline' || shape.type === 'polygon' || shape.type === 'freeDrawing') {
                        const isMultiDimensionalArray = shape.latLngs.every((subTable) =>
                            subTable.every(Array.isArray),
                        );
                        return {
                            ...shape,
                            latLngs: isMultiDimensionalArray
                                ? shape.latLngs.map((subShape) =>
                                      subShape.map((latLng) => {
                                          return [this.fromRelativeY(latLng[1]), this.fromRelativeX(latLng[0])];
                                      }),
                                  )
                                : shape.latLngs.map((latLng) => {
                                      return [this.fromRelativeY(latLng[1]), this.fromRelativeX(latLng[0])];
                                  }),
                        };
                    } else {
                        let shapeBound;
                        if (shape.type === 'marker' || shape.type === 'filledMarker') {
                            shapeBound = [[this.fromRelativeY(shape.y), this.fromRelativeX(shape.x)]];
                        } else {
                            shapeBound = [
                                [this.fromRelativeY(shape.y1), this.fromRelativeX(shape.x1)],
                                [this.fromRelativeY(shape.y2), this.fromRelativeX(shape.x2)],
                            ];
                        }
                        return {
                            ...shape,
                            style: shape.style,
                            bounds: shapeBound,
                            latLng: shapeBound[0],
                            icon: divIcon({
                                html:
                                    shape.type === 'marker' || shape.type === 'filledMarker'
                                        ? `<div class="flex flex-col items-center shape-icon ">
                            <span style="background-color:${shape.style.color || 'red'};" class="px-1 ${
                                              this.selectedShape && this.selectedShape.id === shape.id ? 'active' : ''
                                          } border">${shape.label || '&nbsp;'}</span>
                            <span class="arrow-down" style="border-top-color:${
                                shape.style.color || 'red'
                            }"></span></div>`
                                        : shape.label,
                                iconSize: '',
                                className: shape.type === 'marker' || shape.type === 'filledMarker' ? '' : 'shapeIcon',
                            }),
                        };
                    }
                });
        },
        imageBounds() {
            let bounds;
            if (this.imageOverlay) {
                bounds = this.imageOverlay.getBounds();
                return {
                    north: bounds.getNorth(),
                    south: bounds.getSouth(),
                    east: bounds.getEast(),
                    west: bounds.getWest(),
                    width: bounds.getEast() - bounds.getWest(),
                    height: bounds.getNorth() - bounds.getSouth(),
                };
            } else {
                return {
                    north: 0,
                    south: 0,
                    east: 0,
                    west: 0,
                    width: 0,
                    height: 0,
                };
            }
        },
    },
    methods: {
        disableAllShapeEdition() {
            this.paintMode = false;
            this.$refs.map.mapObject.editTools.editLayer.eachLayer((layer) => {
                layer.disableEdit ? layer.disableEdit() : null;
                layer.eachLayer((layer) => {
                    layer.disableEdit ? layer.disableEdit() : null;
                });
            });
        },
        selectShape(shape) {
            if (shape === null) {
                this.disableAllShapeEdition();
            } else {
                const shapeElement = Array.isArray(this.$refs.shapes)
                    ? this.$refs.shapes[
                          this.internalShapes.indexOf(this.internalShapes.find((aShape) => aShape.id === shape.id))
                      ]
                    : this.$refs.shapes;
                if (shapeElement) {
                    shapeElement.select();
                }
            }
        },
        onShapeClick(event, shape) {
            if (this.allowShapeEdit) {
                this.disableAllShapeEdition();
                this.$nextTick(() => {
                    event.target.enableEdit();
                    this.$emit('selectShape', shape);
                });
            } else {
                this.$emit('selectShape', shape);
            }
        },
        onUpdateX1Y1X2Y2(x1y1x2y2, { id, type }) {
            this.$emit('updateShape', {
                id,
                type,
                x1: this.toRelativeX(x1y1x2y2.x1),
                x2: this.toRelativeX(x1y1x2y2.x2),
                y1: this.toRelativeY(x1y1x2y2.y1),
                y2: this.toRelativeY(x1y1x2y2.y2),
            });
        },
        onUpdateLatLngs(latLngs, { id, type }) {
            const isMultiDimensionalArray = latLngs.every((subTable) => subTable.every(Array.isArray));
            this.$emit('updateShape', {
                id,
                type,
                latLngs: isMultiDimensionalArray
                    ? latLngs.map((subShape) =>
                          subShape.map((latLng) => {
                              if (type === 'freeDrawing' || type === 'polygon')
                                  return [this.toRelativeY(latLng[0]), this.toRelativeX(latLng[1])];
                              else return [this.toRelativeX(latLng[1]), this.toRelativeY(latLng[0])];
                          }),
                      )
                    : latLngs.map((latLng) => {
                          if (type === 'freeDrawing' || type === 'polygon')
                              return [this.toRelativeX(latLng[1]), this.toRelativeY(latLng[0])];
                          else return [this.toRelativeY(latLng[0]), this.toRelativeX(latLng[1])];
                      }),
            });
        },
        setDragMarkerMode(value) {
            this.dragMarkerMode = value;
            localStorage.setItem('dragMarkerMode', value.toString());
        },
        onPickPositionClick(event) {
            if (!this.longPressToPick) {
                this.onPickPosition(event);
            }
        },
        onPickPositionLongPress(event) {
            if (this.longPressToPick) {
                this.onPickPosition(event);
            }
        },
        onPickPosition(event) {
            const marker = {
                ...transposeXYWithRotation(
                    this.toRelativeX(event.latlng.lng),
                    this.toRelativeY(event.latlng.lat),
                    -this.internalRotation,
                ),
                page: this.currentPage,
            };
            this.$emit('pickPosition', marker);
        },
        onMarkerMoved(event, marker) {
            const latlng = event.target.getLatLng();
            const result = {
                ...marker,
                ...transposeXYWithRotation(
                    this.toRelativeX(latlng.lng),
                    this.toRelativeY(latlng.lat),
                    -this.internalRotation,
                ),
            };
            this.$emit('markerMoved', result);
        },
        cleanUpMarker(marker) {
            const { bounds, icon, latLng, label, draggable, active, ...rest } = marker;
            return rest;
        },
        onResize() {
            this.width = this.$el.parentElement.offsetWidth + 'px';
            this.height =
                this.$el.parentElement.offsetHeight - (this.$refs.toolbar ? this.$refs.toolbar.offsetHeight : 0) + 'px';
        },
        initMap() {
            this.debug();
            this.onResize();
            this.$nextTick(() => {
                const map = this.$refs.map.mapObject;
                map.invalidateSize(false);
                this.initialBounds = map.getBounds();
                const mapWidth = this.initialBounds.getEast() - this.initialBounds.getWest();
                const mapHeight = this.initialBounds.getNorth() - this.initialBounds.getSouth();
                const maxBoundRatio = 0.5;
                map.setMaxBounds([
                    [
                        this.initialBounds.getSouth() - maxBoundRatio * mapHeight,
                        this.initialBounds.getWest() - maxBoundRatio * mapWidth,
                    ],
                    [
                        this.initialBounds.getNorth() + maxBoundRatio * mapHeight,
                        this.initialBounds.getEast() + maxBoundRatio * mapWidth,
                    ],
                ]);
                map.on('zoomend', () => this.onZoomEnd());
                map.on('editable:drawing:end', (event) => {
                    if (event.layer instanceof L.Rectangle) {
                        const rectangle = event.layer;
                        const rectangleBounds = rectangle.getBounds();
                        if (rectangleBounds.isValid()) {
                            this.$emit('newShape', {
                                type: 'rectangle',
                                x1: this.toRelativeX(rectangleBounds.getWest()),
                                y1: this.toRelativeY(rectangleBounds.getNorth()),
                                x2: this.toRelativeX(rectangleBounds.getEast()),
                                y2: this.toRelativeY(rectangleBounds.getSouth()),
                            });
                            this.$refs.map.mapObject.removeLayer(rectangle);
                        }
                    }
                });
            });
        },
        async checkIsPDFWithThumbnails() {
            if (this.pageCount > 0) {
                return true;
            }
            try {
                const response = await fetch(this.src, {
                    method: 'HEAD',
                    headers: getAPIHeaders(),
                });
                const pageCount = response.headers.get('x-page-count');
                if (pageCount && parseInt(pageCount) > 0) {
                    this.numPages = parseInt(pageCount);
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                const response = await fetch(this.src + `_001_high.png`, {
                    headers: getAPIHeaders(),
                });
                return response.ok;
            }
        },
        async loadDocument() {
            this.clearLayers();
            this.numPages = 1;
            this.currentPage = this.page;
            if (isPDF(this.src)) {
                this.isPDFWithThumbnails = await this.checkIsPDFWithThumbnails();
                if (this.isPDFWithThumbnails) {
                    this.addImageLayer(`${this.src}_${this.currentPage.toString().padStart(3, '0')}_high.png`);
                } else {
                    await this.loadPdf();
                    this.addPDFLayer();
                }
            } else {
                this.isPDFWithThumbnails = false;
                this.addImageLayer(this.src);
            }
            await this.loadPage(this.currentPage);
        },
        onZoomEnd() {
            this.zoom = this.$refs.map.mapObject.getZoom();
            if (this.zoom === 1) {
                this.resetView();
            }
        },
        async onImproveResolution() {
            await fetch(this.src, {
                method: 'PUT',
                headers: getAPIHeaders(),
            });
            this.currentPage = 1;
            this.resetView();
            window.location.reload();
        },
        clearLayers() {
            if (this.imageOverlay && this.$refs.map.mapObject.hasLayer(this.imageOverlay)) {
                this.imageOverlay.remove();
            }
        },
        debug() {
            const info = () => {
                const zoom = this.$refs.map.mapObject.getZoom();
                const bounds = this.$refs.map.mapObject.getBounds();
                const center = this.$refs.map.mapObject.getCenter();
                const node = document.getElementById('debugInfo');
                if (node) {
                    node.innerHTML = `
                    <div>zoom ${zoom}</div>
                    <div>getBounds ${bounds.toBBoxString()}</div>
                    <div>center ${center}</div>
`;
                }
            };
            this.$refs.map.mapObject.on('load move moveend zoomend viewreset', info);
        },
        resetView() {
            this.$refs.map.mapObject.fitBounds(this.initialBounds);
        },
        onRotateRight() {
            this.internalRotation = (this.internalRotation + 90) % 360;
            this.$emit('rotate', this.internalRotation);
            this.loadPage(this.currentPage);
        },
        onRotateLeft() {
            if (this.internalRotation > 0) {
                this.internalRotation = (this.internalRotation - 90) % 360;
            } else {
                this.internalRotation = 270;
            }
            this.$emit('rotate', this.internalRotation);
            this.loadPage(this.currentPage);
        },
        async loadPage(page) {
            this.pageLoaded = false;
            this.resetView();
            this.$emit('pageChanging', this.currentPage, page);
            if (this.isPDFWithThumbnails) {
                await this.imageOverlay
                    .loadPage(page, this.internalRotation, `${this.src}_${page.toString().padStart(3, '0')}_high.png`)
                    .then(() => (this.pageLoaded = true));
            } else {
                await this.imageOverlay
                    .loadPage(page, this.internalRotation, this.src)
                    .then(() => (this.pageLoaded = true));
            }
            this.$emit('pageLoaded', page);
        },
        toRelativeX(x) {
            return ((x - this.imageBounds.west) / this.imageBounds.width) * 100;
        },
        toRelativeY(y) {
            return 100 - ((y - this.imageBounds.south) / this.imageBounds.height) * 100;
        },
        fromRelativeY(y) {
            return this.imageBounds.north - (y / 100) * this.imageBounds.height;
        },
        fromRelativeX(x) {
            return this.imageBounds.east - ((100 - x) / 100) * this.imageBounds.width;
        },
        capture(shape) {
            let element = this.imageOverlay._image.firstChild
                ? this.imageOverlay._image.firstChild
                : this.imageOverlay._image;
            let elementWidth;
            let elementHeight;
            let referenceShape;
            let rotatedShape = transposeShapeRotation(shape, -this.internalRotation);
            if (element.nodeName === 'IMG') {
                elementWidth = element.naturalWidth;
                elementHeight = element.naturalHeight;
                referenceShape = transposeShapeRotation(shape, -this.internalRotation);
            } else {
                elementWidth = element.width;
                elementHeight = element.height;
                referenceShape = shape;
            }
            let sourceWidth = Math.floor(((referenceShape.x2 - referenceShape.x1) / 100) * elementWidth);
            let sourceHeight = Math.floor(((referenceShape.y2 - referenceShape.y1) / 100) * elementHeight);
            const framedCanvas = document.createElement('canvas');
            framedCanvas.width = sourceWidth;
            framedCanvas.height = sourceHeight;
            const ctx = framedCanvas.getContext('2d');
            const sx = Math.floor((referenceShape.x1 / 100) * elementWidth);
            const sy = Math.floor((referenceShape.y1 / 100) * elementHeight);
            ctx.drawImage(element, sx, sy, sourceWidth, sourceHeight, 0, 0, framedCanvas.width, framedCanvas.height);

            if (this.internalRotation && element.nodeName === 'IMG') {
                const rotatedCanvas = document.createElement('canvas');
                if (this.internalRotation === 90 || this.internalRotation === 270) {
                    rotatedCanvas.width = sourceHeight;
                    rotatedCanvas.height = sourceWidth;
                } else {
                    rotatedCanvas.width = sourceWidth;
                    rotatedCanvas.height = sourceHeight;
                }
                const ctx = rotatedCanvas.getContext('2d');
                ctx.translate(rotatedCanvas.width / 2, rotatedCanvas.height / 2);
                ctx.rotate((this.internalRotation * Math.PI) / 180);
                ctx.drawImage(framedCanvas, -framedCanvas.width / 2, -framedCanvas.height / 2);

                rotatedCanvas.toBlob(
                    (result) => {
                        this.$emit('captured', result, rotatedShape);
                    },
                    'image/jpeg',
                    0.95,
                );
            } else {
                framedCanvas.toBlob(
                    (result) => {
                        this.$emit('captured', result, rotatedShape);
                    },
                    'image/jpeg',
                    0.95,
                );
            }
        },
        stopDrawing() {
            this.$refs.map.mapObject.editTools.stopDrawing();
        },
        drawRectangle() {
            this.disableAllShapeEdition();
            const polyline = this.$refs.map.mapObject.editTools.startRectangle(null, { draggable: true });
            polyline.on('editable:drawing:end', () => {
                this.$refs.map.mapObject.editTools.stopDrawing();
                this.$emit('stopDrawing');
                if (this.isValidPolyline(polyline)) {
                    this.$emit('createShape', {
                        type: 'rectangle',
                        style: { color: this.color },
                        x1: this.toRelativeX(polyline.getBounds().getWest()),
                        x2: this.toRelativeX(polyline.getBounds().getEast()),
                        y1: this.toRelativeY(polyline.getBounds().getNorth()),
                        y2: this.toRelativeY(polyline.getBounds().getSouth()),
                    });
                } else {
                    polyline.remove();
                }
            });
        },
        drawCircle() {
            this.disableAllShapeEdition();
            this.$refs.map.mapObject.editTools.startCircle(null, { draggable: true });
        },
        drawMarker() {
            this.disableAllShapeEdition();
            this.$refs.map.mapObject.editTools.startMarker(null, { draggable: true });
        },
        isValidPolyline(polyline) {
            if (!polyline._parts) {
                return false;
            }
            const point1 = polyline._parts[0][0];
            const point2 = polyline._parts[0][1];
            const dist = Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
            return dist > 10;
        },
        freeDrawing() {
            this.disableAllShapeEdition();
            this.paintMode = true;
            let myPolyline;
            this.$refs.map.mapObject.dragging.disable();
            let onMouseDown = () => {
                myPolyline = L.polyline([], {
                    color: this.color,
                }).addTo(this.$refs.map.mapObject);
                this.$refs.map.mapObject.on('mousemove', onMouseMove);
                this.$refs.map.mapObject.on('mouseup', onMouseUp);
            };
            let onMouseMove = (e) => {
                myPolyline.addLatLng(e.latlng);
            };
            let onMouseUp = (e) => {
                this.$refs.map.mapObject.off('mousedown', onMouseDown);
                this.$refs.map.mapObject.off('mousemove', onMouseMove);
                this.$refs.map.mapObject.off('mouseup', onMouseUp);
                myPolyline.addLatLng(e.latlng);
                this.$refs.map.mapObject.dragging.enable();
                this.$emit('stopDrawing');
                console.log(myPolyline._latlngs);
                this.$emit('createShape', {
                    type: 'freeDrawing',
                    style: { color: this.color },
                    latLngs: myPolyline._latlngs.map((level1) => [
                        this.toRelativeX(level1.lng),
                        this.toRelativeY(level1.lat),
                    ]),
                });
                myPolyline = null;
            };
            this.$refs.map.mapObject.on('mousedown', onMouseDown);
        },
        drawArrow(patterns, type) {
            this.disableAllShapeEdition();
            const polyline = this.$refs.map.mapObject.editTools.startPolyline(null, {
                draggable: true,
                maxPoints: 2,
                color: this.color,
                fill: true,
            });
            polyline.on('editable:drawing:clicked', (event) => {
                if (event.target._parts.length === 1) {
                    if (event.target._parts[0].length === 2) {
                        var decorator = L.polylineDecorator(polyline, {
                            patterns: patterns || [
                                {
                                    offset: -10,
                                    repeat: 0,
                                    symbol: L.Symbol.arrowHead({
                                        pixelSize: 10,
                                        polygon: true,
                                        pathOptions: {
                                            stroke: true,
                                            color: this.color,
                                            fill: true,
                                            fillColor: this.color,
                                            fillOpacity: 1,
                                        },
                                        headAngle: -290,
                                    }),
                                },
                            ],
                        }).addTo(this.$refs.map.mapObject);
                        polyline.on('editable:drag editable:dragend editable:editing', () => {
                            decorator.setPaths(polyline);
                        });
                        polyline.on('remove', () => {
                            decorator.remove();
                        });
                        this.$refs.map.mapObject.editTools.stopDrawing();
                        this.$emit('stopDrawing');
                        if (this.isValidPolyline(polyline)) {
                            const latLng1 = this.$refs.map.mapObject.layerPointToLatLng(event.target._parts[0][0]);
                            const latLng2 = this.$refs.map.mapObject.layerPointToLatLng(event.target._parts[0][1]);
                            this.$emit('createShape', {
                                type: type || 'arrow',
                                style: { color: this.color },
                                x1: this.toRelativeX(latLng1.lng),
                                x2: this.toRelativeX(latLng2.lng),
                                y1: this.toRelativeY(latLng1.lat),
                                y2: this.toRelativeY(latLng2.lat),
                            });
                        } else {
                            polyline.remove();
                            decorator.remove();
                        }
                    }
                }
            });
        },
        drawSegment() {
            this.disableAllShapeEdition();
            const polyline = this.$refs.map.mapObject.editTools.startPolyline(null, {
                draggable: true,
                maxPoints: 2,
                color: this.color,
                fill: true,
            });
            polyline.on('editable:drawing:clicked', (event) => {
                if (event.target._parts.length === 1) {
                    if (event.target._parts[0].length === 2) {
                        this.$refs.map.mapObject.editTools.stopDrawing();
                        this.$emit('stopDrawing');
                        if (this.isValidPolyline(polyline)) {
                            const latLng1 = this.$refs.map.mapObject.layerPointToLatLng(event.target._parts[0][0]);
                            const latLng2 = this.$refs.map.mapObject.layerPointToLatLng(event.target._parts[0][1]);
                            this.$emit('createShape', {
                                type: 'segment',
                                style: { color: this.color },
                                x1: this.toRelativeX(latLng1.lng),
                                x2: this.toRelativeX(latLng2.lng),
                                y1: this.toRelativeY(latLng1.lat),
                                y2: this.toRelativeY(latLng2.lat),
                            });
                        } else {
                            polyline.remove();
                        }
                    }
                }
            });
        },
        drawPolyline() {
            this.disableAllShapeEdition();
            const polyline = this.$refs.map.mapObject.editTools.startPolyline(null, {
                draggable: true,
                color: this.color,
                fill: false,
            });
            polyline.on('editable:drawing:end', (event) => {
                this.$refs.map.mapObject.editTools.stopDrawing();
                this.$emit('stopDrawing');
                this.$emit('createShape', {
                    type: 'polyline',
                    style: { color: this.color },
                    latLngs: event.target._latlngs.map((level1) => [
                        this.toRelativeY(level1.lat),
                        this.toRelativeX(level1.lng),
                    ]),
                });
            });
        },
        drawPolygon() {
            this.disableAllShapeEdition();
            const polygon = this.$refs.map.mapObject.editTools.startPolygon(null, {
                draggable: true,
                color: this.color,
                fill: false,
            });
            polygon.on('editable:drawing:end', (event) => {
                this.$refs.map.mapObject.editTools.stopDrawing();
                this.$emit('stopDrawing');
                this.$emit('createShape', {
                    type: 'polygon',
                    style: { color: this.color },
                    latLngs: [
                        event.target._latlngs[0].map((level1) => [
                            this.toRelativeY(level1.lat),
                            this.toRelativeX(level1.lng),
                        ]),
                    ],
                });
            });
        },
        drawDoubleArrow() {
            this.drawArrow(
                [
                    {
                        offset: '100%',
                        repeat: 0,
                        symbol: L.Symbol.arrowHead({
                            pixelSize: 10,
                            polygon: true,
                            pathOptions: {
                                stroke: true,
                                color: this.color,
                                fill: true,
                                fillColor: this.color,
                                fillOpacity: 1,
                            },
                        }),
                    },
                    {
                        offset: 0,
                        repeat: 0,
                        symbol: L.Symbol.arrowHead({
                            pixelSize: 10,
                            polygon: true,
                            pathOptions: {
                                stroke: true,
                                color: this.color,
                                fill: true,
                                fillColor: this.color,
                                fillOpacity: 1,
                            },
                            headAngle: -290,
                        }),
                    },
                ],
                'doubleArrow',
            );
        },
        async loadPdf() {
            if (this.pdf?.destroy) {
                this.pdf.destroy();
            }
            try {
                this.pdf = await getDocument({ url: this.src }).promise;
                this.numPages = this.pdf.numPages;
            } catch (e) {
                console.error(e);
                await reportError(e);
            }
        },
        addPDFLayer: function () {
            this.imageOverlay = new AppLeafletPDFOverlay({
                pdf: this.pdf,
                rotation: this.internalRotation,
                page: this.currentPage,
                mapBound: this.initialBounds,
                maxArea: this.preview
                    ? {
                          width: parseInt(this.width),
                          height: parseInt(this.height),
                      }
                    : null,
            });
            this.$refs.map.mapObject.addLayer(this.imageOverlay);
        },
        addImageLayer: function (src) {
            this.imageOverlay = new AppLeafletImageOverlay({
                src,
                rotation: this.internalRotation,
                onLoaded: (event) => {
                    if (this.imageFullSize) {
                        this.$nextTick(() => {
                            const A4MaxWidth = 768;
                            const A4MaxHeight = 1024;
                            const lastHeight = parseInt(this.height);
                            const lastWidth = parseInt(this.width);
                            let hScale;
                            let vScale;
                            if (this.internalRotation === 0 || this.internalRotation === 180) {
                                hScale = Math.round((A4MaxWidth / event.img.naturalWidth) * 1000) / 1000;
                                vScale = Math.round((A4MaxHeight / event.img.naturalHeight) * 1000) / 1000;
                                const scale = Math.min(9, hScale, vScale);
                                this.width = event.img.naturalWidth * scale + 'px';
                                this.height = event.img.naturalHeight * scale + 'px';
                            } else {
                                hScale = Math.round((A4MaxWidth / event.img.naturalHeight) * 1000) / 1000;
                                vScale = Math.round((A4MaxHeight / event.img.naturalWidth) * 1000) / 1000;
                                const scale = Math.min(9, hScale, vScale);
                                this.width = event.img.naturalHeight * scale + 'px';
                                this.height = event.img.naturalWidth * scale + 'px';
                            }
                            if (lastHeight !== this.height || lastWidth !== this.width) {
                                this.$nextTick(() => {
                                    this.$refs.map.mapObject.invalidateSize();
                                    this.imageOverlay.resetBounds();
                                    this.pageLoaded = true;
                                });
                            }
                        });
                    } else {
                        this.pageLoaded = true;
                    }
                    this.$emit('pageLoaded', this.currentPage);
                },
            });
            this.$refs.map.mapObject.addLayer(this.imageOverlay);
        },
    },
};
</script>
<style>
.preview .leaflet-container {
    background-color: white;
}
.prevent-select {
    user-select: none;
}
.shapeIcon {
    background-color: red;
    color: white;
    margin: 0;
    top: 0;
    left: 0;
    padding: 1px;
    padding-right: 4px;
}
.markerIcon {
    width: 0;
}
.markerIcon .marker {
    transform: translate(-50%, calc(-100% - 4px));
    min-height: 30px;
    min-width: 30px;
}
.markerIcon .marker div {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}
</style>
