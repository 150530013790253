<template>
    <div class="flex m-1 border p-1 justify-between">
        <div class="flex" style="flex-direction: inherit">
            <!--
            <app-button
                size="mini"
                class="m-1"
                icon="icon-circle-outline"
                @click="draw('ellipse')"
                :title="$t('pdf.drawEllipse')"
                :variant="drawing === 'ellipse' ? 'selected' : 'default'"
                :disabled="disabled"
            />-->
            <app-button
                size="mini"
                class="m-1"
                icon="icon-draw"
                @click="draw('freeDrawing')"
                :disabled="disabled"
                :title="$t('pdf.freeDrawing')"
                :variant="drawing === 'freeDrawing' ? 'selected' : 'default'"
            />
            <app-button
                size="mini"
                class="m-1"
                icon="icon-rectangle-outline"
                @click="draw('rectangle')"
                :disabled="disabled"
                :title="$t('pdf.drawRectangle')"
                :variant="drawing === 'rectangle' ? 'selected' : 'default'"
                v-if="!isMobile()"
            />
            <app-button
                size="mini"
                class="m-1"
                icon="icon-vector-line"
                @click="draw('segment')"
                :title="$t('pdf.drawSegment')"
                :disabled="disabled"
                :variant="drawing === 'segment' ? 'selected' : 'default'"
            />
            <app-button
                size="mini"
                class="m-1"
                icon="icon-vector-polyline"
                @click="draw('polyline')"
                :title="$t('pdf.drawPolyline')"
                :disabled="disabled"
                :variant="drawing === 'polyline' ? 'selected' : 'default'"
            />
            <app-button
                size="mini"
                class="m-1"
                icon="icon-hexagon-outline"
                @click="draw('polygon')"
                :title="$t('pdf.drawPolygon')"
                :disabled="disabled"
                :variant="drawing === 'polygon' ? 'selected' : 'default'"
            />
            <app-button
                size="mini"
                class="m-1"
                icon="icon-arrow-top-right-bottom-left"
                @click="draw('doubleArrow')"
                :title="$t('pdf.drawDoubleArrow')"
                :disabled="disabled"
                :variant="drawing === 'doubleArrow' ? 'selected' : 'default'"
            />
            <app-button
                size="mini"
                class="m-1"
                icon="icon-arrow-bottom-left"
                :title="$t('pdf.drawSimpleArrow')"
                @click="draw('arrow')"
                :disabled="disabled"
                :variant="drawing === 'arrow' ? 'selected' : 'default'"
            />
            <app-button
                size="mini"
                class="m-1"
                icon="icon-map-marker"
                @click="draw('marker')"
                :title="$t('pdf.addMarker')"
                :disabled="disabled"
                :variant="drawing === 'marker' ? 'selected' : 'default'"
            />
            <app-button
                size="mini"
                class="m-1"
                icon="icon-filled-map-marker"
                @click="draw('filledMarker')"
                :title="$t('pdf.addMarker')"
                :disabled="disabled"
                :variant="drawing === 'filledMarker' ? 'selected' : 'default'"
            />
            <label class="m-1 ml-4 border">
                <div class="flex h-full">
                    <span class="absolute" style="left: -10000px">{{ $t('pdf.pickColor') }}</span>
                    <input
                        class="p-1 h-full focus:outline-none bg-white focus:ring hover:border-gray-400"
                        type="color"
                        :disabled="disabled"
                        :value="color"
                        @input="$emit('pickColor', $event.target.value)"
                    />
                </div>
            </label>
        </div>
        <div class="">
            <app-button
                size="mini"
                class="m-1"
                icon="icon-trash-can-outline"
                @click="$emit('delete')"
                :title="$t('pdf.removeShape')"
                variant="danger"
                :disabled="disabled || !selectedShape"
            />
        </div>
    </div>
</template>
<script>
import AppButton from '@/components/appButton/AppButton';
import AppInputText from '@/components/appInputText/AppInputText';
import { isMobile } from '@/state/state';
export default {
    components: { AppInputText, AppButton },
    props: {
        selectedShape: Object | String,
        color: String,
        disabled: Boolean,
    },
    data() {
        return {
            drawing: null,
        };
    },
    methods: {
        isMobile() {
            return isMobile;
        },
        draw(type) {
            if (this.drawing !== type) {
                this.drawing = type;
                this.$emit('draw', type);
            } else {
                this.drawing = null;
                this.$emit('draw', null);
            }
        },
        cancelDraw() {
            this.drawing = null;
        },
    },
};
</script>
