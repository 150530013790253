<template>
    <div v-if="started">
        <icon-rotate-right class="animate animate-spin" v-if="loading"></icon-rotate-right>
        <div v-show="!loading">
            <div
                v-show="cameraScreen"
                class="fixed inset-0 flex justify-center items-center flex-col bg-black"
                style="width: 100vw; height: 100vh; gap: 1em; z-index: 40; padding-bottom: 10px"
            >
                <video ref="video" class="w-full h-full" style="max-width: 90vw; max-height: 90vh" @canplay="onCanPlay">
                    Le flux vidéo n'est pas disponible.
                </video>
                <app-button
                    class="absolute top-0 right-0 m-2 text-white"
                    variant="borderless"
                    icon="icon-close"
                    @click="close"
                ></app-button>
                <div
                    @click="takePicture"
                    class="mb-6 flex items-center justify-center bg-black border-white"
                    style="border-radius: 100%; border-style: double; border-width: 5px; width: 60px; height: 60px"
                >
                    <button
                        style="border-radius: 100%; background-color: white; width: 40px; height: 40px"
                        @click.prevent
                    ></button>
                </div>
            </div>
            <div
                v-show="!cameraScreen"
                class="fixed inset-0 flex justify-center items-center flex-col bg-black"
                style="width: 100vw; height: 100vh; gap: 1em; z-index: 40"
            >
                <app-button
                    class="absolute top-0 right-0 m-2 text-white"
                    variant="borderless"
                    icon="icon-close"
                    @click="close"
                ></app-button>
                <canvas ref="canvas" style="max-width: 90vw; max-height: 90vh"></canvas>
                <div class="flex items-center justify-between p-10 w-full">
                    <app-button
                        class="text-white"
                        variant="link"
                        ref="retry"
                        @click="retry"
                        :label="$t('photo.retry')"
                    />
                    <app-button
                        class="text-white"
                        variant="variant"
                        ref="validate"
                        @click="validate"
                        :label="$t('photo.validate')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AppButton from '@/components/appButton/AppButton.vue';
import IconRotateRight from '@/icons/IconRotateRight.vue';

export default {
    components: { IconRotateRight, AppButton },
    data() {
        return {
            width: 1280,
            height: 720,
            streaming: false,
            cameraScreen: true,
            loading: false,
            started: false,
        };
    },
    methods: {
        retry() {
            this.clearPhoto();
            this.cameraScreen = true;
        },
        validate() {
            this.$emit('input', this.$refs.canvas.toDataURL('image/jpg'));
            this.close();
        },
        close() {
            this.clearPhoto();
            this.loading = false;
            this.started = false;
            this.$refs.video.pause();
            this.streaming = false;
            this.$refs.video.srcObject = null;
        },
        start() {
            this.loading = true;
            this.started = true;
            this.cameraScreen = true;
            navigator.mediaDevices
                .getUserMedia({
                    video: {
                        width: { ideal: 1280 },
                        facingMode: 'environment',
                    },
                    audio: false,
                })
                .then((stream) => {
                    this.$refs.video.srcObject = stream;
                    this.$refs.video.play();
                })
                .catch((err) => {
                    console.error(`Une erreur est survenue : ${err}`);
                });
            this.clearPhoto();
        },
        onCanPlay() {
            if (!this.streaming) {
                this.height = this.$refs.video.videoHeight / (this.$refs.video.videoWidth / this.width);
                // Firefox bug
                if (isNaN(this.height)) {
                    this.height = this.width / (4 / 3);
                }
                this.$refs.video.setAttribute('width', this.width);
                this.$refs.video.setAttribute('height', this.height);
                this.$refs.canvas.setAttribute('width', this.width);
                this.$refs.canvas.setAttribute('height', this.height);
                this.streaming = true;
                this.loading = false;
            }
        },
        clearPhoto() {
            if (this.$refs.canvas) {
                const context = this.$refs.canvas.getContext('2d');
                context.fillStyle = '#AAA';
                context.fillRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
            }
        },
        takePicture() {
            this.cameraScreen = false;
            const context = this.$refs.canvas.getContext('2d');
            if (this.width && this.height) {
                this.$refs.canvas.width = this.width;
                this.$refs.canvas.height = this.height;
                context.drawImage(this.$refs.video, 0, 0, this.width, this.height);
            } else {
                this.clearPhoto();
            }
        },
    },
};
</script>
