<template>
    <label class="flex" @drop.prevent="dropFile" @dragover.prevent="dragover" @dragleave.prevent="dragleave">
        <a
            class="font-bold cursor-pointer flex items-center"
            :class="{
                'opacity-25': disabled,
                'px-2': icon,
                'p-3': size !== 'mini',
                'p-1': size === 'mini',
                'text-xs': size === 'mini',
                btn: variant !== 'link',
                underline: variant === 'link',
                'bg-gray-300': isDraggingOver,
                'bg-white': !isDraggingOver,
            }"
        >
            <Icon
                :class="{ 'mx-2': size !== 'mini', 'mr-2': size === 'mini' }"
                width="16"
                height="16"
                :name="icon"
                v-if="icon"
            />
            {{ label }}
            <icon-rotate-right class="animate animate-spin" v-if="loading"></icon-rotate-right>
        </a>
        <input
            ref="input"
            type="file"
            class="hidden"
            tabindex="-1"
            v-on:change="onSelect"
            :accept="resize ? 'image/*' : ''"
            :disabled="disabled"
            :name="name"
        />
    </label>
</template>
<script>
import Icon from '../../icons/Icon';
import ImageBlobReduce from 'image-blob-reduce';
import IconRotateRight from '../../icons/IconRotateRight';
import { getAPIHeaders } from '@/services/api.service';
export default {
    components: { IconRotateRight, Icon },
    data() {
        return {
            loading: false,
            imageBlobReduce: new ImageBlobReduce(),
            isDraggingOver: false,
        };
    },
    props: {
        endPoint: {
            type: String,
        },
        method: {
            type: String,
            default: 'POST',
        },
        name: String,
        scope: String,
        label: String,
        size: String,
        icon: String,
        variant: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        resize: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        dragover() {
            this.isDraggingOver = true;
        },
        dragleave() {
            this.isDraggingOver = false;
        },
        dropFile(event) {
            this.onSelect({ target: event.dataTransfer });
            this.isDraggingOver = false;
        },
        async onSelect(event) {
            const files = event.target.files;
            const formData = new FormData();
            this.loading = true;
            try {
                if (this.resize) {
                    try {
                        formData.append(
                            'file',
                            await this.imageBlobReduce.toBlob(files[0], { max: this.resize }),
                            files[0].name,
                        );
                    } catch (e) {
                        formData.append('file', files[0]);
                    }
                } else {
                    formData.append('file', files[0]);
                }
                const fileResult = await (
                    await fetch(
                        this.endPoint
                            ? this.endPoint
                            : `/api/files/${this.$route.params.projectId}/documents/${
                                  this.scope ? '?scope=' + this.scope : ''
                              }`,
                        {
                            method: this.method,
                            body: formData,
                            headers: getAPIHeaders(),
                        },
                    )
                ).json();
                this.$emit('select', fileResult.url ? { name: files[0].name, url: fileResult.url } : fileResult);
            } finally {
                this.loading = false;
            }
        },
        click() {
            this.$refs.input.click();
        },
    },
};
</script>
<style scoped>
.btn {
    border-color: #e5e5e5;
    border-width: 1px;
}
.btn:disabled {
    opacity: 0.3;
}
.btn:hover:not(:disabled),
.btn:focus:not(:disabled) {
    background-color: #f0f0f0;
    border-color: #e3e3e3;
}
</style>
