import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const amendmentVisas = {
    schema: {
        title: 'amendmentVisas schema',
        version: 0,
        description: 'describes a amendmentVisa',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: { type: 'string', final: true, maxLength: 40 },
            amendmentId: { type: ['string', 'null'] },
            conclusion: { type: ['string', 'null'] },
            emissionDueDate: { type: ['string', 'null'] },
            emissionDate: { type: ['string', 'null'] },
            emitterId: { type: ['string', 'null'] },
            url: { type: ['string', 'null'] },
            generationStartDate: { type: ['string', 'null'] },
            generationEndDate: { type: ['string', 'null'] },
            generationSucceed: { type: ['boolean', 'null'] },
            attachments: {
                type: ['array', 'null'],
                items: {
                    type: 'object',
                    properties: {
                        url: { type: 'string' },
                        fileName: { type: 'string' },
                    },
                },
            },
            createdBy: { type: ['string', 'null'], final: true },
            updatedAt: { type: ['string', 'null'] },
            deletedAt: { type: ['string', 'null'] },
            deletedBy: { type: ['string', 'null'] },
            createdAt: { type: ['string', 'null'] },
            updatedBy: { type: ['string', 'null'] },
            projectId: { type: ['string', 'null'] },
            rootVersion: { type: ['string', 'null'] },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
