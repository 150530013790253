import db from '@/rxdb/database';
import { map, mergeMap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { groupBy } from '@/services/sanitize.service';
import { getCachedObservable } from '@/rxdb/observablesCache';
import { createObservationsReport } from '@/features/observationsReports/observationsReports.service';

function mapReception(item) {
    return item
        ? {
              ...item.toMutableJSON(),
              emissionDueDate: item.emissionDueDate ? new Date(item.emissionDueDate) : null,
              emissionDate: item.emissionDate ? new Date(item.emissionDate) : null,
          }
        : null;
}

function mapReceptions(dbReceptions) {
    const receptions = dbReceptions.map(mapReception);
    return groupBy(receptions, 'groupId').map((versions) => {
        const last = versions[versions.length - 1];
        return {
            ...last,
            versions,
        };
    });
}

export function getReceptions(projectId) {
    return getCachedObservable('getReceptions_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .receptions.find({ selector: {}, sort: [{ createdAt: 'asc' }] })
            .$.pipe(map(mapReceptions)),
    );
}
export function getAllReceptions(projectId) {
    return getCachedObservable('getAllReceptions_' + projectId, () =>
        db
            .getProjectCollections(projectId)
            .receptions.find({ selector: {}, sort: [{ createdAt: 'asc' }] })
            .$.pipe(map((receptions) => receptions.map((reception) => reception.toMutableJSON()))),
    );
}

export function removeReception(projectId, id) {
    return db
        .getProjectCollections(projectId)
        .receptions.findOne({ selector: { id: id } })
        .remove();
}

export async function createReception(projectId, item) {
    const result = await db.getProjectCollections(projectId).receptions.insert({
        locationIds: [],
        groupId: uuidv4(),
        ...item,
        emissionDueDate: item.emissionDueDate ? item.emissionDueDate.toISOString() : new Date().toISOString(),
    });
    const reception = await result.toJSON();
    await createObservationsReport(projectId, {
        name: '',
        date: new Date(),
        showEmptyZones: false,
        showTables: true,
        showPhotos: true,
        showPending: true,
        showAdministrative: true,
        showObservationsOnTodoWork: true,
        showObservationsOnDoneWork: true,
        showOtherObservations: true,
        showObservationsWithoutZone: true,
        showCONObservations: true,
        showEXEObservations: true,
        showOPRObservations: true,
        showReceiptObservations: true,
        showOPLObservations: true,
        showDeliveryObservations: true,
        showAPAObservations: true,
        showMaps: true,
        showResolved: true,
        showStats: true,
        generateAPDFByBundle: false,
        showValidated: true,
        bundleIds: [],
        zoneIds: [],
        files: [],
        relatedContentId: reception.id,
        relatedContentType: 'reception',
    });
    return reception;
}

export async function updateReception(projectId, reception) {
    const dbReception = await db
        .getProjectCollections(projectId)
        .receptions.findOne({ selector: { id: reception.id } })
        .exec();
    const patch = { ...reception };
    if (reception.emissionDate === null || !!reception.emissionDate) {
        patch.emissionDate = reception.emissionDate ? reception.emissionDate.toISOString() : null;
    }
    if (reception.emissionDueDate === null || !!reception.emissionDueDate) {
        patch.emissionDueDate = reception.emissionDueDate ? reception.emissionDueDate.toISOString() : null;
    }
    return dbReception.atomicPatch(patch);
}

export function getReception(projectId, receptionId) {
    return db
        .getProjectCollections(projectId)
        .receptions.findOne({ selector: { id: receptionId } })
        .$.pipe(
            mergeMap(async (reception) => {
                if (!reception) {
                    throw new Error(`unknown reception [${receptionId}]`);
                }
                const versions = await db
                    .getProjectCollections(projectId)
                    .receptions.find({ selector: { groupId: reception.groupId }, sort: [{ createdAt: 'asc' }] })
                    .exec();
                return {
                    ...mapReception(reception),
                    versions: versions.map((version) => version.toJSON()),
                };
            }),
        );
}

export async function queryReception(projectId, receptionId) {
    const dbReception = await db
        .getProjectCollections(projectId)
        .receptions.findOne({ selector: { id: receptionId } })
        .exec();
    return mapReception(dbReception);
}
export async function removeReceptionGroup(projectId, receptionGroupId) {
    const entities = await db
        .getProjectCollections(projectId)
        .receptions.find({ selector: { groupId: receptionGroupId } })
        .exec();
    return Promise.all(entities.map((entity) => entity.remove()));
}

export async function filterLocationIds(projectId, reception, filterFn) {
    const dbReception = await db
        .getProjectCollections(projectId)
        .receptions.findOne({ selector: { id: reception.id } })
        .exec();
    return dbReception.atomicPatch({
        locationIds: dbReception.locationIds.filter(filterFn),
    });
}
export default {
    filterLocationIds,
    updateReception,
};
