import db from '@/rxdb/database';
import { map } from 'rxjs';

function mapAmendmentAttachment(item) {
    if (item) {
        return item.toMutableJSON();
    } else {
        return null;
    }
}

export function getAmendmentAttachments(projectId, amendmentId) {
    return db
        .getProjectCollections(projectId)
        .amendmentAttachments.find({ selector: { amendmentId } })
        .$.pipe(map((amendmentAttachments) => amendmentAttachments.map(mapAmendmentAttachment)));
}
export function getAmendmentAttachment(projectId, amendmentAttachmentId) {
    return db
        .getProjectCollections(projectId)
        .amendmentAttachments.findOne({ selector: { id: amendmentAttachmentId } })
        .$.pipe(map(mapAmendmentAttachment));
}

export function removeAmendmentAttachment(projectId, amendmentAttachmentId) {
    return db
        .getProjectCollections(projectId)
        .amendmentAttachments.findOne({ selector: { id: amendmentAttachmentId } })
        .remove();
}

export async function updateAmendmentAttachment(projectId, amendmentAttachment) {
    const dbAmendment = await db
        .getProjectCollections(projectId)
        .amendmentAttachments.findOne({ selector: { id: amendmentAttachment.id } })
        .exec();
    return dbAmendment.atomicPatch({ rotation: amendmentAttachment.rotation });
}
