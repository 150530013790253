<template>
    <div class="flex flex-col gap-4">
        <app-multiple-upload-button
            class="col-span-2 text-sm offline:hidden"
            icon="icon-paperclip"
            :label="$t('project.editions.meetingReports.newAttachment')"
            :end-point="`/api/projects/${$route.params.projectId}/meetings/${meeting.id}/attachments`"
            :name="$t('project.editions.meetingReports.newAttachment')"
            :resize="1024"
            @loaded="onNewAttachment"
        />
        <div class="flex flex-wrap col-span-2 gap-2">
            <table class="w-full">
                <tr class="odd:bg-blue-50" v-for="attachment in meeting.attachments">
                    <td style="width: 200px; height: 130px">
                        <div class="flex justify-center">
                            <app-photo :url="attachment.url" :removable="false"></app-photo>
                        </div>
                    </td>
                    <td>
                        <app-input-text
                            v-model="attachment.legend"
                            :placeholder="$t('commons.legend')"
                            @blur="onLegendUpdate(meeting.attachments)"
                        />
                    </td>
                    <td style="width: 80px">
                        <div class="flex justify-end m-2">
                            <app-button
                                size="mini"
                                variant="danger"
                                aria-label="remove photo"
                                icon="icon-trash-can-outline"
                                @click="onDeleteAttachment(attachment)"
                            />
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { getMeeting, updateMeeting } from './meetings.service';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton';
import AppPhoto from '@/components/appPhoto/AppPhoto';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppButton from '@/components/appButton/AppButton.vue';
export default {
    components: {
        AppButton,
        AppInputText,
        AppMultipleUploadButton,
        AppPhoto,
        AppUploadButton,
    },
    created() {
        this.subscriptions = [
            getMeeting(this.$route.params.projectId, this.$route.params.meetingId).subscribe((meeting) => {
                this.meeting = meeting;
            }),
        ];
    },
    methods: {
        onNewAttachment(attachments) {
            updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                attachments: JSON.parse(attachments),
            });
        },
        onLegendUpdate(attachments) {
            updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                attachments,
            });
        },
        async onDeleteAttachment(attachment) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                return updateMeeting(this.$route.params.projectId, {
                    id: this.meeting.id,
                    attachments: this.meeting.attachments.filter((anAttachment) => anAttachment.url !== attachment.url),
                });
            }
        },
    },
    data() {
        return {
            meeting: {},
            subscriptions: [],
        };
    },
};
</script>
