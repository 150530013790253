<template>
    <div class="flex flex-col w-full px-5 gap-5">
        <template v-if="loading">
            <div class="flex justify-center my-5">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-col md:flex-row gap-2 text-sm md:sticky md:top-toolbar bg-white z-40">
                <div class="flex flex-col gap-2 flex-grow mt-5">
                    <div class="flex gap-2">
                        <div class="flex gap-2">
                            <app-button
                                size="mini"
                                icon="icon-chevron-left"
                                :title="$t('project.follow.byBundle.previousBundle')"
                                :disabled="bundleIndex === 0"
                                @click="previousBundle()"
                            ></app-button>
                            <div class="flex-grow">
                                <app-bundle-picker
                                    :value="bundle"
                                    @input="goTo($event.id)"
                                    :options="options"
                                    :label="$t('project.follow.byBundle.currentBundle')"
                                    :showLabel="false"
                                    :showTip="false"
                                    :required="true"
                                ></app-bundle-picker>
                            </div>
                            <app-button
                                size="mini"
                                icon="icon-chevron-right"
                                :title="$t('project.follow.byBundle.nextBundle')"
                                :disabled="bundleIndex === bundles.length - 1"
                                @click="nextBundle()"
                            ></app-button>
                        </div>
                        <div class="flex-grow">
                            <app-filter ref="filter" v-model="filterAsString" @input="updateState"></app-filter>
                        </div>
                        <div class="flex items-center">
                            <app-button
                                size="mini"
                                @click="$refs.settings.open()"
                                :aria-label="$t('commons.settings')"
                                :title="$t('commons.settings')"
                                icon="icon-tune"
                            />
                        </div>
                    </div>
                    <div class="flex gap-2 text-xs w-full flex-wrap" v-if="bundle">
                        <div v-if="bundle.reference" class="flex gap-1 items-center">
                            <router-link
                                class="hover:underline flex"
                                :to="{
                                    name: 'bundle',
                                    params: {
                                        ...$route.params,
                                        bundleId: bundle.id,
                                    },
                                }"
                            >
                                {{ bundle.reference }}
                            </router-link>
                        </div>
                        <div v-if="bundle.company" class="flex gap-1 items-center">
                            <icon-domain width="16" height="16" />
                            {{ bundle.company.name }}
                        </div>
                        <div v-if="bundle.responsible" class="flex gap-1 items-center">
                            <icon-user width="16" height="16" />
                            {{ bundle.responsible.firstName }} {{ bundle.responsible.lastName }}
                        </div>
                        <app-phone
                            v-if="bundle.responsible && bundle.responsible.phone"
                            :number="bundle.responsible.phone"
                        />
                        <app-mail
                            v-if="bundle.responsible && bundle.responsible.email"
                            :email="bundle.responsible.email"
                        />
                    </div>
                    <div class="text-xs gap-4 flex w-full" v-if="$route.params.bundleId !== 'generals'">
                        <span class="font-bold">
                            {{ $t('project.editions.meetingReports.attendanceStatsTitle') }} :
                        </span>
                        <span>
                            {{ attendanceStats.present }}
                            {{ $t('project.editions.meetingReports.attendanceValuesPlural.present') }}
                        </span>
                        <span>
                            {{ attendanceStats.excusedAbsent }}
                            {{ $t('project.editions.meetingReports.attendanceValuesPlural.excusedAbsent') }}
                        </span>
                        <span>
                            {{ attendanceStats.unexcusedAbsent }}
                            {{ $t('project.editions.meetingReports.attendanceValuesPlural.unexcusedAbsent') }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col w-full" v-if="$route.params.bundleId !== 'generals'">
                <app-separator variant="underline" class="w-full my-2 text-base">
                    <div class="flex items-center justify-between w-full">
                        <span class="flex gap-2 items-center">
                            <a id="conceptionsToEmit" style="scroll-margin-top: 9em">
                                {{ $t('project.editions.meetingReports.conceptionsToEmit') }}
                            </a>
                            <span class="text-sm" v-if="filteredConceptions.length > 0">
                                ({{ filteredConceptions.length }})
                            </span>
                        </span>
                        <app-button
                            size="mini"
                            :label="$t('preparations.newPreparation')"
                            class="mb-1"
                            @click="newPreparation('con')"
                        />
                    </div>
                </app-separator>
                <span class="ml-2 text-xs italic text-gray-700" v-if="filteredConceptions.length === 0">
                    {{ $t('commons.emptyList') }}
                </span>
                <table class="w-full text-xs border" v-if="filteredConceptions.length > 0">
                    <thead class="table-header-group font-bold">
                        <tr class="border">
                            <td class="p-1">{{ $t('commons.name') }}</td>
                            <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="preparation in filteredConceptions">
                            <tr class="border odd:bg-blue-50">
                                <td class="p-1 relative">
                                    <div class="w-full flex flex-col">
                                        <div class="flex w-full gap-2">
                                            <a
                                                class="absolute top-0"
                                                :id="'uuid_' + preparation.id"
                                                style="scroll-margin-top: 9em"
                                            ></a>
                                            <span class="">
                                                {{
                                                    preparation.type ? $t('preparations.types.' + preparation.type) : ''
                                                }}
                                                :
                                            </span>
                                            <router-link
                                                class="hover:underline flex"
                                                :to="{
                                                    name: 'followConceptionByBundlePreparation',
                                                    params: {
                                                        ...$route.params,
                                                        preparationId: preparation.id,
                                                    },
                                                }"
                                                @click.native="setLastVisited(preparation.id)"
                                            >
                                                <icon-circle-medium
                                                    v-if="lastVisitedId === preparation.id && pulse"
                                                    class="text-main animate-ping"
                                                    width="16"
                                                    height="16"
                                                ></icon-circle-medium>
                                                {{ preparation.name.length ? preparation.name : '...' }}
                                            </router-link>
                                            <div v-if="preparation.versions.length > 1">
                                                <span class="text-red-500 text-xs italic">
                                                    {{ $t('preparations.newVersionTip') }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="ml-4">
                                            <div
                                                v-for="locationPath in mergeLocationsPathsByFolders(
                                                    preparation.locations,
                                                )"
                                            >
                                                {{ locationPath }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td
                                    class="p-1"
                                    :class="{
                                        'font-bold text-red-500': preparation.isLate,
                                    }"
                                >
                                    {{ preparation.label }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <app-separator variant="underline" class="w-full my-2 text-base">
                    <span class="flex gap-2 items-center">
                        <a id="conceptionsToVisa" style="scroll-margin-top: 9em">
                            {{ $t('project.editions.meetingReports.conceptionsToVisa') }}
                        </a>
                        <span class="text-sm" v-if="conceptionVisas.length > 0">({{ conceptionVisas.length }})</span>
                    </span>
                </app-separator>
                <span class="ml-2 text-xs italic text-gray-700" v-if="conceptionVisas.length === 0">
                    {{ $t('commons.emptyList') }}
                </span>
                <table class="w-full text-xs border" v-else>
                    <thead class="table-header-group font-bold">
                        <tr class="border">
                            <td class="p-1">{{ $t('commons.name') }}</td>
                            <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="preparationVisa in filteredConceptionVisas">
                            <tr class="border odd:bg-blue-50">
                                <td class="p-1">
                                    <div class="w-full flex flex-col">
                                        <div class="flex gap-1 relative">
                                            <a
                                                :id="'uuid_' + preparationVisa.id"
                                                style="scroll-margin-top: 9em"
                                                class="absolute top-0"
                                            ></a>
                                            <router-link
                                                class="hover:underline flex"
                                                :to="{
                                                    name: 'followConceptionByBundlePreparationVisa',
                                                    params: {
                                                        ...$route.params,
                                                        preparationId: preparationVisa.id,
                                                        preparationVisaId: preparationVisa.visa.id,
                                                    },
                                                }"
                                                @click.native="setLastVisited(preparationVisa.id)"
                                            >
                                                <icon-circle-medium
                                                    v-if="lastVisitedId === preparationVisa.id && pulse"
                                                    class="text-main animate-ping"
                                                    width="16"
                                                    height="16"
                                                ></icon-circle-medium>
                                                {{ preparationVisa.name.length ? preparationVisa.name : '...' }}
                                            </router-link>
                                            <app-tips class="flex gap-1" v-if="preparationVisa.bundle">
                                                {{ preparationVisa.bundle.label }}
                                            </app-tips>
                                        </div>
                                        <div class="ml-4">
                                            <div
                                                v-for="locationPath in mergeLocationsPathsByFolders(
                                                    preparationVisa.locations,
                                                )"
                                            >
                                                {{ locationPath }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td
                                    class="p-1"
                                    :class="{
                                        'font-bold text-red-500': preparationVisa.isLate,
                                    }"
                                >
                                    {{ preparationVisa.label }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

                <template v-for="group in filteredObservationsGroups">
                    <template v-if="group.observations.length > 0 || group.type !== 'private'">
                        <app-separator variant="underline" class="w-full my-2 text-base">
                            <div class="flex items-center justify-between w-full">
                                <span class="flex gap-2 items-center">
                                    <span v-if="group.type === 'private'">
                                        <icon-incognito class="text-violet-700" />
                                    </span>
                                    <a :id="group.type" style="scroll-margin-top: 9em">{{ group.label }}</a>
                                    <span class="text-sm" v-if="group.observations.length > 0">
                                        ({{ group.observations.length }})
                                    </span>
                                </span>
                                <app-button
                                    size="mini"
                                    :label="$t('project.follow.observation.newObservation')"
                                    class="mb-1"
                                    @click="newObservation(group.type)"
                                    v-if="isObservationAllowed(group.type)"
                                />
                            </div>
                        </app-separator>
                        <span v-if="group.observations.length === 0" class="ml-2 text-xs italic text-gray-700">
                            {{ $t('commons.emptyList') }}
                        </span>
                        <table class="w-full text-xs border" v-else>
                            <thead class="table-header-group font-bold">
                                <tr class="border">
                                    <td class="p-1" style="width: auto">{{ $t('commons.index') }}</td>
                                    <td class="p-1" style="width: 70%">
                                        {{ $t('project.editions.observationsReports.title') }}
                                    </td>
                                    <td class="p-1" style="width: 20%">{{ $t('commons.status') }}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="observation in group.observations">
                                    <tr class="border odd:bg-blue-50">
                                        <td class="p-1 relative">
                                            <a
                                                :id="'uuid_' + observation.id"
                                                style="scroll-margin-top: 9em"
                                                class="absolute top-0"
                                            ></a>
                                            {{ (observation.index + 1).toString().padStart(3, '0') }}
                                        </td>
                                        <td class="p-1">
                                            <div class="w-full flex flex-col">
                                                <router-link
                                                    class="hover:underline flex"
                                                    :to="{
                                                        name: 'followConceptionByBundleObservation',
                                                        params: {
                                                            ...$route.params,
                                                            observationId: observation.id,
                                                        },
                                                    }"
                                                    @click.native="setLastVisited(observation.id)"
                                                >
                                                    <icon-circle-medium
                                                        v-if="lastVisitedId === observation.id && pulse"
                                                        class="text-main animate-ping"
                                                        width="16"
                                                        height="16"
                                                    ></icon-circle-medium>
                                                    <div class="flex flex-col justify-start">
                                                        <span
                                                            v-html="observation.title"
                                                            class="whitespace-pre-wrap html-breakline"
                                                        />
                                                        <span
                                                            v-if="!observation.title || observation.title.length === 0"
                                                        >
                                                            ...
                                                        </span>
                                                        <div
                                                            class="w-full"
                                                            v-if="
                                                                observation.attachments &&
                                                                observation.attachments.length > 0
                                                            "
                                                        >
                                                            <template v-for="attachment of observation.attachments">
                                                                <div style="min-height: 100px; max-height: 200px">
                                                                    <app-leaflet-viewer
                                                                        class="flex-grow"
                                                                        :src="attachment.url"
                                                                        :shapes="attachment.shapes"
                                                                        :preview="true"
                                                                        :page="1"
                                                                        style="min-height: 100px"
                                                                    />
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </router-link>
                                                <div class="ml-4" v-if="observation.room">
                                                    {{ observation.room.fullName }}
                                                </div>
                                                <div class="ml-4" v-else-if="observation.zone">
                                                    {{ observation.zone.fullName }}
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            class="p-1"
                                            :class="{
                                                'font-bold text-red-500': observation.isLate,
                                            }"
                                        >
                                            <app-date-link
                                                :label="$t('observations.closeDate')"
                                                show-label="onFocus"
                                                :disabled="!isObservationAdmin"
                                                @input="resolveObservation(observation, $event)"
                                                v-model="observation.resolvedAt"
                                                v-if="!observation.resolvedAt"
                                            >
                                                <span :class="{ underline: isObservationAdmin }">
                                                    {{ observation.label }}
                                                </span>
                                            </app-date-link>
                                            <app-date-link
                                                :label="$t('observations.validate')"
                                                show-label="onFocus"
                                                :disabled="!isObservationValidator"
                                                @input="validateObservation(observation, $event)"
                                                v-model="observation.validatedAt"
                                                v-if="observation.resolvedAt && !observation.validatedAt"
                                            >
                                                <span :class="{ underline: isObservationValidator }">
                                                    {{ observation.label }}
                                                </span>
                                            </app-date-link>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>
                </template>
            </div>
            <div v-else>
                <template>
                    <app-separator variant="underline" class="w-full my-2 text-base">
                        <div class="flex items-center justify-between w-full">
                            <span class="flex gap-2 items-center">
                                <a id="generals" style="scroll-margin-top: 9em">
                                    {{ $t('observations.generalObservations') }}
                                </a>
                                <span class="text-sm">({{ filteredGeneralObservations.length }})</span>
                            </span>
                            <app-button
                                size="mini"
                                :label="$t('observations.newObservation')"
                                class="mb-1"
                                @click="newObservation('general')"
                                v-if="isObservationAllowed('general')"
                            />
                        </div>
                    </app-separator>
                    <span v-if="filteredGeneralObservations.length === 0" class="ml-2 text-xs italic text-gray-700">
                        {{ $t('commons.emptyList') }}
                    </span>
                    <table class="w-full text-xs border table-fixed" v-else>
                        <thead class="table-header-group font-bold">
                            <tr class="border">
                                <th class="p-1" style="width: 3rem">{{ $t('commons.index') }}</th>
                                <th class="p-1" style="width: auto">
                                    {{ $t('project.editions.observationsReports.title') }}
                                </th>
                                <th class="p-1" style="width: 7rem">
                                    <span class="invisible">{{ $t('observations.resolveDate') + ' :' }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="observation in filteredGeneralObservations">
                                <tr class="border odd:bg-blue-50">
                                    <td class="p-1 align-top relative">
                                        <a
                                            :id="'uuid_' + observation.id"
                                            style="scroll-margin-top: 9em"
                                            class="absolute top-0"
                                        ></a>
                                        {{ (observation.index + 1).toString().padStart(3, '0') }}
                                    </td>
                                    <td class="p-1">
                                        <div class="w-full flex flex-col">
                                            <router-link
                                                class="hover:underline flex"
                                                :to="{
                                                    name: 'followConceptionByBundleObservation',
                                                    params: {
                                                        ...$route.params,
                                                        observationId: observation.id,
                                                    },
                                                }"
                                                @click.native="setLastVisited(observation.id)"
                                            >
                                                <icon-circle-medium
                                                    v-if="lastVisitedId === observation.id && pulse"
                                                    class="text-main animate-ping"
                                                    width="16"
                                                    height="16"
                                                ></icon-circle-medium>
                                                <span
                                                    v-html="observation.title"
                                                    class="whitespace-pre-wrap html-breakline"
                                                />
                                                <span v-if="!observation.title || observation.title.length === 0">
                                                    ...
                                                </span>
                                            </router-link>
                                        </div>
                                    </td>
                                    <td class="p-1">
                                        <div class="flex justify-end pr-2">
                                            <app-date-link
                                                :label="$t('observations.closeDate')"
                                                @input="updateObservation(observation, $event)"
                                                v-model="observation.resolvedAt"
                                                show-label="onFocus"
                                            ></app-date-link>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </template>
            </div>
            <div style="min-height: 100vw" />
        </template>

        <app-popup ref="settings" :showHeader="true" :title="$t('commons.settings')">
            <form class="flex flex-col gap-4 p-2">
                <fieldset>
                    <legend class="text-gray-700 font-bold flex flex-col gap-1">
                        {{ $t('observations.filterByEmitter') }}
                    </legend>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="emitters"
                        :options="emitterOptions"
                        :label="$t('project.editions.observationsReports.emitters')"
                        :show-label="false"
                        :placeholder="$t('commons.all')"
                        labelKey="label"
                        @input="updateState"
                    >
                        <template v-slot:option="{ option }">
                            <app-bundle :bundle="option"></app-bundle>
                        </template>
                    </app-multi-picker>
                </fieldset>
                <app-footer @click="$refs.settings.close()"></app-footer>
            </form>
            <div style="padding-bottom: 40px"></div>
        </app-popup>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </div>
</template>

<script>
import AppList from '@/components/appList/AppList';
import { filterMatch, getMapById, sortBy } from '@/services/sanitize.service';
import AppBundle from '@/components/app-bundle/appBundle';
import AppButton from '@/components/appButton/AppButton';
import AppSelect from '@/components/appSelect/AppSelect';
import AppDateLink from '@/components/appDateLink/AppDateLink';
import { updateBreadCrumbs } from '@/state/state';
import { mergeLocationsPathsByFolders } from '@/services/location.service';
import { applyDelay } from '@/services/duration.service';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppInputText from '@/components/appInputText/AppInputText';
import AppFilter from '@/components/appFilter/AppFilter';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import { createObservation, getObservations, updateObservation } from '@/features/observations/observation.service';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import AppBundlePicker from '@/components/appBundlePicker';
import { bundlesToGroups, getBundleMap, sortBundles } from '@/features/bundles/bundles.service';
import AppLabel from '@/components/appLabel/AppLabel';
import AppMail from '@/components/appMail/AppMail';
import AppPhone from '@/components/appPhone/AppPhone';
import AppTips from '@/components/app-tips/AppTips';
import { getProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import { getCompanies } from '@/features/companies/companies.service';
import { getContactMap } from '@/features/contacts/contacts.service';
import { getByBundleDataForConception, getByBundlePreparedDate } from '@/features/meetings/byBundle.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppFooter from '@/components/appFooter/AppFooter';
import AppPopup from '@/components/app-popup/AppPopup';
import IconCircleMedium from '@/icons/IconCircleMedium.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions.vue';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer.vue';
import { createPreparation } from '@/features/preparations/preparations.service';

export default {
    components: {
        AppLeafletViewer,
        AppQuickActions,
        AppMultiPicker,
        IconCircleMedium,
        AppPopup,
        AppFooter,
        AppCheckbox,
        AppTips,
        AppPhone,
        AppMail,
        AppLabel,
        AppBundlePicker,
        AppNumberLink,
        AppDropDownButton,
        AppDateInput,
        AppFilter,
        AppInputText,
        AppSeparator,
        AppSelect,
        AppButton,
        AppBundle,
        AppList,
        AppDateLink,
    },
    async created() {
        this.subscriptions = [
            getByBundleDataForConception(this.$route.params.projectId).subscribe((byBundleData) => {
                this.byBundleData = byBundleData;
                this.refresh();
            }),
            combineLatest([
                getBundleMap(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
                getCompanies(this.$route.params.projectId),
                getContactMap(this.$route.params.projectId),
                getObservations(this.$route.params.projectId),
            ]).subscribe(([bundleMap, project, companies, contactMap, observations]) => {
                const bundles = sortBundles(Object.values(bundleMap));
                this.emitterOptions = bundles;
                this.allowedFeatures = [...project.projectFeatures, ...project.me.allowedFeatures];
                this.generalObservations = sortBy(
                    observations.filter(
                        (observation) =>
                            observation.phase === 'con' &&
                            observation.type === 'general' &&
                            !observation.resolvedAt &&
                            !observation.obsoleteAt,
                    ),
                    'createdAt',
                );
                this.isTaskAdmin = project.me.allowedFeatures.includes('project_planning');
                this.isObservationValidator = project.me.allowedFeatures.includes('project_observations_MOEValidation');
                this.isObservationAdmin = project.me.allowedFeatures.includes('project_observations');
                this.me = project.me;
                const companyMap = getMapById(companies);
                const allowedBundles = bundles
                    .filter(
                        (bundle) =>
                            project.me.allowedFeatures.includes('project_followAll') ||
                            project.me.bundleIds.includes(bundle.id),
                    )
                    .map((bundle) => ({
                        ...bundle,
                        company: companyMap[bundle.companyId],
                        responsible: contactMap[bundle.responsibleId],
                    }));
                const bundleGroups = bundlesToGroups(sortBy(allowedBundles, 'code'));
                this.options = this.buildOptions(bundleGroups);
                this.bundles = this.buildBundleList(bundleGroups);
                this.restoreState();
                if (!this.$route.params.bundleId && this.bundles.length > 0) {
                    this.backToLastBundle();
                } else {
                    this.refresh();
                }
                this.loading = false;
            }),
        ];
    },
    computed: {
        filteredConceptions() {
            if (this.filterAsString === '') return this.conceptions;
            else return this.conceptions.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredConceptionVisas() {
            if (this.filterAsString === '') return this.conceptionVisas;
            else return this.conceptionVisas.filter((item) => filterMatch(item.name, this.filterAsString, true));
        },
        filteredObservationsGroups() {
            return this.observationsGroups.map((group) => ({
                ...group,
                observations: group.observations.filter((item) => filterMatch(item.title, this.filterAsString, true)),
            }));
        },
        filteredGeneralObservations() {
            return this.generalObservations.filter((item) => filterMatch(item.title, this.filterAsString, true));
        },
        quickActions() {
            if (!this.bundle) {
                return [];
            }
            return this.bundle.id === 'generals'
                ? [
                      {
                          name: this.$t('project.follow.observation.newObservation'),
                          run: () => this.newObservation('general'),
                      },
                  ]
                : [
                      {
                          name: this.$t('project.follow.byBundle.newOnDoneWorkObservation'),
                          run: () => {
                              this.newObservation('onDoneWork');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newOnTodoWorkObservation'),
                          run: () => {
                              this.newObservation('onTodoWork');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newAdministrativeObservation'),
                          run: () => {
                              this.newObservation('administrative');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newPrivateObservation'),
                          run: () => {
                              this.newObservation('private');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newOtherObservation'),
                          run: () => {
                              this.newObservation('other');
                          },
                      },
                      {
                          name: this.$t('project.follow.byBundle.newConPreparation'),
                          run: () => {
                              this.newPreparation('con');
                          },
                      },
                  ];
        },
    },
    watch: {
        $route: function () {
            this.refresh();
        },
    },
    methods: {
        setLastVisited(id) {
            localStorage.setItem('observation.lastVisitedObservationId.' + this.$route.params.projectId, id);
        },
        restoreState() {
            const stateStr = localStorage.getItem('conceptionsByBundle' + this.$route.params.projectId);
            const state = stateStr
                ? JSON.parse(stateStr)
                : {
                      filterAsString: '',
                      emitterIds: [],
                  };
            if (state) {
                this.filterAsString = state.filterAsString;
                this.emitters = (state.emitterIds || [])
                    .map((id) => this.emitterOptions.find((bundle) => bundle.id === id))
                    .filter((a) => !!a);
            }
        },
        updateState() {
            localStorage.setItem(
                'conceptionsByBundle' + this.$route.params.projectId,
                JSON.stringify({
                    filterAsString: this.filterAsString,
                    emitterIds: this.emitters.map((bundle) => bundle.id),
                }),
            );
            this.refresh();
        },
        buildBundleList(bundleGroups) {
            return [
                {
                    id: 'generals',
                },
                ...bundleGroups.MOA,
                ...bundleGroups.AMOA,
                ...bundleGroups.MOE,
                ...bundleGroups.generalContractor,
                ...bundleGroups.distributor,
            ];
        },
        buildOptions(bundleGroups) {
            return [
                {
                    isGroup: false,
                    name: '- ' + this.$t('observations.generalObservations') + ' -',
                    id: 'generals',
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.MOA'),
                    children: bundleGroups.MOA,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.AMOA'),
                    children: bundleGroups.AMOA,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.MOE'),
                    children: bundleGroups.MOE,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.generalContractor'),
                    children: bundleGroups.generalContractor,
                },
                {
                    isGroup: true,
                    name: this.$t('bundles.categories.distributor'),
                    children: bundleGroups.distributor,
                },
            ];
        },
        isObservationAllowed(type) {
            return (
                type === 'private' ||
                (type === 'administrative' && this.allowedFeatures.includes('project_observations_administrative')) ||
                (type === 'general' && this.allowedFeatures.includes('project_observations_general')) ||
                (type === 'other' && this.allowedFeatures.includes('project_observations_other')) ||
                (['onDoneWork', 'onTodoWork'].includes(type) &&
                    this.allowedFeatures.includes('project_observations_work'))
            );
        },
        async newPreparation() {
            const result = await createPreparation(this.$route.params.projectId, {
                name: this.$t('preparations.newName'),
                deliveryDuration: 5,
                bundleId: this.bundle.id,
                phase: 'con',
            });
            this.setLastVisited(result.id);
            await this.$router.push({
                name: 'followConceptionByBundlePreparation',
                params: {
                    projectId: this.$route.params.projectId,
                    preparationId: result.id,
                },
            });
        },
        async newObservation(type) {
            const observation = await createObservation(this.$route.params.projectId, {
                type,
                phase: 'CON',
                reportedBy: this.me.bundleIds.length ? this.me.bundleIds[0] : null,
                title: '',
                recipientIds: this.bundle.id === 'generals' ? null : [this.bundle.id],
                dueDate: applyDelay(this.now, 7, this.agenda, this.bundle.id),
            });
            this.setLastVisited(observation.id);
            this.$router.push({
                name: 'followConceptionByBundleObservation',
                params: {
                    projectId: this.$route.params.projectId,
                    observationId: observation.id,
                },
            });
        },
        resolveObservation(observation, $event) {
            return updateObservation(this.$route.params.projectId, {
                id: observation.id,
                resolvedAt: $event,
                resolvedBy: this.me.bundleIds.length ? this.me.bundleIds[0] : null,
            });
        },
        validateObservation(observation, $event) {
            return updateObservation(this.$route.params.projectId, {
                id: observation.id,
                validatedAt: $event,
                validatedBy: this.me.bundleIds.length ? this.me.bundleIds[0] : null,
            });
        },
        backToLastBundle() {
            if (this.bundles && this.bundles.length > 0) {
                const cache = localStorage.getItem('conceptionsByBundle.lastBundleId.' + this.$route.params.projectId);
                if (cache && this.bundles.find((bundle) => bundle.id === cache)) {
                    return this.goTo(cache, true);
                } else {
                    return this.goTo(this.bundles[0].id, true);
                }
            }
        },
        scrollToLastVisited() {
            this.lastVisitedId = localStorage.getItem(
                'observation.lastVisitedObservationId.' + this.$route.params.projectId,
            );
            if (this.lastVisitedId) {
                this.$nextTick(() => {
                    const element = this.$el.querySelector('#uuid_' + this.lastVisitedId);
                    if (element) element.scrollIntoView();
                    this.pulse = true;
                    setTimeout(() => (this.pulse = false), 5000);
                });
            }
        },
        async refresh() {
            if (this.byBundleData.length === 0) {
                return;
            }
            this.bundle = this.bundles.find((bundle) => bundle.id === this.$route.params.bundleId);
            if (!this.bundle && this.$route.params.bundleId !== 'generals') {
                return this.backToLastBundle();
            } else if (this.$route.params.bundleId === 'generals') {
                this.bundleIndex = 0;
                this.bundle = { id: 'generals' };
                updateBreadCrumbs({ bundleName: this.$t('observations.generalObservations') });
                localStorage.setItem('conceptionsByBundle.lastBundleId.' + this.$route.params.projectId, 'generals');
            } else {
                this.bundleIndex = this.bundles.indexOf(this.bundle);
                updateBreadCrumbs({ bundleName: this.bundle.reference + ' ' + this.bundle.name });
                localStorage.setItem(
                    'conceptionsByBundle.lastBundleId.' + this.$route.params.projectId,
                    this.bundle.id,
                );
            }
            const data = getByBundlePreparedDate(
                (key) => this.$t(key),
                null,
                this.bundle.id,
                null,
                null,
                this.byBundleData,
                false,
                false,
                true,
                true,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                null,
                this.emitters.map((bundle) => bundle.id),
            );
            this.meeting = data.meeting;
            this.attendanceStats = data.attendanceStats;
            this.hasItems = data.hasItems;
            this.observationsGroups = data.observationsGroups;
            this.conceptionVisas = data.conceptionVisas;
            this.conceptions = data.conceptions;
            this.scrollToLastVisited();
        },
        goTo(bundleId, replace = false) {
            const destination = {
                name: 'followConceptionByBundle',
                params: {
                    projectId: this.$route.params.projectId,
                    bundleId,
                },
            };
            if (replace) {
                this.$router.replace(destination);
            } else {
                this.$router.push(destination);
            }
        },
        nextBundle() {
            this.goTo(this.bundles[this.bundleIndex + 1].id);
        },
        previousBundle() {
            this.goTo(this.bundles[this.bundleIndex - 1].id);
        },
        mergeLocationsPathsByFolders,
    },
    data() {
        return {
            lastVisitedId: null,
            pulse: false,
            loading: true,
            folders: [],
            emitters: [],
            emitterOptions: [],
            subscriptions: [],
            observations: [],
            now: new Date(),
            allowedFeatures: [],
            options: [],
            bundles: [],
            bundle: {},
            bundleIndex: 0,
            agenda: [],
            project: { folders: [] },
            locations: [],
            meeting: {},
            byBundleData: [],
            observationsGroups: [],
            conceptions: [],
            conceptionVisas: [],
            administrativeObservations: [],
            onToDoWorkObservations: [],
            otherObservations: [],
            onDoneWorkObservations: [],
            generalObservations: [],
            filterAsString: '',
            isObservationValidator: false,
            isObservationAdmin: false,
            me: null,
            sortKey: 'date',
            attendanceStats: { present: 0, excusedAbsent: 0, unexcusedAbsent: 0 },
        };
    },
};
</script>
