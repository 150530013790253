import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const amendments = {
    schema: {
        title: 'amendment schema',
        version: 0,
        description: 'describes a amendment',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: { type: 'string', final: true, maxLength: 40 },
            groupId: { type: ['string', 'null'], final: true },
            projectId: { type: 'string', final: true, maxLength: 40 },
            bundleId: { type: 'string', maxLength: 40 },

            code: { type: ['string', 'null'] },
            object: { type: ['string', 'null'] },
            type: { type: ['string', 'null'] },
            locationIds: {
                type: ['array', 'null'],
                items: {
                    type: 'string',
                },
            },
            amountHT: { type: ['number', 'string', 'null'] },
            quoteStatus: { type: ['string', 'null'] },
            amendmentStatus: { type: ['string', 'null'] },
            SOStatus: { type: ['string', 'null'] },
            emissionDate: { type: ['string', 'null'] },
            emissionDueDate: { type: ['string', 'null'] },

            deletedAt: { type: ['string', 'null'] },
            createdAt: { type: ['string', 'null'] },
            createdBy: { type: ['string', 'null'] },
            updatedAt: { type: ['string', 'null'] },
            updatedBy: { type: ['string', 'null'] },

            rootVersion: { type: ['string', 'null'] },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
