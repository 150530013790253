import { preInsert, preRemove, preSave } from '@/rxdb/defaultHooks';
import defaultReplication from '@/rxdb/defaultReplication';

export const amendmentAttachments = {
    schema: {
        title: 'amendmentAttachments schema',
        version: 0,
        description: 'describes a amendmentAttachment',
        primaryKey: 'id',
        type: 'object',
        properties: {
            id: { type: 'string', final: true, maxLength: 40 },
            name: { type: ['string', 'null'] },
            amendmentId: { type: ['string', 'null'] },
            url: { type: ['string', 'null'] },
            rotation: { type: ['number', 'null'] },
            createdBy: { type: ['string', 'null'], final: true },
            updatedAt: { type: ['string', 'null'] },
            deletedAt: { type: ['string', 'null'] },
            deletedBy: { type: ['string', 'null'] },
            createdAt: { type: ['string', 'null'] },
            updatedBy: { type: ['string', 'null'] },
            projectId: { type: ['string', 'null'] },
            rootVersion: { type: ['string', 'null'] },
        },
    },
    options: {
        onCollectionCreated(projectId, rxCollection) {
            rxCollection.replicationState = defaultReplication(rxCollection, projectId);
            rxCollection.preRemove(preRemove, false);
            rxCollection.preInsert(preInsert, false);
            rxCollection.preSave(preSave, false);
        },
    },
    migrationStrategies: {},
};
