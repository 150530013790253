import db from '@/rxdb/database';
import { map } from 'rxjs';

function mapAmendmentVisa(item) {
    if (item) {
        const plainItem = item.toMutableJSON();
        return {
            ...plainItem,
            emissionDueDate: plainItem.emissionDueDate ? new Date(plainItem.emissionDueDate) : null,
            emissionDate: plainItem.emissionDate ? new Date(plainItem.emissionDate) : null,
        };
    } else {
        return null;
    }
}

export function getAmendmentVisas(projectId, amendmentId) {
    return db
        .getProjectCollections(projectId)
        .amendmentVisas.find({ selector: { amendmentId } })
        .$.pipe(map((amendmentVisas) => amendmentVisas.map(mapAmendmentVisa)));
}
export function getAllAmendmentVisas(projectId) {
    return db
        .getProjectCollections(projectId)
        .amendmentVisas.find({})
        .$.pipe(map((amendmentVisas) => amendmentVisas.map(mapAmendmentVisa)));
}
export function getAmendmentVisa(projectId, amendmentVisaId) {
    return db
        .getProjectCollections(projectId)
        .amendmentVisas.findOne({ selector: { id: amendmentVisaId } })
        .$.pipe(map(mapAmendmentVisa));
}
export async function queryAmendmentVisa(projectId, amendmentVisaId) {
    return mapAmendmentVisa(
        await db
            .getProjectCollections(projectId)
            .amendmentVisas.findOne({ selector: { id: amendmentVisaId } })
            .exec(),
    );
}

export function removeAmendmentVisa(projectId, amendmentVisaId) {
    return db
        .getProjectCollections(projectId)
        .amendmentVisas.findOne({ selector: { id: amendmentVisaId } })
        .remove();
}

export async function updateAmendmentVisa(projectId, amendmentVisa) {
    const dbAmendment = await db
        .getProjectCollections(projectId)
        .amendmentVisas.findOne({ selector: { id: amendmentVisa.id } })
        .exec();

    const patch = { ...JSON.parse(JSON.stringify(amendmentVisa)) };
    return dbAmendment.atomicPatch(patch);
}

export async function createAmendmentVisa(projectId, amendmentVisa) {
    const result = await db.getProjectCollections(projectId).amendmentVisas.insert({
        ...amendmentVisa,
        projectId,
        emissionDueDate: amendmentVisa.emissionDueDate ? amendmentVisa.emissionDueDate.toISOString() : null,
    });
    return result.toJSON();
}

export async function bulkDelete(projectId, amendmentVisaIds) {
    return await db.getProjectCollections(projectId).amendmentVisas.bulkRemove(amendmentVisaIds);
}
export default { bulkDelete };
