<template>
    <div>
        <app-popup ref="popup" @close="onClose" :title="title" :show-header="true" popup-extra-class="left-50">
            <zone-observation-form
                :observationId="observationId"
                @openPhoto="goToFullScreenPhoto"
                @roomPicked="onRoomPicked"
                @deleted="close"
            ></zone-observation-form>
        </app-popup>
        <app-autocomplete
            @input="onTitleDefined"
            :options="titleOptions"
            :allowStringCriteria="true"
            :stringCriteriaPrefix="$t('observations.createPrefix')"
            ref="titlePopup"
            :filterStringFunction="observationFilterStringFunction"
        >
            <template v-slot:title>
                <app-separator>
                    <h2>{{ $t('observations.titleLabel') }}</h2>
                    <div class="flex flex-grow"></div>
                    <app-button
                        size="mini"
                        icon="icon-close"
                        aria-label="close popup"
                        @click="$refs.titlePopup.close()"
                    />
                </app-separator>
            </template>

            <template v-slot:item="{ item }">
                <span v-if="item._isStringCriteria">{{ item.content }}</span>
                <div class="flex p-1" v-else>
                    <app-clamp :html-content="item.title || item.name" class="font-bold" />
                    <div class="flex flex-col max-w-1/3" v-if="item.recipients && item.recipients.length === 1">
                        <div v-if="item.recipients">
                            <span class="mx-1 text-xs" v-if="item.recipients[0].reference">
                                #{{ item.recipients[0].reference }}
                            </span>
                            <span class="">{{ item.recipients[0].name }}</span>
                            <span class="text-gray-500 italic mr-2 truncate" v-if="item.recipients[0].company">
                                {{ item.recipients[0].company.name }}
                            </span>
                        </div>
                        <span v-else>{{ item.recipients }}</span>
                    </div>
                    <div class="flex flex-col max-w-1/3" v-if="item.recipients && item.recipients.length > 1">
                        <div>
                            <span class="mx-1 text-xs">
                                {{
                                    item.recipients
                                        .map((recipient) => (recipient ? '#' + recipient.reference : '???'))
                                        .join(' + ')
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </app-autocomplete>
        <app-autocomplete
            @input="onRecipientDefined"
            :options="bundles"
            ref="recipientPopup"
            :filterStringFunction="
                (bundle) => bundle.reference + ' ' + bundle.name + ' ' + (bundle.company ? bundle.company.name : '')
            "
        >
            <template v-slot:title>
                <app-separator>
                    <h2>{{ $t('observations.recipient') }}</h2>
                    <div class="flex flex-grow"></div>
                    <app-button
                        size="mini"
                        icon="icon-close"
                        aria-label="close popup"
                        @click="$refs.recipientPopup.close()"
                    />
                </app-separator>
            </template>

            <template v-slot:item="{ item }">
                <app-bundle class="p-1" :bundle="item" />
            </template>
        </app-autocomplete>
    </div>
</template>

<script>
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppMarker from '@/components/appMarker/AppMarker';
import AppPopup from '@/components/app-popup/AppPopup';
import AppFooter from '@/components/appFooter/AppFooter';
import AppLegend from '@/components/appLegend/AppLegend';
import AppFieldset from '@/components/appFieldset/AppFieldset';
import AppPicker from '@/components/appPicker/AppPicker';
import AppBundlePicker from '@/components/appBundlePicker';
import locationService from '@/services/location.service';
import AppInputText from '@/components/appInputText/AppInputText';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete';
import AppBundle from '@/components/app-bundle/appBundle';
import AppButton from '@/components/appButton/AppButton';
import AppUploadButton from '@/components/app-uploadButton/AppUploadButton';
import AppPhoto from '@/components/appPhoto/AppPhoto';
import AppLabel from '@/components/appLabel/AppLabel';
import AppSelect from '@/components/appSelect/AppSelect';
import ZoneObservationForm from '@/features/tours/ZoneObservationForm';
import { createObservation, getObservations } from '@/features/observations/observation.service';
import { applyDuration } from '@/services/duration.service';
import { queryProject } from '@/features/projects/projects.service';
import { getAgenda } from '@/features/planning/agenda/agenda.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import AppClamp from '@/components/appClamp/AppClamp';
import { getObservationAttachments } from '@/features/observations/observationAttachments.service';
import { sortBy, uniqBy } from '@/services/sanitize.service';

export default {
    components: {
        AppClamp,
        ZoneObservationForm,
        AppSelect,
        AppLabel,
        AppPhoto,
        AppUploadButton,
        AppButton,
        AppBundle,
        AppAutocomplete,
        AppInputText,
        AppBundlePicker,
        AppPicker,
        AppFieldset,
        AppLegend,
        AppFooter,
        AppPopup,
        AppMarker,
        AppSeparator,
    },
    props: {
        zoneId: String,
        supportId: String,
    },
    async created() {
        queryProject(this.$route.params.projectId).then((project) => {
            this.isEXEAllowed = project.projectFeatures.includes('project_EXE');
            this.isOPRAllowed = project.projectFeatures.includes('project_OPR');
            this.isOPLAllowed = project.projectFeatures.includes('project_OPL');
            this.isAPAAllowed = project.projectFeatures.includes('project_OPL');
            this.phase = project.phase;
            this.defaultReporterId = project.me.bundleIds[0];
        });
        this.subscriptions = [
            getAgenda(this.$route.params.projectId).subscribe((agenda) => {
                this.agenda = agenda;
            }),
            combineLatest([
                getObservations(this.$route.params.projectId),
                getBundleMap(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getObservationAttachments(this.$route.params.projectId),
            ]).subscribe(([observations, bundleMap, folders, attachments]) => {
                this.observations = observations.map((observation) => ({
                    ...observation,
                    recipients: observation.recipientIds.map((id) => bundleMap[id]),
                    attachments: attachments.filter((attachment) => attachment.observationId === observation.id),
                }));
                this.attachments = attachments;
                this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                this.locationOptions = locationService.buildLocationOptions(folders);
                this.zone = this.locationOptions.find(
                    (location) => location.id === (this.zoneId || this.$route.params.zoneId),
                );
                this.titleOptions = this.getObservationHistoryGroupedByUse(this.observations);
            }),
        ];
    },
    methods: {
        getObservationHistoryGroupedByUse(observations) {
            return uniqBy(observations, (obs) => obs?.title?.trim() + '-' + obs.recipientIds.join());
        },
        close() {
            this.$refs.popup.close();
        },
        goToFullScreenPhoto(attachment) {
            this.$router.push({
                name: 'zonePhotoTour',
                params: {
                    projectId: this.$route.params.projectId,
                    zoneId: this.zoneId || this.$route.params.zoneId,
                    observationId: attachment.observationId,
                    attachmentId: attachment.id,
                },
            });
        },
        observationFilterStringFunction(observation) {
            return observation.title;
        },
        edit(observation) {
            this.title =
                this.$t('observations.observation') + (observation.index ? ' #' + (observation.index + 1) : '');
            this.observationId = observation.id;
            this.$refs.popup.open();
        },
        defineDefaultPhase() {
            const phase = this.$route.params.phase || this.phase;
            if (phase === 'EXE' && this.isEXEAllowed) {
                return phase;
            } else if (phase === 'OPR' && this.isOPRAllowed) {
                return phase;
            } else if (phase === 'Receipt' && this.isOPRAllowed) {
                return phase;
            } else if (phase === 'OPL' && this.isOPLAllowed) {
                return phase;
            } else if (phase === 'Delivery' && this.isOPLAllowed) {
                return phase;
            } else if (phase === 'APA' && this.isAPAAllowed) {
                return phase;
            } else {
                if (this.isEXEAllowed) {
                    return 'EXE';
                } else if (this.isOPRAllowed) {
                    return 'OPR';
                } else if (this.isOPLAllowed) {
                    return 'OPL';
                } else if (this.isAPAAllowed) {
                    return 'APA';
                } else {
                    return null;
                }
            }
        },
        start(position) {
            if (position) {
                this.newObservation.footprint = {
                    x: position.x,
                    y: position.y,
                    page: position.page || 1,
                    type: 'marker',
                };
            } else {
                this.newObservation.footprint = null;
            }
            this.newObservation.reportedBy = this.defaultReporterId;
            this.newObservation.phase = this.defineDefaultPhase();
            this.newObservation.zone = this.zone;
            this.newObservation.roomId = localStorage.getItem('createObservationPopup_lastChosenRoom_' + this.zone.id);
            this.$refs.titlePopup.open();
        },
        async onTitleDefined(choice) {
            if (choice._isStringCriteria) {
                this.newObservation.title = choice.content;
                this.newObservation.recipientIds = [];
            } else {
                this.newObservation.title = choice.title;
                this.newObservation.recipientIds = choice.recipientIds;
            }
            if (this.newObservation.recipientIds.length === 0) {
                this.$refs.recipientPopup.open();
            } else {
                await this.createObservation();
                this.$refs.popup.open();
            }
        },
        async onRecipientDefined(bundle) {
            this.newObservation.recipientIds = [bundle.id];
            this.$refs.popup.open();
            await this.createObservation();
        },
        async createObservation() {
            const observationEntity = {
                title: this.newObservation.title,
                recipientIds: this.newObservation.recipientIds,
                phase: this.phase,
                reportedBy: this.defaultReporterId,
                dueDate: applyDuration(
                    new Date(),
                    7,
                    this.agenda,
                    this.newObservation.recipientIds.length === 1 ? this.newObservation.recipientIds[0] : null,
                ),
                zoneId: this.zoneId || this.$route.params.zoneId,
                roomId: this.newObservation.roomId || null,
                supportId: this.supportId || this.$route.params.supportId,
                footprint: this.newObservation.footprint,
                page: this.newObservation.page,
                type: 'onDoneWork',
            };
            const observation = await createObservation(this.$route.params.projectId, observationEntity);
            this.edit(observation);
        },
        onClose() {
            this.$emit('closed', this.observationId);
            this.observationId = null;
            this.title = this.$t('observations.newObservation');
        },
        onRoomPicked(observation) {
            localStorage.setItem('createObservationPopup_lastChosenRoom_' + observation.zoneId, observation.roomId);
        },
    },
    data() {
        return {
            agenda: [],
            observations: [],
            locationOptions: [],
            subscriptions: [],
            bundles: [],
            attachments: [],
            phase: null,
            defaultReporterId: null,
            isEXEAllowed: false,
            isOPRAllowed: false,
            isAPAAllowed: false,
            isOPLAllowed: false,
            title: this.$t('observations.newObservation'),
            newObservation: {},
            observationId: null,
            titleOptions: [],
            zone: {},
        };
    },
};
</script>
