<template>
    <div>
        <div class="flex justify-between flex-col-reverse sm:flex-row sm:items-center">
            <div class="flex sm:items-center flex-col sm:flex-row">
                <table class="mx-2">
                    <tr>
                        <th scope="row" class="text-right text-sm whitespace-nowrap">{{ $t('commons.version') }} :</th>
                        <td class="px-3">
                            <app-select
                                :label="$t('commons.version')"
                                :value="amendment.id"
                                class=""
                                :show-label="false"
                                :disabled="!amendment.id"
                                @change="onVersionSelected($event)"
                            >
                                <option
                                    :value="version.id"
                                    v-for="(version, index) in amendment.versions"
                                    :key="version.id"
                                >
                                    {{ amendment.versions.length - index }} ({{ version.createdAt | humanizeDate }})
                                </option>
                            </app-select>
                        </td>
                    </tr>
                </table>

                <div class="m-2 sm:my-0">
                    <app-button @click="onNewVersion" :disabled="!amendment.id">
                        {{ $t('commons.newVersion') }}
                    </app-button>
                </div>
            </div>
        </div>
        <ValidationObserver v-slot="{ dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('amendments.object')"
                        v-model="amendment.object"
                        class="col-span-2 md:col-span-1"
                        :required="true"
                        @blur="save()"
                        ref="objectInput"
                    />
                    <app-input-text
                        :label="$t('amendments.code')"
                        v-model="amendment.code"
                        class="col-span-2 md:col-span-1"
                        @blur="save()"
                    />
                    <app-select
                        :label="$t('amendments.type')"
                        v-model="amendment.type"
                        class="col-span-2"
                        @change="save()"
                    >
                        <option value="tma">{{ $t('amendments.types.tma') }}</option>
                        <option value="choice">{{ $t('amendments.types.choice') }}</option>
                        <option value="conceptionError">{{ $t('amendments.types.conceptionError') }}</option>
                        <option value="executionError">{{ $t('amendments.types.executionError') }}</option>
                        <option value="unexpected">{{ $t('amendments.types.unexpected') }}</option>
                        <option value="cie">{{ $t('amendments.types.cie') }}</option>
                        <option value="marketOption">{{ $t('amendments.types.marketOption') }}</option>
                        <option value="marketUpdate">{{ $t('amendments.types.marketUpdate') }}</option>
                        <option value="other">{{ $t('amendments.types.other') }}</option>
                    </app-select>
                    <app-bundle-picker
                        class="col-span-2"
                        v-model="amendment.bundle"
                        :options="bundles"
                        @input="onBundleChange"
                    ></app-bundle-picker>
                    <app-multi-picker
                        class="col-span-2"
                        v-model="amendment.locations"
                        :options="locations"
                        :show-label="true"
                        label-key="fullName"
                        @input="save()"
                        :label="$t('amendments.locations')"
                        :title="$t('amendments.chooseLocations')"
                    ></app-multi-picker>
                    <app-number-input
                        :label="$t('amendments.amountHT')"
                        v-model="amendment.amountHT"
                        class="col-span-2 md:col-span-1"
                        @blur="save()"
                    />
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-5" :label="$t('commons.dates')">
                    <app-date-input
                        class="col-span-2 md:col-span-1"
                        v-model="amendment.emissionDueDate"
                        :label="$t('amendments.emissionDueDate')"
                        @input="save()"
                    ></app-date-input>
                    <app-date-input
                        class="col-span-2 md:col-span-1"
                        v-model="amendment.emissionDate"
                        :label="$t('amendments.emissionDate')"
                        @input="save()"
                    />
                </app-fieldset>
                <app-save-on-leave :dirty="dirty" :saveFn="() => save(true)"></app-save-on-leave>

                <div class="col-span-2 mt-5">
                    <app-separator :label="$t('commons.status')" class="mt-5"></app-separator>
                    <table class="w-full border">
                        <tr class="border-b">
                            <td class="p-2 px-4 text-left">{{ $t('amendments.quoteStatusTitle') }}</td>
                            <td class="p-2 px-4 text-left">
                                <app-select
                                    :label="$t('amendments.quoteStatusTitle')"
                                    :show-label="false"
                                    v-model="amendment.quoteStatus"
                                    @input="save('quoteStatus', $event)"
                                >
                                    <option value="toEmit">{{ $t('amendments.quoteStatus.toEmit') }}</option>
                                    <option value="toAnalyse">{{ $t('amendments.quoteStatus.toAnalyse') }}</option>
                                    <option value="toNegotiate">{{ $t('amendments.quoteStatus.toNegotiate') }}</option>
                                    <option value="toValidateMOA">
                                        {{ $t('amendments.quoteStatus.toValidateMOA') }}
                                    </option>
                                    <option value="validated">{{ $t('amendments.quoteStatus.validated') }}</option>
                                    <option value="rejected">{{ $t('amendments.quoteStatus.rejected') }}</option>
                                    <option value="obsolete">{{ $t('amendments.quoteStatus.obsolete') }}</option>
                                    <option value="market">{{ $t('amendments.quoteStatus.market') }}</option>
                                    <option value="marketOption">
                                        {{ $t('amendments.quoteStatus.marketOption') }}
                                    </option>
                                    <option value="noImpact">{{ $t('amendments.quoteStatus.noImpact') }}</option>
                                </app-select>
                            </td>
                        </tr>
                        <tr class="border-b">
                            <td class="p-2 px-4 text-left">{{ $t('amendments.amendmentStatus') }}</td>
                            <td class="p-2 px-4 text-left">
                                <app-select
                                    :label="$t('amendments.amendmentStatus')"
                                    :show-label="false"
                                    v-model="amendment.amendmentStatus"
                                    @input="save('amendmentStatus', $event)"
                                >
                                    <option value="toEmit">{{ $t('amendments.status.toEmit') }}</option>
                                    <option value="toSignByBundle">{{ $t('amendments.status.toSignByBundle') }}</option>
                                    <option value="toSignByMOE">{{ $t('amendments.status.toSignByMOE') }}</option>
                                    <option value="toSignByMOA">{{ $t('amendments.status.toSignByMOA') }}</option>
                                    <option value="signed">{{ $t('amendments.status.signed') }}</option>
                                </app-select>
                            </td>
                        </tr>
                        <tr class="border-b">
                            <td class="p-2 px-4 text-left">{{ $t('amendments.SOStatus') }}</td>
                            <td class="p-2 px-4 text-left">
                                <app-select
                                    :label="$t('amendments.SOStatus')"
                                    :show-label="false"
                                    v-model="amendment.SOStatus"
                                    @input="save('SOStatus', $event)"
                                >
                                    <option value="toEmit">{{ $t('amendments.status.toEmit') }}</option>
                                    <option value="toSignByBundle">{{ $t('amendments.status.toSignByBundle') }}</option>
                                    <option value="toSignByMOE">{{ $t('amendments.status.toSignByMOE') }}</option>
                                    <option value="toSignByMOA">{{ $t('amendments.status.toSignByMOA') }}</option>
                                    <option value="signed">{{ $t('amendments.status.signed') }}</option>
                                </app-select>
                            </td>
                        </tr>
                    </table>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import AppLegend from '../../components/appLegend/AppLegend';
import { getMapById, sanitize } from '@/services/sanitize.service';
import AppSelect from '../../components/appSelect/AppSelect';
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppFooter from '../../components/appFooter/AppFooter';
import AppErrors from '../../components/appErrors/AppErrors';
import AppDateInput from '../../components/appDateInput/AppDateInput';
import AppPicker from '../../components/appPicker/AppPicker';
import AppButton from '../../components/appButton/AppButton';
import AppDurationInput from '../../components/appDurationInput/AppDurationInput';
import { createAmendment, getAmendment, updateAmendment } from './amendments.service';

import AppMultiPicker from '../../components/appMultiPicker/AppMultiPicker';
import AppBundlePicker from '../../components/appBundlePicker';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppTips from '@/components/app-tips/AppTips';
import AppAutocomplete from '@/components/app-autocomplete/AppAutocomplete';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppBundle from '@/components/app-bundle/appBundle';
import AppDurationLink from '@/components/appDurationLink/AppDurationLink';
import { combineLatest, map } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import locationService from '@/services/location.service';
import { createAmendmentVisa, getAmendmentVisas } from '@/features/amendments/amendmentVisas.service';
import { isMobile } from '@/state/state';
import AppLabel from '@/components/appLabel/AppLabel.vue';
import { getCompanies } from '@/features/companies/companies.service';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput.vue';
import AmendmentAttachments from '@/features/amendments/AmendmentAttachments.vue';

export default {
    components: {
        AmendmentAttachments,
        AppNumberInput,
        AppLabel,
        AppDurationLink,
        AppBundle,
        AppSeparator,
        AppAutocomplete,
        AppTips,
        AppSaveOnLeave,
        AppBundlePicker,
        AppMultiPicker,
        AppDurationInput,
        AppButton,
        AppPicker,
        AppDateInput,
        AppErrors,
        AppFooter,
        AppFieldset,
        AppInputText,
        AppSelect,
        AppLegend,
    },
    async created() {
        this.init();
        localStorage.setItem(
            'amendment.lastVisitedAmendmentId.' + this.$route.params.projectId,
            this.$route.params.amendmentId,
        );
    },
    watch: {
        $route: function (from, to) {
            if (from.name !== to.name || from.params.amendmentId !== to.params.amendmentId) {
                this.focusDone = false;
            }
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getBundles(this.$route.params.projectId),
                    getCompanies(this.$route.params.projectId),
                    getLocationsTree(this.$route.params.projectId).pipe(
                        map((folders) => locationService.buildLocationOptions(folders)),
                    ),
                    getAmendment(this.$route.params.projectId, this.$route.params.amendmentId),
                    getAmendmentVisas(this.$route.params.projectId, this.$route.params.amendmentId),
                ]).subscribe(([bundles, companies, locations, amendment, visas]) => {
                    this.locations = locations.filter((location) => location.type === 'location');
                    const locationMap = getMapById(locations);
                    const companyMap = getMapById(companies);
                    const mappedBundles = bundles.map((bundle) => ({
                        ...bundle,
                        company: companyMap[bundle.companyId],
                    }));
                    const bundleMap = getMapById(mappedBundles);
                    this.amendmentVisas = visas.map((visa) => ({ ...visa, emitter: bundleMap[visa.emitterId] }));
                    this.amendment = {
                        ...amendment,
                        bundle: mappedBundles.find((bundle) => bundle.id === amendment.bundleId),
                        locations: amendment.locationIds.map((id) => locationMap[id]),
                    };
                    this.bundles = mappedBundles;
                    this.selectedVersion = this.$route.params.amendmentId;
                    if (!this.focusDone && !isMobile && this.amendment.name === '') {
                        this.$nextTick(() => {
                            this.$refs.objectInput.focus();
                        });
                        this.focusDone = true;
                    }
                }),
            ];
        },
        onBundleChange() {
            this.save();
        },
        async onVersionSelected(amendmentId) {
            await this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    amendmentId,
                },
            });
        },
        async onNewVersion() {
            const amendmentEntity = {
                groupId: this.amendment.groupId,
                name: this.amendment.name,
                type: this.amendment.type,
                code: this.amendment.code,
                emissionDate: null,
                emissionDueDate: null,
                bundleId: this.amendment.bundle ? this.amendment.bundle.id : null,
                locationIds: this.amendment.locations.map((location) => location.id),
            };
            const result = await createAmendment(this.$route.params.projectId, amendmentEntity);
            await Promise.all(
                this.amendmentVisas
                    .filter((visa) => visa.emitterId)
                    .map((visa) => {
                        return createAmendmentVisa(this.$route.params.projectId, {
                            emitterId: visa.emitterId,
                            amendmentId: result.id,
                        });
                    }),
            );
            await this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    amendmentId: result.id,
                },
            });
        },
        sanitizeAmendment(amendment, toOmit = []) {
            return {
                ...sanitize(amendment, [
                    'bundle',
                    'locations',
                    'visas',
                    'documents',
                    'versions',
                    'conclusion',
                    'createdAt',
                    ...toOmit,
                ]),
                bundleId: amendment.bundle ? amendment.bundle.id : null,
                locationIds: amendment.locations.map((location) => location.id),
                emissionDueDate: amendment.emissionDueDate || null,
            };
        },
        async save() {
            if (this.savingFlag) {
                return;
            } else {
                this.savingFlag = true;
            }
            try {
                const amendmentEntity = this.sanitizeAmendment(this.amendment);
                await updateAmendment(this.$route.params.projectId, {
                    id: this.amendment.id,
                    ...amendmentEntity,
                });
                if (this.$refs.observer) {
                    this.$refs.observer.reset();
                }
            } finally {
                this.savingFlag = false;
            }
        },
        async createNew() {
            const result = await createAmendment(this.$route.params.projectId, {
                name: '',
            });
            await this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    amendmentId: result.id,
                },
            });
        },
    },
    data() {
        return {
            focusDone: false,
            subscriptions: [],
            savingFlag: false,
            amendmentReviewMargin: 0,
            bundles: [],
            bundlesToCopy: [],
            amendmentVisas: [],
            amendment: {
                name: '',
                locations: [],
                bundle: null,
                documents: [],
                versions: [],
                deliveryDuration: 0,
            },
            selectedVersion: null,
            project: {},
            locations: [],
        };
    },
};
</script>
