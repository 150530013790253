var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-separator',{staticClass:"mt-5",attrs:{"label":_vm.$t('preparations.visas')}}),_c('div',{staticClass:"grid grid-cols-2"},[_c('div',{staticClass:"my-5 col-span-2 md:col-span-1"},[(_vm.editable)?_c('app-multi-picker',{attrs:{"options":_vm.bundles,"variant":"button","show-label":false,"show-input":false,"label":_vm.$t('preparations.newVisa'),"title":_vm.$t('preparations.newVisa'),"filterStringFunction":(bundle) => bundle.reference + ' ' + bundle.name},on:{"check":_vm.onBundleCheck,"uncheck":_vm.onBundleUncheck},scopedSlots:_vm._u([{key:"option",fn:function({ option }){return [_c('app-bundle',{attrs:{"bundle":option}})]}}],null,false,1493590273),model:{value:(_vm.selectedBundles),callback:function ($$v) {_vm.selectedBundles=$$v},expression:"selectedBundles"}}):_vm._e()],1),_c('table',{staticClass:"m-5 col-span-2 md:col-span-1"},[_c('tr',[_c('th',{staticClass:"text-right text-sm whitespace-nowrap",attrs:{"scope":"row"}},[_vm._v("\n                    "+_vm._s(_vm.$t('preparations.conclusion'))+" :\n                ")]),_c('td',{staticClass:"px-3 whitespace-nowrap text-sm"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('div',{staticClass:"flex items-center"},[_c('div',[_c('app-preparation-visa-status',{attrs:{"visa":{ conclusion: _vm.conclusionValue, emissionDate: 1 },"preparationEmissionDate":_vm.preparation.emissionDate}})],1),_c('div',[_vm._v(_vm._s(this.$t('preparations.conclusionValues.' + _vm.conclusionValue)))])]),(
                                ['waitingForDocument', 'incompleteVisa', 'noConclusion'].indexOf(
                                    _vm.conclusionValue,
                                ) === -1
                            )?_c('app-button',{attrs:{"icon":"icon-email-download-outline","size":"mini"},on:{"click":function($event){return _vm.$refs.emailPopup.open()}}}):_vm._e()],1)])])]),_c('preparation-visas-mail-popup',{ref:"emailPopup",attrs:{"visas":_vm.visas,"preparation":_vm.preparation}})],1),_c('table',{staticClass:"w-full border"},[_c('thead',[_c('tr',{staticClass:"border-b"},[_c('th',{staticClass:"p-2 px-4 text-left",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('bundles.emitter')))]),_c('th',{staticClass:"p-2 px-4 text-left",attrs:{"scope":"col"}},[_vm._v("\n                    "+_vm._s(_vm.$t('preparations.visaConclusion'))+"\n                ")]),_c('td',{staticClass:"p-2"})])]),(_vm.visas.length === 0 && !_vm.loading)?_c('tr',[_c('td',{staticClass:"p-2 px-4 text-sm italic text-center text-gray-700",attrs:{"colspan":"8"}},[_vm._v("\n                "+_vm._s(_vm.$t('preparations.emptyVisa'))+"\n            ")])]):_vm._e(),_vm._l((_vm.visas),function(visa){return [_c('tr',{staticClass:"border-b hover:bg-gray-200 text-sm",attrs:{"v-key":visa.id},on:{"click":function($event){return _vm.editVisa(visa)}}},[_c('td',{staticClass:"p-2 px-4 hover:underline"},[_c('app-bundle',{attrs:{"bundle":visa.emitter}})],1),_c('td',{staticClass:"p-2 px-4"},[(visa.conclusion && visa.generationStartDate && !visa.generationEndDate)?_c('div',[_c('icon-rotate-right',{staticClass:"animate animate-spin"})],1):(
                            visa.conclusion &&
                            visa.generationSucceed &&
                            visa.generationStartDate &&
                            visa.generationEndDate
                        )?_c('div',[_c('app-file-link',{staticClass:"underline",attrs:{"url":visa.url,"showIcon":false}},[_c('div',{staticClass:"flex"},[_c('app-preparation-visa-status',{staticClass:"mx-2",attrs:{"visa":visa,"preparationEmissionDate":_vm.preparation.emissionDate}}),_vm._v("\n                                "+_vm._s(_vm.$t('preparations.conclusionValues.' + visa.conclusion))+"\n                            ")],1)])],1):_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('app-preparation-visa-status',{staticClass:"mx-2",attrs:{"visa":visa,"preparationEmissionDate":_vm.preparation.emissionDate}}),_vm._v("\n                            "+_vm._s(visa.conclusion
                                    ? _vm.$t('preparations.conclusionValues.' + visa.conclusion)
                                    : _vm.preparation.emissionDate
                                    ? _vm.$t('preparations.conclusionValues.incompleteVisa')
                                    : '')+"\n                        ")],1),(!visa.emissionDate && visa.emissionDueDate && !_vm.preparation.emissionDate)?_c('span',{staticClass:"ml-2"},[_vm._v("\n                            "+_vm._s(_vm._f("humanizeDate")(visa.emissionDueDate))+"\n                        ")]):_vm._e(),(_vm.preparation.emissionDate && !visa.emissionDate && visa.emissionDueDate)?_c('span',{staticClass:"ml-2"},[_vm._v("\n                            ("+_vm._s(_vm._f("humanizeDate")(visa.emissionDueDate))+")\n                        ")]):_vm._e()])]),_c('td',{staticClass:"p-2",staticStyle:{"width":"50px"}},[_c('div',{staticClass:"flex justify-end"},[(_vm.editable)?_c('app-button',{attrs:{"size":"mini","variant":"danger","aria-label":"remove visa","icon":"icon-trash-can-outline"},on:{"click":function($event){return _vm.removeVisa(visa)}}}):_vm._e()],1)])])]})],2),_c('preparation-visa-inline-legend',{staticClass:"mt-8"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }