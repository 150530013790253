<template>
    <div>
        <app-separator :label="$t('commons.status')" class="mt-5"></app-separator>
        <table class="w-full border">
            <tr class="border-b">
                <td class="p-2 px-4 text-left">{{ $t('amendments.quoteStatusTitle') }}</td>
                <td class="p-2 px-4 text-left">
                    <app-drop-down-button
                        :label="$t('amendments.quoteStatusTitle')"
                        :show-label="false"
                        v-model="amendment.quoteStatus"
                        @input="save('quoteStatus', $event)"
                    >
                        <option value="toEmit">{{ $t('amendments.quoteStatus.toEmit') }}</option>
                        <option value="toAnalyse">{{ $t('amendments.quoteStatus.toAnalyse') }}</option>
                        <option value="toNegotiate">{{ $t('amendments.quoteStatus.toNegotiate') }}</option>
                        <option value="toValidateMOA">{{ $t('amendments.quoteStatus.toValidateMOA') }}</option>
                        <option value="validated">{{ $t('amendments.quoteStatus.validated') }}</option>
                        <option value="rejected">{{ $t('amendments.quoteStatus.rejected') }}</option>
                        <option value="obsolete">{{ $t('amendments.quoteStatus.obsolete') }}</option>
                        <option value="market">{{ $t('amendments.quoteStatus.market') }}</option>
                        <option value="marketOption">{{ $t('amendments.quoteStatus.marketOption') }}</option>
                        <option value="noImpact">{{ $t('amendments.quoteStatus.noImpact') }}</option>
                    </app-drop-down-button>
                </td>
            </tr>
            <tr class="border-b">
                <td class="p-2 px-4 text-left">{{ $t('amendments.amendmentStatus') }}</td>
                <td class="p-2 px-4 text-left">
                    <app-drop-down-button
                        :label="$t('amendments.amendmentStatus')"
                        :show-label="false"
                        v-model="amendment.amendmentStatus"
                        @input="save('amendmentStatus', $event)"
                    >
                        <option value="toEmit">{{ $t('amendments.status.toEmit') }}</option>
                        <option value="toSignByBundle">{{ $t('amendments.status.toSignByBundle') }}</option>
                        <option value="toSignByMOE">{{ $t('amendments.status.toSignByMOE') }}</option>
                        <option value="toSignByMOA">{{ $t('amendments.status.toSignByMOA') }}</option>
                        <option value="signed">{{ $t('amendments.status.signed') }}</option>
                    </app-drop-down-button>
                </td>
            </tr>
            <tr class="border-b">
                <td class="p-2 px-4 text-left">{{ $t('amendments.SOStatus') }}</td>
                <td class="p-2 px-4 text-left">
                    <app-drop-down-button
                        :label="$t('amendments.SOStatus')"
                        :show-label="false"
                        v-model="amendment.SOStatus"
                        @input="save('SOStatus', $event)"
                    >
                        <option value="toEmit">{{ $t('amendments.status.toEmit') }}</option>
                        <option value="toSignByBundle">{{ $t('amendments.status.toSignByBundle') }}</option>
                        <option value="toSignByMOE">{{ $t('amendments.status.toSignByMOE') }}</option>
                        <option value="toSignByMOA">{{ $t('amendments.status.toSignByMOA') }}</option>
                        <option value="signed">{{ $t('amendments.status.signed') }}</option>
                    </app-drop-down-button>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { getAmendment, updateAmendment } from './amendments.service';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getAmendmentVisas } from '@/features/amendments/amendmentVisas.service';
import { getMapById } from '@/services/sanitize.service';
import { getAgenda } from '@/features/planning/agenda/agenda.service';
import { getAmendmentAttachments } from '@/features/amendments/amendmentAttachments.service';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton.vue';
import AppSeparator from '@/components/appSeparator/AppSeparator.vue';

export default {
    props: {
        editable: { type: Boolean, default: true },
    },
    components: {
        AppDropDownButton,
        AppSeparator,
    },
    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    computed: {
        bundles() {
            if (this.amendment.bundleId && this.bundleOptions) {
                return this.bundleOptions.filter((bundle) => bundle.id !== this.amendment.bundleId);
            } else {
                return this.bundleOptions;
            }
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getBundles(this.$route.params.projectId),
                    getAmendment(this.$route.params.projectId, this.$route.params.amendmentId),
                    getAmendmentVisas(this.$route.params.projectId, this.$route.params.amendmentId),
                    getAgenda(this.$route.params.projectId),
                    getAmendmentAttachments(this.$route.params.projectId, this.$route.params.amendmentId),
                ]).subscribe(([bundles, amendment, visas, agenda, attachments]) => {
                    const bundleMap = getMapById(bundles);
                    this.agenda = agenda;
                    this.bundleOptions = bundles;
                    this.amendment = amendment;
                    this.amendment.attachments = attachments;
                    this.visas = visas.map((visa) => ({ ...visa, emitter: bundleMap[visa.emitterId] }));
                    this.selectedVersion = this.$route.params.amendmentId;
                    this.selectedBundles = this.selectedBundles = this.visas
                        .map((visa) => visa.emitter)
                        .filter((a) => !!a);
                    this.loading = false;
                }),
            ];
        },
        async save(field, value) {
            await updateAmendment(this.$route.params.projectId, {
                id: this.amendment.id,
                [field]: value,
            });
        },
    },
    data() {
        return {
            interval: null,
            loading: true,
            amendment: {
                locations: [],
                bundle: null,
            },
            selectedBundles: [],
            bundleOptions: [],
            subscriptions: [],
            visas: [],
            agenda: [],
        };
    },
};
</script>
