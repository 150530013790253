<template>
    <main class="max-w-5xl m-5 mb-10 w-full">
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                    <app-input-text
                        :label="$t('commons.name')"
                        v-model="mailTemplate.name"
                        class="col-span-2 md:col-span-1"
                        :required="true"
                        :disabled="readOnly"
                        @blur="save()"
                    />
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-4" :label="$t('project.diffusion.mailTemplates.template')">
                    <app-multi-picker
                        v-model="mailTemplate.to"
                        :options="recipientsOptions"
                        :show-label="true"
                        :label="$t('project.diffusion.mailTemplates.to')"
                        :title="$t('project.diffusion.mailTemplates.to')"
                        :label-function="labelFunction"
                        @input="save"
                        class="col-span-2"
                        :disabled="readOnly"
                    >
                        <template v-slot:tip>
                            <app-tips>{{ $t('project.diffusion.mailTemplates.toTips') }}</app-tips>
                        </template>
                    </app-multi-picker>
                    <app-multi-picker
                        v-model="mailTemplate.cc"
                        :options="ccOptions"
                        :show-label="true"
                        :label="$t('project.diffusion.mailTemplates.cc')"
                        :title="$t('project.diffusion.mailTemplates.cc')"
                        :label-function="labelFunction"
                        @input="save"
                        class="col-span-2"
                        :disabled="readOnly"
                    >
                        <template v-slot:tip>
                            <app-tips>{{ $t('project.diffusion.mailTemplates.ccTips') }}</app-tips>
                        </template>
                    </app-multi-picker>
                    <app-text-editor
                        :label="$t('project.diffusion.mailTemplates.subject')"
                        v-model="mailTemplate.subject"
                        :disabled="readOnly"
                        @blur="save()"
                        class="col-span-2"
                        :inline="true"
                        ref="subject"
                        :showToolbar="false"
                    >
                        <template v-slot:tip>
                            <mail-template-tips
                                :type="mailTemplate.type"
                                :is-body="false"
                                @insertToken="insertTokenToSubject"
                                :disabled="readOnly"
                            ></mail-template-tips>
                        </template>
                    </app-text-editor>
                    <app-text-editor
                        :label="$t('project.diffusion.mailTemplates.body')"
                        v-model="mailTemplate.body"
                        :disabled="readOnly"
                        @blur="save()"
                        class="col-span-2"
                        ref="body"
                        :showToolbar="true"
                        end-point="documents?scope=mailTemplateBody"
                    >
                        <template v-slot:tip>
                            <mail-template-tips
                                :is-body="true"
                                :type="mailTemplate.type"
                                @insertToken="insertTokenToBody"
                                :disabled="readOnly"
                            ></mail-template-tips>
                        </template>
                    </app-text-editor>
                </app-fieldset>
            </form>
        </ValidationObserver>
        <div class="flex justify-start p-2">
            <app-button
                variant="primary"
                v-if="mailTemplate && mailTemplate.type === 'other'"
                icon="icon-email-download-outline"
                :label="$t('commons.downloadSimpleMail')"
                @click="download"
            ></app-button>
        </div>
        <app-quick-actions :options="quickActions" v-if="isAdmin" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { sortBy } from '@/services/sanitize.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import {
    createMailTemplate,
    getMailTemplate,
    updateMailTemplate,
} from '@/features/mailTemplates/mailTemplates.service';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppFieldset from '@/components/appFieldset/AppFieldset.vue';
import AppTextEditor from '@/components/appTextEditor/AppTextEditor.vue';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import MailTemplateTips from '@/features/mailTemplates/MailTemplateTips.vue';
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions.vue';
import { queryProject } from '@/features/projects/projects.service';
import { getEMLFile } from '@/features/emls/emls.service';

export default {
    components: {
        AppQuickActions,
        MailTemplateTips,
        AppButton,
        AppTips,
        AppTextEditor,
        AppFieldset,
        AppMultiPicker,
        AppSelect,
        AppInputText,
    },
    created() {
        updateBreadCrumbs({ mailTemplateName: '' });
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    computed: {
        ccOptions() {
            return this.recipientsOptions.map((group) => ({
                ...group,
                children: group.children.map((option) => ({
                    ...option,
                    disabled: !!this.mailTemplate.to.find((item) => item.id === option.id),
                })),
            }));
        },
    },
    methods: {
        buildRecipientOptions() {
            let dynamicContacts = [];
            if (this.mailTemplate.type === 'preparationVisa') {
                dynamicContacts = [
                    {
                        id: 'preparation.bundleId',
                    },
                    {
                        id: 'visa.emitterId',
                    },
                ];
            }
            if (this.mailTemplate.type === 'preparationVisas') {
                dynamicContacts = [
                    {
                        id: 'preparation.bundleId',
                    },
                    {
                        id: 'visas.emitterId',
                    },
                ];
            } else if (this.mailTemplate.type === 'planning') {
                dynamicContacts = [
                    {
                        id: 'planningExport.filter.bundles',
                    },
                ];
            }
            return [
                ...(dynamicContacts.length > 0
                    ? [
                          {
                              isGroup: true,
                              name: this.$t('project.diffusion.mailTemplates.tokens.title'),
                              id: this.$t('project.diffusion.mailTemplates.tokens.title'),
                              children: dynamicContacts.map((dynamicContact, index) => ({
                                  id: dynamicContact.id,
                                  name: dynamicContact.name,
                                  firstOfCriteriaType: index === 0,
                                  _isDynamicContact: true,
                              })),
                          },
                      ]
                    : []),
                {
                    isGroup: true,
                    name: this.$t('commons.bundleCriteriaType'),
                    id: this.$t('commons.bundleCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.reference ? bundle.reference + ' - ' + bundle.name : +bundle.name,
                        firstOfCriteriaType: index === 0,
                        _isBundleCriteria: true,
                    })),
                },
            ];
        },
        init() {
            queryProject(this.$route.params.projectId).then((project) => {
                this.isAdmin = project.me.allowedFeatures.includes('project_diffusion');
            });
            this.subscriptions = [
                combineLatest([
                    getBundleMap(this.$route.params.projectId),
                    getMailTemplate(this.$route.params.projectId, this.$route.params.mailTemplateId),
                ]).subscribe(([bundleMap, mailTemplate]) => {
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.mailTemplate = {
                        ...mailTemplate,
                        to: mailTemplate.to.map((contact) => ({ id: contact })),
                        cc: mailTemplate.cc.map((contact) => ({ id: contact })),
                        subject: !mailTemplate.subject
                            ? ''
                            : mailTemplate.subject.replace(
                                  /(NomReunion|DateReunion|TableauConvocations|ProchaineReunion|LienCR|NomRapport|LienRapport|NomPlanning|EmetteurVisa|NomDocument|LienVisa|ConclusionVisa)/gim,
                                  '<span class="template_keyword">$1</span>',
                              ),
                        body: !mailTemplate.body
                            ? ''
                            : mailTemplate.body.replace(
                                  /(NomReunion|DateReunion|TableauConvocations|ProchaineReunion|LienCR|NomRapport|LienRapport|NomPlanning|EmetteurVisa|NomDocument|LienVisa|ConclusionVisa|TableauDocumentsVises|TableauDocumentsVisas)/gim,
                                  '<span class="template_keyword">$1</span>',
                              ),
                    };
                    this.recipientsOptions = this.buildRecipientOptions();
                    this.readOnly = !mailTemplate.projectId;
                    updateBreadCrumbs({ mailTemplateName: mailTemplate.name });
                }),
            ];
        },
        insertTokenToSubject(token) {
            this.$refs.subject.insert(token);
        },
        insertTokenToBody(token) {
            this.$refs.body.insert(token);
        },
        getSanitizedEntity() {
            const allowedId = this.recipientsOptions.reduce(
                (acc, group) => [...acc, ...group.children.map((item) => item.id)],
                [],
            );
            return {
                name: this.mailTemplate.name,
                subject: this.$refs.subject
                    .getText()
                    .trim()
                    .replace(/[\n\t]/gm, ''),
                type: this.mailTemplate.type,
                body: this.mailTemplate.body.replace(
                    /<span class="template_keyword">(NomReunion|DateReunion|TableauConvocations|ProchaineReunion|LienCR|NomRapport|LienRapport|NomPlanning|EmetteurVisa|NomDocument|LienVisa|ConclusionVisa)<\/span>/gim,
                    '$1',
                ),
                to: this.mailTemplate.to
                    .filter((contact) => allowedId.includes(contact.id))
                    .map((contact) => contact.id),
                cc: this.mailTemplate.cc
                    .filter((contact) => allowedId.includes(contact.id))
                    .map((contact) => contact.id),
            };
        },
        async save() {
            if (this.readOnly) {
                return;
            }
            return updateMailTemplate(this.$route.params.projectId, {
                ...this.getSanitizedEntity(),
                id: this.$route.params.mailTemplateId,
            });
        },
        download() {
            getEMLFile(this.$route.params.projectId, {
                subject: this.mailTemplate.subject,
                body: this.mailTemplate.body,
                recipientIds: this.mailTemplate.to.map((item) => item.id),
                copyIds: this.mailTemplate.cc.map((item) => item.id),
                attachments: [],
            });
        },
        labelFunction(item) {
            return item.name || this.dynamicRecipientLabelsMap[item.id];
        },
    },
    data() {
        return {
            isAdmin: false,
            dynamicRecipientLabelsMap: {
                'preparation.bundleId': this.$t('project.diffusion.mailTemplates.tokens.preparationEmitter'),
                'visa.emitterId': this.$t('project.diffusion.mailTemplates.tokens.visaEmitter'),
                'visas.emitterId': this.$t('project.diffusion.mailTemplates.tokens.visasEmitters'),
                'planningExport.filter.bundles': this.$t('project.diffusion.mailTemplates.tokens.planningBundles'),
            },
            readOnly: true,
            subscriptions: [],
            recipientsOptions: [],
            bundles: [],
            quickActions: [
                {
                    name: this.$t('commons.duplicate'),
                    run: async () => {
                        const result = await createMailTemplate(this.$route.params.projectId, {
                            ...this.getSanitizedEntity(),
                            name: this.readOnly
                                ? this.$t('project.diffusion.mailTemplates.types.' + this.mailTemplate.type)
                                : this.mailTemplate.name + ' ' + this.$t('commons.newCopySuffixName'),
                        });
                        await this.$router.push({
                            name: 'mailTemplate',
                            params: {
                                projectId: this.$route.params.projectId,
                                mailTemplateId: result.id,
                            },
                        });
                    },
                },
            ],
            mailTemplate: {
                name: '',
                subject: '',
                body: '',
                type: 'meetingReport',
                to: [],
                cc: [],
            },
        };
    },
};
</script>
