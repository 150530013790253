<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start">
        <div class="w-full mb-4 flex flex-col sm:flex-row gap-2">
            <app-drop-down-button :placeholder="$t('commons.new')" @input="addBundle" v-if="!readOnly">
                <option value="company">{{ $t('bundles.newLabel.company') }}</option>
                <option value="MOA">{{ $t('bundles.newLabel.MOA') }}</option>
                <option value="AMOA">{{ $t('bundles.newLabel.AMOA') }}</option>
                <option value="MOE">{{ $t('bundles.newLabel.MOE') }}</option>
                <option value="BE">{{ $t('bundles.newLabel.BE') }}</option>
                <option value="distributor">{{ $t('bundles.newLabel.distributor') }}</option>
                <option value="SPS">{{ $t('bundles.newLabel.SPS') }}</option>
                <option value="CT">{{ $t('bundles.newLabel.CT') }}</option>
            </app-drop-down-button>
            <div class="flex-grow flex justify-center sm:justify-end">
                <table class="ml-4 text-xs" v-if="isMarketAmountAllowed">
                    <tbody>
                        <th scope="row" class="p-1">{{ $t('bundles.total') }}&nbsp;:</th>
                        <td class="p-1">{{ $n(total, 'currency') }}</td>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-end w-full">
            <app-button
                size="mini"
                icon="icon-file-download-outline"
                @click="downloadCSV()"
                :title="$t('bundles.downloadCSV')"
            />
        </div>
        <div class="flex w-full">
            <table class="flex-grow w-full table-fixed mb-10">
                <thead>
                    <tr>
                        <th style="width: 3em"></th>
                        <th class="w-full md:w-auto"></th>
                        <th class="hidden md:table-cell"></th>
                        <th class="w-full md:w-auto"></th>
                        <th v-if="isMobile" style="width: 7em"></th>
                        <template v-else>
                            <th style="width: 7em"></th>
                            <th></th>
                            <th style="width: 2em"></th>
                            <th style="width: 2em"></th>
                        </template>
                    </tr>
                </thead>
                <tr v-if="bundles.length === 0 && !loading" class="text-center italic text-gray-700 text-sm">
                    <td colspan="8">{{ $t('commons.emptyList') }}</td>
                </tr>
                <template v-for="group in bundleGroups">
                    <template v-if="group.children.length > 0">
                        <tr
                            class="bg-secondary font-bold text-sm text-white pl-2 p-1 w-full truncate whitespace-nowrap"
                        >
                            <td colspan="8">{{ $t('bundles.categories.' + group.id) }}</td>
                        </tr>
                        <draggable
                            class="w-full"
                            v-model="group.children"
                            :disabled="group.id === 'company'"
                            @change="onSorted(group.children)"
                            handle=".handle"
                            tag="tbody"
                        >
                            <tr
                                v-for="item in group.children"
                                :key="item.id"
                                class="border p-2 hover:bg-gray-200 cursor-pointer text-sm"
                                @click="navigateTo(item)"
                            >
                                <td class="overflow-hidden">
                                    <a :id="'uuid_' + item.id">
                                        <span v-if="item.reference" class="text-xs">
                                            <span v-if="item.type === 'company'">
                                                {{ item.reference.toString().padStart(3, '0') }}
                                            </span>
                                            <span v-else>{{ item.reference.toString() }}</span>
                                        </span>
                                    </a>
                                </td>
                                <td>
                                    <span class="font-bold mr-2">{{ item.name }}</span>
                                </td>
                                <td class="hidden md:table-cell">
                                    <span v-if="item.company" class="text-gray-600 mr-2">
                                        {{ item.company.name }}
                                    </span>
                                    <span v-else class="text-yellow-500 text-sm">
                                        {{ $t('bundles.unassigned') }}
                                    </span>
                                </td>
                                <td>
                                    <div v-if="item.responsible" class="text-gray-600 text-xs md:text-sm">
                                        {{ item.responsible | contact }}
                                    </div>
                                </td>

                                <td v-if="isMobile">
                                    <div class="flex gap-4 text-gray-700 md:hidden">
                                        <template v-if="item.responsible && item.responsible.phone">
                                            <a :href="'tel://' + item.responsible.phone" class="btn border p-1">
                                                <icon-phone width="16" height="16"></icon-phone>
                                            </a>
                                            <a
                                                :href="'sms://' + item.responsible.phone"
                                                class="btn border p-1"
                                                v-if="
                                                    item.responsible.phone.startsWith('06') ||
                                                    item.responsible.phone.startsWith('07')
                                                "
                                            >
                                                <icon-message width="16" height="16"></icon-message>
                                            </a>
                                            <a
                                                :href="'mailto://' + item.responsible.email"
                                                class="btn border p-1"
                                                v-if="item.responsible.email"
                                            >
                                                <icon-email-outline width="16" height="16"></icon-email-outline>
                                            </a>
                                        </template>
                                    </div>
                                </td>
                                <template v-else>
                                    <td class="hidden md:table-cell">
                                        <div v-if="item.responsible" class="text-gray-600">
                                            <app-phone
                                                class="mx-2"
                                                :number="item.responsible.phone"
                                                v-if="item.responsible.phone"
                                            ></app-phone>
                                        </div>
                                    </td>
                                    <td class="break-words">
                                        <div v-if="item.responsible" class="text-gray-600 hidden md:table-cell">
                                            <app-mail
                                                :email="item.responsible.email"
                                                v-if="item.responsible.email"
                                            ></app-mail>
                                        </div>
                                    </td>
                                    <td>
                                        <IconDrag
                                            class="text-gray-400 handle"
                                            v-if="!readOnly && group.id !== 'company'"
                                        />
                                    </td>
                                    <td>
                                        <app-button
                                            size="mini"
                                            @click="toggleTexAccess(item)"
                                            :class="item.hasTexAccess ? 'text-green-600' : 'text-red-700'"
                                            :icon="item.hasTexAccess ? 'icon-door-open' : 'icon-door-closed-lock'"
                                            :title="
                                                item.hasTexAccess
                                                    ? $t('bundles.hasTexAccess')
                                                    : $t('bundles.hasNoTexAccess')
                                            "
                                            v-if="!readOnly"
                                        />
                                    </td>
                                </template>
                            </tr>
                        </draggable>
                    </template>
                </template>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()" v-if="!readOnly"></app-quick-actions>
    </main>
</template>

<script>
import { createBundle, getBundles, initNewBundle, updateBundle } from './bundles.service';
import { sortBy } from '@/services/sanitize.service';
import AppButton from '@/components/appButton/AppButton';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import AppPhone from '@/components/appPhone/AppPhone';
import AppMail from '@/components/appMail/AppMail';
import AppSelect from '@/components/appSelect/AppSelect';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import draggable from 'vuedraggable';
import { queryProject } from '@/features/projects/projects.service';
import { getCompanies } from '@/features/companies/companies.service';
import { combineLatest } from 'rxjs';
import { getContacts } from '@/features/contacts/contacts.service';
import BundleCascadePopup from '@/features/bundles/BundleCascadePopup';
import { isMobile } from '@/state/state';
import IconPhone from '@/icons/IconPhone.vue';
import IconEmailOutline from '@/icons/IconEmailOutline.vue';

export default {
    components: {
        IconEmailOutline,
        IconPhone,
        BundleCascadePopup,
        AppDropDownButton,
        AppSelect,
        AppMail,
        AppPhone,
        AppQuickActions,
        AppButton,
        draggable,
    },
    created() {
        queryProject(this.$route.params.projectId).then((project) => (this.project = project));
        this.subscriptions = [
            combineLatest([
                getCompanies(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getContacts(this.$route.params.projectId),
            ]).subscribe(([companies, bundles, contacts]) => {
                this.total = bundles.reduce(
                    (acc, bundles) => (bundles.marketAmount ? bundles.marketAmount + acc : acc),
                    0,
                );
                this.bundles = sortBy(bundles, (bundle) =>
                    bundle.type === 'company'
                        ? bundle.reference
                        : (bundle.index + '').padStart(3, '0') + bundle.reference,
                ).map((bundle) => ({
                    ...bundle,
                    responsible: bundle.responsibleId
                        ? contacts.find((contact) => contact.id === bundle.responsibleId)
                        : null,
                    company: bundle.companyId ? companies.find((company) => company.id === bundle.companyId) : null,
                }));
                this.bundleGroups = [
                    { id: 'MOA', isGroup: true, children: this.bundles.filter((bundle) => bundle.type === 'MOA') },
                    { id: 'AMOA', isGroup: true, children: this.bundles.filter((bundle) => bundle.type === 'AMOA') },
                    { id: 'MOE', isGroup: true, children: this.bundles.filter((bundle) => bundle.category === 'MOE') },
                    {
                        id: 'generalContractor',
                        isGroup: true,
                        children: this.bundles.filter((bundle) => bundle.category === 'generalContractor'),
                    },
                    {
                        id: 'distributor',
                        isGroup: true,
                        children: this.bundles.filter((bundle) => bundle.category === 'distributor'),
                    },
                    {
                        id: 'company',
                        isGroup: true,
                        children: this.bundles.filter((bundle) => bundle.type === 'company'),
                    },
                ];
                this.loading = false;
            }),
        ];
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_bundles');
            this.isMarketAmountAllowed = project.me.allowedFeatures.includes('project_bundles_markerAmount');
        });
    },
    methods: {
        async addBundle(category) {
            if (category === '') {
                return;
            }
            const result = await createBundle(
                this.$route.params.projectId,
                initNewBundle(category, this.bundles, (key) => this.$t(key)),
            );
            if (result) {
                await this.$router.push({
                    name: 'bundle',
                    params: { projectId: this.$route.params.projectId, bundleId: result.id },
                });
            }
        },
        async toggleTexAccess(bundle) {
            if (!this.readOnly) {
                return updateBundle(this.$route.params.projectId, {
                    hasTexAccess: !bundle.hasTexAccess,
                    id: bundle.id,
                });
            }
        },
        navigateTo: function (event) {
            this.$router.push({
                name: 'bundle',
                params: { projectId: this.$route.params.projectId, bundleId: event.id },
            });
        },
        onSorted: async function (array) {
            await Promise.all(
                array.map((bundle, index) => updateBundle(this.$route.params.projectId, { index, id: bundle.id })),
            );
        },
        downloadCSV: function () {
            const header =
                'First Name,Last Name,Display Name,E-mail Address,Business Phone,Job Title,Organization,Notes\n';
            this.downloadAsFile(
                header +
                    this.bundles
                        .filter((bundle) => bundle.responsible)
                        .map((bundle) =>
                            [
                                bundle.responsible.firstName || '',
                                bundle.responsible.lastName || '',
                                (bundle.responsible.firstName || '') + ' ' + bundle.responsible.lastName || '',
                                bundle.responsible.email || '',
                                bundle.responsible.phone && !bundle.responsible.phone.startsWith('0')
                                    ? '0' + bundle.responsible.phone
                                    : bundle.responsible.phone || '',
                                bundle.responsible.role || '',
                                bundle.company?.name || '',
                                `Intervenant sur le project ${this.project.name}`,
                            ]
                                .map((entry) => '"' + entry.replaceAll(',', ' ') + '"')
                                .join(),
                        )
                        .join('\n'),
            );
        },
        async downloadAsFile(content) {
            const a = document.createElement('a');
            a.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content));
            a.download = `contacts_${this.project.code}.csv`;
            a.dataset.downloadurl = ['text/csv', a.download, a.href].join(':');
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () {
                URL.revokeObjectURL(a.href);
            }, 1500);
        },
    },
    data() {
        return {
            isMobile: isMobile,
            total: 0,
            project: {},
            subscriptions: [],
            loading: true,
            readOnly: true,
            isMarketAmountAllowed: false,
            bundles: [],
            bundleGroups: [],
            quickActions: [
                {
                    name: this.$t('bundles.newLabel.company'),
                    run: () => {
                        this.addBundle('company');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.MOA'),
                    run: () => {
                        this.addBundle('MOA');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.AMOA'),
                    run: () => {
                        this.addBundle('AMOA');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.MOE'),
                    run: () => {
                        this.addBundle('MOE');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.BE'),
                    run: () => {
                        this.addBundle('BE');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.SPS'),
                    run: () => {
                        this.addBundle('SPS');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.CT'),
                    run: () => {
                        this.addBundle('CT');
                    },
                },
                {
                    name: this.$t('bundles.newLabel.distributor'),
                    run: () => {
                        this.addBundle('distributor');
                    },
                },
            ],
        };
    },
};
</script>
