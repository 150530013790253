<template>
    <app-mail-popup ref="popup">
        <div>
            <app-select
                :value="selectedMailTemplateId"
                @change="onSelectedMailTemplateChanged"
                :label="$t('project.diffusion.chooseMailTemplateLabel')"
            >
                <option :value="mailTemplate.id" v-for="mailTemplate in mailTemplates">{{ mailTemplate.name }}</option>
            </app-select>
        </div>
    </app-mail-popup>
</template>

<script>
import AppMailPopup from '@/features/planningExports/AppMailPopup.vue';
import { getMailTemplates } from '@/features/mailTemplates/mailTemplates.service';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { getLastMailTemplateId, saveLastMailTemplateId } from '@/services/mailPopupService';
import sanitizeFileName from 'sanitize-filename';

export default {
    props: {
        visa: { type: Object },
        preparation: { type: Object },
        preparationAttachments: { type: Array },
    },
    components: {
        AppSelect,
        AppMailPopup,
    },
    async created() {
        this.subscriptions = [
            getMailTemplates(this.$route.params.projectId).subscribe((mailTemplates) => {
                this.mailTemplates = mailTemplates.filter((mailTemplate) => mailTemplate.type === 'preparationVisa');
                this.init();
            }),
        ];
    },
    watch: {
        visa() {
            this.init();
        },
    },
    methods: {
        init() {
            if (this.mailTemplates && this.visa?.emitter && this.preparation) {
                this.onSelectedMailTemplateChanged(getLastMailTemplateId(this.cacheKey, this.mailTemplates));
                this.visaPdfName = `visa_${this.visa.emitter.reference || ''}_${sanitizeFileName(
                    this.preparation.code || this.preparation.name,
                )}_${(this.preparation.index + '').padStart(2, '0')}.pdf`;
            }
        },
        replaceTokens(string) {
            if (!string) {
                return string;
            }
            return string
                .replaceAll('EmetteurVisa', this.visa.emitter.label)
                .replaceAll('NomDocument', this.preparation.name)
                .replaceAll('LienVisa', `<a href="https://app.tex.moe${this.visa.url}">${this.visaPdfName}</a>`)
                .replaceAll('ConclusionVisa', this.$t('preparations.conclusionValues.' + this.visa.conclusion))
                .replaceAll(
                    'TableauDocumentsVises',
                    `<table style="border: black solid 1px; margin: 0.1em;  border-collapse: collapse;">
                      <tr>
                         <th style="border: black solid 1px; padding: 0.1em">Documents visés</th>
                      </tr>
                    ${this.preparationAttachments
                        .map(
                            (attachment) =>
                                `<tr><td style="border: black solid 1px; padding: 0.2em"><a
                                   href="https://app.tex.moe${attachment.url}"
                                   download="${attachment.name}"
                                   class="underline">${attachment.name}</a></td></tr>`,
                        )
                        .join('')}
                    </table>`,
                );
        },
        replaceRecipientTokens(recipientIds = []) {
            return recipientIds.map((bundleId) =>
                bundleId === 'preparation.bundleId'
                    ? this.preparation.bundleId
                    : bundleId === 'visa.emitterId'
                    ? this.visa.emitterId
                    : bundleId,
            );
        },
        onSelectedMailTemplateChanged(mailTemplateId) {
            this.selectedMailTemplateId = mailTemplateId;
            saveLastMailTemplateId(this.cacheKey, mailTemplateId);
            this.applyMailTemplate(this.mailTemplates.find((mailTemplate) => mailTemplate.id === mailTemplateId));
        },
        applyMailTemplate(template) {
            this.$refs.popup.updateMail({
                subject: this.replaceTokens(template.subject),
                body: this.replaceTokens(template.body),
                recipientIds: this.replaceRecipientTokens(template.to),
                copyIds: this.replaceRecipientTokens(template.cc),
                attachments: [
                    {
                        fileName: this.visaPdfName,
                        url: this.visa.url,
                    },
                ],
            });
        },
        open() {
            this.$refs.popup.open({});
        },
    },
    data() {
        return {
            cacheKey: 'preparationVisa.lastMailTemplate.' + this.$route.params.projectId,
            mailTemplates: [],
            subscriptions: [],
            selectedMailTemplateId: null,
            visaPdfName: '',
        };
    },
};
</script>
