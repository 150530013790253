import { BehaviorSubject, combineLatest } from 'rxjs';
import { getObservationsForGroupDashboard, sortObservations } from '@/features/observations/observation.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getPreparations, mapPreparationStatus } from '@/features/preparations/preparations.service';
import { getCertificates } from '@/features/certificates/certificates.service';
import { getReceptions } from '@/features/receptions/receptions.service';
import { getAutoControls } from '@/features/autoControls/autoControls.service';
import { getDirectories } from '@/features/services/directories.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getPlannedTasks, isPendingTask } from '@/features/tasks/plannedTasks.service';
import { buildTree, getMeetings } from '@/features/meetings/meetings.service';
import { getMapById, sortBy, uniqBy } from '@/services/sanitize.service';
import locationService from '@/services/location.service';
import { humanizeDate } from '@/filters/dateFilter';
import { endOfDay } from 'date-fns';
import { getObservationAttachments } from '@/features/observations/observationAttachments.service';

const fromDate = endOfDay(new Date());
function mapPreparationVisaStatus(preparation, bundleId, i18nFn) {
    const visa = preparation.visas.find((visa) => visa.emitterId === bundleId);
    const isVised = !!visa.emissionDate;
    const isValidated = isVised && visa.conclusion === 'approved';
    const isRejected = isVised && visa.conclusion === 'rejected';
    const hasObservations = isVised && visa.conclusion === 'approvedWithComments';
    const isLate = visa.emissionDueDate && !isVised && endOfDay(new Date(visa.emissionDueDate)) < fromDate;
    let label;
    if (isValidated) {
        label = i18nFn('preparations.approvedOn') + ' ' + humanizeDate(visa.emissionDate);
    } else if (isRejected) {
        label = i18nFn('preparations.rejectedOn') + ' ' + humanizeDate(visa.emissionDate);
    } else if (hasObservations) {
        label = i18nFn('preparations.approvedWithCommentsOn') + ' ' + humanizeDate(visa.emissionDate);
    } else {
        label = i18nFn('commons.expectedOn') + ' ' + humanizeDate(visa.emissionDueDate);
    }
    return {
        ...preparation,
        isVised,
        isLate,
        label,
        visa,
        visaEmissionDueDate: visa.emissionDueDate,
        visaEmissionDate: visa.emissionDate,
    };
}
function mapStatus(item, i18nFn) {
    const isEmitted = !!item.emissionDate;
    const isLate = !isEmitted && endOfDay(item.emissionDueDate) < fromDate;
    let label = isEmitted
        ? i18nFn('commons.emittedOn') + ' ' + humanizeDate(item.emissionDate)
        : i18nFn('commons.expectedOn') + ' ' + humanizeDate(item.emissionDueDate);
    return {
        ...item,
        isEmitted,
        isLate,
        label,
    };
}
export function mapObservationStatus(observation, previousMeetingDate, i18nFn) {
    const isResolved = !!observation.resolvedAt;
    const isValidated = !!observation.validatedAt;
    const isLate = !isResolved && observation.dueDate && endOfDay(observation.dueDate) < fromDate;
    const isNew = previousMeetingDate ? previousMeetingDate < observation.createdAt : false;
    const hasChanged = previousMeetingDate ? previousMeetingDate < observation.updatedAt : false;
    let label = isResolved
        ? i18nFn('project.editions.meetingReports.resolvedOn') + ' ' + humanizeDate(observation.resolvedAt)
        : observation.dueDate
        ? i18nFn('commons.expectedOn') + ' ' + humanizeDate(observation.dueDate)
        : i18nFn('commons.asReminder');
    return {
        ...observation,
        isResolved,
        isValidated,
        isLate,
        label,
        isNew,
        hasChanged,
    };
}
export function getByBundleData(projectId) {
    return combineLatest([
        getObservationsForGroupDashboard(projectId, null),
        getObservationAttachments(projectId),
        getLocationsTree(projectId),
        getPreparations(projectId, 'exe'),
        getPreparations(projectId, 'con'),
        getCertificates(projectId),
        getReceptions(projectId),
        getAutoControls(projectId),
        getDirectories(projectId),
        getCalendar(projectId),
        getPlannedTasks(projectId, new BehaviorSubject(new Date())),
        getBundles(projectId),
        getMeetings(projectId),
    ]);
}
export function getByBundleDataForConception(projectId) {
    return combineLatest([
        getObservationsForGroupDashboard(projectId, ['CON']),
        getObservationAttachments(projectId),
        getLocationsTree(projectId),
        new BehaviorSubject([]),
        getPreparations(projectId, 'con'),
        new BehaviorSubject([]),
        new BehaviorSubject([]),
        new BehaviorSubject([]),
        new BehaviorSubject([]),
        new BehaviorSubject([]),
        new BehaviorSubject([]),
        getBundles(projectId),
        getMeetings(projectId),
    ]);
}
function preparationsToVisa(preparations, bundleId, bundleMap, locationMap, i18nFn) {
    return sortBy(
        uniqBy(preparations, 'groupId')
            .filter(
                (preparation) =>
                    !!preparation.emissionDate &&
                    !!preparation.visas.find((visa) => visa.emitterId === bundleId && !visa.emissionDate),
            )
            .map((preparation) => mapPreparationVisaStatus(preparation, bundleId, i18nFn))
            .map((visa) => ({
                ...visa,
                bundle: visa.bundleId ? bundleMap[visa.bundleId] : null,
                locations: visa.locationIds.map((id) => locationMap[id]).filter((a) => !!a),
            })),
        'visaEmissionDueDate',
    );
}
function mapPreparations(preparations, bundleId, locationMap, i18nFn) {
    return sortBy(
        uniqBy(preparations, 'groupId')
            .filter((preparation) => preparation.bundleId === bundleId && !preparation.emissionDate)
            .map((preparation) => ({
                ...mapPreparationStatus(i18nFn, preparation, fromDate, '-'),
                locations: preparation.locationIds.map((id) => locationMap[id]).filter((a) => !!a),
            })),
        'emissionDueDate',
    );
}
export function getByBundlePreparedDate(
    i18nFn,
    meetingId,
    bundleId,
    startDate,
    endDate,
    [
        observations,
        observationAttachments,
        folders,
        preparations,
        conceptions,
        certificates,
        receptions,
        autoControls,
        directories,
        agenda,
        tasks,
        bundles,
        meetings,
    ],
    showPreparations,
    showPreparationVisas,
    showConceptions,
    showConceptionVisas,
    showCONObservations,
    showEXEObservations,
    showOPRObservations,
    showReceiptObservations,
    showOPLObservations,
    showDeliveryObservations,
    showAPAObservations,
    showCertificates,
    showReceptions,
    showAutoControls,
    showPrivate,
    sortKey,
    emitterIds,
) {
    const attendanceStats = { present: 0, excusedAbsent: 0, unexcusedAbsent: 0, unknown: 0 };
    const meeting = meetingId ? meetings.find((meeting) => meeting.id === meetingId) : null;
    const previousMeeting = meetingId ? meetings[meetings.findIndex((meeting) => meeting === meetingId) - 1] : null;
    const refDate = new Date();
    for (const meeting of meetings.filter((meeting) => meeting.date < refDate)) {
        const convocation = meeting.convocations.find((convocation) => convocation.bundleId === bundleId);
        if (convocation) {
            attendanceStats[convocation.attendance]++;
        }
    }

    const locationMap = getMapById(locationService.buildLocationOptions(folders));

    const bundleMap = getMapById(bundles);

    const preparationVisas = preparationsToVisa(preparations, bundleId, bundleMap, locationMap, i18nFn);
    const conceptionVisas = preparationsToVisa(conceptions, bundleId, bundleMap, locationMap, i18nFn);
    preparations = mapPreparations(preparations, bundleId, locationMap, i18nFn);
    conceptions = mapPreparations(conceptions, bundleId, locationMap, i18nFn);

    receptions = sortBy(
        uniqBy(receptions, 'groupId')
            .filter((reception) => reception.bundleId === bundleId && !reception.emissionDate)
            .map((item) => ({ ...mapStatus(item, i18nFn), locations: item.locationIds.map((id) => locationMap[id]) })),
        'emissionDueDate',
    );

    autoControls = sortBy(
        autoControls
            .filter((autoControl) => autoControl.bundleId === bundleId && !autoControl.emissionDate)
            .map((item) => ({ ...mapStatus(item, i18nFn), locations: item.locationIds.map((id) => locationMap[id]) })),
        'emissionDueDate',
    );

    certificates = sortBy(
        certificates
            .filter(
                (certificate) =>
                    certificate.emitterId === bundleId && !certificate.emissionDate && !certificate.obsoleteAt,
            )
            .filter((certificate) => emitterIds.length === 0 || emitterIds.includes(certificate.reportedBy))
            .map((item) => ({
                ...mapStatus(item, i18nFn),
                locations: item.locationIds.map((id) => locationMap[id]),
            })),
        'emissionDueDate',
    );
    const relevantObservations = observations
        .filter((observation) => {
            return (
                (emitterIds.length === 0 || emitterIds.includes(observation.reportedBy)) &&
                observation.recipientIds.find((recipientId) => recipientId === bundleId) &&
                (!observation.resolvedAt || !observation.validatedAt) &&
                (observation.type !== 'private' || showPrivate) &&
                !observation.obsoleteAt &&
                ((observation.phase === 'CON' && showCONObservations) ||
                    ((observation.phase === 'EXE' ||
                        observation.phase === 'reception' ||
                        observation.phase === 'autoControl') &&
                        showEXEObservations) ||
                    (observation.phase === 'OPR' && showOPRObservations) ||
                    (observation.phase === 'Receipt' && showReceiptObservations) ||
                    (observation.phase === 'OPL' && showOPLObservations) ||
                    (observation.phase === 'Delivery' && showDeliveryObservations) ||
                    (observation.phase === 'APA' && showAPAObservations))
            );
        })
        .map((observation) => ({
            ...mapObservationStatus(observation, previousMeeting?.date, i18nFn),
            zone: locationMap[observation.zoneId],
            room: locationMap[observation.roomId],
            attachments: observationAttachments.filter((attachment) => attachment.observationId === observation.id),
        }));

    const administrativeObservations = sortObservations(
        relevantObservations.filter((observation) => observation.type === 'administrative'),
    );
    const onTodoWorkObservations = sortObservations(
        relevantObservations.filter((observation) => observation.type === 'onTodoWork'),
    );
    const otherObservations = sortObservations(
        relevantObservations.filter((observation) => observation.type === 'other'),
    );
    const privateObservations = sortObservations(
        relevantObservations.filter((observation) => observation.type === 'private'),
    );
    const onDoneWorkObservations = sortObservations(
        relevantObservations.filter(
            (observation) =>
                observation.type === 'onDoneWork' ||
                observation.type === 'reception' ||
                observation.type === 'autoControl',
        ),
    );
    const observationsGroups = [
        {
            label: i18nFn('observations.pluralTypes.private'),
            type: 'private',
            observations: privateObservations,
        },
        {
            label: i18nFn('observations.pluralTypes.administrative'),
            type: 'administrative',
            observations: administrativeObservations,
        },
        {
            label: i18nFn('observations.pluralTypes.onDoneWork'),
            type: 'onDoneWork',
            observations: onDoneWorkObservations,
        },
        {
            label: i18nFn('observations.pluralTypes.onTodoWork'),
            type: 'onTodoWork',
            observations: onTodoWorkObservations,
        },
        {
            label: i18nFn('observations.pluralTypes.other'),
            type: 'other',
            observations: otherObservations,
        },
    ];

    const hasItems = !!(
        (showPreparations ? preparations.length : 0) +
        (showConceptions ? conceptions.length : 0) +
        (showReceptions ? receptions.length : 0) +
        (showAutoControls ? autoControls.length : 0) +
        (showCertificates ? certificates.length : 0) +
        (showPreparationVisas ? preparationVisas.length : 0) +
        (showConceptionVisas ? conceptionVisas.length : 0) +
        administrativeObservations.length +
        onTodoWorkObservations.length +
        otherObservations.length +
        onDoneWorkObservations.length +
        privateObservations.length
    );
    const taskTree = buildTree(
        folders,
        bundles,
        directories,
        tasks.filter((task) => {
            return task.service.bundleId === bundleId && isPendingTask(task, startDate, endDate);
        }),
        agenda,
        fromDate,
        sortKey,
    );

    return {
        tasks,
        agenda,
        meeting,
        attendanceStats,
        hasItems,
        taskTree,
        observationsGroups,
        certificates,
        autoControls,
        receptions,
        preparationVisas,
        preparations,
        conceptionVisas,
        conceptions,
    };
}
