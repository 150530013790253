<template>
    <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-4">
            <span class="text-lg">{{ amendment.code }}</span>
            <span class="text-xl font-bold">{{ amendment.name }}</span>
        </div>
        <div class="shadow-md border p-4">
            <table>
                <tr>
                    <th role="rowheader" class="p-2 text-right">{{ $t('commons.version') }} :</th>
                    <td class="p-2">
                        <div class="flex gap-2">
                            <app-select
                                :label="$t('commons.version')"
                                :value="amendment.id"
                                class=""
                                :show-label="false"
                                :disabled="!amendment.id"
                                @change="onVersionSelected($event)"
                            >
                                <option
                                    :value="version.id"
                                    v-for="(version, index) in amendment.versions"
                                    :key="version.id"
                                >
                                    {{ amendment.versions.length - index }} ({{ version.createdAt | humanizeDate }})
                                </option>
                            </app-select>
                            <app-button @click="onNewVersion" :disabled="!amendment.id" v-if="isEmitter">
                                {{ $t('commons.newVersion') }}
                            </app-button>
                        </div>
                    </td>
                </tr>
                <tr v-if="amendment.type">
                    <th role="rowheader" class="p-2 text-right">{{ $t('amendments.type') }} :</th>
                    <td class="p-2">{{ $t('amendments.types.' + amendment.type) }}</td>
                </tr>
                <tr v-if="amendment.subPhase">
                    <th role="rowheader" class="p-2 text-right">{{ $t('commons.phase') }} :</th>
                    <td class="p-2">{{ $t('amendments.subPhases.' + amendment.subPhase) }}</td>
                </tr>
                <tr v-if="amendment.emissionDueDate">
                    <th role="rowheader" class="p-2 text-right">{{ $t('amendments.emissionDueDate') }} :</th>
                    <td class="p-2">{{ amendment.emissionDueDate | humanizeDate }}</td>
                </tr>
                <tr v-if="amendment.emissionDate">
                    <th role="rowheader" class="p-2 text-right">{{ $t('amendments.emissionDate') }} :</th>
                    <td class="p-2">{{ amendment.emissionDate | humanizeDate }}</td>
                </tr>
                <tr v-if="amendment.bundle">
                    <th role="rowheader" class="p-2 text-right">{{ $t('bundles.emitter') }} :</th>
                    <td class="p-2"><app-bundle :bundle="amendment.bundle" /></td>
                </tr>
                <tr v-if="amendment.locations">
                    <th role="rowheader" class="p-2 text-right align-top">{{ $t('amendments.locations') }} :</th>
                    <td class="p-2 text-sm">
                        <div v-for="location in amendment.locations">
                            {{ location.fullName }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import AppSelect from '../../components/appSelect/AppSelect';
import { createAmendment, getAmendment } from './amendments.service';
import AppBundle from '@/components/app-bundle/appBundle';
import { updateBreadCrumbs } from '@/state/state';
import { getLocationsTree } from '@/features/locations/locations.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import locationService from '@/services/location.service';
import AppButton from '@/components/appButton/AppButton.vue';
import { createAmendmentVisa, getAmendmentVisas } from '@/features/amendments/amendmentVisas.service';

export default {
    components: {
        AppButton,
        AppBundle,
        AppSelect,
    },
    props: { isEmitter: { type: Boolean, default: false } },
    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getLocationsTree(this.$route.params.projectId),
                    getBundleMap(this.$route.params.projectId),
                    getAmendment(this.$route.params.projectId, this.$route.params.amendmentId),
                    getAmendmentVisas(this.$route.params.projectId, this.$route.params.amendmentId),
                ]).subscribe(([folders, bundleMap, amendment, visas]) => {
                    const locationMap = locationService.getLocationMap(folders);
                    this.amendmentVisas = visas;
                    this.amendment = {
                        ...amendment,
                        bundle: bundleMap[amendment.bundleId],
                        locations: amendment.locationIds.map((id) => locationMap[id]),
                    };
                    this.selectedVersion = this.amendment.id;
                    updateBreadCrumbs({
                        amendmentName: amendment.name,
                    });
                }),
            ];
        },
        onVersionSelected(amendmentId) {
            return this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    amendmentId,
                },
            });
        },
        async onNewVersion() {
            const amendmentEntity = {
                groupId: this.amendment.groupId,
                name: this.amendment.name,
                type: this.amendment.type,
                code: this.amendment.code,
                deliveryDuration: this.amendment.deliveryDuration,
                emissionDate: null,
                emissionDueDate: null,
                bundleId: this.amendment.bundle ? this.amendment.bundle.id : null,
                locationIds: this.amendment.locations.map((location) => location.id),
                phase: this.amendment.phase,
            };
            const result = await createAmendment(this.$route.params.projectId, amendmentEntity);
            await Promise.all(
                this.amendmentVisas
                    .filter((visa) => visa.emitterId)
                    .map((visa) => {
                        return createAmendmentVisa(this.$route.params.projectId, {
                            emitterId: visa.emitterId,
                            amendmentId: result.id,
                        });
                    }),
            );
            await this.$router.push({
                name: this.$route.name,
                params: {
                    projectId: this.$route.params.projectId,
                    amendmentId: result.id,
                },
            });
        },
    },
    data() {
        return {
            amendmentVisas: [],
            subscriptions: [],
            selectedVersion: null,
            amendment: {},
        };
    },
};
</script>
