<template>
    <div>
        <app-separator :label="$t('preparations.visas')" class="mt-5"></app-separator>
        <div class="grid grid-cols-2">
            <div class="my-5 col-span-2 md:col-span-1">
                <app-multi-picker
                    v-if="editable"
                    v-model="selectedBundles"
                    @check="onBundleCheck"
                    @uncheck="onBundleUncheck"
                    :options="bundles"
                    variant="button"
                    :show-label="false"
                    :show-input="false"
                    :label="$t('preparations.newVisa')"
                    :title="$t('preparations.newVisa')"
                    :filterStringFunction="(bundle) => bundle.reference + ' ' + bundle.name"
                >
                    <template v-slot:option="{ option }">
                        <app-bundle :bundle="option" />
                    </template>
                </app-multi-picker>
            </div>
            <table class="m-5 col-span-2 md:col-span-1">
                <tr>
                    <th scope="row" class="text-right text-sm whitespace-nowrap">
                        {{ $t('preparations.conclusion') }} :
                    </th>
                    <td class="px-3 whitespace-nowrap text-sm">
                        <div class="flex items-center gap-2">
                            <div class="flex items-center">
                                <div>
                                    <app-preparation-visa-status
                                        :visa="{ conclusion: conclusionValue, emissionDate: 1 }"
                                        :preparationEmissionDate="preparation.emissionDate"
                                    />
                                </div>
                                <div>{{ this.$t('preparations.conclusionValues.' + conclusionValue) }}</div>
                            </div>
                            <app-button
                                @click="$refs.emailPopup.open()"
                                icon="icon-email-download-outline"
                                size="mini"
                                v-if="
                                    ['waitingForDocument', 'incompleteVisa', 'noConclusion'].indexOf(
                                        conclusionValue,
                                    ) === -1
                                "
                            ></app-button>
                        </div>
                    </td>
                </tr>
            </table>
            <preparation-visas-mail-popup
                ref="emailPopup"
                :visas="visas"
                :preparation="preparation"
            ></preparation-visas-mail-popup>
        </div>

        <table class="w-full border">
            <thead>
                <tr class="border-b">
                    <th scope="col" class="p-2 px-4 text-left">{{ $t('bundles.emitter') }}</th>
                    <th scope="col" class="p-2 px-4 text-left">
                        {{ $t('preparations.visaConclusion') }}
                    </th>
                    <td class="p-2"></td>
                </tr>
            </thead>

            <tr v-if="visas.length === 0 && !loading">
                <td colspan="8" class="p-2 px-4 text-sm italic text-center text-gray-700">
                    {{ $t('preparations.emptyVisa') }}
                </td>
            </tr>
            <template v-for="visa in visas">
                <tr :v-key="visa.id" class="border-b hover:bg-gray-200 text-sm" @click="editVisa(visa)">
                    <td class="p-2 px-4 hover:underline">
                        <app-bundle :bundle="visa.emitter"></app-bundle>
                    </td>
                    <td class="p-2 px-4">
                        <div v-if="visa.conclusion && visa.generationStartDate && !visa.generationEndDate">
                            <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                        </div>
                        <div
                            v-else-if="
                                visa.conclusion &&
                                visa.generationSucceed &&
                                visa.generationStartDate &&
                                visa.generationEndDate
                            "
                        >
                            <app-file-link :url="visa.url" class="underline" :showIcon="false">
                                <div class="flex">
                                    <app-preparation-visa-status
                                        class="mx-2"
                                        :visa="visa"
                                        :preparationEmissionDate="preparation.emissionDate"
                                    ></app-preparation-visa-status>
                                    {{ $t('preparations.conclusionValues.' + visa.conclusion) }}
                                </div>
                            </app-file-link>
                        </div>
                        <div v-else class="flex">
                            <div class="flex">
                                <app-preparation-visa-status
                                    class="mx-2"
                                    :visa="visa"
                                    :preparationEmissionDate="preparation.emissionDate"
                                ></app-preparation-visa-status>
                                {{
                                    visa.conclusion
                                        ? $t('preparations.conclusionValues.' + visa.conclusion)
                                        : preparation.emissionDate
                                        ? $t('preparations.conclusionValues.incompleteVisa')
                                        : ''
                                }}
                            </div>
                            <span
                                v-if="!visa.emissionDate && visa.emissionDueDate && !preparation.emissionDate"
                                class="ml-2"
                            >
                                {{ visa.emissionDueDate | humanizeDate }}
                            </span>
                            <span
                                v-if="preparation.emissionDate && !visa.emissionDate && visa.emissionDueDate"
                                class="ml-2"
                            >
                                ({{ visa.emissionDueDate | humanizeDate }})
                            </span>
                        </div>
                    </td>
                    <td class="p-2" style="width: 50px">
                        <div class="flex justify-end">
                            <app-button
                                size="mini"
                                @click="removeVisa(visa)"
                                variant="danger"
                                aria-label="remove visa"
                                icon="icon-trash-can-outline"
                                v-if="editable"
                            />
                        </div>
                    </td>
                </tr>
            </template>
        </table>
        <preparation-visa-inline-legend class="mt-8"></preparation-visa-inline-legend>
    </div>
</template>

<script>
import { confirm } from '../dialogs/dialogs.service';
import { conclusionResult, getPreparation } from './preparations.service';
import { humanizeDate } from '@/filters/dateFilter';
import AppBundle from '@/components/app-bundle/appBundle';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import IconRotateRight from '@/icons/IconRotateRight';
import AppSeparator from '@/components/appSeparator/AppSeparator';
import AppButton from '@/components/appButton/AppButton';
import PreparationVisaInlineLegend from '@/features/preparations/PreparationVisaInlineLegend';
import AppPreparationVisaStatus from '@/components/appPreparationVisaStatus/AppPreparationVisaStatus';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import {
    getPreparationVisas,
    createPreparationVisa,
    removePreparationVisa,
} from '@/features/preparations/preparationVisas.service';
import { getMapById } from '@/services/sanitize.service';
import { queryObservationsForRelatedContent } from '@/features/observations/observation.service';
import { error } from '@/features/toasts/toats.service';
import { applyDelay } from '@/services/duration.service';
import { getAgenda } from '@/features/planning/agenda/agenda.service';
import PreparationVisaMailPopup from '@/features/preparations/PreparationVisaMailPopup.vue';
import PreparationVisasMailPopup from '@/features/preparations/PreparationVisasMailPopup.vue';
import { getPreparationAttachments } from '@/features/preparations/preparationAttachments.service';

export default {
    props: {
        editable: { type: Boolean, default: true },
    },
    components: {
        PreparationVisasMailPopup,
        PreparationVisaMailPopup,
        AppMultiPicker,
        AppPreparationVisaStatus,
        PreparationVisaInlineLegend,
        IconRotateRight,
        AppBundle,
        AppButton,
        AppFileLink,
        AppSeparator,
    },
    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    computed: {
        bundles() {
            if (this.preparation.bundleId && this.bundleOptions) {
                return this.bundleOptions.filter((bundle) => bundle.id !== this.preparation.bundleId);
            } else {
                return this.bundleOptions;
            }
        },
        conclusionValue() {
            return conclusionResult(this.preparation, this.visas);
        },
        conclusionDate() {
            const conclusion = conclusionResult(this.preparation, this.visas);
            if (conclusion === 'incompleteVisa' || conclusion === 'noConclusion') {
                return this.$t('preparations.conclusionValues.' + conclusion);
            } else {
                return humanizeDate(
                    this.visas
                        .filter((visa) => visa.conclusion)
                        .map((visa) => visa.emissionDate)
                        .sort()
                        .pop(),
                );
            }
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getBundles(this.$route.params.projectId),
                    getPreparation(this.$route.params.projectId, this.$route.params.preparationId),
                    getPreparationVisas(this.$route.params.projectId, this.$route.params.preparationId),
                    getAgenda(this.$route.params.projectId),
                    getPreparationAttachments(this.$route.params.projectId, this.$route.params.preparationId),
                ]).subscribe(([bundles, preparation, visas, agenda, attachments]) => {
                    const bundleMap = getMapById(bundles);
                    this.agenda = agenda;
                    this.bundleOptions = bundles;
                    this.preparation = preparation;
                    this.preparation.attachments = attachments;
                    this.visas = visas.map((visa) => ({ ...visa, emitter: bundleMap[visa.emitterId] }));
                    this.selectedVersion = this.$route.params.preparationId;
                    this.selectedBundles = this.selectedBundles = this.visas
                        .map((visa) => visa.emitter)
                        .filter((a) => !!a);
                    this.loading = false;
                }),
            ];
        },
        onBundleCheck(bundles) {
            bundles.map((bundle) => this.createVisa(bundle));
        },
        onBundleUncheck(bundles) {
            bundles.map(async (bundle) => {
                const visaToRemove = this.visas.find((visa) => visa.emitterId === bundle.id);
                const observations = await queryObservationsForRelatedContent(
                    this.$route.params.projectId,
                    visaToRemove.id,
                );
                if (!visaToRemove.conclusion && observations.length === 0) {
                    removePreparationVisa(this.$route.params.projectId, visaToRemove.id);
                } else {
                    if (observations.length > 0) {
                        error(this.$t('preparations.canNoteRemoveVisaWithObservation'));
                    } else if (visaToRemove.conclusion) {
                        error(this.$t('preparations.canNoteRemoveVisaWithConclusion'));
                    }
                    // revert uncheck
                    this.$nextTick(() => {
                        this.selectedBundles = [...this.selectedBundles, bundle];
                    });
                }
            });
        },
        editVisa(visa) {
            this.$router.push({
                name: this.preparation.phase === 'exe' ? 'preparationVisa' : 'conceptionVisa',
                params: {
                    ...this.$route.params,
                    preparationVisaId: visa.id,
                },
            });
        },
        async removeVisa(visa) {
            if (await confirm(this.$t('preparations.confirmVisaMessage'))) {
                return removePreparationVisa(this.$route.params.projectId, visa.id);
            }
        },
        createVisa(emitter) {
            return createPreparationVisa(this.$route.params.projectId, {
                preparationId: this.$route.params.preparationId,
                emitterId: emitter.id,
                emissionDueDate: this.preparation.emissionDate
                    ? applyDelay(this.preparation.emissionDate, emitter.visaDelay, this.agenda, emitter.id)
                    : null,
                emissionDate: null,
                conclusion: null,
            });
        },
    },
    data() {
        return {
            interval: null,
            loading: true,
            preparation: {
                locations: [],
                bundle: null,
            },
            selectedBundles: [],
            bundleOptions: [],
            subscriptions: [],
            visas: [],
            agenda: [],
        };
    },
};
</script>
