<template>
    <div>
        <app-separator
            :label="$t('amendments.attachments')"
            icon="icon-file-document-outline"
            class="my-5 col-span-4"
        ></app-separator>
        <div class="flex">
            <app-multiple-upload-button
                ref="fileInput"
                icon="icon-paperclip"
                :label="$t('commons.newAttachment')"
                :end-point="`/api/projects/${$route.params.projectId}/amendments/${$route.params.amendmentId}/attachments`"
                :name="$t('commons.newAttachment')"
                v-if="isAdmin || allowNewAttachment"
                class="text-sm offline:hidden"
            />
        </div>
        <table class="w-full my-5">
            <tr v-if="attachments.length === 0">
                <td class="p-2 px-4 text-sm italic text-center text-gray-700">
                    <span>{{ $t('commons.emptyList') }}</span>
                </td>
            </tr>
            <template v-for="attachment in attachments">
                <tr :v-key="attachment.id" class="border-b hover:bg-gray-200">
                    <td class="p-2" style="width: 30px">
                        <a
                            :href="attachment.url"
                            :download="attachment.name"
                            aria-label="download attachment"
                            target="_blank"
                        >
                            <IconDownload width="16" heigth="16" />
                        </a>
                    </td>
                    <td class="p-2 text-left">
                        <a
                            :href="attachment.url"
                            :download="attachment.name"
                            class="text-sm"
                            aria-label="download attachment"
                            target="_blank"
                        >
                            {{ attachment.name }}
                        </a>
                    </td>
                    <td class="p-2" style="width: 30px" v-if="isAdmin">
                        <app-button
                            size="mini"
                            variant="danger"
                            aria-label="remove attachment"
                            icon="icon-trash-can-outline"
                            @click="removeAttachment(attachment)"
                        />
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import AppSeparator from '../../components/appSeparator/AppSeparator';
import AppButton from '../../components/appButton/AppButton';
import { confirm } from '../dialogs/dialogs.service';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import { getAmendmentAttachments, removeAmendmentAttachment } from '@/features/amendments/amendmentAttachments.service';

export default {
    props: {
        isAdmin: { type: Boolean, default: false },
        allowNewAttachment: { type: Boolean, default: false },
        me: { type: Object },
    },
    components: {
        AppMultipleUploadButton,
        AppButton,
        AppSeparator,
    },

    async created() {
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    data() {
        return {
            subscriptions: [],
            attachments: [],
        };
    },
    methods: {
        init() {
            this.subscriptions = [
                getAmendmentAttachments(this.$route.params.projectId, this.$route.params.amendmentId).subscribe(
                    (attachments) => {
                        this.attachments = attachments;
                    },
                ),
            ];
        },
        async removeAttachment(attachment) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                await removeAmendmentAttachment(this.$route.params.projectId, attachment.id);
            }
        },
        newAttachment() {
            this.$refs.fileInput.click();
        },
    },
};
</script>
